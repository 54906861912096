import React, { useEffect, useState } from 'react';
import { projectFirestore } from '../../../firebase/config';
import styles from './LocalServices.module.css';

const LocalServices = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            const response = projectFirestore.collection('jobPosts');
            const data = await response.where('jobCategory', '==', 'Personal-Local').get();
            setJobs(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };

        fetchJobs();
    }, []);

    const handleCardClick = (jobId) => {
        // Implement navigation or modal display logic for job details
        console.log("Clicked Job ID:", jobId);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Local Services</h1>
            {jobs.map(job => (
                <div key={job.id} className={styles.jobCard} onClick={() => handleCardClick(job.id)}>
                    <h2 className={styles.jobTitle}>{job.title}</h2>
                    <p className={styles.jobDescription}>{job.description}</p>
                    <p className={styles.jobDetails}>Location: {job.location}</p>
                    <p className={styles.jobDetails}>Salary: {job.salaryRange}</p>
                </div>
            ))}
        </div>
    );
};

export default LocalServices;
