import React from 'react';
import IndividualCustomerProfile from '../../Profile/IndividualCustomerProfile';

const IndividualCustomerDashboard = ({ user }) => {
  return (
    <IndividualCustomerProfile user={user} />
    // Only include role-specific content here
  );
};

export default IndividualCustomerDashboard;
