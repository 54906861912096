import React from 'react'
import Select from 'react-select'
import { useState } from 'react'

import './TestReactSelect.css'

const categories = [
    { value: 'development', label: 'Development' },
    { value: 'design', label: 'Design' },
    { value: 'sales', label: 'Sales' },
    { value: 'marketing', label: 'Marketing' },
  ]

const TestReactSelect = () => {
    const [category, SetCategory] = useState('')
    console.log("assigned category",category)

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("Within handleSubmit", category)
    }
  return (
    <div className='create-form'>
    <h2 className='page-title'>Test for React Select</h2>
    <form onSubmit={handleSubmit}>
    <label>
        <span>Project Category</span>
        <Select 
            onChange={option => SetCategory(option)}
            options={categories}
        />
    </label>
    </form>
    </div>
    
    
  )
}

export default TestReactSelect