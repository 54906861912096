import React, { useState, useEffect } from 'react';
import styles from './PropertyInputForm.module.css';
import { projectFirestore, projectStorage, timestamp } from '../../../firebase/config';
import { africanCountries } from '../../../constants/countries';
import AgentList from '../Home/AgentList';

const PropertyInputForm = () => {
  const [propertyImages, setPropertyImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [formData, setFormData] = useState({
    propertyType: '',
    propertyName: '',
    propertyAddress: '',
    city: '',
    neighborhood: '',
    postcode: '',
    price: '',
    description: '',
    propertyImage: null,
    longitude: '',
    latitude: '',
    country: '',
    agentId: ''
  });

  const handlePropertyImageChange = (event) => {
    console.log('propertyImages:', propertyImages);
    setPropertyImages([...event.target.files]);
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   if (name === 'country') {
  //     setSelectedAgent('');
  //   }
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   if (name === 'country') {
  //     setSelectedAgent('');
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       agentId: ''
  //     }));
  //   }
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log('formData:', formData);

    if (name === 'country') {
      setSelectedAgent('');
      setFormData(prevState => ({ ...prevState, agentId: '' })); // Clear the agentId field when the country is changed
    }
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };
  
  
  

  useEffect(() => {
    console.log('selectedAgent:', selectedAgent);
    console.log('agents:', agents);

    const unsubscribe = projectFirestore
      .collection('agents')
      .where('country', '==', formData.country) // filter by selected country
      .orderBy('name')
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setAgents(data);
        console.log('Agents fetched:', data); // console log to check if agents are being fetched correctly
      });
  
    return unsubscribe;
  }, [formData.country]);
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGeolocation = () => {
    setErrorMessage(null); // Clear previous error messages

    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log('Geolocation fetched:', position);
        setFormData({
          ...formData,
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        });
      },
      (error) => {
        console.error('Geolocation error:', error);
        setErrorMessage('Failed to fetch your location. Please try again or enter manually.');
      },
      { timeout: 30000 } // 30 seconds timeout
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('formData:', formData);
    console.log('agents:', agents);

  
    try {
      const selectedAgentObj = agents.find((agent) => agent.id === formData.agentId);
      if (!selectedAgentObj) {
        throw new Error('Please select an agent.');
      }
      if (selectedAgentObj.country !== formData.country) {
        throw new Error('Selected agent is not from the selected country.');
      }
  
      // Upload propertyImages to Firebase Storage
      const propertyImageUrls = await Promise.all(
        propertyImages.map(async (file) => {
          const storageRef = projectStorage.ref(`propertyImages/${file.name}`);
          await storageRef.put(file);
          return await storageRef.getDownloadURL();
        })
      );
  
      // Add a new document to the "properties" collection
      const docRef = await projectFirestore.collection('properties').add({
        ...formData,
        propertyImage: propertyImageUrls,
        createdAt: timestamp()
      });
  
      console.log('Document written with ID: ', docRef.id);
  
      // Clear the form data after a successful submission
      setFormData({
        propertyType: '',
        propertyName: '',
        propertyAddress: '',
        city: '',
        neighborhood: '',
        postcode: '',
        price: '',
        description: '',
        propertyImage: null,
        longitude: '',
        latitude: '',
        country: '',
        agentId: ''
      });
      setPropertyImages([]);
  
      // Redirect or display a success message
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };
  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <h2>Add Property</h2>
      <label className={styles.formRow}>
        Country
        <select
          name="country"
          value={formData.country}
          onChange={handleChange}
          required
          className={styles.selectField}
        >
          {africanCountries.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
      </label>
      <label className={styles.formRow}>
        Agent
        <select
          name="agentId"
          value={formData.agentId}
          onChange={handleChange}
          required
          className={styles.selectField}
        >
          <option value="">Select an agent</option>
          {agents
            .filter((agent) => agent.country === formData.country)
            .map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.name}
              </option>
            ))}
        </select>
      </label>
      <label className={styles.formRow}>
        Property Type
        <select name="propertyType" value={formData.propertyType} onChange={handleChange} className={styles.selectField}>
          <option value="Sale">Sale</option>
          <option value="Rent">Rent</option>
          <option value="Buy">Buy</option>
          <option value="New developments">New Developments</option>
          <option value="Commercial">Commercial</option>
          <option value="Industrial">Industrial</option>
          <option value="Agricultural">Agricultural</option>
      </select>

      </label>
      <label className={styles.formRow}>
        Property Name
        <input
          type="text"
          name="propertyName"
          value={formData.propertyName}
          onChange={handleChange}
          required
          className={styles.inputField}
        />
      </label>
      <label className={styles.formRow}>
        Property Address
        <input
          type="text"
          name="propertyAddress"
          value={formData.propertyAddress}
          onChange={handleChange}
          required
          className={styles.inputField}
        />
      </label>
      <label className={styles.formRow}>
        City
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          required
          className={styles.inputField}
        />
      </label>
      <label className={styles.formRow}>
        Neighborhood
        <input
          type="text"
          name="neighborhood"
          value={formData.neighborhood}
          onChange={handleChange}
          required
          className={styles.inputField}
        />
      </label>
      <label className={styles.formRow}>
        Postcode
        <input
          type="text"
          name="postcode"
          value={formData.postcode}
          onChange={handleChange}
          required
          className={styles.inputField}
        />
      </label>
      <label className={styles.formRow}>
        Price
        <input
          type="number"
          name="price"
          value={formData.price}
          onChange={handleChange}
          required
          className={styles.inputField}
        />
      </label>
      <label className={styles.formRow}>
       Description
       <textarea
         name="description"
         value={formData.description}
         onChange={handleChange}
         required
         className={styles.inputField}
       ></textarea>
     </label>
     <label className={styles.formRow}>
       Property Image(s)
       <input
           type="file"
           name="propertyImage"
           onChange={handlePropertyImageChange}
           accept="image/*"
           multiple
           className={styles.inputField}
       />
     </label>


     <label htmlFor="longitude">Longitude:</label>
     <input
       type="text"
       name="longitude"
       id="longitude"
       value={formData.longitude}
       onChange={handleChange}
       className={styles.inputField}
     />
     <label htmlFor="latitude">Latitude:</label>
     <input
       type="text"
       name="latitude"
       id="latitude"
       value={formData.latitude}
       onChange={handleChange}
       className={styles.inputField}
     />
     <button type="button" onClick={handleGeolocation} className={styles.button}>
       Get Current Location
     </button>
     {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
     <button type="submit" className={styles.submitButton}>
       Submit
     </button>
   </form>
 );
};


export default PropertyInputForm;
