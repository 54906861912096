import { useState, useEffect } from 'react';
import { projectStorage, projectFirestore, timestamp } from '../firebase/config';
import { useAuthContext } from './useAuthContext';

const useStorage = (file, folderName = "bookImages" ) => {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);
    const { user } = useAuthContext()
    

    useEffect(() => {
        if (file) {
            // reference where file should be saved
            //const storageRef = projectStorage.ref(file.name);
            //const storageRef = projectStorage.ref().child(`${folderName}/${file.name}`);
            const storageRef = projectStorage.ref(`${folderName}/${file.name}`);

            //const storageRef = projectStorage.ref().child(folderName).child(file.name);

            const collectionRef = projectFirestore.collection('pictures');
            console.log("folder name", folderName)

            storageRef.put(file).on('state_changed', (snap) => {
                let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                setProgress(percentage);
            }, (err) => {
                setError(err);
            }, async () => {
                const url = await storageRef.getDownloadURL();
                const createdAt = timestamp();
                const uid = user.uid
                
                await collectionRef.add({ url, createdAt, uid });
                setUrl(url);
            });
        }
    }, [file, user]);

    return { progress, url, error };
}

export default useStorage;
