import React, { createContext, useReducer, useEffect } from 'react';
import { projectAuth, projectFirestore } from '../firebase/config';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  //console.log('Dispatched action:', action); // Log the action
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload, authIsReady: true };
    case 'LOGOUT':
      return { ...state, user: null, role: null, authIsReady: true };
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true, role: state.role };
    case 'SET_ROLE':
      return { ...state, role: action.payload };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { 
    user: null,
    authIsReady: false,
    role: null
  });



  useEffect(() => {
    const unsub = projectAuth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        // Fetch user document from Firestore
        const userRef = projectFirestore.collection('users').doc(firebaseUser.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          const userData = doc.data();
          // Merge Firebase Auth user object with Firestore user data
          const fullUserDetails = {
            ...firebaseUser,
            ...userData,  // This will include phoneNumber from Firestore
          };
          dispatch({ type: 'AUTH_IS_READY', payload: fullUserDetails });
        } else {
          console.error('User document not found in Firestore');
          dispatch({ type: 'AUTH_IS_READY', payload: firebaseUser });
        }
      } else {
        // Logic when there is no user logged in
        // When no user is logged in, redirect to login
        console.log('No user logged in');
        dispatch({ type: 'LOGOUT' });
      }
    });
  
    return () => unsub();
  }, []);
  

  if (!state.authIsReady) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};





  // useEffect(() => {
  //   const unsub = projectAuth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       const userRef = projectFirestore.collection('users').doc(user.uid);
  //       const doc = await userRef.get();
  //       if (doc.exists && doc.data().role) {
  //         const role = doc.data().role;
  //         dispatch({ type: 'SET_ROLE', payload: role });
  //       } else {
  //         console.log('No role found for user:', user.uid);
  //         // You can dispatch a default role or leave it undefined
  //         // dispatch({ type: 'SET_ROLE', payload: 'default-role' });
  //       }
  //     }
  //     dispatch({ type: 'AUTH_IS_READY', payload: user });
      
  //   });

  //   return () => unsub();
  // }, []);

    // useEffect(() => {
  //   const unsub = projectAuth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       // Logic when the user is logged in
  //       const userRef = projectFirestore.collection('users').doc(user.uid);
  //       const doc = await userRef.get();
  //       if (doc.exists && doc.data().role) {
  //         const role = doc.data().role;
  //         dispatch({ type: 'SET_ROLE', payload: role });
  //       } else {
  //         console.log('No role found for user:', user.uid);
  //         // You can dispatch a default role or leave it undefined
  //         // dispatch({ type: 'SET_ROLE', payload: 'default-role' });
  //       }
  //       dispatch({ type: 'AUTH_IS_READY', payload: user });
  //     } else {
  //       // Logic when there is no user logged in
  //       console.log('No user logged in');
  //       dispatch({ type: 'LOGOUT' });
  //     }
  //   });
  
  //   return () => unsub();
  // }, []);