import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

const useAdverts = () => {
  const [adverts, setAdverts] = useState([]);

  useEffect(() => {
    const unsub = projectFirestore
      .collection('adverts')
      .onSnapshot((snap) => {
        let documents = [];
        snap.forEach((doc) => {
          documents.push({ ...doc.data(), id: doc.id });
        });
        console.log("Fetched documents:", documents); // Add this line
        setAdverts(documents);
      });

    return () => unsub();
  }, []);

  return { adverts };
};

export default useAdverts;
