import React, { useState, useEffect } from 'react';
import { africanCountries } from '../../../constants/countries';
import { genres, languages } from '../../../constants/bookData';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore, projectStorage, timestamp } from '../../../firebase/config';
import { useCountrySettings } from '../../../context/CountrySettingsContext';

import styles from './UpdateBook.module.css';

function UpdateBook({ defaultCountry }) {
  const [books, setBooks] = useState([]);
  //const [selectedBookId, setSelectedBookId] = useState('');
  const [selectedBookId, setSelectedBookId] = useState('');
  const [chapterContent, setChapterContent] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuthContext();
  const history = useHistory();
  const tempCountryCode = sessionStorage.getItem('tempCountry');
  const tempCountry = africanCountries.find((country) => country.code === tempCountryCode);
  const selectedDefaultCountry = tempCountry || defaultCountry || africanCountries[0];
  const [currency, setCurrency] = useState(selectedDefaultCountry.currency);
  const { selectedCountry, setSelectedCountry } = useCountrySettings(selectedDefaultCountry.code);
  const [bookName, setBookName] = useState(''); 
  const [bookAuthor, setBookAuthor] = useState('');
  const [bookType, setBookType] = useState('Subscription');
  const [bookPrice, setBookPrice] = useState('');
  const [subscriptionFee, setSubscriptionFee] = useState('');
  const [bookFreeAccess, setBookFreeAccess] = useState(false);
  const [bookSubscriptionAccess, setBookSubscriptionAccess] = useState(false);
  const [bookGenre, setBookGenre] = useState('');
  const [bookLanguage, setBookLanguage] = useState('');
  const [bookDescription, setBookDescription] = useState('');
  const [bookCoverUrl, setBookCoverUrl] = useState('');
  const [bookCoverImage, setBookCoverImage] = useState(null)
  const [bookCoverImageError, setBookCoverImageError] = useState(null)
  const [lastChapterContent, setLastChapterContent] = useState(
    'Please add the chapter content here. It can be further edited or updated in the UpdateChapter component'
  );
  const [chapterTitles, setChapterTitles] = useState([
    { number: 1, title: '', content: 'Please add the chapter content here. It can be further edited or updated in the UpdateChapter component', isActive: true }
  ]);
  const [isActive, setIsActive] = useState(true); // Assuming the book is active by default
  const [unsubscribe, setUnsubscribe] = useState(null);

  
  useEffect(() => {
    // Existing code for fetching all books
    const fetchBooks = async () => {
      const booksRef = projectFirestore.collection('anpbooks');
      const snapshot = await booksRef.get();
      const fetchedBooks = snapshot.docs.map(doc => ({ ...doc.data(), bookId: doc.id }));
      setBooks(fetchedBooks);
    };
    fetchBooks();
  }, []);
  
  // useEffect(() => {
  //   if (selectedBookId) {
  //     const bookRef = projectFirestore.collection('anpbooks').doc(selectedBookId);
  //     const unsubscribe = bookRef.onSnapshot(doc => {
  //       if (doc.exists) {
  //         const bookData = doc.data();
  //         setBookName(bookData.bookName || '');
  //         setBookAuthor(bookData.bookAuthor || '');
  //         setBookType(bookData.bookType || 'Subscription');
  //         setBookPrice(bookData.bookPrice || '');
  //         setSubscriptionFee(bookData.subscriptionFee || '');
  //         setBookFreeAccess(bookData.bookFreeAccess || false);
  //         setBookGenre(bookData.bookGenre || '');
  //         setBookLanguage(bookData.bookLanguage || '');
  //         setBookDescription(bookData.bookDescription || '');
  //         setIsActive(bookData.isActive !== undefined ? bookData.isActive : false);
  //       }
  //     });
  //     return () => unsubscribe();
  //   }
  // }, [selectedBookId]);

  useEffect(() => {
    if (selectedBookId) {
        const bookRef = projectFirestore.collection('anpbooks').doc(selectedBookId);
        const unsubscribe = bookRef.onSnapshot(doc => {
            if (doc.exists) {
                const bookData = doc.data();
                // Set fields based on Firestore data or default values
                setBookName(bookData.bookName || '');
                setBookAuthor(bookData.bookAuthor || '');
                setBookType(bookData.bookType || 'Subscription');
                setBookPrice(bookData.bookPrice || '');
                setSubscriptionFee(bookData.subscriptionFee || '');
                setBookFreeAccess(bookData.bookFreeAccess || false);
                setBookGenre(bookData.bookGenre || '');
                setBookLanguage(bookData.bookLanguage || '');
                setBookDescription(bookData.bookDescription || '');
                setIsActive(bookData.isActive !== undefined ? bookData.isActive : false);

                // If chapterTitles from Firestore are available, update each with isActive
                // If chapterTitles are not available, default to a single chapter with isActive set to false
                if (bookData.chapterTitles) {
                    bookData.chapterTitles.forEach(chapter => {
                        if (chapter.isActive === undefined) {
                            chapter.isActive = false;
                        }
                    });
                    setChapterTitles(bookData.chapterTitles);
                } else {
                    setChapterTitles([{ number: 1, title: '', content: '', isActive: false }]);
                }
            }
        });

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }
}, [selectedBookId]);



  useEffect(() => {
    // Log chapterTitles to inspect its updated value
    console.log("Updated chapterTitles:", chapterTitles);
  }, [chapterTitles]);  // Dependency array includes chapterTitles

  
  const handleSelectedBook = async (e) => {
    const bookId = e.target.value;
    console.log("Selected book ID:", bookId);  // Debugging line
    setSelectedBookId(bookId);
  
    // If there was a previous subscription, unsubscribe
    if (unsubscribe) {
      unsubscribe();
    }
  
    // Use onSnapshot to listen for real-time changes
    const newUnsubscribe = projectFirestore.collection('anpbooks').doc(bookId).onSnapshot(doc => {
      if (doc.exists) {
        const bookData = doc.data();
        console.log("bookData from Firestore:", bookData);  // Debugging line
        // Update each field with the data from Firestore, or set to default values
        setBookName(bookData.bookName || '');
        setBookAuthor(bookData.bookAuthor || '');
        setBookType(bookData.bookType || 'Subscription');
        setBookPrice(bookData.bookPrice || '');
        setSubscriptionFee(bookData.subscriptionFee || '');
        setBookFreeAccess(bookData.bookFreeAccess || false);
        setBookSubscriptionAccess(bookData.bookSubscriptionAccess || false);
        setBookGenre(bookData.bookGenre || '');
        setBookLanguage(bookData.bookLanguage || '');
        setBookDescription(bookData.bookDescription || '');
        setBookCoverUrl(bookData.bookCoverUrl || '');
        setChapterTitles(bookData.chapterTitles || []);
        setChapterContent(bookData.chapterContent || []);
        // Add any additional fields you might need, using the same pattern
      } else {
        console.log("No such document!");  // Debugging line
        // You can set default values or clear the fields here if the document does not exist
        setBookName('');
        setBookAuthor('');
        setBookType('Subscription');
        setBookPrice('');
        setSubscriptionFee('');
        setBookFreeAccess(false);
        setBookSubscriptionAccess(false);
        setBookGenre('');
        setBookLanguage('');
        setBookDescription('');
        setBookCoverUrl('');
        setChapterTitles([]);
        setChapterContent([]);
        // Clear any additional fields you might have
      }
    },
      error => {
        console.error("Error fetching document:", error);  // Debugging line
      }
    );
    // Set the new unsubscribe function so you can call it later
    setUnsubscribe(newUnsubscribe);
  };
  
  const handleBookSubmit = async (e) => {
    e.preventDefault();
    const updateData = {
      bookName,
      bookAuthor,
      bookType,
      bookPrice,
      subscriptionFee,
      bookFreeAccess,
      bookGenre,
      bookLanguage,
      bookDescription,
      isActive, // isActive is included here
      chapterTitles, // chapterTitles already include isActive
      accountUserId: user.uid,
      updatedAt: timestamp(),
    };

    try {
      const bookRef = projectFirestore.collection('anpbooks').doc(selectedBookId);
      await bookRef.update(updateData);
      history.push(`/books/${selectedBookId}`);
    } catch (error) {
      console.error("Error updating book: ", error);
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  

  const handleAddChapterTitle = () => {
    setChapterTitles([...chapterTitles, { title: '', content: '' }]);
  };

  const handleRemoveChapterTitle = (index) => {
    setChapterTitles(chapterTitles.filter((_, i) => i !== index));
  };

  const handleChangeChapterTitle = (e, index) => {
    const newChapterTitles = [...chapterTitles];
    newChapterTitles[index].title = e.target.value;
    setChapterTitles(newChapterTitles);
  };

  const handleChangeChapterContent = (e, index) => {
    const newChapterTitles = [...chapterTitles];
    newChapterTitles[index].content = e.target.value;
    setChapterTitles(newChapterTitles);
  };
  // const handleChangeChapterActive = (e, index) => {
  //   const newTitles = [...chapterTitles];
  //   newTitles[index].isActive = e.target.checked;
  //   setChapterTitles(newTitles);
  // };
  
  const handleChangeChapterActive = (e, index) => {
    const newTitles = [...chapterTitles];
    newTitles[index].isActive = e.target.checked;
    setChapterTitles(newTitles);
};

  return (
    <div className={styles.updateBookForm}>
      <h2 className={styles.pageTitle}>Update Book</h2>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      <form onSubmit={handleBookSubmit} className={styles.bookForm}>
      <div className={styles.formGroup}>
          <label htmlFor="isActive" className={styles.formLabel}>
            Is Active
          </label>
          <input
            type="checkbox"
            id="isActive"
            name="isActive"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            className={styles.formInput}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="select-book" className={styles.formLabel}>
            Select Book
          </label>
          <select
            id="select-book"
            name="select-book"
            value={selectedBookId}
            onChange={handleSelectedBook}
            className={styles.formInput}
            required
          >
            <option value="">Select a book</option>
            {books.map((book, index) => (
              <option key={index} value={book.bookId}>
                {book.bookName}
              </option>
            ))}
          </select>
        </div>
        {/* Rest of the form fields */}
      <div className={styles.formGroup}>
        <label htmlFor="bookName" className={styles.formLabel}>
          Book Name
        </label>
        <input
          type="text"
          id="bookName"
          name="bookName"
          value={bookName}
          onChange={(e) => setBookName(e.target.value)}
          className={styles.formInput}
          required
        />
      </div>
     
        <div className={styles.formGroup}>
    <label htmlFor="bookType" className={styles.formLabel}>
      Book Type
    </label>
    <select
      id="bookType"
      name="bookType"
      value={bookType}
      onChange={(e) => setBookType(e.target.value)}
      className={styles.formInput}
      required
    >
      <option value="Subscription">Subscription</option>
      <option value="Free">Free</option>
    </select>
  </div>


  <div className={styles.formGroup}>
          <label htmlFor="bookPrice" className={styles.formLabel}>
            Book Price
          </label>
          <input
            type="number"
            id="bookPrice"
            name="bookPrice"
            value={bookPrice}
            onChange={(e) => {
              if (e.target.value < 0) {
                // Add logic for showing an error message
              } else {
                setBookPrice(e.target.value);
              }
            }}
            className={styles.formInput}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="bookAuthor" className={styles.formLabel}>
            Book Author
          </label>
          <input
            type="text"
            id="bookAuthor"
            name="bookAuthor"
            value={bookAuthor}
            onChange={(e) => {
              if (e.target.value.trim().length < 3) {
                // Add logic for showing an error message
              } else {
                setBookAuthor(e.target.value);
              }
            }}
            className={styles.formInput}
            required
          />
        </div>

  <div className={styles.formGroup}>
    <label htmlFor="subscriptionFee" className={styles.formLabel}>
      Subscription Fee
    </label>
    <input
      type="text"
      id="subscriptionFee"
      name="subscriptionFee"
      value={subscriptionFee}
      onChange={(e) => setSubscriptionFee(e.target.value)}
      className={styles.formInput}
      required
    />
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="bookFreeAccess" className={styles.formLabel}>
      Book Free Access
    </label>
    <input
      type="checkbox"
      id="bookFreeAccess"
      name="bookFreeAccess"
      checked={bookFreeAccess}
      onChange={(e) => setBookFreeAccess(e.target.checked)}
      className={styles.formInput}
    />
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="bookSubscriptionAccess" className={styles.formLabel}>
      Book Subscription Access
    </label>
    <input
      type="checkbox"
      id="bookSubscriptionAccess"
      name="bookSubscriptionAccess"
      checked={bookSubscriptionAccess}
      onChange={(e) => setBookSubscriptionAccess(e.target.checked)}
      className={styles.formInput}
    />
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="bookGenre" className={styles.formLabel}>
      Book Genre
    </label>
    <select
      id="bookGenre"
      name="bookGenre"
      value={bookGenre}
      onChange={(e) => setBookGenre(e.target.value)}
      className={styles.formInput}
      required
    >
      {genres.map((genre, index) => (
        <option key={index} value={genre}>
          {genre}
        </option>
      ))}
    </select>
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="bookLanguage" className={styles.formLabel}>
      Book Language
    </label>
    <select
      id="bookLanguage"
      name="bookLanguage"
      value={bookLanguage}
      onChange={(e) => setBookLanguage(e.target.value)}
      className={styles.formInput}
      required
      >
      {languages.map((language, index) => (
        <option key={index} value={language}>
          {language}
        </option>
      ))}
    </select>
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="bookDescription" className={styles.formLabel}>
      Book Description
    </label>
    <textarea
      id="bookDescription"
      name="bookDescription"
      value={bookDescription}
      onChange={(e) => setBookDescription(e.target.value)}
      className={styles.formInput}
      required
    />
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="bookCoverUrl" className={styles.formLabel}>
      Book Cover URL
    </label>
    <input
      type="text"
      id="bookCoverUrl"
      name="bookCoverUrl"
      value={bookCoverUrl}
      onChange={(e) => setBookCoverUrl(e.target.value)}
      className={styles.formInput}
      required
    />
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="bookCoverImage" className={styles.formLabel}>
      New Book Cover Image
    </label>
    <input
      type="file"
      id="bookCoverImage"
      name="bookCoverImage"
      onChange={(e) => {
        if (!e.target.files[0]) return;
        const selected = e.target.files[0];
        const SIZE_LIMIT = 1024 * 1024 * 5; // 5MB
        if (selected.size > SIZE_LIMIT) {
          setBookCoverImageError("File size should not exceed 5MB");
          return;
        }
        if (!selected.type.match('image/*')) {
          setBookCoverImageError("Invalid file type. Only images are accepted.");
          return;
        }
        setBookCoverImageError(null);
        setBookCoverImage(selected);
      }}
      className={styles.formInput}
    />
    {bookCoverImageError && <div>{bookCoverImageError}</div>}
  </div>

  <div className={styles.formGroup}>
    <label htmlFor="lastChapterContent" className={styles.formLabel}>
      Last Chapter Content
    </label>
    <textarea
      id="lastChapterContent"
      name="lastChapterContent"
      value={lastChapterContent}
      onChange={(e) => setLastChapterContent(e.target.value)}
      className={styles.formInput}
      required
    />
  </div>

{/* For displaying chapter titles, you can map over them and create a read-only field for each title */}
{chapterTitles.map((chapterTitle, index) => (
          <div className={styles.formGroup} key={index}>
            <label htmlFor={`chapterTitle${index}`} className={styles.formLabel}>
              Chapter {index + 1} Title
            </label>
            <div className={styles.readOnlyField}>
              {chapterTitle.title}
            </div>
            <label htmlFor={`chapterContent${index}`} className={styles.formLabel}>
              Content
            </label>
            <div className={styles.readOnlyField}>
              {chapterTitle.content}
            </div>
            <label htmlFor={`chapterActive${index}`} className={styles.formLabel}>
              Is Active
            </label>
            <div className={styles.readOnlyField}>
              {chapterTitle.isActive ? "Active" : "Inactive"}
            </div>
          </div>
        ))}
        <div className={styles.buttonContainer}>
          <button type="submit" className={styles.updateButton}>Update Book</button>
        </div>

      </form>
    </div>
  );
};

export default UpdateBook;