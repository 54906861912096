import { globalCountries } from '../../../constants/countries';

const getConfirmationThreshold = (countryCode) => {
    const countryInfo = globalCountries.find(country => country.code === countryCode);

    if (!countryInfo || !countryInfo.GeoRegion) {
        console.error(`Invalid or undefined country code: ${countryCode}`);
        return 30; // Default threshold if country or region is not identified
    }

    const thresholds = {
        'Africa': 10,
        'Asia': 30,
        'Europe': 30,
        'North America': 30,
        'South America': 25,
        'Oceania': 30,
        'Antarctica': 30,
        'The Caribbean': 15,
        'The Middle East': 20,
        'Central America': 20,
        'The Arctic': 30
    };

    return thresholds[countryInfo.GeoRegion] || 30; // Return the threshold or default value if not found
};

export default getConfirmationThreshold;



