import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { projectFirestore } from '../../firebase/config';
import { useAuthContext } from '../../hooks/useAuthContext';


import styles from './ProfilePersonalInformation.module.css';

const ProfilePersonalInformation = () => {
  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDOB] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();


  useEffect(() => {
    //console.log("User data in useEffect:", user); // Check what user data is available
    if (user) {
      setDisplayName(user.displayName || '');
      setPhoneNumber(user.phoneNumber || '');
      setDOB(user.dateOfBirth || '');
      // ... other user data settings
    }
  }, [user]);

  const validateForm = () => {
    let errors = {};
    if (!displayName.trim()) errors.displayName = "Display name is required";
    if (!phoneNumber.trim()) errors.phoneNumber = "Phone number is required";
    if (!dob.trim()) errors.dob = "Date of birth is required";

    // Add more validations as needed
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updateUserProfile = async () => {
    if (!validateForm()) return;

    try {
      await projectFirestore.collection('users').doc(user.uid).update({
        displayName,
        phoneNumber,
        dateOfBirth: dob
      });
      // Update the user object with new details
      user.displayName = displayName;
      user.phoneNumber = phoneNumber;
      user.dateOfBirth = dob;

      // Redirect to home page after successful update
      history.push('/'); // Replace '/' with your home page route if different
    } catch (error) {
      console.error('Error updating user profile: ', error);
    }
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    updateUserProfile();
  };

  return (
    <div className={styles.profileContainer}>
      <h1 className={styles.profileTitle}>Profile</h1>
      <div className={styles.profileContent}>
        <div className={styles.profileSection}>
          <h2 className={styles.profileSectionTitle}>Personal Information</h2>
          <div className={styles.profileSectionContent}>
            <form onSubmit={handleUpdateProfile}>
              {/* Display Name */}
              <div className={styles.formGroup}>
                <label htmlFor="displayName" className={styles.formLabel}>
                  Display Name
                </label>
                <input
                  type="text"
                  id="displayName"
                  name="displayName"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  className={styles.formInput}
                  required
                />
                {formErrors.displayName && <p className={styles.error}>{formErrors.displayName}</p>}
              </div>

              {/* Email (Read-Only) */}
              <div className={styles.formGroup}>
                <label htmlFor="email" className={styles.formLabel}>
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={user.email}
                  className={`${styles.formInput} ${styles.formInputReadOnly}`}
                  readOnly
                />
              </div>

              {/* Phone Number */}
              <div className={styles.formGroup}>
                <label htmlFor="phoneNumber" className={styles.formLabel}>
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className={styles.formInput}
                  required
                />
                {formErrors.phoneNumber && <p className={styles.error}>{formErrors.phoneNumber}</p>}
              </div>

              {/* Date of Birth */}
              <div className={styles.formGroup}>
                <label htmlFor="dob" className={styles.formLabel}>
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={dob}
                  onChange={(e) => setDOB(e.target.value)}
                  className={styles.formInput}
                  required
                />
                {formErrors.dob && <p className={styles.error}>{formErrors.dob}</p>}
              </div>

              <button type="submit" className={styles.formSubmitButton}>
                Update Profile
              </button>
            </form>
          </div>
        </div>

       




      </div>
    </div>
  );
};

export default ProfilePersonalInformation;
