import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { projectFirestore, projectStorage, timestamp } from '../../../firebase/config';
import { africanCountries } from '../../../constants/countries';
import PropertuLayout from '../../../components/Hoc/PropertuLayout/PropertuLayout';
import AdvertArea from '../../../components/Propertu/AdvertFeatures/AdvertArea/AdvertArea';
import Navbar from '../../../components/Navbar/Navbar';
import FeaturedProperties from '../../../components/Propertu/AdvertFeatures/FeaturedProperties/FeaturedProperties';

import styles from './PropertuPage.module.css';

const PropertuPage = () => {
  const [currentPropertyType, setCurrentPropertyType] = useState('Residential');
  const [currentSearchType, setCurrentSearchType] = useState('Sale');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [allProperties, setAllProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchState, setSearchState] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();
 
  const [searchCriteria, setSearchCriteria] = useState({
    range: '',
    priceFrom: '',
    priceTo: '',
    searchQuery: '',
  });

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };


useEffect(() => {
  const fetchProperties = async () => {
    try {
      const snapshot = await projectFirestore.collection('properties').get();
      const properties = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setAllProperties(properties);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  fetchProperties();
}, []);

  

  const handleMenuClick = (propertyType) => {
    setCurrentPropertyType(propertyType);
  };

  const handleSearchTypeChange = (searchType) => {
    setCurrentSearchType(searchType);
  };



const handleSubmit = async (e) => {
  e.preventDefault();
  
  setIsLoading(true);
  try {
    let query = projectFirestore.collection('properties');
    if (selectedCountry) {
      query = query.where('country', '==', selectedCountry);
    }
    if (searchCriteria.priceFrom) {
      query = query.where('price', '>=', Number(searchCriteria.priceFrom));
    }
    if (searchCriteria.priceTo) {
      query = query.where('price', '<=', Number(searchCriteria.priceTo));
    }
    if (searchCriteria.searchQuery) {
      query = query.where('propertyAddress', '==', searchCriteria.searchQuery);
    }
    console.log('Query before execution:', query);
    const snapshot = await query.get();
    console.log('Query after execution:', query);
    console.log('Snapshot:', snapshot.docs);
    const properties = snapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .filter((property) => property.propertyType.toLowerCase().includes(currentSearchType.toLowerCase()));
    setSearchResults(properties);
    console.log('searchResults:', searchResults); // log searchResults here


    history.push('/search-results', { searchResults });
  } catch (error) {
    console.error('Error searching for properties:', error);
  }
  setIsLoading(false);
};


 
  const renderProperties = () => {
    if (allProperties.length === 0) {
      return <p>No properties found</p>;
    }
  
    return allProperties.map((property) => {
      const countryObj = africanCountries.find((country) => country.code === property.country) || {
        flag: '',
        name: '',
        currencySymbol: '',
      };
  
      return (
        <div key={property.id} className={`${styles.propertyItem}`}>
          <img
            src={property.propertyImage && property.propertyImage[0]}
            alt={property.title}
            className={styles.propertyImage}
          />
          <div className={styles.propertyDetails}>
            <h3>{property.propertyAddress}</h3>
            <h3>{property.neighborhood}, {property.city}, {property.postcode}</h3>
            <h3 className={styles.propertyPrice}>  {`${countryObj.currencySymbol} ${property.price}`}</h3>
            <h3 className={styles.propertyPrice}>{`${countryObj.flag} ${countryObj.name}`}</h3>
          </div>
        </div>
      );
    });
  };
  
  
  
  

  return (
    <PropertuLayout selectedCountry={selectedCountry}>
    <div className={styles.landingPage}>
    {/* <Navbar onCountryChange={handleCountryChange} /> */}
    
    <AdvertArea selectedCountry={selectedCountry} />
    <main>
    <section className={styles.hero}>
    <h2>Perfect properties within the Global Africa space</h2>
    {/* <div className={styles.propertiesContainer}>
    {renderProperties()}
    </div> */}
    <form
    className={styles.searchForm}
    onSubmit={(e) => {
    e.preventDefault();
    handleSubmit(e); // pass the event to the function
    }}
    >
    <div className="search-country">
    <select
    id="country"
    onChange={(e) => setSelectedCountry(e.target.value)}
    >
    <option value="">Select a country</option>
    {africanCountries.map((country) => (
    <option key={country.code} value={country.name}>
    {country.name}
    </option>
    ))}
    </select>
    </div>
    <div className="search-property-type">
    <select
    value={currentSearchType}
    onChange={(e) => handleSearchTypeChange(e.target.value)}
    placeholder="Property Type"
    >
    <option value="">Property Type</option>
    <option value="Sale">Sale</option>
    <option value="Rent">Rent</option>
    <option value="Buy">Buy</option>
    <option value="New developments">New Developments</option>
    <option value="Commercial">Commercial</option>
    <option value="Industrial">Industrial</option>
    <option value="Agricultural">Agricultural</option>
    </select>
    </div>
    <input
    type="text"
    placeholder="City, neighborhood, address, postcode etc"
    onChange={(e) =>
    setSearchCriteria({
    ...searchCriteria,
    searchQuery: e.target.value,
    })
    }
    />
    <input
    type="number"
    pattern="[0-9]+"
    value={searchCriteria.priceFrom || ''}
    onChange={(e) =>
    setSearchCriteria({
    ...searchCriteria,
    priceFrom: e.target.value,
    })
    }
    placeholder="Price range from"
    //required
    />
    <select
    value={searchCriteria.range || ''}
    onChange={(e) =>
    setSearchCriteria({
    ...searchCriteria,
    range: e.target.value,
    })
    }
    placeholder="Range (km)"
    >
    <option value="">Select Range</option>
    <option value="1">1 km</option>
    <option value="5">5 km</option>
    <option value="10">10 km</option>
    <option value="20">20 km</option>
    </select>
    <input
    type="number"
    value={searchCriteria.priceTo || ''}
    onChange={(e) =>
    setSearchCriteria({
    ...searchCriteria,
    priceTo: e.target.value,
    })
    }
    placeholder="Price range to"
    />
    <button type="submit">
    {isLoading ? 'Loading...' : 'Search'}
    </button>
    </form>
    <div className={styles.propertiesContainer}>
    {renderProperties()}
    </div>
    
    </section>
    
    <button onClick={() => handleMenuClick('Residential')}>
    Residential
    </button>
    <button onClick={() => handleMenuClick('Rentals')}>Rentals</button>
    <button onClick={() => handleMenuClick('To Buy')}>To Buy</button>
    <button onClick={() => handleMenuClick('To Sell')}>To Sell</button>
    <button onClick={() => handleMenuClick('New Development')}>
    New Development
    </button>
    <button onClick={() => handleMenuClick('Adverts')}>Adverts</button>
    <FeaturedProperties propertyType={currentPropertyType} />
</main>
  </div>
  </PropertuLayout>
  );
};
export default PropertuPage;


 {/* <div className={styles.propertyDetails}>
          <h3>{property.propertyAddress}</h3>
          <h3>{property.postcode }{property.city}</h3>
          <p>{property.price}</p>
          <h3>{property.country}</h3> 
        </div>*/}