import React, { useState } from 'react';
import { projectFirestore } from '../../firebase/config';
import { useAuthContext } from '../../hooks/useAuthContext';
import styles from './CustomerSupport.module.css';

const CustomerSupport = () => {
  const { user } = useAuthContext();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState({ status: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct support message object
    const supportMessage = {
      userId: user.uid,
      email: 'info@boameooo.com', // Default email address
      subject,
      message,
      createdAt: new Date()
    };

    try {
      // Store the message in the 'customerSupport' collection
      await projectFirestore.collection('customerSupport').add(supportMessage);
      setSubmitStatus({ status: 'success', message: 'Your message has been sent successfully.' });
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setSubmitStatus({ status: 'error', message: 'Failed to send message. Please try again.' });
    }
  };

  return (
    <div className={styles.customerSupportContainer}>
    <h2 className={styles.profileSectionTitle}>Customer Support</h2>
      <form onSubmit={handleSubmit} className={styles.customerSupportForm}>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>
            Email:
            <input
              type="email"
              value="info@boameooo.com"
              readOnly
              className={styles.formInput}
            />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>
            Subject:
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className={styles.formInput}
            />
          </label>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>
            Message:
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className={styles.formInput}
            />
          </label>
        </div>
        <button type="submit" className={styles.formButton}>Submit</button>
        {submitStatus.message && (
          <div className={`${styles.submitStatus} ${styles[submitStatus.status]}`}>
            {submitStatus.message}
          </div>
        )}
      </form>
      {/* FAQs Section */}
      <div className={styles.faqsSection}>
        <h2 className={styles.customerSupportTitle}>FAQs</h2>
        <ul>
          {/* Add FAQ items */}
        </ul>
      </div>
    </div>
  );
};

export default CustomerSupport;


// // src/components/CustomerSupport/CustomerSupport.js

// import React, { useState } from 'react';
// import { projectFirestore } from '../../firebase/config';
// import { useAuthContext } from '../../hooks/useAuthContext';

// import styles from './CustomerSupport.module.css';

// const CustomerSupport = () => {
//   const [email, setEmail] = useState('');
//   const [subject, setSubject] = useState('');
//   const [message, setMessage] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission, e.g., send the message to customer support via email or save it in the database
//     console.log('Contact form submitted:', { email, subject, message });
//     setEmail('');
//     setSubject('');
//     setMessage('');
//   };

//   return (
//     <div className={styles.customerSupportContainer}>
//       {/* <h2 className={styles.customerSupportTitle}>Contact Customer Support</h2> */}
//       <form onSubmit={handleSubmit} className={styles.customerSupportForm}>
//       <div className={styles.formGroup}>
//         <label className={styles.formLabel}>
//           Email:
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//             className={styles.formInput}
//           />
//         </label>
//         </div>
//         <div className={styles.formGroup}>
//         <label className={styles.formLabel}>
//           Subject:
//           <input
//             type="text"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             required
//             className={styles.formInput}
//           />
//         </label>
//         </div>
//         <div className={styles.formGroup}>
//         <label className={styles.formLabel}>
//           Message:
//           <textarea
//             value={message}
//             onChange={(e) => setMessage(e.target.value)}
//             required
//             className={styles.formInput}
//           />
//         </label>
//         </div>
//         <button type="submit" className={styles.formButton}>Submit</button>
//       </form>
//       <div className={styles.faqsSection}>
//         <h2 className={styles.customerSupportTitle}>FAQs</h2>
//         <ul>
//         <li>
//           <h3>How do I track my order?</h3>
//           <p>Answer goes here.</p>
//         </li>
//         {/* Add more FAQ items */}
//       </ul>
//       </div>
//     </div>
//   );
// };

// export default CustomerSupport;
      
