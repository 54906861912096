import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; // React Router import
import { projectFirestore } from '../../firebase/config';
import GeneralLayout from '../../components/Hoc/GeneralLayout/GeneralLayout';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  
import styles from './WeAreLookingForForm.module.css';

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],  // toggled buttons
  
    ['blockquote', 'code-block'],  // blocks
  
    [{ 'header': 1 }, { 'header': 2 }, { 'header': [3, 4, 5, 6, false] }], // headers
    
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],  // lists
    
    [{ 'script': 'sub'}, { 'script': 'super' }],  // superscript/subscript
    
    [{ 'indent': '-1'}, { 'indent': '+1' }],  // outdent/indent
    
    [{ 'direction': 'rtl' }],  // text direction
    
    [{ 'size': ['small', false, 'large', 'huge'] }],  // font size
    
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // custom header levels
    
    [{ 'color': [] }, { 'background': [] }],  // text/background color
    
    [{ 'font': [] }],  // font family
    
    [{ 'align': [] }],  // text alignment
    
    ['clean'],  // remove formatting
    
    ['link', 'image', 'video']  // link, image and video
  ];


const WeAreLookingForForm = () => {
    const [title, setTitle] = useState(''); // State for the title
    const [message, setMessage] = useState(''); // State to manage the message content
    const [author, setAuthor] = useState(''); // State to manage the author
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const history = useHistory();

    // Function to handle changes in the title input field
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  
    // Function to handle changes in the ReactQuill editor
    const handleDescriptionChange = (content) => {
      setMessage(content);
    };
  
    // Function to handle changes in the author input field
    const handleAuthorChange = (event) => {
      setAuthor(event.target.value);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
  
      const postData = {
        title,
        message,
        author: author || 'Anonymous',
        createdAt: new Date()
      };
  
      try {
        await projectFirestore.collection('wearelookingfor').add(postData);
        setFeedbackMessage('Message successfully posted!');
        setTitle(''); // Reset title field
        setMessage(''); // Reset message field
        setAuthor(''); // Reset author field
        history.push('/lookmessageform');
      } catch (error) {
        console.error('Error writing document: ', error);
        setFeedbackMessage('Failed to post message. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    };
  
    return (
        <GeneralLayout>
        <form onSubmit={handleSubmit} className={styles.wearelookingforForm}>
            <label htmlFor="title">Title:</label>
            <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={handleTitleChange}
            required
            />
          <div className={styles.fullWidth}>
            <label htmlFor="message" className={styles.label}>MESSAGE</label>
            <div className={styles.quillContainer}> {/* Apply the new class here */}
              <ReactQuill 
                value={message}
                onChange={handleDescriptionChange}
                modules={{ toolbar: toolbarOptions }}
                style={{ height: '608px' }} // Direct inline style for testing
              />
            </div>
          </div>
          <label htmlFor="author">Author (optional):</label>
          <input type="text" id="author" name="author" value={author} onChange={handleAuthorChange} />
          
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
  
          {feedbackMessage && <div className={styles.feedback}>{feedbackMessage}</div>}
        </form>
      </GeneralLayout>
    );
  };
  
  export default WeAreLookingForForm;