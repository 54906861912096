//import React from 'react';
import React, { useState, useRef, useEffect, useContext  } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { africanCountries } from '../../constants/countries';
import { useCountrySettings } from '../../context/CountrySettingsContext'; 
import defaultAvatarImg from '../../assets/defaultAvatarImg.png'
import Avatar from '../Avatar/Avatar';
import styles from './Navbar.module.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// Import these additional components from MUI at the top of the file
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';



const Navbar = ({ serviceName, menuItems = [], boameColor }) => {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const history = useHistory();
  const userRole = user?.role; // Access role
  const userCountryCode = user?.country;
  const userCountryName = getCountryName(userCountryCode, africanCountries); // Use the utility function
  const serviceNameRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [servicesAnchorEl, setServicesAnchorEl] = React.useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // Add a new state for the selected country
  const { selectedCountry, setSelectedCountry } = useCountrySettings();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  

  const services = [
    // {
    //   name: 'Visuals',
    //   icon: '📷',
    //   link: '/pictures',
      
    // },
    {
      name: 'Books',
      icon: '📚',
      link: '/books',
      
    },
      {
      name: 'Proposals',
      icon: '🖋️',
      link: '/proposallanding',
      
    },
    {
      name: 'Delivery',
      icon: '🚚',
      link: '/deliveryhome',
      
    },
    {
      name: 'Works & Jobs',
      icon: '👥',
      link: '/worksandjobs',
      
    },
    {
      name: 'Global Africa Exchange',
      icon: '🌳🌍',
      link: '/global-africa-exchange',
      
    },
    // {
    //   name: 'Propertu',
    //   icon: '🏠',
    //   link: '/propertu',
      
    // },
  ];

  
  // Inside your component
useEffect(() => {
  if (serviceNameRef.current) {
    const serviceNameWidth = serviceNameRef.current.offsetWidth;
    const calculatedOffset = serviceNameWidth / 2;
    setOffset(calculatedOffset);
  }
}, [serviceName]);


useEffect(() => {
  // console.log('Selected country:', selectedCountry);
}, [selectedCountry]);


useEffect(() => {
  if (!selectedCountry) {
    const sessionStorageCountry = sessionStorage.getItem("tempCountry");
    if (sessionStorageCountry) {
      setSelectedCountry(sessionStorageCountry);
    } else {
      setSelectedCountry(africanCountries[0].code);
    }
  }
}, [selectedCountry, setSelectedCountry]);


const handleCountryChange = (event) => {
  const countryCode = event.target.value;
  const newCountry = africanCountries.find(c => c.code === countryCode);
  setSelectedCountry(newCountry);
  sessionStorage.setItem("tempCountry", countryCode); 
};


  const handleServicesClick = (event) => {
    setServicesAnchorEl(event.currentTarget);
  };

  const handleServicesClose = () => {
    setServicesAnchorEl(null);
  };

  const handleAvatarClick = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const handleLogoutClick = async () => {
    console.log("Logout clicked"); // Add this line
    handleClose();
    await logout();
    // Redirect to login page after logout
    history.push('/login');
  };

  const handleDropdownClick = (event, itemName) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(dropdownOpen === itemName ? '' : itemName);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setDropdownOpen(null);
  };

  function getCountryName(code, countries) {
    const country = countries.find(country => country.code === code);
    return country ? country.name : null;
  }
  

  return (
    <nav className={styles.nav}>
      <div className={styles.logoWrapper}>
  <div className={styles.logoContainer}>
    <h1 className={styles.logo}>
      <span className={styles.boame} style={{ color: boameColor }}>
        Boame
      </span>
      <span className={styles.ooo}>ooo</span>
    </h1>
    {serviceName && (
      <h2 className={`${styles.serviceName} ${styles.logoServiceName}`}>{serviceName}</h2>
    )}
  </div>
</div>
  
  {/* Menu items
  <ul className={styles.menuItems}>
  {menuItems &&
    menuItems.map((item, index) => (
      <li key={index}>
        <NavLink to={item.link} activeClassName={styles.activeLink}>
          <span
            className={`${styles.serviceName} ${styles.serviceNameBlack} ${item.type}`}
          >
            {item.name}
          </span>
        </NavLink>
      </li>
    ))}
    
</ul> */}
{/* Menu items with dropdowns */}
<ul className={styles.menuItems}>
        {menuItems && menuItems.map((item, index) => (
          <li key={index}>
            {item.dropdown ? (
              <>
                <button onClick={(e) => handleDropdownClick(e, item.name)}>
                  {item.name}
                </button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(dropdownOpen === item.name)}
                  onClose={handleDropdownClose}
                >
                  {item.dropdown.map((dropdownItem) => (
                    <MenuItem key={dropdownItem.name} onClick={handleDropdownClose}>
                      <NavLink to={dropdownItem.link}>{dropdownItem.name}</NavLink>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <NavLink to={item.link}>{item.name}</NavLink>
            )}
          </li>
        ))}
      </ul>
  <div className={styles.dropdownsWrapper}>
    {/* Services Dropdown */}
    <div className={styles.servicesDropdown}>
      <IconButton onClick={handleServicesClick}>
        <div className={styles.nineDots}></div>
      </IconButton>
      <Menu
        anchorEl={servicesAnchorEl}
        open={Boolean(servicesAnchorEl)}
        onClose={handleServicesClose}
        onClick={handleServicesClose}
      >
        {services.map((service, index) => (
          <MenuItem key={index}>
            <NavLink to={service.link} className={styles.serviceLink}>
              <span className={styles.serviceIcon}>{service.icon}</span>
              <span className={styles.serviceName}>{service.name}</span>
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </div>

    {/* Avatar Dropdown */}
    <div className={styles.avatarDropdown}>
      <IconButton onClick={handleAvatarClick}>
        <Avatar src={user?.photoURL || defaultAvatarImg} />
      </IconButton>
      <Menu
        anchorEl={avatarAnchorEl}
        open={Boolean(avatarAnchorEl)}
        onClose={handleAvatarClose}
        onClick={handleAvatarClose}
      >
        {user ? (
          [
            <MenuItem key="displayName" className={styles.myMenuItem}>{user.displayName}</MenuItem>,
            <MenuItem key="email" className={styles.myMenuItem}>{user.email}</MenuItem>,
            <MenuItem key="role" className={styles.myMenuItem}>{userRole}</MenuItem>,
            <MenuItem key="country" className={styles.myMenuItem}>{userCountryName}</MenuItem>,


            // Additional links
            <MenuItem key="afropunks" className={styles.myMenuItem}>
                <NavLink to="/afroform" className={styles.dropdownItem}>
                    Become an AfroPunk
                </NavLink>
            </MenuItem>,
            <MenuItem key="guardian-council" className={styles.myMenuItem}>
                <NavLink to="/afroform" className={styles.dropdownItem}>
                    Become an African Guardian Councillor
                </NavLink>
            </MenuItem>,
            <MenuItem key="afholder" className={styles.myMenuItem}>
                <NavLink to="/afholderrequest" className={styles.dropdownItem}>
                    Become an Afholder
                </NavLink>
            </MenuItem>,
          
            <MenuItem key="account" className={styles.myMenuItem}>
              <NavLink to="/dashboard" className={styles.dropdownItem}>
                Manage Account
              </NavLink>
            </MenuItem>,
            <MenuItem key="logout" onClick={handleLogoutClick} className={styles.myMenuItem}>
              Logout
            </MenuItem>,
          ]
        ) : (
          [
            <MenuItem key="login" className={styles.myMenuItem}>
              <NavLink to="/login" className={styles.dropdownItem}>
                Login
              </NavLink>
            </MenuItem>,
            <MenuItem key="signup" className={styles.myMenuItem}>
              <NavLink to="/signup" className={styles.dropdownItem}>
                Signup
              </NavLink>
            </MenuItem>,
          ]
        )}
      </Menu>
    </div>
  </div>
</nav>

  );
};

export default Navbar;
