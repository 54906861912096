import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { projectFirestore } from '../../../firebase/config';
import styles from './ImportDetailPage.module.css'; // Assuming you have a CSS module

const ImportDetailPage = () => {
    const { id } = useParams();
    const [importData, setImportData] = useState(null);

    useEffect(() => {
        const fetchImportData = async () => {
            try {
                const doc = await projectFirestore.collection('postimports').doc(id).get();
                if (doc.exists) {
                    const data = doc.data();
                    setImportData({
                        ...data,
                        createdAt: data.createdAt ? new Date(data.createdAt.seconds * 1000).toLocaleDateString() : '',
                        expectedDeliveryDate: data.expectedDeliveryDate ? new Date(data.expectedDeliveryDate.seconds * 1000).toLocaleDateString() : '',
                        // Add other timestamp fields here if needed
                    });
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        fetchImportData();
    }, [id]);

    if (!importData) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.container}>
            <h2>{importData.productName}</h2>
            <div className={styles.imagesContainer}>
                {importData.productImages && importData.productImages.map((url, index) => (
                    <img key={index} src={url} alt={`Product Image ${index + 1}`} className={styles.productImage} />
                ))}
            </div>
            <p><strong>Product Name:</strong> {importData.productName}</p>
            <p><strong>Comapny Name:</strong> {importData.companyName}</p>  {/* To be addedd  */}
            <p><strong>Country of Origin:</strong> {importData.countryOfDesiredImport}</p>
            <p><strong>Price Range:</strong> {importData.targetPriceRange}</p>
            <p><strong>Currency:</strong> {importData.preferredCurrency}</p>
            <p><strong>Payment Terms:</strong> {importData.paymentTerms}</p>
            <p><strong>Minimum Import Quantity:</strong> {importData.desiredQuantity}</p>
            <p><strong>Product Description:</strong> <div dangerouslySetInnerHTML={{ __html: importData.qualityRequirements }} /></p>
            <p><strong>Legal and Regulatory Compliance:</strong> <div dangerouslySetInnerHTML={{ __html: importData.legalCompliance }} /></p>
            <p><strong>Expected Delivery Date:</strong> {importData.expectedDeliveryDate}</p>
            <p><strong>Customs and Import Taxes:</strong> <div dangerouslySetInnerHTML={{ __html: importData.customsTaxes }} /></p>
            <p><strong>Quality Standards & Specification Requirements:</strong> <div dangerouslySetInnerHTML={{ __html: importData.qualityStandards }} /></p>
            <div className={styles.imagesContainer}>
                {importData.supportingDocuments && importData.supportingDocuments.map((url, index) => (
                    <img key={index} src={url} alt={`Upload Product Supporting Documents ${index + 1}`} className={styles.productImage} />
                ))}
            </div>
            <p><strong>Preferred Supplier Qualifications:</strong> <div dangerouslySetInnerHTML={{ __html: importData.supplierQualifications }} /></p>
            <p><strong>Additional Logistics Details:</strong> <div dangerouslySetInnerHTML={{ __html: importData.additionalLogistics }} /></p>
            
        </div>
    );
};

export default ImportDetailPage;


