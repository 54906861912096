import React, { useState } from 'react';
import { projectFirestore } from '../../../firebase/config';
import styles from './TradeProducts.module.css';

const TradeProducts = () => {
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [category, setCategory] = useState(''); // Default category state
    const [subCategory, setSubCategory] = useState(''); // Sub-category state
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Data Validation
        if (!productName.trim() || !productDescription.trim() || !category || !subCategory) {
            setError("Product name, description, category, and sub-category are required.");
            return;
        }

        try {
            await projectFirestore.collection('tradeproducts').add({
                name: productName,
                description: productDescription,
                category, // Include category in the document
                subCategory // Include sub-category
            });
            // Reset form fields
            setProductName('');
            setProductDescription('');
            setCategory(''); // Reset category
            setSubCategory(''); // Reset sub-category
            setError('');
        } catch (error) {
            console.error("Error adding document: ", error);
            setError("Failed to save data. Please try again.");
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            {error && <p className={styles.errorMessage}>{error}</p>}
            <div className={styles.inputGroup}>
                <label htmlFor="productName">Product Name:</label>
                <input
                    id="productName"
                    type="text"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    className={styles.input}
                />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="productDescription">Description:</label>
                <textarea
                    id="productDescription"
                    value={productDescription}
                    onChange={(e) => setProductDescription(e.target.value)}
                    className={styles.textarea}
                />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="category">Category:</label>
                <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className={styles.select}
                >
                    <option value="">Select a Category</option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Textiles">Textiles</option>
                    {/* Add more categories as needed */}
                </select>
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="subCategory">Sub-Category:</label>
                <select
                    id="subCategory"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    className={styles.select}
                >
                    <option value="">Select a Sub-Category</option>
                    {/* Sub-categories options based on the selected category */}
                </select>
            </div>
            <button type="submit" className={styles.submitButton}>Submit Product</button>
        </form>
    );
};

export default TradeProducts;
