import React from 'react';
import Rating from '../../components/Ratings/Rating';
import styles from './Book.module.css';

const Book = ({ book, renderItem, currency, onRatingChange, selectedCountry, exchangeRates }) => {
  const exchangeRate = exchangeRates?.[selectedCountry.currency]?.[book.currency] ?? 1;
  const localPrice = book.price * exchangeRate;
    return (
      <div className={styles.book}>
        {renderItem(book, localPrice)}
        <div className={styles.rating}>
          <Rating rating={book.bookRating} displayText={true} onClick={onRatingChange} />
        </div>
      </div>
    );
  };
  

export default Book;
