import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore } from '../../firebase/config';
import DeliveryLayout from '../../components/Hoc/DeliveryLayout/DeliveryLayout';
import styles from './MyOrders.module.css';

function MyOrders() {
  const { user } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allOrders, setAllOrders] = useState([]); 

  useEffect(() => {
    fetchUserOrders();
    fetchAllOrders();
  }, []);



  const fetchUserOrders = async () => {
    if (!user) {
      console.log('No user logged in.');
      return;
    }
  
    setLoading(true);
    try {
      const ordersRef = projectFirestore.collection('deliveryorders');
      const snapshot = await ordersRef.where('userId', '==', user.uid).get();
  
      console.log('Firestore response:', snapshot); // Log the entire Firestore response
  
      if (snapshot.empty) {
        console.log('No orders found for user:', user.uid);
      } else {
        const fetchedUserOrders = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched User Orders:', fetchedUserOrders); // Log fetched orders
        setOrders(fetchedUserOrders);
      }
    } catch (error) {
      console.error('Error fetching user orders:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchAllOrders = async () => {
    setLoading(true);
    try {
      const ordersRef = projectFirestore.collection('deliveryorders');
      const snapshot = await ordersRef.get();

      if (!snapshot.empty) {
        const fetchedAllOrders = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAllOrders(fetchedAllOrders);
      }
    } catch (error) {
      console.error('Error fetching all orders:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const renderOrdersTable = (ordersList, title) => (
    <div>
      <h2>{title}</h2>
      {ordersList.length > 0 ? (
        <table className={styles.ordersTable}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Pickup Date</th>
              <th>Delivery Date</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody>
            {ordersList.map(order => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.packageDetails.pickupDate ? new Date(order.packageDetails.pickupDate).toLocaleDateString() : 'No Date'}</td>
                <td>{order.packageDetails.deliveryDate ? new Date(order.packageDetails.deliveryDate).toLocaleDateString() : 'No Date'}</td>
                <td>{order.orderStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No {title.toLowerCase()} orders.</p>
      )}
    </div>
  );
  

  if (loading) {
    return <div>Loading orders...</div>;
  }

// Filter and log orders
const inTransitOrders = orders.filter(order => order.orderStatus === 'in_transit');
console.log('In-Transit Orders:', inTransitOrders);

const pendingOrders = orders.filter(order => order.orderStatus === 'pending');
console.log('Pending Orders:', pendingOrders);

const deliveredOrders = orders.filter(order => order.orderStatus === 'delivered');
console.log('Delivered Orders:', deliveredOrders);

  return (
    <DeliveryLayout>
      <div className={styles.myOrdersContainer}>
        <h1>My Orders Overview</h1>
        {renderOrdersTable(pendingOrders, 'Pending')}
        {renderOrdersTable(inTransitOrders, 'In-transit')}
        {renderOrdersTable(deliveredOrders, 'Delivered')}

        {/* New section to display all orders */}
        {/* <h1>All Orders in System</h1>
        {renderOrdersTable(allOrders, 'All')} */}
      </div>
    </DeliveryLayout>
  );
}

export default MyOrders;
