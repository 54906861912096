import React from 'react';
import { useHistory } from 'react-router-dom';
import Likes from '../../../../components/Likes/Likes';
import Comments from '../../../../components/Comments/Comments';
import VoteProposals from './VoteProposals';
import DOMPurify from 'dompurify';
// import commentIcon from '../../../../assets/commentIcon.svg';
// import likeIcon from '../../../../assets/likeIcon.svg';
import styles from './ProposalCard.module.css';

// Function to truncate HTML content
const truncateHTML = (htmlContent, maxLength) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlContent, 'text/html');
  let textContent = parsedHtml.body.textContent || '';
  let truncated = false;

  if (textContent.length <= maxLength) return htmlContent;

  const walk = (node) => {
    if (truncated) return;
    if (node.nodeType === 3) { // Text node
      if (node.textContent.length + textContent.length > maxLength) {
        node.textContent = node.textContent.slice(0, maxLength - textContent.length) + '...';
        truncated = true;
      } else {
        textContent += node.textContent;
      }
    } else if (node.nodeType === 1 && node.nodeName !== 'SCRIPT' && node.nodeName !== 'STYLE') { // Element node but not script/style
      for (let child of Array.from(node.childNodes)) {
        walk(child);
        if (truncated) {
          while (child.nextSibling) {
            child.parentNode.removeChild(child.nextSibling);
          }
          break;
        }
      }
    }
  };

  walk(parsedHtml.body);
  return parsedHtml.body.innerHTML;
};

const ProposalCard = ({ proposal, displayOption }) => {
  const history = useHistory();
  // Check if votes object exists and provide default values if not
  const initialVotes = proposal.votes || { support: 0, notSure: 0, doNotSupport: 0 };

  const sanitizedDescription = DOMPurify.sanitize(proposal.description);
  const truncatedDescription = truncateHTML(proposal.description, 250); // Adjust to your desired length
  const sanitizedTruncatedDescription = DOMPurify.sanitize(truncatedDescription);

  // Determine which description to use based on displayOption
  let descriptionToDisplay;

  switch (displayOption) {
    case 'titleLongDescription':
      // Truncate the long description
      descriptionToDisplay = DOMPurify.sanitize(truncateHTML(proposal.description, 250));
      break;
    case 'titleShortDescription':
      // Possibly another truncation logic for shorter descriptions
      descriptionToDisplay = DOMPurify.sanitize(truncateHTML(proposal.description, 100));
      break;
    case 'titleOnly':
      // No description
      descriptionToDisplay = '';
      break;
    default:
      // Full description without truncation
      descriptionToDisplay = DOMPurify.sanitize(proposal.description);
  }

  // Function to stop event propagation
  const handleIconClick = (event) => {
    event.stopPropagation();
    // You can add more logic here if needed
  };

  const navigateToDetails = () => {
    history.push(`/proposals/${proposal.id}`); 
  };

return (
  <div className={styles.proposalCard} onClick={navigateToDetails}>
    <h3 className={styles.title}>{proposal.title}</h3>
    <div className={styles.location}>
      {proposal.country} {' > '} {proposal.region} {' > '} {proposal.city} {' > '} {proposal.zipCode}
    </div>
    {descriptionToDisplay && (
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: descriptionToDisplay }} />
    )}
    <div className={styles.infoWrapper}>
      <div className={styles.details}>
        <span className={styles.author}>By {proposal.author}</span>
        <span className={styles.date}>at {proposal.datePosted}</span>
      </div>
      <div onClick={handleIconClick}>
        <div className={styles.interactions}>
          <VoteProposals proposalId={proposal.id} initialVotes={initialVotes} />
          <Comments proposalId={proposal.id} initialComments={proposal.comments} />
        </div>
      </div>
    </div>
  </div>
);
};

export default ProposalCard;
