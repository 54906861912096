// InvitationComponent.js
import React, { useState } from 'react';
import { useAfholder } from './AfholderContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import styles from './InvitationComponent.module.css'; // Ensure you have this CSS module file

const InvitationComponent = () => {
    const { user } = useAuthContext(); // Use the user from context
    const { sendInvitation } = useAfholder();
    const [inviteeId, setInviteeId] = useState('');
    const [message, setMessage] = useState('');

    const handleSendInvitation = async () => {
        if (!user) {
            setMessage('You must be logged in to send an invitation');
            return;
        }
        try {
            await sendInvitation(user.uid, inviteeId); // Use user's UID for the invitation
            setMessage('Invitation sent successfully');
        } catch (error) {
            setMessage('Error sending invitation');
        }
    };

    return (
        <div className={styles.invitationContainer}>
            <input
                type="text"
                value={inviteeId}
                onChange={(e) => setInviteeId(e.target.value)}
                placeholder="Invitee ID"
                className={styles.inviteInput}
            />
            <button onClick={handleSendInvitation} className={styles.inviteButton}>
                Send Invitation
            </button>
            {message && <p className={styles.message}>{message}</p>}
        </div>
    );
};

export default InvitationComponent;