import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config';

const ApprovalTracking = () => {
    const { user } = useAuthContext();
    const [approvals, setApprovals] = useState(0);

    useEffect(() => {
        if (user) {
            // Subscribe to changes in the user's document
            const unsubscribe = projectFirestore.collection('users').doc(user.uid)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        setApprovals(doc.data().approvalsReceived || 0);
                    }
                });

            return () => unsubscribe();
        }
    }, [user]);

    return (
        <div>
            <h3>Approval Status</h3>
            <p>You have received {approvals} approvals.</p>
        </div>
    );
};

export default ApprovalTracking;
