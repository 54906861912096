import React, { createContext, useContext, useState } from 'react';
import { projectFirestore } from '../../../firebase/config';


const AfholderContext = createContext();

export const useAfholder = () => useContext(AfholderContext);

export const AfholderProvider = ({ children }) => {
    const [afholderInfo, setAfholderInfo] = useState(null);

    const sendInvitation = async (userId, inviteeId) => {
        try {
            // Add your Firestore update logic here
        } catch (error) {
            console.error('Error sending invitation:', error);
        }
    };

    const handleApproval = async (userId, approverId) => {
        try {
            // Add your Firestore update logic here
        } catch (error) {
            console.error('Error handling approval:', error);
        }
    };

    const value = {
        afholderInfo, 
        sendInvitation, 
        handleApproval
    };

    return (
        <AfholderContext.Provider value={value}>
            {children}
        </AfholderContext.Provider>
    );
};
