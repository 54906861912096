import React, { createContext, useContext, useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';
import { useAuthContext } from '../hooks/useAuthContext';
import { africanCountries } from '../constants/countries';



const CountrySettingsContext = createContext();

export function useCountrySettings() {
  const context = useContext(CountrySettingsContext);
  if (context === undefined) {
    throw new Error('useCountrySettings must be used within a CountrySettingsProvider');
  }
  return context;
}

export function CountrySettingsProvider({ children }) {
  
  const [selectedCountry, setSelectedCountry] = useState({ code: '' });
  const { user } = useAuthContext();

  const fetchCountry = async () => {
    let country = {};
  
    if (user) {
      const userDocRef = projectFirestore.collection('users').doc(user.uid);
      const userDoc = await userDocRef.get();
      if (userDoc.exists) {
        const userCountryCode = userDoc.data().country;
        country = africanCountries.find(c => c.code === userCountryCode);
      }
    } else {
      country = africanCountries[0];
    }
  
    const sessionStorageCountry = sessionStorage.getItem("tempCountry");
    if (sessionStorageCountry) {
      const storedCountry = africanCountries.find(c => c.code === sessionStorageCountry);
      if (storedCountry) {
        country = storedCountry;
      }
    }
  
    // Fetch exchange rates for the selected country
    const exchangeRatesDocRef = projectFirestore.collection('currencyExRates').doc(country.currency);
    const exchangeRatesDoc = await exchangeRatesDocRef.get();
    if (exchangeRatesDoc.exists) {
      country.exchangeRates = exchangeRatesDoc.data().rates;
    }
  
    setSelectedCountry(country);
  };
  

  useEffect(() => {
    fetchCountry();
  }, [user]);



  return (
    <CountrySettingsContext.Provider value={{ selectedCountry, setSelectedCountry }}>
      {children}
    </CountrySettingsContext.Provider>
  );
}




