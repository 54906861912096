import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { projectFirestore, timestamp } from '../../../firebase/config';
import { useAuthContext } from '../../../hooks/useAuthContext';
import BookList from '../BookList';
import styles from './DeleteBook.module.css';

const DeleteBook = () => {
  const { user } = useAuthContext();
  const history = useHistory();
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const booksRef = projectFirestore.collection('anpbooks');
    booksRef.get().then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setBooks(data);
    });
  }, []);

  const handleDeleteBook = async () => {
    if (selectedBook === null) {
      alert('Please select a book to delete');
      return;
    }

    const bookRef = projectFirestore.collection('anpbooks').doc(selectedBook.id);

    // Check if the current user is the owner of the book
    const bookDoc = await bookRef.get();
    if (!bookDoc.exists || bookDoc.data().accountUserId !== user.uid) {
      setError("You are not the owner of this book.");
      return;
    }

    // Ask for confirmation before deletion
    const confirmDelete = window.confirm("Are you sure you want to delete this book? This action cannot be undone.");
    if (!confirmDelete) return;

    // Move the book to a "deletedBooks" collection before deleting
    const deletedBooksRef = projectFirestore.collection('deletedBooks').doc(selectedBook.id);
    await deletedBooksRef.set({ ...bookDoc.data(), deletedAt: timestamp() });

    // Delete the book
    bookRef.delete().then(() => {
      console.log('Book successfully deleted!');
      setError(null); // Reset error message
      alert("Book deleted successfully!");
      history.push('/homebooks');
    }).catch((error) => {
      console.error('Error deleting book: ', error);
    });
  };

  const handleChangeSelect = (e) => {
    if (books && books.length > 0) {
      setSelectedBook(books.find((book) => book.id === e.target.value));
    }
  };

  return (
    <div className={styles.deleteBook}>
      {/* To be styled later */}
      <div>
        <BookList />
      </div>
      <div className={styles.deleteBook}>
        <h2 className={styles.pageTitle}>Delete Book</h2>
        {error && <p>{error}</p>}
        <select className={styles.selectBox} onChange={handleChangeSelect}>
          <option value={null}>Select a book to delete</option>
          {books.map((book) => (
            <option key={book.id} value={book.id}>
              {book.bookName}
            </option>
          ))}
        </select>
        <button className={styles.deleteBtn} onClick={handleDeleteBook}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteBook;



