import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BooksLayout from '../../../components/Hoc/BooksLayout/BooksLayout';
import styles from './HomeBooks.module.css';
import { useCollection } from '../../../hooks/useCollection';

import { descriptionText, copyrightText } from './bookTexts'; // Adjust the path if the file is in a different directory

const paymentDetails = {
  Bitcoin: 'bc1q8gj4y7gdfgtumuqtrk45aa60hl6h228p0wdtlj',
  Ethereum: '0x07b084acdc341ffec0b48632b00a3458afc1b658',
  BNB: '0x07b084acdc341ffec0b48632b00a3458afc1b658',
  USDT: '0x07b084acdc341ffec0b48632b00a3458afc1b658',
  BankTransfer: {
    name: 'Kporye',
    address: '73 Sasso Street, GD-236-4827, East Legon, Accra',
    bankName: 'First National Bank',
    bankAddress: 'Spintex Rd shop 67 & 68 Accra Mall',
    sortCode: '330106',
    accountNumber: '62000571291',
    accountType: 'Cheque Account',
  },
};

const HomeBooks = (props) => {
  const { documents, error } = useCollection('anpbooks');
  const [book, setBook] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullCopyright, setShowFullCopyright] = useState(false);
  // Truncated texts
  const truncatedDescription = descriptionText.split('\n')[0]; // Show the first paragraph only
  const truncatedCopyright = copyrightText.split('\n')[0]; // Show the first paragraph only

  const [selectedPayment, setSelectedPayment] = useState(null);

  const handlePaymentClick = (payment) => {
    setSelectedPayment(payment);
  };
  

  useEffect(() => {
    if (documents && documents.length > 0) {
      setBook(documents[0]);
    }
  }, [documents]);

  return (
    <BooksLayout>
      <div className={styles.container}>
        <div className={styles.header}>Featured Book</div>
        {error && <p className={styles.error}>There was an error loading the book. Please try again later.</p>}
        {book ? (
          <div className={styles.bookInfo}>
            <h3>{book.bookName}</h3>
            <p>Author: {book.bookAuthor}</p>
            <p>Genre: {book.bookGenre}</p>
            <p>Type: {book.bookType}</p>
            {book.bookName === 'African Nonism & Peomocracy' && (
              <Link to={`/chapters/${book.id}`} className={styles.link}>View Chapters</Link>
            )}
          </div>
        ) : (
          <p>Loading...</p>
        )}

        {/* Description Text */}
        <div className={styles.textSection}>
          <h3>Description of African Nonism & Peomocracy</h3>
          {(showFullDescription ? descriptionText : truncatedDescription).split('\n').map((paragraph, index) => (
            <p key={index} className={styles.paragraph}>
              {paragraph}
            </p>
          ))}
          <button onClick={() => setShowFullDescription(!showFullDescription)} className={styles.readMore}>
            {showFullDescription ? 'Read Less' : 'Read More'}
          </button>
        </div>

        <div className={styles.textSection}>
          <h3>Copyright Notice</h3>
          {(showFullCopyright ? copyrightText : truncatedCopyright).split('\n').map((paragraph, index) => (
            <p key={index} className={styles.paragraph}>
              {paragraph}
            </p>
          ))}
          <button onClick={() => setShowFullCopyright(!showFullCopyright)} className={styles.readMore}>
            {showFullCopyright ? 'Read Less' : 'Read More'}
          </button>
        </div>


        {/* <div className={styles.supportArea}>
          <h3>Support the Initiative</h3>
          <p>This book is free, but you can “Pay-What-You-Want” to reward us for work being done.</p>
          <div className={styles.paymentOptions}>
            <button>Bitcoin</button>
            <button>Ethereum</button>
            <button>BNB</button>
            <button>USDT</button>
            <button>Bank Transfer</button>
          </div>
        </div> */}
        <div className={styles.supportArea}>
        <h3>Support the Initiative</h3>
        <p>This book is free, but you can “Pay-What-You-Want” to reward us for work being done.</p>
        <div className={styles.paymentOptions}>
          {Object.keys(paymentDetails).map((payment) => (
            <button key={payment} onClick={() => handlePaymentClick(payment)}>
              {payment}
            </button>
          ))}
        </div>
        {selectedPayment && (
          <div className={styles.paymentDetails}>
            <h4>{selectedPayment} Details:</h4>
            {typeof paymentDetails[selectedPayment] === 'string' ? (
              <p>{paymentDetails[selectedPayment]}</p>
            ) : (
              Object.entries(paymentDetails[selectedPayment]).map(([key, value]) => (
                <p key={key}>
                  <strong>{key}:</strong> {value}
                </p>
              ))
            )}
          </div>
        )}
      </div>
      </div>
    </BooksLayout>
  );
};

export default HomeBooks;



// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom'; // make sure you imported Link
// import BooksLayout from '../../../components/Hoc/BooksLayout/BooksLayout';
// import styles from './HomeBooks.module.css';
// import { useCollection } from '../../../hooks/useCollection';

// const HomeBooks = (props) => {
//   const { documents, error } = useCollection('anpbooks');
//   const [book, setBook] = useState(null);

//   useEffect(() => {
//     if (documents && documents.length > 0) {
//       setBook(documents[0]);
//     }
//   }, [documents]);

//   return (
//     <BooksLayout>
//       <div className={styles.container}>
//         <div className={styles.header}>Featured Book</div>
//         {error && <p className={styles.error}>There was an error loading the book. Please try again later.</p>}
//         {book ? (
//           <div className={styles.bookInfo}>
//             <h3>{book.bookName}</h3>
//             <p>Author: {book.bookAuthor}</p>
//             <p>Genre: {book.bookGenre}</p>
//             <p>Type: {book.bookType}</p>
//             {book.bookName === 'African Nonism & Peomocracy' && (
//               <Link to={`/chapters/${book.id}`} className={styles.link}>View Chapters</Link>
//             )}
//           </div>
//         ) : (
//           <p>Loading...</p>
//         )}
//         <div className={styles.supportArea}>
//           <h3>Support the Author</h3>
//           <p>This book is free, but you can pay what you want to support the author.</p>
//           <div className={styles.paymentOptions}>
//             <button>Bitcoin</button>
//             <button>Ethereum</button>
//             <button>BNB</button>
//             <button>USDT</button>
//             <button>Bank Transfer</button>
//           </div>
//         </div>
//       </div>
//     </BooksLayout>
//   );
// };

// export default HomeBooks;




// import React, { useState, useEffect } from 'react';
// import { useCollection } from '../../../hooks/useCollection';
// import BooksLayout from '../../../components/Hoc/BooksLayout/BooksLayout';
// import { projectFirestore } from '../../../firebase/config';
// import { Link } from 'react-router-dom';
// import styles from './HomeBooks.module.css';

// const HomeBooks = (props) => {
//   const { documents, error } = useCollection('anpbooks');
//   const [book, setBook] = useState(null);

//   useEffect(() => {
//     if (documents && documents.length > 0) {
//       // Assuming the first book in the collection is the one you want to display
//       setBook(documents[0]);
//     }
//   }, [documents]);

//   const renderItem = (book) => {
//     return (
//       <>
//         <h3>{book.bookName}</h3>
//         <p>Author: {book.bookAuthor}</p>
//         <p>Genre: {book.bookGenre}</p>
//         <p>Type: {book.bookType}</p>
//       </>
//     );
//   };

//   return (
//     <BooksLayout>
//       <div className={styles.container}>
//         <header className={styles.header}>
//           <div className={styles.logo}></div>
//         </header>
//         <section className={styles.bookSection}>
//           <h2>Featured Book</h2>
//           {error ? (
//             <p>There was an error loading the book. Please try again later.</p>
//           ) : book ? (
//             renderItem(book)
//           ) : (
//             <p>Loading...</p>
//           )}
//           <h3>Support the Author</h3>
//           <p>
//             This book is free, but you can pay what you want to support the author.
//           </p>
//           <div>
//             <button>Bitcoin</button>
//             <button>Ethereum</button>
//             <button>BNB</button>
//             <button>USDT</button>
//             <button>Bank Transfer</button>
//           </div>
//         </section>
//       </div>
//     </BooksLayout>
//   );
// };

// export default HomeBooks;



// import React, { useState, useEffect, useContext } from 'react';
// import { useCollection } from '../../../hooks/useCollection';
// import Cart from '../../../components/ShoppingCart/Cart';
// import BookList from '../BookList';
// import { CartProvider } from '../../../context/CartContext';
// import BooksLayout from '../../../components/Hoc/BooksLayout/BooksLayout';
// import Pagination from '../../../components/Paginations/Pagination';
// import { projectFirestore } from '../../../firebase/config';
// import { genres, languages } from '../../../constants/bookData';
// import { CartContext } from '../../../context/CartContext';

// import styles from './HomeBooks.module.css';

// const HomeBooks = (props) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const { documents, error } = useCollection('anpbooks');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [booksPerPage] = useState(6);
//   const [sortBy, setSortBy] = useState('');
//   const [filterByGenre, setFilterByGenre] = useState('');
//   const [filterByLanguage, setFilterByLanguage] = useState('');
//   const [filteredBooks, setFilteredBooks] = useState([]);
//   const [sortOrder, setSortOrder] = useState('asc');
//   const indexOfLastBook = currentPage * booksPerPage;
//   const indexOfFirstBook = indexOfLastBook - booksPerPage;
//   const { cartItems } = useContext(CartContext);



//   const renderItem = (book) => {
//     let displayPrice;
  
//     if (book.bookType === 'Free Book') {
//       displayPrice = '0.00 EUR';
//     } else if (isNaN(parseFloat(book.bookPrice))) {
//       displayPrice = 'Price not available';
//     } else {
//       displayPrice = parseFloat(book.bookPrice).toFixed(2) + ' EUR';
//     }
  
//     const displayPriceWithSubscription =
//       book.bookType === 'Serialized Book'
//         ? `${displayPrice} pm`
//         : displayPrice;
  
//     return (
//       <>
//         <h3>{book.bookName}</h3>
//         <p>Author: {book.bookAuthor}</p>
//         <p>Genre: {book.bookGenre}</p>
//         <p>Type: {book.bookType}</p>
//         <p>Price: {displayPriceWithSubscription}</p>
//       </>
//     );
//   };
  


//   useEffect(() => {
//     if (!documents) {
//       return;
//     }

//     let updatedBooks = [...documents];

//     if (filterByGenre) {
//       updatedBooks = updatedBooks.filter((book) => book.bookGenre === filterByGenre);
//     }

//     if (filterByLanguage) {
//       updatedBooks = updatedBooks.filter((book) => book.bookLanguage === filterByLanguage);
//     }

//     if (sortBy) {
//       updatedBooks.sort((a, b) => {
//         const aValue = a[sortBy];
//         const bValue = b[sortBy];
//         let comparison = 0;

//         if (typeof aValue === 'string') {
//           comparison = aValue.localeCompare(bValue);
//         } else if (typeof aValue === 'number') {
//           comparison = aValue - bValue;
//         } else if (aValue instanceof Date) {
//           comparison = aValue.getTime() - bValue.getTime();
//         }

//         return sortOrder === 'asc' ? comparison : -comparison;
//       });
//     }

//     setFilteredBooks(updatedBooks);
//   }, [documents, sortBy, sortOrder, filterByGenre, filterByLanguage]);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <>
//       <BooksLayout>
//         <CartProvider>
//           <div className={styles.container}>
//             <header className={styles.header}>
//               <div className={styles.logo}></div>
//               <div className={styles.search}>
//                 <input
//                   type="text"
//                   placeholder="Search for books"
//                   value={searchQuery}
//                   onChange={(e) => setSearchQuery(e.target.value)}
//                 />
//                 <button>Search</button>
//               </div>
//             </header>

//             <section className={styles.bookList}>
//               <h2>Browse Books</h2>
//               <div className={styles.filters}>
//                 <select
//                   value={`${sortBy}:${sortOrder}`}
//                   onChange={(e) => {
//                     const [sort, order] = e.target.value.split(':');
//                     setSortBy(sort);
//                     setSortOrder(order);
//                   }}
//                 >
//                   <option value="">Sort by</option>
//                   <option value="bookName:asc">Title (A-Z)</option>
//                   <option value="bookName:desc">Title (Z-A)</option>
//                   <option value="bookAuthor:asc">Author (A-Z)</option>
//                   <option value="bookAuthor:desc">Author (Z-A)</option>
//                   <option value="rating:asc">Rating (Low to High)</option>
//                   <option value="rating:desc">Rating (High to Low)</option>
//                   <option value="createdAt:asc">Release Date (Old to New)</option>
//                   <option value="createdAt:desc">Release Date (New to Old)</option>
//                 </select>

//                 <select
//                   value={filterByGenre}
//                   onChange={(e) => setFilterByGenre(e.target.value)}
//                 >
//                   <option value="">Filter by Genre</option>
//                   {genres.map((genre) => (
//                     <option key={genre} value={genre}>
//                       {genre}
//                     </option>
//                   ))}
//                 </select>
//                 <select
//                   value={filterByLanguage}
//                   onChange={(e) => setFilterByLanguage(e.target.value)}
//                 >
//                   <option value="">Filter by Language</option>
//                   {languages.map((language) => (
//                     <option key={language} value={language}>
//                       {language}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               {error ? (
//                 <p>There was an error loading the books. Please try again later.</p>
//               ) : (
//                 <BookList
//                   documents={filteredBooks.slice(indexOfFirstBook, indexOfLastBook)}
//                   renderItem={(book) => renderItem(book)}
//                 />
//               )}
//             </section>
//             <section className={styles.cart}>
//               <h2>Your Cart</h2>
//               <Cart />
//             </section>
//             <section className={styles.pagination}>
//               <Pagination
//                 currentPage={currentPage}
//                 itemsPerPage={booksPerPage}
//                 totalItems={filteredBooks.length}
//                 paginate={paginate}
//               />
//             </section>
//           </div>
//         </CartProvider>
//       </BooksLayout>
//     </>
//   );
// };

// export default HomeBooks;




