import React, { useState, useEffect } from "react";
import ProposalLayout from '../../../../components/Hoc/ProposalLayout/ProposalLayout';
import ProposalCard from "./ProposalCard";
import { ProposalFilter } from "./ProposalFilter";
import { useHistory } from 'react-router-dom';
import { projectFirestore, timestamp } from '../../../../firebase/config';
import { useAuthContext } from "../../../../hooks/useAuthContext";

import styles from "./ProposalLanding.module.css"; 

export const ProposalLanding = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchInput, setSearchInput] = useState(''); // Define state for the search input
  const [debounceTimeoutId, setDebounceTimeoutId] = useState(null);
  const [proposals, setProposals] = useState([]); // for main proposals
  const [popularProposals, setPopularProposals] = useState([]); // for popular proposals
  const [filterCriteria, setFilterCriteria] = useState({});
  const [categoryFilter, setCategoryFilter] = useState("");
  const [displayOption, setDisplayOption] = useState("titleLongDescription");
  const [showFilter, setShowFilter] = useState(false);
  const [filteredProposals, setFilteredProposals] = useState([]);
  const [currentTab, setCurrentTab] = useState("all");

  // Set the delay for the debounce (e.g., 500 milliseconds)
  const debounceDelay = 500;

  // Handle search input change with debounce
  const handleSearchChange = (e) => {
      const inputValue = e.target.value.toLowerCase();
      setSearchInput(inputValue); // Update search input state

      // Clear the existing timeout
      if (debounceTimeoutId) {
          clearTimeout(debounceTimeoutId);
      }

      // Set a new timeout
      const timeoutId = setTimeout(() => {
          setSearchTerm(inputValue);
      }, debounceDelay);

      setDebounceTimeoutId(timeoutId);
  };

  // Filter proposals based on search term
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredProposals(proposals);
    } else {
      const filtered = proposals.filter(proposal => 
        proposal.title.toLowerCase().includes(searchTerm) || 
        proposal.description.toLowerCase().includes(searchTerm)
      );
      setFilteredProposals(filtered);
    }
  }, [searchTerm, proposals]);

  useEffect(() => {
    // Cleanup function to clear timeout when component unmounts
    return () => {
        if (debounceTimeoutId) {
            clearTimeout(debounceTimeoutId);
        }
    };
}, [debounceTimeoutId]);

  // useEffect(() => {
  //   const unsubscribe = projectFirestore.collection('proposals').onSnapshot(
  //     (snapshot) => {
  //       const proposalsArray = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setProposals(proposalsArray);
  //       setFilteredProposals(proposalsArray); // Initialize with all proposals
  //     },
  //     (error) => {
  //       console.error("Error fetching proposals: ", error);
  //     }
  //   );
  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const unsubscribe = projectFirestore.collection('proposals').onSnapshot(
        (snapshot) => {
            let fetchedProposals = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            // let trendingProposals = fetchedProposals.filter(p => {
            //     let totalVotes = p.votes.support + p.votes.notSure + p.votes.doNotSupport;
            //     let supportPercentage = totalVotes ? (p.votes.support / totalVotes * 100) : 0;
            //     return supportPercentage >= 80;
            // });
            let trendingProposals = fetchedProposals.filter(p => {
              if (!p.votes || typeof p.votes.support === 'undefined' || typeof p.votes.notSure === 'undefined' || typeof p.votes.doNotSupport === 'undefined') {
                  return false; // Skip this proposal if votes object or its properties are missing
              }
          
              let totalVotes = p.votes.support + p.votes.notSure + p.votes.doNotSupport;
              let supportPercentage = totalVotes ? (p.votes.support / totalVotes * 100) : 0;
              return supportPercentage >= 80;
          });
          

            setProposals(fetchedProposals);
            setPopularProposals(trendingProposals); // Now only proposals with >= 80% support
        },
        (error) => {
            console.error("Error fetching real-time proposals: ", error);
        }
    );
    return () => unsubscribe();
}, []);

  
  const handleTabClick = (tab) => {
    setCurrentTab(tab);
    switch (tab) {
      case "all":
        setFilteredProposals(proposals);
        break;
      case "my":
        setFilteredProposals(proposals.filter(p => p.authorId === user.uid));
        break;
      case "liked":
        // Assuming you have a mechanism to track liked proposals
        // For example, 'likedBy' array in each proposal document
        setFilteredProposals(proposals.filter(p => p.likedBy.includes(user.uid)));
        break;
      default:
        setFilteredProposals(proposals);
    }
  };


  const handleAddClick = () => {
    history.push('/addproposal'); 
  };


  const handleFilterChange = (newFilter) => {
    setFilterCriteria(newFilter);
  
    // Filter proposals based on the newFilter criteria
    // Assuming newFilter has a date property
    const filtered = proposals.filter(proposal => {
      const proposalDate = new Date(proposal.createdAt.seconds * 1000); // Adjust this based on your date format
      return proposalDate >= new Date(newFilter.startDate) && proposalDate <= new Date(newFilter.endDate);
    });
  
    setFilteredProposals(filtered);
  };
  

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  
    // Check if 'all' category is selected. If so, display all proposals
    if (e.target.value === 'all') {
      setFilteredProposals(proposals);
    } else {
      // Otherwise, filter proposals by the selected category
      const filteredByCategory = proposals.filter(proposal => proposal.category === e.target.value);
      setFilteredProposals(filteredByCategory);
    }
  };
  

  const handleDisplayOptionChange = (e) => {
    setDisplayOption(e.target.value);
    // Implement display option logic here...
  };

  // Toggle filter form visibility
  const toggleFilter = () => setShowFilter(!showFilter);


  useEffect(() => {
    // Fetch proposals and apply filters
    const unsubscribe = projectFirestore.collection('proposals').onSnapshot(
        (snapshot) => {
            let fetchedProposals = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Apply geographical filter
            if (filterCriteria.country) {
              fetchedProposals = fetchedProposals.filter(p => p.country === filterCriteria.country);
            }
            // Apply other filters like region, city, zipCode as needed...

            // Apply category filter if not 'all'
            if (categoryFilter && categoryFilter !== 'all') {
              fetchedProposals = fetchedProposals.filter(p => p.category === categoryFilter);
            }

            setProposals(fetchedProposals);
            setPopularProposals(fetchedProposals); // Modify as needed for popular proposals
        },
        (error) => {
            console.error("Error fetching real-time proposals: ", error);
        }
    );
    return () => unsubscribe();
  }, [categoryFilter, filterCriteria]); // Add dependencies

  return (
    <ProposalLayout>
    <div className={styles.flexContainer}>
    <div className={styles.proposalLanding}>
      
      <div className={styles.tabsContainer}>
        {/* <div className={styles.allProposals}>ALL PROPOSALS</div> */}
        <div 
          className={`${styles.tab} ${currentTab === 'all' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick("all")}
        >
          ALL PROPOSALS
        </div>
        {/* <div className={styles.myProposal}>MY PROPOSAL</div> */}
        <div 
          className={`${styles.tab} ${currentTab === 'my' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick("my")}
        >
          MY PROPOSALS
        </div>
        {/* <div className={styles.likedProposal}>LIKED PROPOSAL</div> */}
        <div 
          className={`${styles.tab} ${currentTab === 'liked' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick("liked")}
        >
          LIKED PROPOSALS
        </div>
      </div>
      <div className={styles.proposalsHeader}>
        <div className={styles.totalProposals}>
          {filteredProposals.length} PROPOSALS AVAILABLE
        </div>
        <button className={styles.addProposalButton} onClick={handleAddClick}>+ Add Proposal</button>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.leftColumn}>
        
          <div className={styles.searchAndAdd}>
            <input
              type="text"
              placeholder="Search Proposals..."
              className={styles.searchBar}
              onChange={handleSearchChange}
            />
            {/* <select className={styles.dropdown}> */}
            <select className={styles.dropdown} value={categoryFilter} onChange={handleCategoryChange}>
              <option value="all">All Proposals</option>
              <option value="Education and Research">Education and Research</option>
              <option value="Health and Wellness">Health and Wellness</option>
              <option value="Technology and Innovation">Technology and Innovation</option>
              <option value="Environment and Sustainability">Environment and Sustainability</option>
              <option value="Community Development">Community Development</option>
              <option value="Arts and Culture">Arts and Culture</option>
              <option value="Business and Economics">Business and Economics</option>
              <option value="Social Issues and Advocacy">Social Issues and Advocacy</option>
              <option value="Infrastructure and Urban Planning">Infrastructure and Urban Planning</option>
              <option value="Science and Space Exploration">Science and Space Exploration</option>
              {/* Add more options here */}
            </select>
            {/* <select className={styles.dropdown}> */}
            <select className={styles.dropdown} value={displayOption} onChange={handleDisplayOptionChange}>
              <option value="titleLongDescription">Title & Long Description</option>
              <option value="titleShortDescription">Title & Short Description</option>
              <option value="titleOnly">Only Title</option>
              {/* Add more options here */}
            </select>
            <button onClick={toggleFilter} className={styles.filterButton}>Filter</button>
            {showFilter && <ProposalFilter onFilterChange={handleFilterChange} />}
            
          </div>

          <div className={styles.proposalGrid}>
            {proposals.map((proposal) => (
              // Pass the display option to each ProposalCard
              <ProposalCard key={proposal.id} proposal={proposal} displayOption={displayOption} />
            ))}
          </div>
        
        </div>
        {/* <div className={styles.rightColumn}>
          <div className={styles.popularProposalsTitle}>POPULAR PROPOSALS</div>

          {popularProposals.map((proposal) => (
            // Pass the display option to each ProposalCard
            <ProposalCard key={proposal.id} proposal={proposal} displayOption={displayOption} />
          ))}
        </div> */}
        <div className={styles.rightColumn}>
            <div className={styles.popularProposalsTitle}>POPULAR PROPOSALS</div>
            {popularProposals.map((proposal) => (
                <ProposalCard key={proposal.id} proposal={proposal} displayOption={displayOption} />
            ))}
        </div>



      </div>
    </div>
    
    </div>
    </ProposalLayout>
  );

};
