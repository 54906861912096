import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../../firebase/config';

import styles from './UpdateBook.module.css';

function UpdateBook2({ defaultCountry }) {
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [bookName, setBookName] = useState(''); 
  const [bookAuthor, setBookAuthor] = useState('');
  const [bookType, setBookType] = useState('Subscription');

  // Listen to Firestore changes in real-time
  useEffect(() => {
    const unsubscribe = projectFirestore.collection('anpbooks').onSnapshot((snapshot) => {
      let fetchedBooks = [];
      snapshot.forEach(doc => {
        fetchedBooks.push({ id: doc.id, ...doc.data() });
      });
      setBooks(fetchedBooks);
    });

    return () => unsubscribe(); // Clean-up
  }, []);

  // Populate the form fields whenever selectedBook changes
  useEffect(() => {
    if (selectedBook) {
      setBookName(selectedBook.bookName);
      setBookAuthor(selectedBook.bookAuthor);
      setBookType(selectedBook.bookType);
      // Add other fields that need to be set
    }
  }, [selectedBook]);

  const handleSelectedBook = (e) => {
    const selectedId = e.target.value;
    const book = books.find((b) => b.id === selectedId);
    setSelectedBook(book);
  };

  return (
    <div>
      {/* Your select dropdown here */}
      <select onChange={handleSelectedBook}>
        <option value="" disabled selected>Select a book</option>
        {books.map(book => (
          <option key={book.id} value={book.id}>{book.bookName}</option>
        ))}
      </select>

      {/* Your form fields here */}
      <input type="text" value={bookName} onChange={(e) => setBookName(e.target.value)} placeholder="Book Name" />
      <input type="text" value={bookAuthor} onChange={(e) => setBookAuthor(e.target.value)} placeholder="Book Author" />
      <input type="text" value={bookType} onChange={(e) => setBookType(e.target.value)} placeholder="Book Type" />
      
      {/* Add other form fields as needed */}

      {/* Your submit button here */}
      <button type="submit">Update Book</button>
    </div>
  );
}

export default UpdateBook2;
