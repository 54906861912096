import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './WeAreLookingFor.module.css';

export default function WeAreLookingFor() {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState({ text: '', author: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const snapshot = await projectFirestore.collection('wearelookingfor').get();
        const fetchedMessages = snapshot.docs.map(doc => {
          const data = doc.data();
          return { title: data.title, text: data.message, author: data.author };
        });

        setMessages(fetchedMessages);
        if (fetchedMessages.length > 0) {
          setCurrentMessage(fetchedMessages[0]);
        }
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (messages.length > 0) {
        setCurrentMessage(messages[Math.floor(Math.random() * messages.length)]);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [messages]);

  if (isLoading) {
    return <div className={styles.card}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.card}>Error: {error}</div>;
  }

  if (messages.length === 0 && !isLoading) {
    return <div className={styles.card}>No messages found.</div>;
  }

return (
  <div className={styles.card}>
    <h5>So, What Is Next</h5>
    {currentMessage && (
      <>
      <br />
      <h3 className={styles.messageTitle}>{currentMessage.title}</h3> {/* Display the title */}
        <div 
          className={styles.messageContent} 
          dangerouslySetInnerHTML={createMarkup(currentMessage.text)}
        ></div>
        <p className={styles.author}>{currentMessage.author || 'Anonymous'}</p>
      </>
    )}
  </div>
);
}
