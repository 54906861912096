import React from 'react';
import styles from './Services.module.css';

export default function Services() {
  return (
    <div className={styles.servicesContainer}>
      <h2>Feedback Boameooo</h2>
      <p>"To contain Boameooo services info".</p>
    </div>
  );
}
