import ServicesNavBar from '../servicesnavbar/servicesnavbar';
import Footer from './footer/Footer';
import styles from './serviceshomepage.module.css'

function ServicesHomePage() {
  return (
    <div className={styles.container}>
      <ServicesNavBar />
      <HeaderSection />
      <ServiceCategories />
      <PromotionalBanner />
      <ServiceListings />
      <Footer /> 
    </div>
  );
}



function HeaderSection() {
  return (
      <header className={styles.header}>
          <div className={styles.overlap38}>
              <div className={styles.rectangle61} />
              <img className={styles.maskGroup12} alt="Mask group" src="/assets/mask-group-25.png" />
              <p className={styles.boameooo}>
                  <span>Boameooo</span>
              </p>
              <div className={styles.group272}>
                  <p className={styles.servicesOnDemand}>
                      <span>Services on Demand in Ghana</span>
                  </p>
                  <div className={styles.group273}>
                      <div className={styles.overlapGroup38}>
                          <div className={styles.group274}>
                              <div className={styles.frame31} />
                              <p className={styles.nungua}>
                                  <span>Nungua, Greater Accra, Ghana</span>
                              </p>
                              <img className={styles.rectangle62} alt="Rectangle" src="/assets/rectangle-26-32.png" />
                          </div>
                          <p className={styles.searchForProduct}>
                              <span>Search for product, category and more</span>
                          </p>
                          <div className={styles.frame32} />
                          <div className={styles.rectangle63} />
                      </div>
                  </div>
              </div>
              <div className={styles.group275}>
                  <p className={styles.myAccount}>
                      <span>My Account</span>
                  </p>
                  <img className={styles.vector14} alt="Vector" src="/assets/vector-72.png" />
              </div>
              <div className={styles.group276}>
                  <div className={styles.group277}>
                      <p className={styles.categories}>
                          <span>Categories</span>
                      </p>
                      <img className={styles.vector15} alt="Vector" src="/assets/vector-125.png" />
                  </div>
              </div>
              <div className={styles.group278}>
                  <img className={styles.vector16} alt="Vector" src="/assets/vector-123.png" />
              </div>
          </div>
      </header>
  );
}

function ServiceCategories() {
  console.log("ServiceCategories is rendering!"); // This line
  return (
    <div className={styles.serviceCategories}>
      <div className={styles.topCategories}>
        
        <div className={styles.frame33}>
          <div className={styles.group279}>
            <img className={styles.vector17} alt="Vector" src="/assets/vector-35.png" />
            <p className={styles.applianceRepair}>
              <span>Appliance Repair<br/>Services</span>
            </p>
          </div>
        </div>

        <div className={styles.frame34}>
          <div className={styles.group280}>
            <img className={styles.frame35} alt="Frame" src="/assets/frame-1.png" />
            <p className={styles.spanWrapper60}>
              <span>Home Cleaning<br/>Services</span>
            </p>
          </div>
        </div>

        <div className={styles.frame36}>
          <div className={styles.group281}>
            <img className={styles.vector18} alt="Vector" src="/assets/vector-36.png" />
            <p className={styles.spanWrapper61}>
              <span>Hair Dressing<br/>Services</span>
            </p>
          </div>
        </div>

        <div className={styles.frame37}>
          <div className={styles.group282}>
            <img className={styles.union} alt="Union" src="/assets/union-2.png" />
            <p className={styles.categories2}>
              <span>Salon, Spa and Massage<br/>Services</span>
            </p>
          </div>
        </div>

        <div className={styles.frame38}>
          <div className={styles.group283}>
            <img className={styles.frame39} alt="Frame" src="/assets/frame-4.png" />
            <p className={styles.spanWrapper62}>
              <span>Plumbers &amp; Carpenters<br/>Services</span>
            </p>
          </div>
        </div>

      </div>
    </div>
  );
}





function PromotionalBanner() {
  return (
    <div className={styles.banners}>
      <img className={styles.maskGroup13} alt="Mask group" src="/assets/mask-group-26.png" />
      <img className={styles.maskGroup14} alt="Mask group" src="/assets/mask-group-27.png" />
      <img className={styles.vector19} alt="Vector" src="/assets/vector-29.png" />
      <img className={styles.vector20} alt="Vector" src="/assets/vector-30.png" />
    </div>
  );
}

function ServiceListings() {
  return (
    <div>
      {renderServiceList('Cleaning Services', [
        { img: "/assets/mask-group-39.png", title: "Full Home Cleaning" },
        { img: "/assets/mask-group-29.png", title: "Bathroom Cleaning" },
        { img: "/assets/mask-group-30.png", title: "Kitchen Cleaning" }
      ])}
      {renderServiceList('Salon, Spa and Massage Services', [
        { img: "/assets/mask-group-31.png", title: "Salon At Home" },
        { img: "/assets/mask-group-32.png", title: "Massage Therapy For Men" },
        { img: "/assets/mask-group-33.png", title: "Makeup And Hairstyling" }
      ])}
      {renderServiceList('Appliance Repair Services', [
        { img: "/assets/mask-group-34.png", title: "Electricians" },
        { img: "/assets/mask-group-35.png", title: "Plumbers" },
        { img: "/assets/mask-group-36.png", title: "Carpenters" }
      ])}
    </div>
  );
}

function renderServiceList(title, services) {
  return (
    <div className={styles.serviceSection}>
      <p className={styles.serviceTitle}>
        <span>{title}</span>
      </p>
      <div className={styles.serviceGroup}>
        {services.map(service => (
          <div className={styles.serviceItem} key={service.title}>
            <img className={styles.serviceImage} alt="Service" src={service.img} />
            <p className={styles.serviceName}>
              <span>{service.title}</span>
            </p>
          </div>
        ))}
      </div>
      <p className={styles.viewAll}>
        <span>View All</span>
      </p>
    </div>
  );
}



export default ServicesHomePage;
