// While the name suggests it could handle adding addresses, this component is more about listing and managing 
// existing addresses. It allows users to select from their existing addresses, typically for purposes such as 
// choosing a shipping or billing address during checkout.

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { projectFirestore } from '../../firebase/config';
import AddEditAddressForm from './AddEditAddressForm';
import { useCountrySettings } from '../../context/CountrySettingsContext';
import { africanCountries } from '../../constants/countries';
import { useAddressUpdate } from '../../context/AddressUpdateContext';


import styles from './AddressManager.module.css';

const AddressManager = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const { setAddressUpdated } = useAddressUpdate();
  const { selectedCountry } = useCountrySettings();
  const [addresses, setAddresses] = useState([]);
  const [editingAddress, setEditingAddress] = useState(null);
  const [expandedAddresses, setExpandedAddresses] = useState(new Set()); // Track expanded addresses

  const navigateToSendings = () => {
    history.push('/sendings'); // Replace '/sendings' with the actual route to your Sendings component
  };
  

  useEffect(() => {
    const fetchAddresses = async () => {
      const snapshot = await projectFirestore
        .collection('shippingAddresses')
        .where('userId', '==', user.uid)
        .get();

      const fetchedAddresses = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        country: doc.data().country || selectedCountry || 'Ghana', // Default to selected country or Ghana
        latitude: doc.data().latitude || '',
        longitude: doc.data().longitude || ''
      }));
      setAddresses(fetchedAddresses);
    };

    fetchAddresses();
  }, [user.uid, selectedCountry]);

  const toggleAddressView = (addressId) => {
    setExpandedAddresses(prev => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(addressId)) {
        newExpanded.delete(addressId);
      } else {
        newExpanded.add(addressId);
      }
      return newExpanded;
    });
  };

  const handleEdit = (address) => {
    setEditingAddress(address);
  };

  const handleDelete = async (addressId) => {
    await projectFirestore.collection('shippingAddresses').doc(addressId).delete();
    setAddresses(addresses.filter(addr => addr.id !== addressId));
  };

  // Add new address
  const handleAddShippingAddress = async (newAddress) => {
    console.log("Adding new address:", newAddress);
    try {
      const addressRef = await projectFirestore.collection('shippingAddresses').add({
        ...newAddress,
        userId: user.uid
      });

      setAddresses([...addresses, { id: addressRef.id, ...newAddress }]);
      setAddressUpdated(true);
    } catch (error) {
      console.error("Error adding address: ", error);
    }
  };

  // Edit existing address
  const handleEditShippingAddress = async (addressId, updatedAddress) => {
    console.log("Updating address with ID:", addressId, "Data:", updatedAddress);
    try {
      await projectFirestore.collection('shippingAddresses').doc(addressId).update(updatedAddress);
      
      setAddresses(addresses.map(addr => addr.id === addressId ? { ...addr, ...updatedAddress } : addr));
      setAddressUpdated(true);
    } catch (error) {
      console.error("Error updating address: ", error);
    }
  };

  // Delete address
  const handleDeleteShippingAddress = async (addressId) => {
    try {
      await projectFirestore.collection('shippingAddresses').doc(addressId).delete();
      
      setAddresses(addresses.filter(addr => addr.id !== addressId));
      setAddressUpdated(true);
    } catch (error) {
      console.error("Error deleting address: ", error);
    }
  };

return (
    <div className={styles.addressManagerContainer}>
      <h2>Your Addresses</h2>
      <button onClick={() => setEditingAddress({})}>Add New Address</button>
      

      {addresses.length === 0 ? <p>No addresses found.</p> : (
        <ul className={styles.addressList}>
          {addresses.map(address => (
            <li key={address.id} className={styles.addressItem}>
              <div className={expandedAddresses.has(address.id) ? styles.longAddressFormat : styles.shortAddressFormat}>
                {expandedAddresses.has(address.id) ? (
                  <>
                    <p>Address Type: {address.addressType}</p>
                    <p>Name: {address.fullName}</p>
                    <p>Street: {address.street}</p>
                    <p>City: {address.city}</p>
                    <p>Zip Code: {address.zipCode}</p>
                    <p>Country: {address.country}</p>
                    <p>Coordinates: Lat {address.latitude}, Long {address.longitude}</p>
                  </>
                ) : (
                  <p>{address.addressType}: {address.fullName}, {address.street}, {address.city}, {address.zipCode}</p>
                )}
              </div>
              <div className={styles.addressActions}>
                <button className={styles.toggleButton} onClick={() => toggleAddressView(address.id)}>
                  {expandedAddresses.has(address.id) ? 'Show Less' : 'Show More'}
                </button>
                <button className={styles.editButton} onClick={() => handleEdit(address)}>Edit</button>
                <button className={styles.deleteButton} onClick={() => handleDelete(address.id)}>Delete</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* EditAddressForm component */}
      {editingAddress && (
        <AddEditAddressForm
          address={editingAddress}
          onSave={(updatedAddress) => {
            console.log("onSave called in EditAddressForm", updatedAddress);
          const isNewAddress = !editingAddress.id;
          if (isNewAddress) {
            // Call the function to add a new address to Firestore
            handleAddShippingAddress(updatedAddress);
          } else {
            // Call the function to update an existing address in Firestore
            handleEditShippingAddress(editingAddress.id, updatedAddress);
          }
          setEditingAddress(null);
        }}
        onCancel={() => setEditingAddress(null)}
      />
    )}
      <button onClick={navigateToSendings} className={styles.backToSendingsButton}>
          Back to Sendings
      </button>
    </div>
  );
};

export default AddressManager;



