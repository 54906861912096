import React from 'react';
// import { useParams } from 'react-router-dom';
import { useParams, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import BooksLayoutBlank from '../../components/Hoc/BooksLayout/BooksLayoutBlank';

import styles from './Dchapters.module.css';

const Dchapters = () => {
  const { id, index } = useParams();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [chapter, setChapter] = useState(null);
  const numericIndex = parseInt(index); // Convert the string to a number
  const history = useHistory(); // <-- Call the useHistory hook
  
  useEffect(() => {
    setIsPending(true);
    projectFirestore.collection('anpbooks').doc(id).get().then(doc => {
      if (doc.exists) {
        setIsPending(false);
        setChapter(doc.data());
      } else {
        setIsPending(false);
        setError(`Could not find the chapter`);
      }
    });
  }, [id, index]);

  const chapterTitle = chapter?.chapterTitles?.[numericIndex]?.title;
  const chapterContent = chapter?.chapterContent?.[numericIndex];

  // Handler functions for the buttons
  const goToPreviousChapter = () => {
    if (numericIndex > 0) {
      history.push(`/dchapters/${id}/${numericIndex - 1}`); // Adjust the path accordingly
    }
  };

  const goToNextChapter = () => {
    if (numericIndex < chapter?.chapterTitles?.length - 1 && chapter?.chapterTitles?.[numericIndex + 1]?.isActive) {
      history.push(`/dchapters/${id}/${numericIndex + 1}`); // Adjust the path accordingly
    }
  };

  return (
    <BooksLayoutBlank>
      <div className={styles.container}>
        {error && <p className={styles.error}>{error}</p>}
        {isPending && <p className={styles.loading}>Loading...</p>}
        {chapter && (
          <>
            <h1 className={styles['book-name']}>{chapter.bookName}</h1>
            <div className={styles['chapter-header']}>
              <span className={styles.chapterNumber}>Chapter {numericIndex + 1}</span>
              <div className={styles['chapter-title']}>{chapterTitle}</div>
            </div>
            <div className={styles['chapter-content']} dangerouslySetInnerHTML={{ __html: chapterContent }} />
          </>
        )}
        <div className={styles.navigation}>
          <button 
            className={styles.prevButton}
            disabled={numericIndex === 0}
            onClick={goToPreviousChapter}
          >
            Previous
          </button>
          <button
            className={styles.nextButton}
            disabled={numericIndex >= chapter?.chapterTitles?.length - 1 || !chapter?.chapterTitles?.[numericIndex + 1]?.isActive}
            onClick={goToNextChapter}
          >
            Next
          </button>
        </div>
      </div>
    </BooksLayoutBlank>
  );
};

export default Dchapters;


