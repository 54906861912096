// DeliveryInstructions.js
import React, { useState } from 'react';
import { projectFirestore } from '../../../firebase/config';
import styles from './DeliveryInstructions.module.css'; // Import the CSS module

const DeliveryInstructions = ({ orderId }) => {
  const [instructions, setInstructions] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await projectFirestore.collection('deliveryorders').doc(orderId).update({
        deliveryInstructions: instructions
      });

      alert('Delivery instructions updated successfully.');
      setInstructions('');
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.instructionsContainer}>
      <h3 className={styles.title}>Delivery Instructions</h3>
      <form onSubmit={handleSubmit} className={styles.form}>
        <textarea
          className={styles.textarea}
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          placeholder="Enter special instructions here..."
          required
        ></textarea>
        <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
          {isSubmitting ? 'Updating...' : 'Update Instructions'}
        </button>
      </form>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default DeliveryInstructions;
