import React from 'react';
import GeneralLayout from '../../components/Hoc/GeneralLayout/GeneralLayout';
import QuoteForm from './QuoteForm';
import { projectFirestore } from '../../firebase/config';

const SubmitQuote = () => {
  const handleQuoteSubmission = async (quote, author, country) => {
    // Save the quote to the Firebase collection
    try {
      await projectFirestore.collection('quotes').add({
        text: quote,
        author: author,
        country: country,
        timestamp: new Date(),
      });
      console.log('Quote submitted and saved to the collection:', quote);
    } catch (error) {
      console.error('Error submitting and saving the quote:', error);
    }
  };

  return (
    <GeneralLayout>
      <div>
        <h1>Submit a Quote</h1>
        <QuoteForm onSubmit={handleQuoteSubmission} />
      </div>
    </GeneralLayout>
  );
};

export default SubmitQuote;


