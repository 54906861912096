import { projectFirestore, projectAuth, } from '../firebase/config';

export async function getUserRole(uid) {
    try {
      const userDoc = await projectFirestore.collection('users').doc(uid).get();
  
      if (userDoc.exists) {
        return userDoc.data().role;
      } else {
        console.error('No user document found for the provided uid.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
      return null;
    }
  }
  
  export const updatePassword = async (user, newPassword) => {
    try {
      await user.updatePassword(newPassword);
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };
  
 

export const deleteUserAccount = async (user) => {
  // Delete the user from authentication
  await user.delete();

  // Delete the user's data in the Firestore
  await projectFirestore.collection('users').doc(user.uid).delete();
};
