import React, { useState, useEffect } from 'react';
import { projectFirestore, FieldValue } from '../../../firebase/config';
import EditTradeCategoryForm from './EditTradeCategoryForm';
import styles from './TradeCategoriesManager.module.css';

const TradeCategoriesManager = () => {
  const [tradeCategories, setTradeCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set()); // Track expanded categories

  useEffect(() => {
    const fetchTradeCategories = async () => {
      try {
        const snapshot = await projectFirestore.collection('tradeCategories').get();
        const fetchedCategories = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTradeCategories(fetchedCategories);
      } catch (err) {
        console.error("Error fetching trade categories: ", err);
      }
    };

    fetchTradeCategories();
  }, []);

  // Function to format sub-categories for display
  const formatSubCategoriesDisplay = (subCategories) => {
    return subCategories.map(subCat => `${subCat.name}: ${subCat.items.join(', ')}`).join('; ');
};



// const handleSaveCategory = async (categoryData) => {
//     if (editingCategory && editingCategory.id) {
//         // Update existing category or add a new sub-category to it
//         const updatedCategory = {
//             ...editingCategory,
//             subCategories: [...editingCategory.subCategories, categoryData]
//         };
//         await handleEditCategory(updatedCategory);
//     } else {
//         // Add a completely new category
//         await handleAddCategory(categoryData);
//     }
//     setEditingCategory(null);
// };

// const handleSaveCategory = async (categoryData) => {
//     let updatedCategory;
//     if (categoryData.subCategory.isEdit) {
//         // Edit an existing sub-category
//         updatedCategory = {
//             ...categoryData,
//             subCategories: categoryData.subCategories.map(subCat => 
//                 subCat.name === categoryData.subCategory.name ? categoryData.subCategory : subCat
//             )
//         };
//     } else {
//         // Add a new sub-category or update category details
//         updatedCategory = {
//             ...categoryData,
//             subCategories: categoryData.id ? [...categoryData.subCategories, categoryData.subCategory] : [categoryData.subCategory]
//         };
//     }
//     if (categoryData.id) {
//         // Existing category
//         await handleEditCategory(updatedCategory);
//     } else {
//         // New category
//         await handleAddCategory(updatedCategory);
//     }
//     setEditingCategory(null);
// };

// const handleSaveCategory = async (categoryData) => {
//     if (categoryData.id) {
//         // Existing category: Update or add new sub-category
//         const updatedCategory = {
//             ...categoryData,
//             // Check if subCategories exists and is an array, if not initialize as an array
//             subCategories: Array.isArray(categoryData.subCategories)
//                 ? [...categoryData.subCategories, categoryData.newSubCategory]
//                 : [categoryData.newSubCategory]
//         };
//         await handleEditCategory(updatedCategory);
//     } else {
//         // New category: Initialize subCategories as an array with the new sub-category
//         const newCategory = {
//             ...categoryData,
//             subCategories: [categoryData.newSubCategory]
//         };
//         await handleAddCategory(newCategory);
//     }
//     setEditingCategory(null);
// };

// const handleSaveCategory = async (categoryData) => {
//     if (categoryData.id) {
//         // Existing category: Update or add new sub-category
//         let updatedSubCategories;
//         if (categoryData.newSubCategory.isEdit) {
//             // Edit existing sub-category
//             updatedSubCategories = categoryData.subCategories.map(subCat => 
//                 subCat.name === categoryData.originalSubCategoryName ? categoryData.newSubCategory : subCat
//             );
//         } else {
//             // Add new sub-category
//             updatedSubCategories = [...categoryData.subCategories, categoryData.newSubCategory];
//         }

//         const updatedCategory = {
//             ...categoryData,
//             subCategories: updatedSubCategories
//         };

//         await handleEditCategory(updatedCategory);
//     } else {
//         // New category: Initialize subCategories as an array with the new sub-category
//         const newCategory = {
//             ...categoryData,
//             subCategories: [categoryData.newSubCategory]
//         };
//         await handleAddCategory(newCategory);
//     }
//     setEditingCategory(null);
// };

// const handleSaveCategory = async (categoryData) => {
//     if (categoryData.id) {
//         // Existing category: Update or add new sub-category
//         let updatedSubCategories = categoryData.subCategories || [];
//         if (categoryData.newSubCategory.isEdit) {
//             // Edit existing sub-category
//             updatedSubCategories = updatedSubCategories.map(subCat => 
//                 subCat.name === categoryData.originalSubCategoryName ? categoryData.newSubCategory : subCat
//             );
//         } else {
//             // Add new sub-category
//             updatedSubCategories = [...updatedSubCategories, categoryData.newSubCategory];
//         }

//         const updatedCategory = {
//             ...categoryData,
//             subCategories: updatedSubCategories
//         };

//         await handleEditCategory(updatedCategory);
//     } else {
//         // New category: Initialize subCategories as an array with the new sub-category
//         const newCategory = {
//             ...categoryData,
//             subCategories: [categoryData.newSubCategory]
//         };
//         await handleAddCategory(newCategory);
//     }
//     setEditingCategory(null);
// };

const handleSaveCategory = async (categoryData) => {
    try {
        if (categoryData.id) {
            // Edit an existing category
            const categoryRef = projectFirestore.collection('tradeCategories').doc(categoryData.id);

            // Determine if we are editing an existing sub-category or adding a new one
            if (categoryData.newSubCategory.isEdit) {
                // Update existing sub-category
                await categoryRef.update({
                    subCategories: categoryData.subCategories.map(subCat => 
                        subCat.name === categoryData.originalSubCategoryName ? {...subCat, ...categoryData.newSubCategory} : subCat
                    )
                });
            } else {
                // Add new sub-category
                await categoryRef.update({
                    subCategories: FieldValue.arrayUnion(categoryData.newSubCategory)
                });
            }
        } else {
            // Add a new category
            await projectFirestore.collection('tradeCategories').add({
                category: categoryData.category,
                subCategories: [categoryData.newSubCategory]
            });
        }
    } catch (error) {
        console.error("Error saving category: ", error);
    }
    setEditingCategory(null);
};


const handleAddCategory = async (newCategory) => {
    try {
        const docRef = await projectFirestore.collection('tradeCategories').add(newCategory);
        setTradeCategories([...tradeCategories, { id: docRef.id, ...newCategory }]);
    } catch (error) {
        console.error("Error adding trade category: ", error);
    }
};

const handleEditCategory = async (updatedCategory) => {
    try {
        await projectFirestore.collection('tradeCategories').doc(updatedCategory.id).update(updatedCategory);
        setTradeCategories(tradeCategories.map(cat => cat.id === updatedCategory.id ? updatedCategory : cat));
    } catch (error) {
        console.error("Error updating trade category: ", error);
    }
};


  const handleDeleteCategory = async (categoryId) => {
    try {
      await projectFirestore.collection('tradeCategories').doc(categoryId).delete();
      setTradeCategories(tradeCategories.filter(cat => cat.id !== categoryId));
    } catch (error) {
      console.error("Error deleting trade category: ", error);
    }
  };

  const toggleCategoryView = (categoryId) => {
    setExpandedCategories(prev => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(categoryId)) {
        newExpanded.delete(categoryId);
      } else {
        newExpanded.add(categoryId);
      }
      return newExpanded;
    });
  };

  return (
    <div className={styles.tradeCategoriesManagerContainer}>
        <h2>Trade Categories</h2>

        {/* Displaying each category and its sub-categories */}
        {tradeCategories.map(category => (
            <div key={category.id} className={styles.categoryItem}>
                <div>
                    <p><strong>Category:</strong> {category.category}</p>
                    {expandedCategories.has(category.id) && (
                        <p><strong>Sub-Categories:</strong> {formatSubCategoriesDisplay(category.subCategories)}</p>
                    )}
                </div>
                <div className={styles.categoryActions}>
                    <button onClick={() => toggleCategoryView(category.id)}>
                        {expandedCategories.has(category.id) ? 'Show Less' : 'Show More'}
                    </button>
                    <button onClick={() => setEditingCategory(category)}>Edit</button>
                    <button onClick={() => handleDeleteCategory(category.id)}>Delete</button>
                </div>
            </div>
        ))}

        {/* EditTradeCategoryForm for editing a category */}
        {editingCategory && (
            <EditTradeCategoryForm
                categoryData={editingCategory}
                onSave={handleSaveCategory} // Replace with your save function
                onCancel={() => setEditingCategory(null)} // Reset editing category on cancel
            />
        )}
    </div>
);
};

export default TradeCategoriesManager;

