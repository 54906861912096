import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore, projectStorage, timestamp } from '../../firebase/config';
import DeliveryLayout from '../../components/Hoc/DeliveryLayout/DeliveryLayout';

import styles from './EditPartnerProfile.module.css'; // Assuming similar CSS styling as DeliveryPartnerOnboarding
// 
export default function EditPartnerProfile() {
    const { user } = useContext(AuthContext);
    const history = useHistory();
  
    // Initialize state variables with default values
    const [partnerType, setPartnerType] = useState('');
    const [experience, setExperience] = useState('');
    const [availability, setAvailability] = useState('');
    const [insuranceInfo, setInsuranceInfo] = useState('');
    const [employees, setEmployees] = useState([{ name: '', email: '', phone: '' }]);
    const [paymentDetails, setPaymentDetails] = useState({ bankAccount: '', mobileAccount: '', preferredPaymentMethod: '' });
    const [taxInformation, setTaxInformation] = useState('');
    const [businessDocuments, setBusinessDocuments] = useState({ hasRegistrationCertificate: '', hasTaxIdentificationNumber: '' });
    const [operationalInfo, setOperationalInfo] = useState('');
    const [contactInfo, setContactInfo] = useState({ email: '', phone: '' });
    const [vehicleTypes, setVehicleTypes] = useState({ Bicycle: false, Tricycle: false, Car: false, Van: false, Bus: false, MiniTruck: false, HeavyTruck: false });
  
    // File state for uploading new files (if necessary)
    const [personalIdentification, setPersonalIdentification] = useState(null);
    const [backgroundCheckReport, setBackgroundCheckReport] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [personalIdentificationPreview, setPersonalIdentificationPreview] = useState('');
    const [backgroundCheckReportPreview, setBackgroundCheckReportPreview] = useState('');
    const [profilePhotoPreview, setProfilePhotoPreview] = useState('');

  
    useEffect(() => {
      // Fetch existing partner data from Firestore
      const fetchPartnerData = async () => {
        const docRef = projectFirestore.collection('deliveryPartner').doc(user.uid);
        const doc = await docRef.get();
        if (doc.exists) {
          const data = doc.data();
          setPartnerType(data.partnerType);
          setExperience(data.experience);
          setAvailability(data.availability);
          // ... Set other states with the fetched data
          setInsuranceInfo(data.insuranceInfo);
          setEmployees(data.employees || []);
          setPaymentDetails(data.paymentDetails || {});
          setTaxInformation(data.taxInformation);
          setBusinessDocuments({
            hasRegistrationCertificate: data.hasRegistrationCertificate,
            hasTaxIdentificationNumber: data.hasTaxIdentificationNumber,
          });
          setOperationalInfo(data.operationalInfo);
          setContactInfo(data.contactInfo || {});
          setVehicleTypes(data.vehicleTypes.reduce((acc, type) => ({ ...acc, [type]: true }), {}));
          // Handle setting file states if necessary
        }
      };
  
      fetchPartnerData();
    }, [user.uid]);
  

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        if (partnerType === 'business' && !insuranceInfo) {
          alert('Please fill in all required fields for Business Partner Type');
          return;
        }
      
        // Upload files and get their URLs
        const personalIdUrl = personalIdentification ? await uploadFile(personalIdentification, 'personal_identification/' + personalIdentification.name) : null;
        const backgroundCheckUrl = backgroundCheckReport ? await uploadFile(backgroundCheckReport, 'background_checks/' + backgroundCheckReport.name) : null;
        const profilePhotoUrl = profilePhoto ? await uploadFile(profilePhoto, 'profile_photos/' + profilePhoto.name) : null;
      
        // Construct updated formData
        let updatedFormData = {
          partnerType,
          vehicleTypes: Object.keys(vehicleTypes).filter(type => vehicleTypes[type]),
          ...(partnerType === 'individual' && {
            experience,
            availability,
            personalIdentificationUrl: personalIdUrl,
            backgroundCheckReportUrl: backgroundCheckUrl,
            profilePhotoUrl: profilePhotoUrl,
            contactInfo,
            taxInformation,
            paymentDetails,
          }),
          ...(partnerType === 'business' && {
            insuranceInfo,
            employees: employees.filter(emp => emp.name || emp.email || emp.phone),
            hasRegistrationCertificate: businessDocuments.hasRegistrationCertificate,
            hasTaxIdentificationNumber: businessDocuments.hasTaxIdentificationNumber,
            operationalInfo,
            contactInfo,
            taxInformation,
            paymentDetails,
          }),
        };
      
        try {
          // Update the Firestore document with the user's UID
          await projectFirestore.collection('deliveryPartner').doc(user.uid).update(updatedFormData);
          history.push('/dashboard');
        } catch (error) {
          console.error('Error updating partner details:', error);
          alert('An error occurred while updating your information. Please try again.');
        }
      };
      

  // Handlers for file changes, input changes, etc.
  // ...
  const handleVehicleTypeChange = (vehicleType) => {
    setVehicleTypes(prevTypes => ({
      ...prevTypes,
      [vehicleType]: !prevTypes[vehicleType]
    }));
  };
  
  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    setContactInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleBusinessDocumentsChange = (e) => {
    const { name, value } = e.target;
    setBusinessDocuments(prevDocs => ({
      ...prevDocs,
      [name]: value
    }));
  };
  

  const handleFileChange = (e, setFile, setPreview) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleInsuranceChange = (e) => {
    setInsuranceInfo(e.target.value);
  };

  const handleEmployeeChange = (index, e) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index][e.target.name] = e.target.value;
    setEmployees(updatedEmployees);
  };
  
  const addEmployee = () => {
    setEmployees([...employees, { name: '', email: '', phone: '' }]);
  };
  
  const removeEmployee = (index) => {
    const updatedEmployees = [...employees];
    updatedEmployees.splice(index, 1);
    setEmployees(updatedEmployees);
  };
  
  
  const uploadFile = async (file, path) => {
    if (!file) return;
  
    const storageRef = projectStorage.ref(path);
    const collectionRef = projectFirestore.collection('files');
    
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    
    const createdAt = timestamp();
    await collectionRef.add({ url, createdAt });
    return url;
  };

  return (
    <DeliveryLayout>
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <h2>Edit Partner Profile</h2>
  
          {/* Partner Type Selection */}
          <label className={styles.label}>
            Partner Type:
            <select
              className={styles.input}
              value={partnerType}
              onChange={(e) => setPartnerType(e.target.value)}
              required
            >
              <option value="">Select a Partner Type</option>
              <option value="individual">Individual</option>
              <option value="business">Business</option>
            </select>
          </label>
  
          {partnerType === 'individual' && (
          <>
          <label className={styles.label}>
            Personal Identification:
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setPersonalIdentification, setPersonalIdentificationPreview)}
            />
            {personalIdentificationPreview && <img src={personalIdentificationPreview} alt="Preview" />}
          </label>

          <label className={styles.label}>
            Police Background Check Report:
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setBackgroundCheckReport, setBackgroundCheckReportPreview)}
            />
            {backgroundCheckReportPreview && <img src={backgroundCheckReportPreview} alt="Preview" />}
          </label>
          <label className={styles.label}>
            Profile Photo:
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setProfilePhoto, setProfilePhotoPreview)}
            />
            {profilePhotoPreview && <img src={profilePhotoPreview} alt="Preview" />}
          </label>

            <label className={styles.label}>
              Experience:
              <textarea
                className={styles.input}
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                required
              />
            </label>

            <label className={styles.label}>
              Availability:
              <textarea
                className={styles.input}
                value={availability}
                onChange={(e) => setAvailability(e.target.value)}
                required
              />
            </label>

            {/* Additional Individual Fields Here */}
          </>
        )}

        {partnerType === 'business' && (
  <>
    <div className={styles.formGroup}>
      <p>Do you have a Valid Business Registration Certificate?</p>
      <label>
        <input
          type="radio"
          name="hasRegistrationCertificate"
          value="yes"
          checked={businessDocuments.hasRegistrationCertificate === 'yes'}
          onChange={handleBusinessDocumentsChange}
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          name="hasRegistrationCertificate"
          value="no"
          checked={businessDocuments.hasRegistrationCertificate === 'no'}
          onChange={handleBusinessDocumentsChange}
        />
        No
      </label>
    </div>

    <div className={styles.formGroup}>
      <p>Do you have a Valid Tax Identification Number for the business?</p>
      <label>
        <input
          type="radio"
          name="hasTaxIdentificationNumber"
          value="yes"
          checked={businessDocuments.hasTaxIdentificationNumber === 'yes'}
          onChange={handleBusinessDocumentsChange}
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          name="hasTaxIdentificationNumber"
          value="no"
          checked={businessDocuments.hasTaxIdentificationNumber === 'no'}
          onChange={handleBusinessDocumentsChange}
        />
        No
      </label>
    </div>

    <div className={styles.formGroup}>
      <p>Do you have valid insurance?</p>
      <label>
        <input
          type="radio"
          name="insuranceInfo"
          value="yes"
          checked={insuranceInfo === 'yes'}
          onChange={handleInsuranceChange}
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          name="insuranceInfo"
          value="no"
          checked={insuranceInfo === 'no'}
          onChange={handleInsuranceChange}
        />
        No
      </label>
    </div>

    {employees.map((employee, index) => (
  <div key={index}>
    <input
      type="text"
      name="name"
      placeholder="Employee Name"
      value={employee.name}
      onChange={(e) => handleEmployeeChange(index, e)}
    />
    <input
      type="email"
      name="email"
      placeholder="Employee Email"
      value={employee.email}
      onChange={(e) => handleEmployeeChange(index, e)}
    />
    <input
      type="tel"
      name="phone"
      placeholder="Employee Phone"
      value={employee.phoneNumber}
      onChange={(e) => handleEmployeeChange(index, e)}
    />
    {index > 0 && (
      <button type="button" onClick={() => removeEmployee(index)}>Remove</button>
    )}
      </div>
    ))}
    <button type="button" onClick={addEmployee}>Add Another Employee</button>

    <label className={styles.label}>
      Operational Information:
      <textarea
        name="operationalInfo"
        className={styles.textarea}
        value={operationalInfo}
        onChange={(e) => setOperationalInfo(e.target.value)}
        placeholder="Types of goods/services and geographic coverage area"
      />
    </label>

  </>
)}

  
          {/* Common Fields for Both Individual and Business */}
          <label className={styles.label}>
            Email:
            <input
              type="email"
              name="email"
              className={styles.input}
              value={contactInfo.email}
              onChange={handleContactInfoChange}
              required
            />
          </label>
  
          <label className={styles.label}>
            Phone Number:
            <input
              type="tel"
              name="phone"
              className={styles.input}
              value={contactInfo.phone}
              onChange={handleContactInfoChange}
              required
            />
          </label>
          <label className={styles.label}>
          Tax Information ID:
          <input
            type="text"
            name="taxInformation"
            className={styles.input}
            value={taxInformation}
            onChange={(e) => setTaxInformation(e.target.value)}
            placeholder="Enter tax information"
          />
        </label>
  
          {/* Vehicle Types Selection */}
          <div className={styles.dropdown}>
            <button className={styles.dropbtn}>Select the Vehicle types you have</button>
            <div className={styles.dropdownContent}>
              {Object.keys(vehicleTypes).map((type) => (
                <label key={type}>
                  <input
                    type="checkbox"
                    checked={vehicleTypes[type]}
                    onChange={() => handleVehicleTypeChange(type)}
                  />
                  {type}
                </label>
              ))}
            </div>
          </div>
  
          {/* Payment Details */}
          <h3>Payment Details</h3>
        <p>Please fill in both and check mark your preferred payment option:</p>

        <label className={styles.label}>
          Bank Account Details:
          <input
            type="text"
            name="bankAccount"
            className={styles.input}
            value={paymentDetails.bankAccount}
            onChange={handlePaymentDetailsChange}
          />
        </label>

        <label className={styles.label}>
          Mobile Account Number:
          <input
            type="tel"
            name="mobileAccount"
            className={styles.input}
            value={paymentDetails.mobileAccount}
            onChange={handlePaymentDetailsChange}
          />
        </label>

        <div className={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="preferredPaymentMethod"
              value="bank"
              checked={paymentDetails.preferredPaymentMethod === 'bank'}
              onChange={handlePaymentDetailsChange}
            />
            Bank Account (Preferred)
          </label>
          <label>
            <input
              type="radio"
              name="preferredPaymentMethod"
              value="mobile"
              checked={paymentDetails.preferredPaymentMethod === 'mobile'}
              onChange={handlePaymentDetailsChange}
            />
            Mobile Account (Preferred)
          </label>
        </div>
  
          {/* Submit Button */}
          <button type="submit" className={styles.button}>Update Profile</button>
        </form>
      </div>
    </DeliveryLayout>
  );
}  



