import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '300px',
};

const AccraMap = (props) => {
  return (
    <Map
      google={props.google}
      zoom={14}
      style={mapStyles}
      initialCenter={{ lat: 5.6037, lng: -0.1870 }} // Coordinates of Accra
    >
      <Marker position={{ lat: 5.6037, lng: -0.1870 }} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBKxQB693R75Y8UJnlSCvc0ca9JGynXKYk'
})(AccraMap);
