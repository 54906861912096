import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../../../firebase/config';
import { FieldValue } from '../../../../firebase/config';
import voteIcon from '../../../../assets/voteIcon.svg'; 
import { useAuthContext } from '../../../../hooks/useAuthContext';


import styles from './VoteProposals.module.css'; 

const VoteProposals = ({ proposalId, initialVotes = { support: 0, notSure: 0, doNotSupport: 0 } }) => {

    const { user } = useAuthContext();
    const userId = user.uid;

    // const [votes, setVotes] = useState({ support: 0, notSure: 0, doNotSupport: 0 });
    const [votes, setVotes] = useState(initialVotes);
    const [percentages, setPercentages] = useState({ support: 0, notSure: 0, doNotSupport: 0 }); // Add this line

    const [userVote, setUserVote] = useState({ type: null, changes: 0 });
    const [showOptions, setShowOptions] = useState(false);
    const [iconColor, setIconColor] = useState('black'); // Default icon color

    // useEffect(() => {
    //     const userVoteRef = projectFirestore.collection('proposals').doc(proposalId).collection('userVotes').doc(userId);
    //     const proposalRef = projectFirestore.collection('proposals').doc(proposalId);

    //     const unsubscribeUserVote = userVoteRef.onSnapshot(doc => {
    //         if (doc.exists) {
    //             setUserVote(doc.data());
    //         }
    //     });

    //     const unsubscribeProposal = proposalRef.onSnapshot(snapshot => {
    //         if (snapshot.exists && snapshot.data().votes) {
    //             setVotes(snapshot.data().votes);
    //         }
    //     });

    //     return () => {
    //         unsubscribeUserVote();
    //         unsubscribeProposal();
    //     };
    // }, [proposalId, userId]);

    useEffect(() => {
        const proposalRef = projectFirestore.collection('proposals').doc(proposalId);
        const unsubscribeProposal = proposalRef.onSnapshot(snapshot => {
            if (snapshot.exists && snapshot.data().votes) {
                console.log("Fetched Votes:", snapshot.data().votes);  // Debugging log
                setVotes(snapshot.data().votes);
            }
        }, error => {
            console.error("Error fetching votes: ", error);
        });
    
        return () => {
            unsubscribeProposal();
        };
    }, [proposalId]);
    
   
    


    const handleVote = async (voteType) => {
        console.log("Voting for:", voteType);
        
    
        if (userVote.changes >= 5) {
            console.log("Vote change limit reached.");
            alert("You have reached the maximum number of vote changes.");
            return; // Prevent voting after 5 changes
        }
    
        const newUserVote = {
            type: voteType,
            changes: userVote.type === voteType ? userVote.changes : userVote.changes + 1
        };
    
        try {
            const batch = projectFirestore.batch();
            const userVoteRef = projectFirestore.collection('proposals').doc(proposalId).collection('userVotes').doc(userId);
            batch.set(userVoteRef, newUserVote);
    
            const proposalRef = projectFirestore.collection('proposals').doc(proposalId);
    
            if (userVote.type !== voteType) {
                batch.update(proposalRef, { [`votes.${voteType}`]: FieldValue.increment(1) });
                if (userVote.type) {
                    batch.update(proposalRef, { [`votes.${userVote.type}`]: FieldValue.increment(-1) });
                }
            }
    
            await batch.commit();
            setUserVote(newUserVote);
        } catch (error) {
            console.error("Error in voting:", error);
        }
    
        setShowOptions(false);
        console.log("User Vote After Update:", newUserVote); // Debugging log
    };
    


    useEffect(() => {
        const totalVotes = votes.support + votes.notSure + votes.doNotSupport;
        const supportPercentage = totalVotes ? (votes.support / totalVotes * 100).toFixed(2) : 0;
        const notSurePercentage = totalVotes ? (votes.notSure / totalVotes * 100).toFixed(2) : 0;
        const doNotSupportPercentage = totalVotes ? (votes.doNotSupport / totalVotes * 100).toFixed(2) : 0;
      
        // Update local state with these percentages
        setPercentages({
          support: supportPercentage,
          notSure: notSurePercentage,
          doNotSupport: doNotSupportPercentage
        });

        // Log the updated percentages for debugging
        console.log("Updated Percentages:", { supportPercentage, notSurePercentage, doNotSupportPercentage });

      
        // Determine icon color
        const newIconColor = supportPercentage > 80 ? 'red' : 'black';
        setIconColor(newIconColor);
      
      }, [votes]);

    return (
        
        <div className={styles.voteWrapper}>
            <div className={styles.voteIconAndCounts}>
                <span onClick={() => setShowOptions(!showOptions)}>
                    <img src={voteIcon} alt="Vote" 
                         className={styles.voteIcon} 
                         style={{ color: iconColor }} />
                    Vote
                </span>
                <div className={styles.voteCounts}>
                    <span className={styles.votePercentage}>Support: {percentages.support}%</span>
                    <span className={styles.votePercentage}>Not Sure: {percentages.notSure}%</span>
                    <span className={styles.votePercentage}>Do Not Support: {percentages.doNotSupport}%</span>
                </div>
                
            </div>

            {showOptions && (
                <div>
                    <button className={`${styles.voteButton} ${userVote.type === 'support' ? styles.selectedVote : ''}`} onClick={() => handleVote('support')}>Support</button>
                    <button className={`${styles.voteButton} ${userVote.type === 'notSure' ? styles.selectedVote : ''}`} onClick={() => handleVote('notSure')}>Not Sure</button>
                    <button className={`${styles.voteButton} ${userVote.type === 'doNotSupport' ? styles.selectedVote : ''}`} onClick={() => handleVote('doNotSupport')}>Do Not Support</button>
                </div>

                
                
            )}
        </div>
    );
};

export default VoteProposals;


