import { useState, useEffect } from 'react';
import { projectFirestore } from './config';

// export const useFirestoreDoc = (collection, docId) => {
//   const [document, setDocument] = useState(null);

//   useEffect(() => {
//     const docRef = projectFirestore.collection(collection).doc(docId);
//     docRef.get().then((doc) => {
//       if (doc.exists) {
//         setDocument(doc.data());
//       }
//     });
//   }, [collection, docId]);

//   return document;
// };

// export const useFirestoreDoc = (collection, docId) => {
//   const [document, setDocument] = useState(null);

//   useEffect(() => {
//     const docRef = projectFirestore.collection(collection).doc(docId);
//     docRef.get()
//       .then((doc) => {
//         if (doc.exists) {
//           setDocument(doc.data());
//         } else {
//           console.log("No such document!");
//         }
//       })
//       .catch((error) => {
//         console.log("Error getting document:", error);
//       });
//   }, [collection, docId]);

//   return document;
// };

export const useFirestoreDoc = (collection, docId) => {
  const [document, setDocument] = useState(null);

  useEffect(() => {
    console.log("Querying:", collection, docId);  // Log the query params
    const docRef = projectFirestore.collection(collection).doc(docId);
    docRef.get()
      .then((doc) => {
        if (doc.exists) {
          setDocument(doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [collection, docId]);

  return document;
};



export const useFirestoreCollection = (collection) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const unsubscribe = projectFirestore.collection(collection)
      .onSnapshot(snapshot => {
        const data = [];
        snapshot.forEach(doc => data.push({ ...doc.data(), id: doc.id }));
        setDocuments(data);
      });

    return () => unsubscribe();
  }, [collection]);

  return documents;
};
