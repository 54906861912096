import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const AddPackage = () => {
  let history = useHistory();

  const [formState, setFormState] = useState({
    item_picture: '',
    packageType: '',
    unitPackageWeight: '',
    Length: '',
    breadth: '',
    height: '',
    itemQuantity: ''
  });

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle your form submission logic here
  };

  return (
    <div className="form-div-for-contact class-tracking-card">
      {/* ... */}
      <form onSubmit={handleSubmit}>
        <div className="form-control-seler">
          <label>Select image</label>
          <div className="new-sign-up-image-upload image-licence ">
            <div className="avatar-wrapper ">
              <img className="profile-pic" src={formState.item_picture} alt="Item" />
              <div className="upload-button"></div>
              <input id="file" className="file-upload" type="file" accept="image/*" 
                name="item_picture" onChange={handleChange} />
            </div>
          </div>
        </div>
        {/* ... repeat for other form fields ... */}
        <button type="submit" className="btn btn-dark w-100 btn-pading-new">Add Package</button>
      </form>
      {/* ... */}
    </div>
  );
}

export default AddPackage;
