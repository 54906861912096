
// import React, { useState, useEffect } from 'react';
// //import { projectFirestore } from '../../firebase/config';
// //import { projectFirestore, timestamp } from '../../../firebase/config';
// import { projectFirestore, FieldValue } from '../../../firebase/config';

// import { useHistory } from 'react-router-dom';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // import styles

// import styles from './AddChapterComponent.module.css'; // Adjust the import path as needed

// const toolbarOptions = [
//     ['bold', 'italic', 'underline', 'strike'],  // toggled buttons
  
//     ['blockquote', 'code-block'],  // blocks
  
//     [{ 'header': 1 }, { 'header': 2 }, { 'header': [3, 4, 5, 6, false] }], // headers
    
//     [{ 'list': 'ordered'}, { 'list': 'bullet' }],  // lists
    
//     [{ 'script': 'sub'}, { 'script': 'super' }],  // superscript/subscript
    
//     [{ 'indent': '-1'}, { 'indent': '+1' }],  // outdent/indent
    
//     [{ 'direction': 'rtl' }],  // text direction
    
//     [{ 'size': ['small', false, 'large', 'huge'] }],  // font size
    
//     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // custom header levels
    
//     [{ 'color': [] }, { 'background': [] }],  // text/background color
    
//     [{ 'font': [] }],  // font family
    
//     [{ 'align': [] }],  // text alignment
    
//     ['clean'],  // remove formatting
    
//     ['link', 'image', 'video']  // link, image and video
//   ];

// const AddChapterComponent = () => {
//   const [books, setBooks] = useState([]);
//   const [selectedBook, setSelectedBook] = useState('');
//   const [newChapterTitle, setNewChapterTitle] = useState('');
//   const [newChapterContent, setNewChapterContent] = useState('');
//   const history = useHistory();

//   useEffect(() => {
//     const unsubscribe = projectFirestore.collection('anpbooks').onSnapshot(snapshot => {
//       const booksData = [];
//       snapshot.forEach(doc => {
//         booksData.push({ ...doc.data(), id: doc.id });
//       });
//       setBooks(booksData);
//     });
//     return () => unsubscribe();
//   }, []);

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     if (name === 'book') {
//       setSelectedBook(value);
//     } else if (name === 'newChapterTitle') {
//       setNewChapterTitle(value);
//     }
//   };

//   const handleQuillChange = (value) => {
//     setNewChapterContent(value);
//   };


// const handleAddChapter = () => {
//     if (selectedBook && newChapterTitle) {
//       const selectedBookRef = projectFirestore.collection('anpbooks').doc(selectedBook);
//       selectedBookRef.update({
//         chapters: FieldValue.arrayUnion({
//           title: newChapterTitle,
//           content: newChapterContent,
//         }),
//       }).then(() => {
//         history.push('/some-route'); // Adjust the redirection as needed
//       });
//     }
//   };

//   return (
//     <div className={styles.container}>
//       <h1 className={styles.title}>Add New Chapter</h1>
//       <div className={styles.field}>
//         <label htmlFor="book-select" className={styles.label}>Select a book:</label>
//         <select id="book-select" name="book" value={selectedBook} onChange={handleChange} className={styles.select}>
//           <option value="">Select a book</option>
//           {books.map(book => (
//             <option key={book.id} value={book.id}>
//               {book.bookName}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div className={styles.field}>
//         <label htmlFor="newChapterTitle" className={styles.label}>Chapter Title:</label>
//         <input type="text" id="newChapterTitle" name="newChapterTitle" value={newChapterTitle} onChange={handleChange} className={styles.input} />
//       </div>
//       <div className={styles.field}>
//         <label htmlFor="editor" className={styles.label}>Chapter Content:</label>
//         <ReactQuill value={newChapterContent}
//                     onChange={handleQuillChange}
//                     modules={{ toolbar: toolbarOptions }} />
//       </div>
//       <button onClick={handleAddChapter} className={styles.button}>Add Chapter</button>
//     </div>
//   );
// };

// export default AddChapterComponent;





import React, { useState, useEffect } from 'react';
import { projectFirestore, FieldValue } from '../../../firebase/config';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './AddChapterComponent.module.css';

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],  // toggled buttons
  
    ['blockquote', 'code-block'],  // blocks
  
    [{ 'header': 1 }, { 'header': 2 }, { 'header': [3, 4, 5, 6, false] }], // headers
    
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],  // lists
    
    [{ 'script': 'sub'}, { 'script': 'super' }],  // superscript/subscript
    
    [{ 'indent': '-1'}, { 'indent': '+1' }],  // outdent/indent
    
    [{ 'direction': 'rtl' }],  // text direction
    
    [{ 'size': ['small', false, 'large', 'huge'] }],  // font size
    
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // custom header levels
    
    [{ 'color': [] }, { 'background': [] }],  // text/background color
    
    [{ 'font': [] }],  // font family
    
    [{ 'align': [] }],  // text alignment
    
    ['clean'],  // remove formatting
    
    ['link', 'image', 'video']  // link, image and video
  ];

const AddChapterComponent = () => {
    // Define the missing state variables
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState('');
  const [newChapterTitle, setNewChapterTitle] = useState('');
  const [newChapterContent, setNewChapterContent] = useState('');
  

  // Correctly define the useHistory hook
  const history = useHistory();
  // ... your existing state variables ...
  const [isActive, setIsActive] = useState(true);

  // ... your existing useEffect for fetching books ...
  useEffect(() => {
    const unsubscribe = projectFirestore.collection('anpbooks').onSnapshot(snapshot => {
      const booksData = [];
      snapshot.forEach(doc => {
        booksData.push({ ...doc.data(), id: doc.id });
      });
      console.log('Fetched Books:', booksData); // Log the fetched books data
      setBooks(booksData);
    });
    return () => unsubscribe();
  }, []);
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'book') {
      setSelectedBook(value);
    } else if (name === 'newChapterTitle') {
      setNewChapterTitle(value);
    }
  };

  const handleQuillChange = (value) => {
    setNewChapterContent(value);
  };



// const handleAddChapter = async () => {
//     if (selectedBook && newChapterTitle && newChapterContent) {
//         try {
//             const selectedBookRef = projectFirestore.collection('anpbooks').doc(selectedBook);

//             // Retrieve the current book document
//             const bookSnapshot = await selectedBookRef.get();
//             const bookData = bookSnapshot.data();

//             if (bookData) {
//                 // Get the current chapters array
//                 const chapters = bookData.chapters || [];

//                 // Add the new chapter to the end of this array
//                 const newChapter = {
//                     title: newChapterTitle,
//                     content: newChapterContent,
//                     isActive: true, // Assuming you have a variable or state for isActive
//                 };
//                 chapters.push(newChapter);

//                 // Update the entire chapters array in the Firestore document
//                 await selectedBookRef.update({ chapters });

//                 // Redirect or perform additional actions as needed
//                 history.push('/some-route'); // Adjust the redirection as needed
//             }
//         } catch (error) {
//             console.error("Error adding chapter: ", error);
//         }
//     }
// };

// const handleAddChapter = () => {
//     if (selectedBook && newChapterTitle && newChapterContent) {
//         const selectedBookRef = projectFirestore.collection('anpbooks').doc(selectedBook);
//         // Retrieve the current chapters from the selected book document
//         selectedBookRef.get().then((doc) => {
//             if (doc.exists) {
//                 const bookData = doc.data();
//                 // Determine the next chapter number
//                 const nextChapterNumber = bookData.chapterTitles.length;
//                 // Create the new chapter object
//                 const newChapter = {
//                     content: newChapterContent,
//                     title: newChapterTitle,
//                     number: nextChapterNumber,
//                     isActive: true, // Set isActive to true for the new chapter
//                 };
//                 // Update chapterContent and chapterTitles with the new chapter
//                 selectedBookRef.update({
//                     chapterContent: FieldValue.arrayUnion(newChapterContent),
//                     chapterTitles: FieldValue.arrayUnion(newChapter),
//                 }).then(() => {
//                     history.push('/some-route'); // Adjust the redirection as needed
//                 });
//             } else {
//                 console.error("No such document!");
//             }
//         }).catch((error) => {
//             console.error("Error getting document:", error);
//         });
//     }
// };

const handleAddChapter = () => {
    if (selectedBook && newChapterTitle && newChapterContent) {
        const selectedBookRef = projectFirestore.collection('anpbooks').doc(selectedBook);
        // Retrieve the current chapters from the selected book document
        selectedBookRef.get().then((doc) => {
            if (doc.exists) {
                const bookData = doc.data();
                // Determine the next chapter number, increment by 1
                const nextChapterNumber = bookData.chapterTitles.length + 1;
                // Create the new chapter object
                const newChapter = {
                    content: newChapterContent,
                    title: newChapterTitle,
                    number: nextChapterNumber,
                    isActive: true, // Set isActive to true for the new chapter
                };
                // Update chapterContent and chapterTitles with the new chapter
                selectedBookRef.update({
                    chapterContent: FieldValue.arrayUnion(newChapterContent),
                    chapterTitles: FieldValue.arrayUnion(newChapter),
                }).then(() => {
                    history.push('/addchapter'); // Adjust the redirection as needed
                });
            } else {
                console.error("No such document!");
            }
        }).catch((error) => {
            console.error("Error getting document:", error);
        });
    }
};





  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Add New Chapter</h1>
      <div className={styles.field}>
        <label htmlFor="book-select" className={styles.label}>Select a book:</label>
        <select id="book-select" name="book" value={selectedBook} onChange={handleChange} className={styles.select}>
          <option value="">Select a book</option>
          {books.map(book => (
            <option key={book.id} value={book.id}>
              {book.bookName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.field}>
        <label htmlFor="newChapterTitle" className={styles.label}>Chapter Title:</label>
        <input type="text" id="newChapterTitle" name="newChapterTitle" value={newChapterTitle} onChange={handleChange} className={styles.input} />
      </div>
      <div className={styles.field}>
        <label htmlFor="editor" className={styles.label}>Chapter Content:</label>
        <ReactQuill value={newChapterContent}
                    onChange={handleQuillChange}
                    modules={{ toolbar: toolbarOptions }} />
      </div>
      <div className={styles.field}>
        <label className={styles.label}>Is Active:</label>
        <input type="checkbox" checked={isActive} onChange={() => setIsActive(!isActive)} />
      </div>
      <button onClick={handleAddChapter} className={styles.button}>Add Chapter</button>
    </div>
  );
};

export default AddChapterComponent;
