// ImportLogistics.js
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { projectFirestore, projectStorage } from '../../../firebase/config';

import styles from './ImportLogistics.module.css';

const ImportLogistics = ({ formData, setFormData }) => {
    const [selectedDate, setSelectedDate] = useState(formData.expectedDeliveryDate || new Date());
    
    const handleQuillChange = (value, field) => {
        setFormData({ ...formData, [field]: value });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const handleDateChange = (date) => {
    //     setSelectedDate(date);
    //     setFormData({ ...formData, expectedDeliveryDate: date });
    // };

    return (
        <div className={styles.importLogisticsContainer}>
            <h2>Logistics Details</h2>

            {/* <label className={styles.label}>Expected Delivery Date:</label>
            <DatePicker 
                selected={selectedDate}
                onChange={handleDateChange}
                className={styles.datePicker}
                required // Mark as required
            /> */}

<label className={styles.label}>Customs and Import Taxes:</label>
            <ReactQuill 
                value={formData.customsTaxes || ''}
                onChange={(value) => handleQuillChange(value, 'customsTaxes')}
                className={styles.quill}
                placeholder="Who will handle customs and import taxes?"
            />

            <label className={styles.label}>Preferred Shipping Method:</label>
            <select
                name="shippingMethod"
                className={styles.select}
                value={formData.shippingMethod || ''}
                onChange={handleInputChange}
            >
                <option value="">Select Shipping Method</option>
                <option value="air">Air Freight</option>
                <option value="sea">Sea Freight</option>
                <option value="land">Land Transport</option>
                <option value="express">Express Delivery</option>
                {/* Add more shipping methods as needed */}
            </select>

            <label className={styles.label}>Additional Logistics Details:</label>
            <ReactQuill 
                value={formData.additionalLogistics || ''}
                onChange={(value) => handleQuillChange(value, 'additionalLogistics')}
                className={styles.quill}
                placeholder="Any additional logistics information or requirements"
            />
        </div>
    );
};

export default ImportLogistics;
