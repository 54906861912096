// ContactDeliveryPerson.js
import React, { useState } from 'react';
import styles from './ContactDeliveryPerson.module.css'; // Import the CSS module

const ContactDeliveryPerson = ({ deliveryPersonContact }) => {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to send message to the delivery person
    console.log(`Message to delivery person: ${message}`);
    // Reset the message input after sending
    setMessage('');
  };

  return (
    <div className={styles.contactContainer}>
      <h3 className={styles.title}>Contact Delivery Person</h3>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <textarea
          className={styles.messageInput}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message or special delivery instructions"
          required
        ></textarea>
        <button className={styles.sendButton} type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactDeliveryPerson;
