import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import { useAuthContext } from '../../hooks/useAuthContext';
import NotificationSettings from '../Notifications/NotificationSettings';


const NotificationManagement = () => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const { user } = useAuthContext();

  useEffect(() => {
    const notificationsRef = projectFirestore.collection('notifications').where('userId', '==', user.uid).where('read', '==', false);
    const unsubscribe = notificationsRef.onSnapshot((snapshot) => {
      const newNotifications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setNotifications(newNotifications);
    });

    return () => unsubscribe();
  }, [user]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      await projectFirestore.collection('notifications').doc(notificationId).update({
        read: true
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div>
      <button onClick={() => setShowNotifications(true)}>Notifications ({notifications.length})</button>
      {showNotifications && (
        <div className="notifications-modal">
          <h2>Notifications</h2>
          {notifications.length > 0 ? (
            <ul>
              {notifications.map((notification) => (
                <li key={notification.id} onClick={() => handleMarkAsRead(notification.id)}>
                  <p>{notification.message}</p>
                  <small>{notification.timestamp.toDate().toLocaleString()}</small>
                </li>
              ))}
            </ul>
          ) : (
            <p>No new notifications.</p>
          )}
          <button onClick={() => setShowNotifications(false)}>Close</button>
        </div>
      )}
      {/* NotificationSettings */}
      <NotificationSettings user={user} />
    </div>
  );
};

export default NotificationManagement;
