import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import BooksLayout from '../../components/Hoc/BooksLayout/BooksLayout';

import styles from './BookChapters.module.css';

export default function BookChapters() {
  const { id } = useParams();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [book, setBook] = useState(null);

  useEffect(() => {
    setIsPending(true);

    projectFirestore
      .collection('anpbooks')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setIsPending(false);
          setBook(doc.data());
        } else {
          setIsPending(false);
          setError(`Could not find the book`);
        }
      });
  }, [id]);

  const handleInactiveChapterClick = (e) => {
    e.preventDefault();
    alert("This chapter is not yet released.");
  }


  return (
    <BooksLayout>
      <div className={styles.container}>
        {error && <p className={styles.error}>{error}</p>}
        {isPending && <p className={styles.loading}>Loading...</p>}
        {book && (
          <>
            <h2 className={styles.pageTitle}>{book.bookName}</h2>
            <ul className={styles.chapter}>
              {book.chapterTitles.map((chapter, index) => (
                <li key={index} className={styles.chapterItem}>
                  {chapter.isActive ? (
                    <Link
                      to={`/dchapters/${id}/${index}`}
                      className={styles.chapterLink}
                    >
                      <span className={styles.chapterNumber}>
                        Chapter {index + 1}
                      </span>
                      <span className={styles.chapterTitle}>{chapter.title}</span>
                    </Link>
                  ) : (
                    <a 
                      href="#" 
                      onClick={handleInactiveChapterClick}
                      className={`${styles.chapterLink} ${styles.inactiveChapter}`}
                    >
                      <span className={styles.chapterNumber}>
                        Chapter {index + 1}
                      </span>
                      <span className={styles.chapterTitle}>{chapter.title}</span>
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </BooksLayout>
  );
}

//   return (
//     <BooksLayout>
//     <div className={styles.container}>
//       {error && <p className={styles.error}>{error}</p>}
//       {isPending && <p className={styles.loading}>Loading...</p>}
//       {book && (
//         <>
//           <h2 className={styles.pageTitle}>{book.bookName}</h2>
//           <ul className={styles.chapter}>
//             {book.chapterTitles.map((chapter, index) => (
//               <li key={index} className={styles.chapterItem}>
//                 <Link
//                   to={`/dchapters/${id}/${index}`}
//                   className={styles.chapterLink}
//                 >
//                   <span className={styles.chapterNumber}>
//                     Chapter {index + 1}
//                   </span>
//                   <span className={styles.chapterTitle}>{chapter.title}</span>
//                 </Link>
//               </li>
//             ))}
//           </ul>
//         </>
//       )}
//     </div>
//     </BooksLayout>
//   );
// }


