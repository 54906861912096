import React, { useState } from 'react';
import { projectFirestore, projectStorage, timestamp } from '../../../../firebase/config';
// import { africanCountries } from '../../../../data/africanCountries';
import { africanCountries } from '../../../../constants/countries';
import styles from './AdvertForm.module.css';

const AdvertForm = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [country, setCountry] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const handleImageChange = (e) => {
    const selected = e.target.files[0];
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    if (selected && allowedTypes.includes(selected.type)) {
      setImage(selected);
    } else {
      setImage(null);
      alert('Please select a valid image file (png, jpeg or jpg).');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      alert('Please upload an image.');
      return;
    }

    const storageRef = projectStorage.ref(`adverts/${image.name}-${timestamp()}`);
    const collectionRef = projectFirestore.collection('adverts');

    try {
      const uploadedImage = await storageRef.put(image);
      const url = await uploadedImage.ref.getDownloadURL();

      await collectionRef.add({
        title,
        imageUrl: url,
        country,
        createdAt: timestamp(),
      });

      setTitle('');
      setImage(null);
      setCountry('');
      setImageUrl('');
      alert('Advert submitted successfully.');
    } catch (error) {
      console.error('Error submitting advert: ', error);
      alert('Error submitting advert. Please try again.');
    }
  };

  return (
    <div className={styles.advertFormContainer}>
      <h3>Submit an Advert</h3>
      <form onSubmit={handleSubmit} className={styles.advertForm}>
        <div className={styles.formGroup}>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="country">Country:</label>
          <select
            id="country"
            name="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option value="">Select a country</option>
            {africanCountries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default AdvertForm;
