import React, { useEffect, useState } from 'react';
import IndividualPartnerProfile from '../../Profile/IndividualPartnerProfile';


const IndividualPartnerDashboard = ({ user }) => {

  return (
    <IndividualPartnerProfile user={user} />
  );
};

export default IndividualPartnerDashboard;

