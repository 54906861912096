import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { projectFirestore, projectStorage } from '../../../firebase/config';
import { globalCountries } from '../../../constants/countries';
import ReactSelect from 'react-select';
import { paymentTermOptions } from '../../../constants/constants';
import styles from './ImportBasicDetails.module.css';

const ImportBasicDetails = ({ formData, setFormData, onNext }) => {
    const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(formData.paymentTerms || '');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [errors, setErrors] = useState({});
    const [productImages, setProductImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');

    // State for the input values
    const [importData, setImportData] = useState({
        countryOfDesiredImport: formData.countryOfDesiredImport || '',
        targetPriceRange: formData.targetPriceRange || '',
        preferredCurrency: formData.preferredCurrency || '',
        desiredQuantity: formData.desiredQuantity || '',
        qualityRequirements: formData.qualityRequirements || '',
        productName: formData.productName || '',
    });


    // Validation
    const validate = () => {
        let tempErrors = {};
        // console.log("Validating fields");
        if (!importData.countryOfDesiredImport) tempErrors.countryOfDesiredImport = "Country of desired import is required.";
        if (!importData.targetPriceRange) tempErrors.targetPriceRange = "Target price range is required.";
        if (!importData.preferredCurrency) tempErrors.preferredCurrency = "Preferred currency is required.";
        if (!importData.desiredQuantity) tempErrors.desiredQuantity = "Desired quantity is required.";
        if (!selectedPaymentTerm) tempErrors.paymentTerms = "Payment terms are required.";
        if (!importData.productName) tempErrors.productName = "Product name is required.";
        if (!importData.qualityRequirements) tempErrors.qualityRequirements = "Quality requirements are required.";

        setErrors(tempErrors);
        // console.log("Errors found:", tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleQualityRequirementsChange = value => {
        setImportData({ ...importData, qualityRequirements: value });
        setFormData({ ...formData, qualityRequirements: value });
        console.log("Updated qualityRequirements:", value);
    };

    // Fetch categories from the database
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const snapshot = await projectFirestore.collection('tradeCategories').get();
                const fetchedCategories = snapshot.docs.map(doc => ({
                    value: doc.id,
                    label: doc.data().category
                }));
                setCategories(fetchedCategories);
            } catch (error) {
                console.error("Error fetching categories: ", error);
            }
        };
        fetchCategories();
    }, []);

   
    const handleCategoryChange = async (selectedOption) => {
        console.log("Selected Category: ", selectedOption);
        setSelectedCategory(selectedOption);
        setImportData({ ...importData, category: selectedOption.label, subCategory: '', item: '' });
    
        try {
            const categoryDoc = await projectFirestore.collection('tradeCategories').doc(selectedOption.value).get();
            const subCategories = categoryDoc.data()?.subCategories || [];
            console.log("Fetched Sub-Categories: ", subCategories);
            if (subCategories.length > 0) {
                setSubCategories(subCategories.map(subCat => ({ value: subCat.name, label: subCat.name })));
            } else {
                setSubCategories([]);
            }
            setItems([]); // Reset item options
        } catch (error) {
            console.error("Error fetching sub-categories: ", error);
            setSubCategories([]);
            setItems([]);
        }
    };
    
    const handleSubCategoryChange = async (selectedOption) => {
        console.log("Selected Sub-Category: ", selectedOption);
        setSelectedSubCategory(selectedOption);
        setImportData({ ...importData, subCategory: selectedOption.label, item: '' });
    
        if (selectedCategory) {
            try {
                const categoryDocRef = projectFirestore.collection('tradeCategories').doc(selectedCategory.value);
                const doc = await categoryDocRef.get();
                const categoryData = doc.data();
                console.log("Category data:", categoryData);
    
                const subCategory = categoryData.subCategories.find(subCat => subCat.name === selectedOption.label);
                console.log("Found sub-category:", subCategory);
    
                if (subCategory && subCategory.items) {
                    setItems(subCategory.items.map(item => ({ value: item, label: item })));
                } else {
                    setItems([]);
                }
            } catch (error) {
                console.error("Error fetching items: ", error);
                setItems([]);
            }
        } else {
            setItems([]);
        }
    };
    

    const handleItemChange = selectedOption => {
        setSelectedItem(selectedOption);
        const newProductName = selectedOption ? selectedOption.label : '';
        setImportData({ ...importData, productName: newProductName });
        setFormData({ ...formData, productName: newProductName });
        console.log("Updated productName:", newProductName);
    };
    


    // Next button handler
    const handleNext = () => {
        console.log("Next button clicked");
        if (validate()) {
            // console.log("Validation passed, calling onNext");
            onNext();
        } else {
            // console.log("Validation failed");
            alert("Please fill in all required fields. In ImportBasic");
        }
    };

    

    // Handlers for changes in ReactSelect components
    const handlePaymentTermChange = selectedOption => {
        setSelectedPaymentTerm(selectedOption);
        setFormData({ ...formData, paymentTerms: selectedOption.value });
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setImportData({ ...importData, [name]: value });
        setFormData({ ...formData, [name]: value, errors });
    };
    
    // Handle image file uploads
    const handleImageUpload = async (e) => {
        const files = Array.from(e.target.files);
    
        // Validate each file
        const isValid = files.every(file => {
            const isSizeValid = file.size <= 5000000; // 5MB size limit
            const isFormatValid = ['image/jpeg', 'image/png'].includes(file.type); // Accept JPEG and PNG
            return isSizeValid && isFormatValid;
        });
    
        if (!isValid) {
            setUploadStatus('Invalid file size or format');
            return;
        }
    
        setUploadStatus('Uploading...');
    
        const uploadPromises = files.map(file => {
            const storageRef = projectStorage.ref(`productImages/${file.name}`);
            return storageRef.put(file).then(snapshot => snapshot.ref.getDownloadURL());
        });
    
        try {
            const downloadURLs = await Promise.all(uploadPromises);
            setFormData(prevFormData => ({
                ...prevFormData,
                productImages: [...(prevFormData.productImages || []), ...downloadURLs]
            }));
            setImagePreviews(prevPreviews => [...prevPreviews, ...downloadURLs]);
            setUploadStatus('Upload complete');
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploadStatus('Error during upload');
        }
    };
    

      return (
        <div className={styles.importBasicDetailsContainer}>
            <h2>Basic Import Details</h2>
            {/* Country of Desired Import */}
            <div className={styles.formGroup}>
                <label htmlFor="countryOfDesiredImport">Country of Desired Import:</label>
                <select 
                    name="countryOfDesiredImport" 
                    value={importData.countryOfDesiredImport} 
                    onChange={handleInputChange}
                    className={styles.select}
                >
                    <option value="">Select Country</option>
                    {globalCountries.map(country => (
                        <option key={country.code} value={country.name}>{country.name}</option>
                    ))}
                </select>
                {errors.countryOfDesiredImport || formData.errors?.countryOfDesiredImport ?
                    <p className={styles.errorMessage}>
                        {errors.countryOfDesiredImport || formData.errors?.countryOfDesiredImport}
                    </p> : null
                }
            </div>
                {/* Target Price Range */}
                <div className={styles.formGroup}>
                <label htmlFor="targetPriceRange">Target Price Range</label>
                <input
                    type="text"
                    id="targetPriceRange"
                    name="targetPriceRange"
                    value={importData.targetPriceRange}
                    onChange={handleInputChange}
                    className={styles.input}
                />
                
                {errors.targetPriceRange || formData.errors?.targetPriceRange ?
                    <p className={styles.errorMessage}>
                        {errors.targetPriceRange || formData.errors?.targetPriceRange}
                    </p> : null
                }
            </div>

            {/* Preferred Currency */}
            <div className={styles.formGroup}>
                <label htmlFor="preferredCurrency">Preferred Currency</label>
                <input
                    type="text"
                    id="preferredCurrency"
                    name="preferredCurrency"
                    value={importData.preferredCurrency}
                    onChange={handleInputChange}
                    className={styles.input}
                />
                
                {errors.preferredCurrency || formData.errors?.preferredCurrency ?
                    <p className={styles.errorMessage}>
                        {errors.preferredCurrency || formData.errors?.preferredCurrency}
                    </p> : null
                }
            </div>
            {/* Desired Quantity */}
            <div className={styles.formGroup}>
                <label htmlFor="desiredQuantity">Desired Quantity</label>
                <input
                    type="text"
                    id="desiredQuantity"
                    name="desiredQuantity"
                    value={importData.desiredQuantity}
                    onChange={handleInputChange}
                    className={styles.input}
                />
                 
                 {errors.desiredQuantity || formData.errors?.desiredQuantity ?
                    <p className={styles.errorMessage}>
                        {errors.desiredQuantity || formData.errors?.desiredQuantity}
                    </p> : null
                }
            </div>
            {/* Payment Terms Dropdown */}
            <div className={styles.formGroup}>
                <label htmlFor="paymentTerms">Payment Terms</label>
                <ReactSelect
                    id="paymentTerms"
                    value={selectedPaymentTerm}
                    onChange={handlePaymentTermChange}
                    options={paymentTermOptions}
                    className={styles.select}
                />
                 
                 {errors.selectedPaymentTerm || formData.errors?.selectedPaymentTerm ?
                    <p className={styles.errorMessage}>
                        {errors.selectedPaymentTerm || formData.errors?.selectedPaymentTerm}
                    </p> : null
                }
            </div>
            {/* Category, Sub-Category, and Item Dropdowns */}
            {/* Category Dropdown */}
            <div className={styles.formGroup}>
                <label htmlFor="category">Category</label>
                <ReactSelect
                    id="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={categories}
                    className={styles.select}
                    placeholder="Select a Category"
                />
                 
                 {errors.selectedCategory || formData.errors?.selectedCategory ?
                    <p className={styles.errorMessage}>
                        {errors.selectedCategory || formData.errors?.selectedCategory}
                    </p> : null
                }
            </div>

            {/* Sub-Category Dropdown */}
            <div className={styles.formGroup}>
                <label htmlFor="subCategory">Sub-Category</label>
                <ReactSelect
                    id="subCategory"
                    value={selectedSubCategory}
                    onChange={handleSubCategoryChange}
                    options={subCategories}
                    className={styles.select}
                    placeholder="Select a Sub-Category"
                    isDisabled={!selectedCategory}
                />
                 
                 {errors.selectedSubCategory || formData.errors?.selectedSubCategory ?
                    <p className={styles.errorMessage}>
                        {errors.selectedSubCategory || formData.errors?.selectedSubCategory}
                    </p> : null
                }
            </div>

            {/* Item Dropdown */}
            <div className={styles.formGroup}>
                <label htmlFor="item">Item</label>
                <ReactSelect
                    id="item"
                    value={selectedItem}
                    onChange={handleItemChange}
                    options={items}
                    className={styles.select}
                    placeholder="Select an Item"
                    isDisabled={!selectedSubCategory}
                />
                 
                 {errors.selectedItem || formData.errors?.selectedItem ?
                    <p className={styles.errorMessage}>
                        {errors.selectedItem || formData.errors?.selectedItem}
                    </p> : null
                }
            </div>

            {/* Product or Service Name */}
            <div className={styles.formGroup}>
                <label htmlFor="productName">Product or Service Name:</label>
                <input
                    type="text"
                    name="productName"
                    value={importData.productName}
                    onChange={handleInputChange}
                    className={styles.input}
                    readOnly
                />
                 
                 {errors.productName || formData.errors?.productName ?
                    <p className={styles.errorMessage}>
                        {errors.productName || formData.errors?.productName}
                    </p> : null
                }
            </div>
            {/* Product Images Upload */}
            <div className={styles.formGroup}>
                <label htmlFor="productImages" className={styles.label}>Product Images:</label>
                <input 
                    type="file" 
                    id="productImages" 
                    name="productImages" 
                    onChange={handleImageUpload}
                    className={styles.input}
                    multiple
                    accept="image/*"
                />
                 {/* {errors.targetPriceRange && <p className={styles.errorMessage}>{errors.targetPriceRange}</p>} */}
                {uploadStatus && <p className={styles.uploadStatus}>{uploadStatus}</p>}
                <div className={styles.imagePreviews}>
                    {imagePreviews.map((url, index) => (
                        <img key={index} src={url} alt={`Product Image ${index + 1}`} className={styles.imagePreview} />
                    ))}
                </div>
            </div>

            {/* Quality or Specification Requirements */}
            <div className={styles.formGroup}>
                <label htmlFor="qualityRequirements">Quality/Specification Requirements:</label>
                <ReactQuill
                    value={importData.qualityRequirements}
                    onChange={handleQualityRequirementsChange}
                    className={styles.quill}
                />
                 
                 {errors.qualityRequirements || formData.errors?.qualityRequirements ?
                    <p className={styles.errorMessage}>
                        {errors.qualityRequirements || formData.errors?.qualityRequirements}
                    </p> : null
                }
            </div>
            {/* Display error messages */}
            {Object.keys(errors).map(errorKey => (
                <p key={errorKey} className={styles.errorMessage}>{errors[errorKey]}</p>
            ))}

        </div>
    );
};

export default ImportBasicDetails;
