import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';

const SubscriptionForm = ({ onDurationChange }) => {
  const [duration, setDuration] = useState(1);
  const { user } = useContext(AuthContext);

  const handleDurationChange = (event) => {
    const newDuration = parseInt(event.target.value, 10);
    setDuration(newDuration);
    onDurationChange(newDuration);
  };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Get the user object and pass it to setPaymentDuration along with the chosen duration
    
    
//   };

  return (
    <form>
      <label htmlFor="duration">Choose subscription duration:</label>
      <select id="duration" value={duration} onChange={handleDurationChange}>
        {[...Array(12)].map((_, index) => (
          <option key={index} value={index + 1}>
            {index + 1} month(s)
          </option>
        ))}
      </select>
    </form>
  );
};

export default SubscriptionForm;