import React, { useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config'; // Import Firestore
import NotificationsDeactivation from './NotificationsDeactivation';


import styles from './AccountDeactivation.module.css';

const AccountDeactivation = () => {
  const { user, signOut } = useAuthContext();
  const [notification, setNotification] = useState(null);

  const handleDeactivateAccount = async () => {
    if (window.confirm('Are you sure you want to deactivate your account? This action cannot be undone.')) {
      try {
        await projectFirestore.collection('users').doc(user.uid).update({
          isActive: false,
        });

        await signOut();
        setNotification({ type: 'success', message: 'Your account has been deactivated.' });
      } catch (error) {
        console.error('Error deactivating account:', error);
        setNotification({ type: 'error', message: `Error deactivating account: ${error.message}` });
      }
    }
  };

  return (
    <div className={styles.accountDeactivationContainer}>
    <h2 className={styles.profileSectionTitle}>Account Deactivation</h2>
      <p className={styles.accountDeactivationContent}>
        If you wish to deactivate your account, please click the button below. This action cannot be undone.
      </p>
      <button onClick={handleDeactivateAccount} className={styles.accountDeactivationButton}>
        Deactivate Account
      </button>
      {notification && (
        <NotificationsDeactivation 
          type={notification.type} 
          message={notification.message} 
          onClose={() => setNotification(null)} 
        />
      )}
    </div>
  );
};

export default AccountDeactivation;

