import React, { useState, useEffect } from 'react';
import styles from './EditTradeCategoryForm.module.css';

const EditTradeCategoryForm = ({ categoryData, onSave, onCancel }) => {
    const [categoryName, setCategoryName] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('Add New');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [items, setItems] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setCategoryName(categoryData.category || '');
        // Reset fields when the form is opened
        setSelectedSubCategory('Add New');
        setSubCategoryName('');
        setItems('');
    }, [categoryData]);

    const handleSubCategorySelection = (e) => {
        const selected = e.target.value;
        setSelectedSubCategory(selected);

        if (selected !== 'Add New') {
            const subCat = categoryData.subCategories.find(sc => sc.name === selected);
            setSubCategoryName(subCat.name);
            setItems(subCat.items.join(', '));
        } else {
            setSubCategoryName('');
            setItems('');
        }
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const itemsArray = items.split(',').map(item => item.trim());

    //     if (!subCategoryName.trim() || itemsArray.length === 0) {
    //         setError("Sub-category name and items are required.");
    //         return;
    //     }

    //     onSave({
    //         id: categoryData.id,
    //         category: categoryName,
    //         subCategory: {
    //             name: subCategoryName,
    //             items: itemsArray,
    //             isEdit: selectedSubCategory !== 'Add New'
    //         }
    //     });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const itemsArray = items.split(',').map(item => item.trim());
    
    //     if (!subCategoryName.trim() || itemsArray.length === 0) {
    //         setError("Sub-category name and items are required.");
    //         return;
    //     }
    
    //     onSave({
    //         id: categoryData.id, // Include id if editing an existing category
    //         category: categoryName,
    //         newSubCategory: {
    //             name: subCategoryName,
    //             items: itemsArray
    //         }
    //     });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const itemsArray = items.split(',').map(item => item.trim());
    
    //     if (!subCategoryName.trim() || itemsArray.length === 0) {
    //         setError("Sub-category name and items are required.");
    //         return;
    //     }
    
    //     const newSubCategory = {
    //         name: subCategoryName,
    //         items: itemsArray,
    //         isEdit: selectedSubCategory !== 'Add New'
    //     };
    
    //     onSave({
    //         id: categoryData.id, // Include id if editing an existing category
    //         category: categoryName,
    //         newSubCategory,
    //         originalSubCategoryName: selectedSubCategory !== 'Add New' ? selectedSubCategory : null
    //     });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const itemsArray = items.split(',').map(item => item.trim());
    
    //     if (!subCategoryName.trim() || itemsArray.length === 0) {
    //         setError("Sub-category name and items are required.");
    //         return;
    //     }
    
    //     const isEditingSubCategory = selectedSubCategory !== 'Add New';
    //     const newSubCategory = {
    //         name: subCategoryName,
    //         items: itemsArray,
    //         isEdit: isEditingSubCategory
    //     };
    
    //     onSave({
    //         id: categoryData.id, // Include id if editing an existing category
    //         category: categoryName,
    //         newSubCategory,
    //         originalSubCategoryName: isEditingSubCategory ? selectedSubCategory : null
    //     });
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        const itemsArray = items.split(',').map(item => item.trim());
    
        if (!subCategoryName.trim() || itemsArray.length === 0) {
            setError("Sub-category name and items are required.");
            return;
        }
    
        const isEditingSubCategory = selectedSubCategory !== 'Add New';
        const newSubCategory = {
            name: subCategoryName,
            items: itemsArray,
            isEdit: isEditingSubCategory
        };
    
        onSave({
            id: categoryData.id, 
            category: categoryName,
            newSubCategory,
            originalSubCategoryName: isEditingSubCategory ? selectedSubCategory : null,
            subCategories: categoryData.subCategories // pass existing sub-categories for reference
        });
    };
    
    
    
    
    

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            {error && <p className={styles.errorMessage}>{error}</p>}

            <div className={styles.inputGroup}>
                <label htmlFor="categoryName">Category Name:</label>
                <input
                    id="categoryName"
                    type="text"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    className={styles.input}
                    disabled={Boolean(categoryData && categoryData.id)} // Disable if editing an existing category
                />
            </div>

            <div className={styles.inputGroup}>
                <label htmlFor="subCategorySelect">Choose Sub-Category to Edit:</label>
                <select 
                    id="subCategorySelect" 
                    value={selectedSubCategory}
                    onChange={handleSubCategorySelection}
                    className={styles.select}
                    disabled={!categoryData || !categoryData.id} // Disable if adding a new category
                >
                    <option value="Add New">Add New Sub-Category</option>
                    {categoryData && categoryData.subCategories && categoryData.subCategories.map((sc, index) => (
                        <option key={index} value={sc.name}>{sc.name}</option>
                    ))}
                </select>
            </div>

            <div className={styles.inputGroup}>
                <label htmlFor="subCategoryName">Sub-Category Name:</label>
                <input
                    id="subCategoryName"
                    type="text"
                    value={subCategoryName}
                    onChange={(e) => setSubCategoryName(e.target.value)}
                    className={styles.input}
                />
            </div>

            <div className={styles.inputGroup}>
                <label htmlFor="items">Items (comma separated):</label>
                <input
                    id="items"
                    type="text"
                    value={items}
                    onChange={(e) => setItems(e.target.value)}
                    className={styles.input}
                />
            </div>

            <button type="submit" className={styles.saveButton}>Save</button>
            <button type="button" onClick={onCancel} className={styles.cancelButton}>Cancel</button>
        </form>
    );
};

export default EditTradeCategoryForm;
