import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config';
import styles from './ApproveAfholders.module.css';

const ApproveAfholders = () => {
    const { user } = useAuthContext();
    const [invitations, setInvitations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const createAfholderNotification = async (userId, message) => {
        await projectFirestore.collection('afholderNotifications').add({
            userId: userId,
            message: `Your invitation to ${user.displayName} has been approved.`,
            createdAt: new Date(),
            isRead: false
        });
    };

    useEffect(() => {
        const unsubscribe = projectFirestore.collection('invitations')
            .where('status', 'in', ['pending', 'approved']) // Fetch only 'pending' and 'approved' invitations
            .onSnapshot(snapshot => {
                const allInvitations = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setInvitations(allInvitations);
                setIsLoading(false);
            }, err => {
                console.error('Error fetching invitations:', err);
                setIsLoading(false);
            });

        return () => unsubscribe();
    }, []);

    // const handleApprove = async (invitationId, inviterId) => {
    //     const invitationRef = projectFirestore.collection('invitations').doc(invitationId);
    //     const inviterRef = projectFirestore.collection('users').doc(inviterId);

    //     return projectFirestore.runTransaction(async (transaction) => {
    //         const invitationDoc = await transaction.get(invitationRef);
    //         const inviterDoc = await transaction.get(inviterRef);

    //         if (!invitationDoc.exists || !inviterDoc.exists) {
    //             throw new Error('Document does not exist!');
    //         }

    //         const inviterData = inviterDoc.data();
    //         if (inviterData.approvalsReceived < inviterData.approvalsNeeded) {
    //             transaction.update(inviterRef, {
    //                 approvalsReceived: inviterData.approvalsReceived + 1
    //             });
    //             transaction.update(invitationRef, { status: 'approved' });
    //         }
    //     }).then(() => {
    //         console.log("Transaction successfully committed!");
    //         setInvitations(prevInvitations => prevInvitations.map(invite => {
    //             if (invite.id === invitationId) {
    //                 return { ...invite, isApproved: true };
    //             }
    //             return invite;
    //         }));
    //     }).catch((error) => {
    //         console.log("Transaction failed: ", error);
    //     });

    //     // After successful approval, create a notification for inviter
    //     await createAfholderNotification(inviterId, `Your invitation has been approved by ${user.displayName || user.email}.`);

    // };

    const handleApprove = async (invitationId, inviterId) => {
        const invitationRef = projectFirestore.collection('invitations').doc(invitationId);
        const inviterRef = projectFirestore.collection('users').doc(inviterId);
    
        try {
            await projectFirestore.runTransaction(async (transaction) => {
                const invitationDoc = await transaction.get(invitationRef);
                const inviterDoc = await transaction.get(inviterRef);
    
                if (!invitationDoc.exists || !inviterDoc.exists) {
                    throw new Error('Document does not exist!');
                }
    
                const inviterData = inviterDoc.data();
                if (inviterData.approvalsReceived < inviterData.approvalsNeeded) {
                    transaction.update(inviterRef, {
                        approvalsReceived: inviterData.approvalsReceived + 1
                    });
                    transaction.update(invitationRef, { status: 'approved' });
                }
            });
    
            console.log("Transaction successfully committed!");
            // Update local state to reflect the approval
            setInvitations(prevInvitations => prevInvitations.map(invite => {
                if (invite.id === invitationId) {
                    return { ...invite, status: 'approved' }; // Mark as approved in local state
                }
                return invite;
            }));
    
            // After successful approval, create a notification for inviter
            await createAfholderNotification(inviterId, `Your invitation has been approved by ${user.displayName || user.email}.`);
        } catch (error) {
            console.log("Transaction failed: ", error);
        }
    };
    
    

    if (isLoading) return <p>Loading...</p>;
    if (!invitations.length) return <p>No pending invitations.</p>;

    return (
        <div className={styles.container}>
            <h3>Approval Requests for New Afholders</h3>
            <h6>Review and respond to invitations from individuals seeking to become Afholders. 
                Your approval will grant them access to the shared benefits of our community. 
                Please ensure you are confident that the applicant is of 
                African descent or an African before approving their request.</h6>
            <ul>
                {invitations.map(invite => (
                    <li key={invite.id} style={{ opacity: invite.status === 'approved' ? 0.5 : 1 }}>
                        {JSON.stringify(invite)}
                        <button
                            onClick={() => handleApprove(invite.id, invite.inviterId)}
                            disabled={invite.status === 'approved'}
                        >
                            {invite.status === 'approved' ? "Approved" : "Approve"}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ApproveAfholders;
