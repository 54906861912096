import React, { useState } from 'react';
import styles from './ProposalLanding.module.css'; 

export const ProposalFilter = ({ onFilterChange }) => {
  const [filter, setFilter] = useState({
    country: '',
    region: '',
    city: '',
    zipCode: '',

  });

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilterChange(filter);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.filterForm}>
      {/* Input fields for filtering criteria */}
      <input
        name="country"
        value={filter.country}
        onChange={handleInputChange}
        placeholder="Country"
      />
      <input
        name="region"
        value={filter.region}
        onChange={handleInputChange}
        placeholder="Region"
      />
      <input
        name="city"
        value={filter.city}
        onChange={handleInputChange}
        placeholder="City"
      />
      <input
        name="zipCode"
        value={filter.zipCode}
        onChange={handleInputChange}
        placeholder="ZIP Code"
      />
      <button type="submit">Apply Filter</button>
    </form>
  );
};

