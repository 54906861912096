import React from 'react';
import { motion } from 'framer-motion';
import { useCollection } from '../../../../hooks/useCollection';
import styles from './ImageGrid.module.css';

const ImageGrid = ({ setSelectedImg }) => {
  const { isPending, error, documents } = useCollection('pictures');

  return (
    <div className={styles.imgGrid}>
      {documents && documents.map(doc => (
        <motion.div className={styles.imgWrap} key={doc.id} 
          layout
          whileHover={{ opacity: 1 }}
          onClick={() => setSelectedImg(doc.url)}
        >
          <motion.img src={doc.url} alt="global afro pictures"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default ImageGrid;


// import React from 'react';
// import { motion } from 'framer-motion';
// import { useCollection } from '../../../../hooks/useCollection';
// import styles from './ImageGrid.module.css';

// const ImageGrid = ({ setSelectedImg }) => {
//   const { isPending, error, documents } = useCollection('pictures');

//   return (
//     <div className={styles.imgGrid}>
//       {documents && documents.map(doc => (
//         <motion.div className={styles.imgWrap} key={doc.id} 
//           layout
//           whileHover={{ opacity: 1 }}
//           onClick={() => setSelectedImg(doc.url)}
//         >
//           <motion.img src={doc.url} alt="global afro pictures"
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ delay: 1 }}
//           />
//         </motion.div>
//       ))}
//     </div>
//   );
// };

// export default ImageGrid;


// import React from 'react'
// import { motion } from 'framer-motion';
// //import { useFirestore } from '../../../../hooks/useFirestore';
// import { useCollection } from '../../../../hooks/useCollection'


// import "./ImageGrid.css"

// const ImageGrid = ({ setSelectedImg }) => {
//   //const { docs } = useFirestore('images');
//   const { isPending, error, documents } = useCollection('pictures')

//   return (
//     <div className="img-grid">
//       {documents && documents.map(doc => (
//         <motion.div className="img-wrap" key={doc.id} 
//           layout
//           whileHover={{ opacity: 1 }}s
//           onClick={() => setSelectedImg(doc.url)}
//         >
//           <motion.img src={doc.url} alt="global afro pictures"
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ delay: 1 }}
//           />
//         </motion.div>
//       ))}
//     </div>
//   )
// }

// export default ImageGrid;