import React from 'react';
import styles from './ViewPackageDetails.module.css';


const ViewPackageDetails = ({ senderName, senderAddress, deliveryName, deliveryAddress, dimensions, weight, cost }) => {
  if (!senderName || !deliveryName) {
    return <p>Package details not available.</p>;
  }

  // Format package size
  const packageSize = `${dimensions.length} x ${dimensions.width} x ${dimensions.height}`;

  return (
    <div className={styles.packageDetailsContainer}>
      <h3 className={styles.title}>Package Details</h3>
      <div className={styles.detailItem}><strong>Sender:</strong> {senderName}</div>
      <div className={styles.detailItem}><strong>Sender Address:</strong> {senderAddress}</div>
      <div className={styles.detailItem}><strong>Recipient:</strong> {deliveryName}</div>
      <div className={styles.detailItem}><strong>Delivery Address:</strong> {deliveryAddress}</div>
      <div className={styles.detailItem}><strong>Package Size:</strong> {packageSize}</div>
      <div className={styles.detailItem}><strong>Weight:</strong> {weight} kg</div>
      <div className={styles.detailItem}><strong>Cost:</strong> {cost ? `${cost.toFixed(2)} Currency` : 'Calculating...'}</div>
      {/* Add more package details as needed */}
    </div>
  );
};

export default ViewPackageDetails;


