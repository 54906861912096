
export const manageAfholderFeatures = [
    'Approval Requests',
    'Approve Afholders',
    'Notifications',
    'Become an Afholder',         // Corresponds to AfholderRequest component
    'Approval Tracking',        // Corresponds to ApprovalTracking component
    'Request Afholder Approval',          // Corresponds to InvitationComponent component
    // 'Invitation Out',
    
];


export const commonAccountFeatures = [
    'Reviews', 
    'Country Settings',
    'Shipping Addresses', 
    'Exchange Rates Display', 
    'Currency Converter',
    'Missing Currencies',
    'Check Calculations',
    'Notifications', 
    'Account Settings', 
    'Customer Support'
];

export const manageIndivCusBooksFeatures = [
    'Bookshelf', 
    'Book reader', 
    'Book categories', 
    'Recommendation', 
    'Bookmarks', 
    'Refund & Cancellation'
];

export const manageVisualsFeatures = [
    'Gallery', 
    'Slideshow', 
    'Video', 
    'Animation', 
    'Infographics'
];
export const manageProposalFeatures = [
    'Add Proposal', 
];

export const managePropertuFeatures = [
    'Property Listings',
    'Property Search',
    'Property Details',
    'Property Comparison',
    'Mortgage Calculator',
    'Test Pro',
  ];

  export const manageDeliveryFeatures = [
    'Package Tracking', 
    'Delivery Notifications', 
    'Delivery History', 
    'Delivery Address Book', 
    'Delivery Preferences'
];

// constants.js or a similar file where you define constants

export const manageDeliveryPartnerFeatures = [
    'Package Pickup',
    'Delivery Management',
    'Route Optimization',
    'Delivery History',
    'Earnings Report',
    'Feedback and Ratings',
    'Order Status Manager'
    // ... other relevant features for delivery partners
];


export const manageIndivPartnerBooksFeatures = [
    'Bookshelf', 
    'Add Book', 
    'Update Book', 
    'Chapter Update', 
    'Archive Book', 
    'Delete Book', 
    'Read Book', 
    'Book categories', 
    'Recommendation', 
    'Bookmarks', 
    'Refund & Cancellation'
];


export const paymentTermOptions = [
    { value: 'advancePayment', label: 'Advance Payment' },
    { value: 'cashOnDelivery', label: 'Cash on Delivery' },
    { value: 'letterOfCredit', label: 'Letter of Credit' },
    { value: 'net30', label: 'Net 30' },
    { value: 'net60', label: 'Net 60' },
    // ... add more payment terms as needed
];

// Add more constants as needed



//   ['Property Listings', 'Property Search', 'Property Details', 'Property Comparison', 'Mortgage Calculator']