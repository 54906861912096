import React, { useState } from 'react';
import { projectFirestore, FieldValue } from '../../../firebase/config';
import styles from './TradeCategories.module.css';

const TradeCategories = () => {
    const [category, setCategory] = useState('');
    const [subCategoryName, setSubCategoryName] = useState(''); // For the sub-category name
    const [subCategoryItems, setSubCategoryItems] = useState(''); // For the items in the sub-category
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Split sub-category items into an array
        const itemsArray = subCategoryItems.split(',').map(item => item.trim());

        if (!category.trim() || !subCategoryName.trim() || itemsArray.length === 0) {
            setError("Category, sub-category name, and items are required.");
            return;
        }

        // Construct the sub-category object
        const subCategory = {
            name: subCategoryName,
            items: itemsArray
        };

        try {
            const docRef = await projectFirestore.collection('tradeCategories').doc(category);
            const doc = await docRef.get();

            if (doc.exists) {
                // Update existing category with new sub-category
                await docRef.update({
                    subCategories: FieldValue.arrayUnion(subCategory)
                });
            } else {
                // Create new category document with first sub-category
                await docRef.set({
                    category,
                    subCategories: [subCategory]
                });
            }

            // Reset form fields
            setCategory('');
            setSubCategoryName('');
            setSubCategoryItems('');
            setError('');
        } catch (error) {
            console.error("Error updating document: ", error);
            setError("Failed to save data. Please try again.");
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            {error && <p className={styles.errorMessage}>{error}</p>}
            <div className={styles.inputGroup}>
                <label htmlFor="category" className={styles.label}>Category:</label>
                <input
                    id="category"
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className={styles.input}
                />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="subCategoryName" className={styles.label}>Sub-Category Name:</label>
                <input
                    id="subCategoryName"
                    type="text"
                    value={subCategoryName}
                    onChange={(e) => setSubCategoryName(e.target.value)}
                    className={styles.input}
                />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="subCategoryItems" className={styles.label}>Items (comma separated):</label>
                <input
                    id="subCategoryItems"
                    type="text"
                    value={subCategoryItems}
                    onChange={(e) => setSubCategoryItems(e.target.value)}
                    className={styles.input}
                />
            </div>
            <button type="submit" className={styles.submitButton}>Submit</button>
        </form>
    );
};

export default TradeCategories;
