import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { projectFirestore, FieldValue } from '../../../firebase/config';
import styles from './AdminDeliveryCategories.module.css';

const AdminDeliveryCategories = () => {
    const { user } = useContext(AuthContext);
    const [categories, setCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryAttributes, setNewCategoryAttributes] = useState([{ key: '', value: '' }]);
    const [newSubCategoryName, setNewSubCategoryName] = useState('');
    const [newSubCategoryAttributes, setNewSubCategoryAttributes] = useState([{ key: '', value: '' }]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [editingSubCategoryId, setEditingSubCategoryId] = useState(null);
    const [editingCategoryName, setEditingCategoryName] = useState('');
    const [editingSubCategoryName, setEditingSubCategoryName] = useState('');

    // Add a utility function to validate category or sub-category data
    const isValidCategoryData = (name, attributes) => {
        if (!name.trim()) return false; // Check if name is not empty
        // Ensure all attributes have non-empty keys and values
        return attributes.every(attr => attr.key.trim() && attr.value.trim());
    };

    useEffect(() => {
        setIsLoading(true);
        projectFirestore.collection('DeliveryCategories')
            .get()
            .then(snapshot => {
                const categoriesData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCategories(categoriesData);
            })
            .catch(err => setError(err.message))
            .finally(() => setIsLoading(false));
    }, []);

   // Function to add a new attribute field for categories
const addCategoryAttributeField = () => {
    setNewCategoryAttributes([...newCategoryAttributes, { key: '', value: '' }]);
};

// Function to remove an attribute field for categories
const removeCategoryAttributeField = index => {
    setNewCategoryAttributes(newCategoryAttributes.filter((_, i) => i !== index));
};

// Function to handle category attribute changes
const handleCategoryAttributeChange = (index, event) => {
    const updatedAttributes = newCategoryAttributes.map((attribute, i) => {
        if (i === index) {
            return { ...attribute, [event.target.name]: event.target.value };
        }
        return attribute;
    });
    setNewCategoryAttributes(updatedAttributes);
};

    // Function to refresh categories after an addition
    const refreshCategories = async () => {
        setIsLoading(true);
        try {
            const snapshot = await projectFirestore.collection('DeliveryCategories').get();
            const categoriesData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setCategories(categoriesData);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAttributeChange = (index, event) => {
        const updatedAttributes = newSubCategoryAttributes.map((attribute, i) => {
            if (i === index) {
                return { ...attribute, [event.target.name]: event.target.value };
            }
            return attribute;
        });
        setNewSubCategoryAttributes(updatedAttributes);
    };

    const addAttributeField = () => {
        setNewSubCategoryAttributes([...newSubCategoryAttributes, { key: '', value: '' }]);
    };

    const removeAttributeField = index => {
        setNewSubCategoryAttributes(newSubCategoryAttributes.filter((_, i) => i !== index));
    };

 

    const handleAddCategory = async () => {
        // Validate data before adding
        if (!isValidCategoryData(newCategoryName, newCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }

        const attributesObject = newCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});

        try {
            setIsLoading(true);
            await projectFirestore.collection('DeliveryCategories').add({
                name: newCategoryName,
                attributes: attributesObject,
                subCategories: []
            });
            setNewCategoryName('');
            setNewCategoryAttributes([{ key: '', value: '' }]);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
        await refreshCategories(); // Refresh categories after adding
    };


    const handleAddSubCategory = async () => {
        // Validate data before adding
        if (!isValidCategoryData(newSubCategoryName, newSubCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }
        const attributesObject = newSubCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            const newSubCategory = {
                id: new Date().getTime().toString(),
                name: newSubCategoryName,
                attributes: attributesObject
            };
            const categoryRef = projectFirestore.collection('DeliveryCategories').doc(selectedCategoryId);
            await categoryRef.update({
                subCategories: FieldValue.arrayUnion(newSubCategory)
            });
            setNewSubCategoryName('');
            setNewSubCategoryAttributes([{ key: '', value: '' }]);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
        await refreshCategories();
    };
    

    const handleDeleteCategory = async (categoryId) => {
        try {
            setIsLoading(true);
            await projectFirestore.collection('DeliveryCategories').doc(categoryId).delete();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteSubCategory = async (categoryId, subCategoryId) => {
        console.log("Deleting sub-category with ID:", subCategoryId);
        try {
            setIsLoading(true);
            const categoryRef = projectFirestore.collection('DeliveryCategories').doc(categoryId);
            const categoryDoc = await categoryRef.get();
    
            if (!categoryDoc.exists) {
                throw new Error("Category not found");
            }
    
            // Filter out the sub-category to delete
            const updatedSubCategories = categoryDoc.data().subCategories.filter(subCat => subCat.id !== subCategoryId);
    
            // Update the category with the new array of sub-categories
            await categoryRef.update({ subCategories: updatedSubCategories });
    
            console.log("Sub-category deleted, refreshing categories.");
            await refreshCategories();
        } catch (err) {
            console.error("Error deleting sub-category:", err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleUpdateCategory = async () => {
        // Validate data before updating
        if (!isValidCategoryData(editingCategoryName, newCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }
        const attributesObject = newCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            await projectFirestore.collection('DeliveryCategories').doc(editingCategoryId).update({
                name: editingCategoryName,
                attributes: attributesObject
            });
            await refreshCategories();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
            resetEditState();
        }
    };
    
    const handleUpdateSubCategory = async () => {
        // Validate data before updating
        if (!isValidCategoryData(editingSubCategoryName, newSubCategoryAttributes)) {
            setError("Please fill in all fields correctly.");
            return;
        }
        const attributesObject = newSubCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            const categoryRef = projectFirestore.collection('DeliveryCategories').doc(selectedCategoryId);
            const categoryDoc = await categoryRef.get();
            const updatedSubCategories = categoryDoc.data().subCategories.map(subCat => {
                if (subCat.id === editingSubCategoryId) {
                    return { ...subCat, name: editingSubCategoryName, attributes: attributesObject };
                }
                return subCat;
            });
    
            await categoryRef.update({ subCategories: updatedSubCategories });
            await refreshCategories();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
            resetEditState();
        }
    };
    

    const startEditingCategory = (category) => {
        setEditingCategoryId(category.id);
        setEditingCategoryName(category.name);
        const globalAttributes = Object.entries(category.attributes || {}).map(([key, value]) => ({ key, value }));
        setNewCategoryAttributes(globalAttributes);
    };
    



    const startEditingSubCategory = (categoryId, subCategory) => {
        setSelectedCategoryId(categoryId); // Set the selected category ID
        setEditingSubCategoryId(subCategory.id); // Set the editing sub-category ID
        setEditingSubCategoryName(subCategory.name); // Set the sub-category name for editing
    
        // Load specific attributes for editing
        const specificAttributes = Object.entries(subCategory.attributes || {}).map(([key, value]) => ({ key, value }));
        setNewSubCategoryAttributes(specificAttributes);
    };

    const resetEditState = () => {
        setEditingCategoryId(null);
        setEditingSubCategoryId(null);
        setNewCategoryName('');
        setNewSubCategoryName('');
        setNewCategoryAttributes([{ key: '', value: '' }]);
        setNewSubCategoryAttributes([{ key: '', value: '' }]);
        setError(null); // Clear any existing error
    };
    
    const handleEditCategory = async () => {
        if (!editingCategoryId) {
            console.error("No category selected for editing");
            return;
        }
        const globalAttributesObject = newCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            await projectFirestore.collection('DeliveryCategories').doc(editingCategoryId).update({
                name: newCategoryName,
                attributes: globalAttributesObject
            });
            await refreshCategories();
            setEditingCategoryId(null);
            setNewCategoryName('');
            setNewCategoryAttributes([{ key: '', value: '' }]);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
        resetEditState(); // Reset the state after update
    };
    
    const handleEditSubCategory = async () => {
        if (!selectedCategoryId || !editingSubCategoryId) {
            console.error("No sub-category selected for editing");
            return;
        }
        const specificAttributesObject = newSubCategoryAttributes.reduce((obj, item) => {
            if (item.key) obj[item.key] = item.value;
            return obj;
        }, {});
    
        try {
            setIsLoading(true);
            const categoryRef = projectFirestore.collection('DeliveryCategories').doc(selectedCategoryId);
            const categoryDoc = await categoryRef.get();
            const categoryData = categoryDoc.data();
    
            const updatedSubCategories = categoryData.subCategories.map(subCat => {
                if (subCat.id === editingSubCategoryId) {
                    return { ...subCat, name: editingSubCategoryName, attributes: specificAttributesObject };
                }
                return subCat;
            });
    
            await categoryRef.update({ subCategories: updatedSubCategories });
            await refreshCategories();
            setEditingSubCategoryId(null);
            setEditingSubCategoryName('');
            setNewSubCategoryAttributes([{ key: '', value: '' }]);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
        resetEditState(); // Reset the state after update
    };
    


    const renderAttributeFields = () => {
        return newSubCategoryAttributes.map((attribute, index) => (
            <div key={index}>
                <input
                    type="text"
                    placeholder="Attribute Key"
                    name="key"
                    value={attribute.key}
                    onChange={(e) => handleAttributeChange(index, e)}
                />
                <input
                    type="text"
                    placeholder="Attribute Value"
                    name="value"
                    value={attribute.value}
                    onChange={(e) => handleAttributeChange(index, e)}
                />
                <button onClick={() => removeAttributeField(index)}>Remove</button>
            </div>
        ));
    };

    // // Render attributes for a sub-category
   
    const renderCategoryAttributes = (attributes) => {
        return Object.entries(attributes || {}).map(([key, value]) => (
            <span key={key}>{key}: {value}; </span>
        ));
    };

    const renderSubCategoryAttributes = (subCategory, categoryAttributes) => {
        const combinedAttributes = { ...categoryAttributes, ...subCategory.attributes };
        return Object.entries(combinedAttributes).map(([key, value]) => (
            <span key={key}>{key}: {value}; </span>
        ));
    };

    

return (
    <div className={styles.adminDeliveryCategories}>
        {/* Loading and error messages */}
        {isLoading && <p>Loading...</p>}
        {/* {error && <p>Error: {error}</p>} */}
        {error && <p className="error-message">{error}</p>} {/* Display error message */}


        <h2>Manage Delivery Categories</h2>

        {/* Form to add new category with global attributes */}

        <div>
            <input 
                type="text" 
                placeholder="Category Name" 
                value={editingCategoryId ? editingCategoryName : newCategoryName}
                onChange={(e) => editingCategoryId ? setEditingCategoryName(e.target.value) : setNewCategoryName(e.target.value)} 
            />
            {/* Category attributes input fields */}
            {newCategoryAttributes.map((attribute, index) => (
                <div key={index}>
                    <input
                        type="text"
                        placeholder="Attribute Key"
                        name="key"
                        value={attribute.key}
                        onChange={(e) => handleCategoryAttributeChange(index, e)}
                    />
                    <input
                        type="text"
                        placeholder="Attribute Value"
                        name="value"
                        value={attribute.value}
                        onChange={(e) => handleCategoryAttributeChange(index, e)}
                    />
                    <button onClick={() => removeCategoryAttributeField(index)}>Remove</button>
                </div>
            ))}
            {/* <button onClick={addCategoryAttributeField}>Add Category Attribute</button>
            <button onClick={handleAddCategory}>Add Category</button> */}
            <button onClick={addCategoryAttributeField}>Add Category Attribute</button>
            
            {editingCategoryId ? 
                <button onClick={handleUpdateCategory}>Update Category</button> :
                <button onClick={handleAddCategory}>Add Category</button>
            }
            {editingCategoryId && (
                <button onClick={resetEditState}>Cancel Edit</button>
            )}
        </div>

        {/* Form to add new sub-category */}
        <div>
            <select onChange={(e) => setSelectedCategoryId(e.target.value)}>
                <option value="">Select a Category</option>
                {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                ))}
            </select>
           
            <input 
                type="text" 
                placeholder="Sub-Category Name" 
                value={editingSubCategoryId ? editingSubCategoryName : newSubCategoryName}
                onChange={(e) => editingSubCategoryId ? setEditingSubCategoryName(e.target.value) : setNewSubCategoryName(e.target.value)} 
            />
            {newSubCategoryAttributes.map((attribute, index) => (
                <div key={index}>
                    <input
                        type="text"
                        placeholder="Attribute Key"
                        name="key"
                        value={attribute.key}
                        onChange={(e) => handleAttributeChange(index, e)}
                    />
                    <input
                        type="text"
                        placeholder="Attribute Value"
                        name="value"
                        value={attribute.value}
                        onChange={(e) => handleAttributeChange(index, e)}
                    />
                    <button onClick={() => removeAttributeField(index)}>Remove</button>
                </div>
            ))}
            {/* <button onClick={addAttributeField}>Add Sub-Category Attribute</button>
            <button onClick={() => handleAddSubCategory(selectedCategoryId)}>Add Sub-Category</button> */}
            <button onClick={addAttributeField}>Add Sub-Category Attribute</button>
            {editingSubCategoryId ? 
                <button onClick={handleUpdateSubCategory}>Update Sub-Category</button> :
                <button onClick={() => handleAddSubCategory(selectedCategoryId)}>Add Sub-Category</button>
            }
            {editingSubCategoryId && (
                <button onClick={resetEditState}>Cancel Edit</button>
            )}
        </div>

        {/* Display categories and sub-categories */}
        <table>
            <thead>
                <tr>
                    <th>Category Name</th>
                    <th>Attributes</th>
                    <th>Sub-Categories</th>
                    <th>Actions</th> {/* Add a column for actions */}
                </tr>
            </thead>
            <tbody>
                {categories.map(category => (
                    <tr key={category.id}>
                        <td>{category.name}</td>
                        <td>{renderCategoryAttributes(category.attributes)}</td>
                        <td>
                            <ul>
                                {category.subCategories.map(subCategory => (
                                    <li key={subCategory.id}>
                                        {subCategory.name} - {renderSubCategoryAttributes(subCategory, category.attributes)}
                                        <button onClick={() => startEditingSubCategory(category.id, subCategory)}>Edit</button>
                                        <button onClick={() => handleDeleteSubCategory(category.id, subCategory.id)}>Delete</button>
                                    </li>
                                ))}
                            </ul>
                        </td>
                        <td>
                            {/* Edit and Delete buttons for each category */}
                            <button onClick={() => startEditingCategory(category)}>Edit</button>
                            <button onClick={() => handleDeleteCategory(category.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
};

export default AdminDeliveryCategories;