import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore } from '../../firebase/config';
import MultiPayment from '../Payments/MultiPayment';
import SubscriptionForm from './SubscriptionForm';
import { useHistory } from 'react-router-dom';
import { useCountrySettings } from '../../context/CountrySettingsContext';

import styles from './SerializedCheckout.module.css';

const SerializedCheckout = () => {
  
  const { cartItems, dispatch } = useContext(CartContext);

  console.log('Dispatch function within SerializedCheckout:', dispatch);

  useEffect(() => {
    console.log("Cart items with useEffect:", cartItems);
  }, [cartItems]);
  
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const { user } = useContext(AuthContext);
  //console.log('User data from AuthContext:', user); // Added console log
  const history = useHistory();
  const tx_ref = `tx-${user.uid}-${Date.now()}`;
  const { selectedCountry } = useCountrySettings();
  const [successMessage, setSuccessMessage] = useState('');
  
  

    const handlePaymentSuccess = (response) => {
      console.log("Payment response:", response);

      const serializedBooks = cartItems.filter(
        (item) => item.bookType === "Serialized Book"
      );

      console.log("Serialized books:", serializedBooks);

      // Map through the serializedBooks and extract the book IDs
      const serializedBookIds = serializedBooks.map((book) => book.id);

      const subscriptionEndDate = new Date();
      subscriptionEndDate.setMonth(subscriptionEndDate.getMonth() + subscriptionDuration);


      const transactionData = {
        transactionId: response.transaction_id,
        amount: response.amount,
        currency: response.currency,
        //cartItems: cartItems.filter(item => item.bookType === "Serialized Book"),
        cartItems: serializedBookIds,
        customer: customer,
        timestamp: new Date().toISOString(),
        purchaseType: 'subscribe',
        subscriptionDuration: subscriptionDuration,
        subscriptionEndDate: subscriptionEndDate,
        // Add any other relevant fields from the response object or elsewhere
      };
      console.log('Transaction data before saving Serialized Book:', transactionData); // Added console log
      // Save transaction history to Firestore
      saveTransactionHistory(transactionData);

      console.log("Clearing cart");
      // Clear the cart after a successful transaction
      dispatch({ type: 'CLEAR_CART' });

      setSuccessMessage("Payment successful! Redirecting to Books page...");
      setTimeout(() => {
        history.push("/books");
      }, 3000);
    };

    const saveTransactionHistory = async (transactionData) => {
      try {
        // Get the user's transactionsHistory collection
        const transactionsHistoryRef = projectFirestore.collection("transactionsHistory");
          //console.log('Transaction data before saving:', transactionData);
          transactionData.accountUserId = user.uid;
        // Add the transactionData object to the transactionsHistory collection
        await transactionsHistoryRef.add(transactionData);
        console.log("Transaction history saved successfully");
      } catch (error) {
        console.error("Error saving transaction history:", error);
      }
    };

    const calculateTotalSerialized = () => {
    return cartItems
      .filter((item) => item.bookType === "Serialized Book")
      .reduce((total, item) => total + item.subscriptionFee * item.quantity, 0);
  };

  const totalSerialized = location.state?.totalSerialized || calculateTotalSerialized();
  const currency = location.state?.currency || selectedCountry.currency;

  const [subscriptionDuration, setSubscriptionDuration] = useState(1);
  const [totalAmount, setTotalAmount] = useState(calculateTotalSerialized());

  useEffect(() => {
    setTotalAmount(totalSerialized * subscriptionDuration);
  }, [subscriptionDuration, totalSerialized]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      try {
        const userDoc = await projectFirestore.collection('users').doc(user.uid).get();
        if (userDoc.exists) {
          const data = userDoc.data();
          console.log("User data fetched:", data);
          setUserData(data);
          
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleDurationChange = (duration) => {
    setSubscriptionDuration(duration);
  };
  //console.log("User data before setting customer:", userData);

  const customer = userData
    ? {
        email: userData.email,
        phone_number: userData.phoneNumber || 'N/A',
        name: userData.displayName,
      }
    : null;
    //console.log('Customer in SerializedCheckout:', customer);

  return (
    
    <div className={styles.serializedCheckout}>
      <h2>Serialized Books Checkout</h2>

      <SubscriptionForm onDurationChange={handleDurationChange} />

      <div className={styles.totalAmount}>
        <h3>
          Total Amount: {totalAmount} {currency}
        </h3>
      </div>
      {successMessage && <div className={styles.successMessage}>{successMessage}</div>}
      {customer && (
        <MultiPayment
          totalSerialized={totalAmount}
          initialCurrency={currency}
          customer={customer}
          tx_ref={tx_ref}
          onSuccess={handlePaymentSuccess} // Pass the success handler to MultiPayment
        />
      )}
    </div>
  );
};

export default SerializedCheckout;

