import React, { useContext } from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import { AuthContext } from '../../../context/AuthContext';
import { Link } from 'react-router-dom'; 


const ProposalLayout = ({ children }) => {
    const { user, logout } = useContext(AuthContext);
    const handleLogout = () => {
      logout();
      // Additional logout logic if needed
  };
  const menuItems = [
      { name: 'Home', link: '/' },
      { name: 'Dashboard', link: '/dashboard' },
      { name: 'Proposals', link: '/proposallanding' },
      { name: 'About', link: '/about-us' },
      { name: 'Service', link: '/services' },
      { name: 'Tracking', link: '/tracking' },
      { name: 'Contact', link: '/contact' },
      { name: 'Feedback', link: '/feedback' },
      // 
      // 
      
    ];
    const authItems = user ? [
      { name: 'Logout', action: handleLogout },
  ] : [
      { name: 'Login', link: '/login' },
  ];

  const boameColor = '#2ca59c';

  return (
    <>
    
    <Navbar serviceName=" " menuItems={menuItems} authItems={authItems} boameColor={boameColor} />
        <main>{children}</main>
    <Footer />
      
    </>
  );
};

export default ProposalLayout;
