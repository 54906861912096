import React, { useState } from 'react';
import ProgressBar from '../progressbar/ProgressBar';
import imageCompression from 'browser-image-compression';
import { projectStorage } from '../../../../firebase/config';


import styles from './UploadForm.module.css';

const UploadForm = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);

    const types = ['image/png', 'image/jpeg'];

    const changeHandler = (e) => {
        let selected = e.target.files[0];

        if (selected && types.includes(selected.type)) {
            setFile(selected);
            setError('')
        } else {
            setFile(null);
            setError("Please select an image file (png or jpeg");
        }
    }

    const compressImage = async (file) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1080,
          useWebWorker: true,
        };
        const compressedImage = await imageCompression(file, options);
        return compressedImage;
    }

    const uploadFile = async (file) => {
        const compressedImage = await compressImage(file);

        const storageRef = projectStorage.ref(`pictures/${compressedImage.name}`);
        const uploadTask = storageRef.put(compressedImage);

        uploadTask.on('state_changed', (snapshot) => {
            // progress function ...
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress);
        }, (error) => {
            // error function ...
            console.log(error);
        }, () => {
            // complete function ...
            storageRef.getDownloadURL().then((url) => {
                console.log(url);
            });
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (file) {
            uploadFile(file);
            setFile(null);
            setError('');
        } else {
            setError("Please select an image file (png or jpeg");
        }
    }

    return (
        <form className={styles.upform} onSubmit={handleSubmit}>
          <label>
            <input type="file" onChange={changeHandler} />
            <span>+</span>
          </label>
          <div className={styles.output}>
            {error && <div className={styles.error}>{error}</div>}
            {file && <div>{file.name}</div>}
            {file && <ProgressBar file={file} setFile={setFile} />}
          </div>
          <button className={styles.uploadBtn}>Upload</button>
        </form>
      );
}

export default UploadForm;


