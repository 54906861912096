import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { projectFirestore, projectStorage } from '../../../firebase/config';
import { globalCountries } from '../../../constants/countries';
import styles from './BasicDetails.module.css';

const paymentTermOptions = [
    { value: 'advancePayment', label: 'Advance Payment' },
    { value: 'cashOnDelivery', label: 'Cash on Delivery' },
    { value: 'letterOfCredit', label: 'Letter of Credit' },
    { value: 'net30', label: 'Net 30' },
    { value: 'net60', label: 'Net 60' },
    // ... add more payment terms as needed
];

const BasicDetails = ({ formData, setFormData, userCountry }) => {
    const [localData, setLocalData] = useState({
        ...formData,
        country: userCountry || '', // Set default to userCountry
        countryOfOrigin: '',
        price: '',
        currency: '',
        minimumOrder: '',
        paymentTerms: '',
        category: '',
        subCategory: '',
        item: '',
        productImages: [],
        description: ''
    });

    const [errors, setErrors] = useState({});
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');

    // Simple URL validation function
    const isValidURL = (string) => {
        var res = string.match(/(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);
        return (res !== null);
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const snapshot = await projectFirestore.collection('tradeCategories').get();
                const fetchedCategories = snapshot.docs.map(doc => ({
                    value: doc.id,
                    label: doc.data().category
                }));
                setCategoryOptions(fetchedCategories);
            } catch (error) {
                console.error("Error fetching categories: ", error);
            }
        };
        fetchCategories();
    }, []);

  

    useEffect(() => {
        const filteredCountries = globalCountries.filter(country => 
            country.GeoRegion === 'Africa' || country.GeoRegion === 'West Asia');
        setFilteredCountries(filteredCountries);
    
        // Set the default country if userCountry is available
        if (userCountry) {
            setLocalData(prevState => ({ ...prevState, country: userCountry }));
        }
    }, [userCountry, globalCountries]);
    

    // Handle image file uploads
    const handleImageUpload = async (e) => {
        const files = Array.from(e.target.files);
    
        // Validate each file
        const isValid = files.every(file => {
            const isSizeValid = file.size <= 5000000; // 5MB size limit
            const isFormatValid = ['image/jpeg', 'image/png'].includes(file.type); // Accept JPEG and PNG
            return isSizeValid && isFormatValid;
        });
    
        if (!isValid) {
            setUploadStatus('Invalid file size or format');
            return;
        }
    
        setUploadStatus('Uploading...');
    
        const uploadPromises = files.map(file => {
            const storageRef = projectStorage.ref(`productImages/${file.name}`);
            return storageRef.put(file).then(snapshot => snapshot.ref.getDownloadURL());
        });
    
        try {
            const downloadURLs = await Promise.all(uploadPromises);
            setFormData(prevFormData => ({
                ...prevFormData,
                productImages: [...(prevFormData.productImages || []), ...downloadURLs]
            }));
            setImagePreviews(prevPreviews => [...prevPreviews, ...downloadURLs]);
            setUploadStatus('Upload complete');
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploadStatus('Error during upload');
        }
    };
    

    const handleCategoryChange = async (selectedOption) => {
        setLocalData({ ...localData, category: selectedOption.value, subCategory: '', item: '' });
        setFormData({ ...formData, category: selectedOption.label, productName: '', subCategory: '', item: '' });
    
        try {
            const categoryDoc = await projectFirestore.collection('tradeCategories').doc(selectedOption.value).get();
            const subCategories = categoryDoc.data()?.subCategories || [];
            if (subCategories.length > 0) {
                setSubCategoryOptions(subCategories.map(subCat => ({ value: subCat.name, label: subCat.name })));
            } else {
                setSubCategoryOptions([]);
            }
            setItemOptions([]); // Reset item options
        } catch (error) {
            console.error("Error fetching sub-categories: ", error);
            setSubCategoryOptions([]);
            setItemOptions([]);
        }
    };

  
    const handleSubCategoryChange = (selectedOption) => {
        console.log("Selected sub-category option:", selectedOption);
    
        setLocalData({ ...localData, subCategory: selectedOption.value, item: '' });
        setFormData({ ...formData, subCategory: selectedOption.label, productName: '', item: '' });
    
        const selectedCategory = categoryOptions.find(cat => cat.value === localData.category);
        console.log("Selected category:", selectedCategory);
    
        if (selectedCategory) {
            const categoryDocRef = projectFirestore.collection('tradeCategories').doc(selectedCategory.value);
            categoryDocRef.get().then(doc => {
                const categoryData = doc.data();
                console.log("Category data:", categoryData);
    
                const subCategory = categoryData.subCategories.find(subCat => subCat.name === selectedOption.label);
                console.log("Found sub-category:", subCategory);
    
                if (subCategory && subCategory.items) {
                    const itemsOptions = subCategory.items.map(item => ({ value: item, label: item }));
                    setItemOptions(itemsOptions);
                    console.log("Items for selected sub-category:", itemsOptions);
                } else {
                    setItemOptions([]); // Reset if no items found
                    console.log("No items found for selected sub-category");
                }
            }).catch(error => {
                console.error("Error fetching items: ", error);
                setItemOptions([]);
            });
        } else {
            console.log("No category selected or found");
            setItemOptions([]);
        }
    };
    
    
    

    const handleItemChange = (selectedOption) => {
        setLocalData({ ...localData, item: selectedOption.value });
        setFormData({ ...formData, productName: selectedOption.label, item: selectedOption.label });
    
        // Additional logic if needed, for example:
        // If you want to append the item name to the category and sub-category names:
        const fullProductName = `${localData.category} - ${localData.subCategory} - ${selectedOption.label}`;
        setFormData({ ...formData, productName: fullProductName });
    };
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocalData({ ...localData, [name]: value });
        setFormData({ ...formData, [name]: value }); // Update formData in parent component
    };
    
    const handlePaymentTermsChange = (selectedOption) => {
                setLocalData({ ...localData, paymentTerms: selectedOption.value });
            };
        

    const validateField = (name, value) => {
        let newErrors = { ...errors };

        // Example of different validations for different fields
        switch (name) {
            case 'productName':
            case 'countryOfOrigin':
            case 'currency':
            case 'paymentTerms':
                if (!value.trim()) {
                    newErrors[name] = `${name} is required.`;
                } else {
                    delete newErrors[name];
                }
                break;
            case 'price':
            case 'minimumOrder':
                if (!value || isNaN(value)) {
                    newErrors[name] = `${name} must be a valid number.`;
                } else {
                    delete newErrors[name];
                }
                break;
            case 'productImages':
            case 'certifications':
            case 'productReviews':
                if (!value.length) {
                    newErrors[name] = `${name} cannot be empty.`;
                } else {
                    delete newErrors[name];
                }
                break;
            case 'productVideo':
                // Assuming you want to check for a valid URL
                if (!isValidURL(value)) {
                    newErrors[name] = `Please enter a valid URL for ${name}.`;
                } else {
                    delete newErrors[name];
                }
                break;
            // Add more cases as needed for other fields
            default:
                if (!value) {
                    newErrors[name] = `${name} is required.`;
                } else {
                    delete newErrors[name];
                }
        }
        setErrors(newErrors);
    };
    


    return (
        <div className={styles.container}>
            {/* Country Selection */}
            <div className={styles.formGroup}>
                <label htmlFor="country" className={styles.formLabel}>Country:</label>
                <select 
                    name="country" 
                    id="country" 
                    value={localData.country} 
                    onChange={handleInputChange} 
                    className={styles.select}
                >
                    {filteredCountries.map(country => (
                        <option key={country.code} value={country.code}>{country.name}</option>
                    ))}
                </select>
                {errors.country && <p className={styles.errorMessage}>{errors.country}</p>}
            </div>

                <div className={styles.formGroup}>
                    <label htmlFor="countryOfOrigin" className={styles.formLabel}>Origin Country:</label>
                    <select 
                        name="countryOfOrigin" 
                        id="countryOfOrigin" 
                        value={localData.countryOfOrigin} 
                        onChange={handleInputChange} 
                        className={styles.select}
                    >
                        <option value="">Select Country</option>
                        {globalCountries.map(country => (
                            <option key={country.code} value={country.name}>{country.name}</option>
                        ))}
                    </select>
                    {errors.countryOfOrigin && <p className={styles.errorMessage}>{errors.countryOfOrigin}</p>}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="price" className={styles.formLabel}>Price:</label>
                    <input type="text" name="price" id="price" value={localData.price} onChange={handleInputChange} className={styles.input} placeholder="Enter price" />
                    {errors.price && <p className={styles.errorMessage}>{errors.price}</p>}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="currency" className={styles.formLabel}>Currency:</label>
                    <input type="text" name="currency" id="currency" value={localData.currency} onChange={handleInputChange} className={styles.input} />
                    {errors.currency && <p className={styles.errorMessage}>{errors.currency}</p>}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="minimumOrder" className={styles.formLabel}>Minimum Order:</label>
                    <input type="number" name="minimumOrder" id="minimumOrder" value={localData.minimumOrder} onChange={handleInputChange} className={styles.input} placeholder="Minimum order quantity" />
                    {errors.minimumOrder && <p className={styles.errorMessage}>{errors.minimumOrder}</p>}
                </div>
                
            {/* </div> */}
            <div className={styles.formGroup}>
                <label htmlFor="paymentTerms" className={styles.formLabel}>Payment Terms:</label>
                <ReactSelect
                    name="paymentTerms"
                    options={paymentTermOptions}
                    onChange={handlePaymentTermsChange}
                    classNamePrefix="select"
                    placeholder="Select Payment Terms"
                />
                {errors.paymentTerms && <p className={styles.errorMessage}>{errors.paymentTerms}</p>}
            </div> 

            {/* Category, Sub-Category, Item Selection */}
            <div className={styles.formGroup}>
                <ReactSelect
                    name="category"
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    classNamePrefix="select"
                    placeholder="Select Category"
                />
                {errors.category && <p className={styles.errorMessage}>{errors.category}</p>}
            </div>

            <div className={styles.formGroup}>
                <ReactSelect
                    name="subCategory"
                    options={subCategoryOptions}
                    onChange={handleSubCategoryChange}
                    classNamePrefix="select"
                    placeholder="Select Sub-Category"
                />
                {errors.subCategory && <p className={styles.errorMessage}>{errors.subCategory}</p>}
            </div>

            <div className={styles.formGroup}>
                <ReactSelect
                    name="item"
                    options={itemOptions}
                    onChange={handleItemChange}
                    classNamePrefix="select"
                    placeholder="Select Item"
                />
                {errors.item && <p className={styles.errorMessage}>{errors.item}</p>}
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="productImages" className={styles.label}>Product Images:</label>
                <input 
                    type="file" 
                    id="productImages" 
                    name="productImages" 
                    onChange={handleImageUpload}
                    className={styles.input}
                    multiple
                    accept="image/*"
                />
                {uploadStatus && <p className={styles.uploadStatus}>{uploadStatus}</p>}
                <div className={styles.imagePreviews}>
                    {imagePreviews.map((url, index) => (
                        <img key={index} src={url} alt={`Product Image ${index + 1}`} className={styles.imagePreview} />
                    ))}
                </div>
            </div>

            {/* Product Description */}
            <div className={styles.formGroup}>
            <label className={styles.formLabel}>Detail Product Description:</label>
                <ReactQuill
                    value={localData.description}
                    onChange={(content) => handleInputChange({ target: { name: 'description', value: content } })}
                    placeholder="Provide a detailed description of your product..."
                    className={styles.detailDescription}
                />
                {errors.description && <p className={styles.errorMessage}>{errors.description}</p>}
            </div>
        </div>
    );
};

export default BasicDetails;



