import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { projectFirestore } from '../../../firebase/config';
import styles from './ExportDetailPage.module.css'; // Assuming you have a CSS module

const ExportDetailPage = () => {
    const { id } = useParams();
    const [exportData, setExportData] = useState(null);

    useEffect(() => {
        const fetchExportData = async () => {
            try {
                const doc = await projectFirestore.collection('postexports').doc(id).get();
                if (doc.exists) {
                    setExportData(doc.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        fetchExportData();
    }, [id]);

    if (!exportData) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.container}>
            <h2>{exportData.productName}</h2>
            <div className={styles.imagesContainer}>
                {exportData.productImages && exportData.productImages.map((url, index) => (
                    <img key={index} src={url} alt={`Product Image ${index + 1}`} className={styles.productImage} />
                ))}
            </div>
            <p><strong>Product Name:</strong> {exportData.productName}</p>
            <p><strong>Comapny Name:</strong> {exportData.companyName}</p>  {/* To be addedd  */}
            <p><strong>Country of Origin:</strong> {exportData.countryOfOrigin}</p>
            <p><strong>Price:</strong> {exportData.price}</p>
            <p><strong>Currency:</strong> {exportData.currency}</p>
            <p><strong>Minimum Export Quantity:</strong> {exportData.minimumOrder}</p>
            <p><strong>Product Description:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.description }} /></p>
            <p><strong>Payment Terms:</strong> {exportData.paymentTerms}</p>
            <p><strong>HS Code:</strong> {exportData.hsCode}</p>
            <div className={styles.imagesContainer}>
                {exportData.productCertifications && exportData.productCertifications.map((url, index) => (
                    <img key={index} src={url} alt={`Product Certification ${index + 1}`} className={styles.productImage} />
                ))}
            </div>

            <p><strong>Export Market Experience:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.exportMarketExperience }} /></p>
            <p><strong>Packaging Details:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.packagingDetails }} /></p>
            <p><strong>Sample Availablity:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.sampleAvailability }} /></p>
            <p><strong>Lead Time:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.leadTime }} /></p>
            <p><strong>Shipping & Logistics Options:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.shippingOptions }} /></p>
            <p><strong>Volume Discount:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.volumeDiscounts }} /></p>
            <p><strong>Return & Warranty Policies:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.warrantyPolicy }} /></p>
            <p><strong>Product Variants:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.productVariants }} /></p>
            <p><strong>Shipping Terms:</strong> {exportData.shippingTerms}</p>
            <p><strong>Additional Details:</strong> <div dangerouslySetInnerHTML={{ __html: exportData.additionalDetails }} /></p>
            <div className={styles.videoContainer}>
                {exportData.productVideo && (
                    <video className={styles.productVideo} controls>
                        <source src={exportData.productVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
            
            
        </div>
    );
};

export default ExportDetailPage;
