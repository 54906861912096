import React from 'react';
import { Link } from 'react-router-dom';
import styles from './BookList.module.css';

const BookItem = ({ book }) => {
  return (
    <Link to={`/books/${book.id}`} className={styles.bookItem}>
      <div className={styles.bookItemImage}>
        <img src={book.imageUrl} alt={book.bookName} />
      </div>
      <div className={styles.bookItemInfo}>
        <h3 className={styles.bookItemTitle}>{book.bookName}</h3>
        <p className={styles.bookItemAuthor}>by {book.bookAuthor}</p>
      </div>
    </Link>
  );
};

export default BookItem;
