import React, { useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config';
import UserSearch from './UserSearch';
import getConfirmationThreshold from './thresholdUtils';
import styles from './RequestAfholderApproval.module.css'; 

const RequestAfholderApproval = () => {
    const { user } = useAuthContext();
    const [inviteeEmail, setInviteeEmail] = useState('');
    const [inviteeId, setInviteeId] = useState('');
    const [invitationType, setInvitationType] = useState('email');
    const [message, setMessage] = useState('');

    const createAfholderNotification = async (userId, message) => {
        await projectFirestore.collection('afholderNotifications').add({
            userId: userId,
            message: `You have a new invitation from ${user.displayName}`,
            createdAt: new Date(),
            isRead: false
        });
    };
    

    const generateLinkToken = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };

    const sendInvitation = async () => {
        if (!user) {
            setMessage('You need to be logged in to send an invitation.');
            return;
        }

        // Generate new invitation document
        let newInvitation = {
            inviterId: user.uid,
            status: 'pending',
            linkToken: generateLinkToken()
        };

        if (invitationType === 'email') {
            newInvitation = { ...newInvitation, inviteeEmail };
        } else {
            newInvitation = { ...newInvitation, inviteeId };
        }

        // Add the invitation to Firestore
        await projectFirestore.collection('invitations').add(newInvitation);

        // Update the user's document for Afholder request
        const confirmationThreshold = getConfirmationThreshold(user.country); // Assuming user.country is available
        await projectFirestore.collection('users').doc(user.uid).update({
            afholderRequestInitiated: true,
            approvalsNeeded: confirmationThreshold,
            approvalsReceived: 0
        });

        setMessage('Invitation sent successfully.');
        setInviteeEmail('');
        setInviteeId('');

        // After sending invitation, create a notification
        await createAfholderNotification(user.uid, `You sent an invitation to ${inviteeEmail || inviteeId}.`);

    };

    const handleUserSelect = (selectedUser) => {
        setInviteeId(selectedUser.id);
        setInviteeEmail(selectedUser.email); // Assuming the UserSearch component provides this
    };

    return (
        <div className={styles.invitationContainer}>
        <h3>Initiate Afholder Approval</h3>
            <h6>Use this form to extend invitations to current Afholders, 
            seeking their approval for your Afholder status. 
            Each invitation you send, either through email or directly within the app, 
            requests verification of your African heritage or descent. 
            Achieving the required number of approvals is essential for 
            your acceptance as an Afholder. 
            The number of approvals needed depends on your region of residence.Africa: 10 approvals
            Asia, Europe, North America, Oceania, Antarctica, The Arctic: 30 approvals
            South America: 25 approvals
            The Caribbean: 15 approvals
            The Middle East, Central America: 20 approvals </h6>
            <select
                value={invitationType}
                onChange={(e) => setInvitationType(e.target.value)}
                className={styles.invitationTypeSelect}
            >
                <option value="email">Email Invitation</option>
                <option value="app">In-App Invitation</option>
            </select>

            {invitationType === 'email' ? (
                <input
                    type="email"
                    value={inviteeEmail}
                    onChange={(e) => setInviteeEmail(e.target.value)}
                    placeholder="Enter email to invite"
                    className={styles.inviteeInput}
                />
            ) : (
                <UserSearch onSelectUser={handleUserSelect} />
            )}

            <button onClick={sendInvitation} className={styles.sendButton}>
                Send Invitation
            </button>
            {message && <p className={styles.message}>{message}</p>}
        </div>
    );
};

export default RequestAfholderApproval;



