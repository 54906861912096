import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../../context/AuthContext';
import GlobalAfricaExchangeLayout from '../../../components/Hoc/GlobalAfricaExchangeLayout/GlobalAfricaExchangeLayout';
import BasicDetails from './BasicDetails';
import AdvancedOptions from './AdvancedOptions';
import ShippingLogistics from './ShippingLogistics';
import { projectFirestore } from '../../../firebase/config'; // Ensure this is the correct path
import styles from './PostExportOfferForm.module.css';
import { globalCountries } from '../../../constants/countries';

const PostExportOfferForm = () => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        country: user?.country || '',
        productImages: [],
        productName: '',
        countryOfOrigin: '',
        price: '',
        currency: '',
        minimumOrder: '',
        paymentTerms: '',
        description: '',
        hsCode: '',
        certifications: '',
        exportMarketExperience: '',
        packagingDetails: '',
        sampleAvailability: '',
        leadTime: '',
        shippingMethods: '',
        volumeDiscounts: '',
        productVariants: '',
        returnPolicy: '',
        warrantyPolicy: '',
        productReviews: '',
        productVideo: ''
    });

    // Initialize selectedCountry with the user's country
    const [selectedCountry, setSelectedCountry] = useState(user?.country || '');

    useEffect(() => {
        // Update the selectedCountry when the user's country changes
        setSelectedCountry(user?.country || '');
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true); // Disable further submissions
    
        // Example validation logic
        const errors = {};
        if (!formData.productName.trim()) errors.productName = 'Product name is required';
        if (!formData.countryOfOrigin.trim()) errors.countryOfOrigin = 'Country of origin is required';
        if (!formData.price.trim()) errors.price = 'Price is required';
        // Numeric validation
        if (isNaN(formData.price)) errors.price = 'Price must be a number';
        // ... Add more validations as per your requirements
    
        // Check if there are any errors
        if (Object.keys(errors).length > 0) {
            setIsSubmitting(false); // Re-enable the submit button if there are errors
            alert("Please fill in all required fields."); // Replace with a more user-friendly error display
            return;
        }
    
        try {
            await projectFirestore.collection('postexports').add({
                ...formData,
                userId: user?.uid,
                createdAt: new Date()

                
            });
            history.push('/post-export'); // Redirect to a confirmation page or dashboard
        } catch (error) {
            console.error("Error submitting export offer: ", error);
            setIsSubmitting(false); // Re-enable the submit button in case of error
            // Handle error (show error message to user)
        }
    };
    

    const nextStep = () => setCurrentStep(currentStep + 1);
    const prevStep = () => setCurrentStep(currentStep - 1);

    return (
        <GlobalAfricaExchangeLayout>
            <form onSubmit={handleSubmit} className={styles.form}>
            {currentStep === 1 && (
                <BasicDetails 
                        formData={formData} 
                        setFormData={setFormData}
                        globalCountries={globalCountries} // Pass the entire globalCountries array
                        userCountry={selectedCountry} // Pass the selected country
                    />
                )}
                {currentStep === 2 && (
                    <AdvancedOptions 
                        formData={formData} 
                        setFormData={setFormData} 
                    />
                )}
                {currentStep === 3 && (
                    <ShippingLogistics 
                        formData={formData} 
                        setFormData={setFormData} 
                    />
                )}
                
                <div className={styles.navigationButtons}>
                    {currentStep > 1 && (
                        <button type="button" onClick={prevStep} className={styles.prevButton}>Previous</button>
                    )}
                    {currentStep < 3 && (
                        <button type="button" onClick={nextStep} className={styles.nextButton}>Next</button>
                    )}
                    {currentStep === 3 && (
                        <button type="submit" className={styles.submitButton}>Submit</button>
                    )}
                </div>
            </form>
        </GlobalAfricaExchangeLayout>
    );
};

export default PostExportOfferForm;
