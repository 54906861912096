import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore } from '../../firebase/config';
import DeliveryLayout from '../../components/Hoc/DeliveryLayout/DeliveryLayout';
import { useAddressUpdate } from '../../context/AddressUpdateContext';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';

import styles from './Sendings.module.css';

function Sendings() {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [categories, setCategories] = useState([]);
    const [pickupAddresses, setPickupAddresses] = useState([]);
    const [selectedPickupAddress, setSelectedPickupAddress] = useState(null);
    const [deliveryAddresses, setDeliveryAddresses] = useState([]);
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
    
    const { addressUpdated, setAddressUpdated } = useAddressUpdate();
    const [selectionError, setSelectionError] = useState('');

    // Function to navigate to Address Manager
    const navigateToAddressManager = () => {
      history.push('/address-manager'); // Replace with your actual route
  };

    const handleNextClick = () => {
        if (selectedPickupAddress && selectedDeliveryAddress) {
            history.push('/delivery-form', {
                selectedPickupAddress,
                selectedDeliveryAddress
            });
        } else {
            setSelectionError('Please select both a pickup and delivery address.');
        }
    };

    const fetchAddresses = async () => {
        const userId = user.uid;
        const addressesRef = projectFirestore.collection('shippingAddresses');
        const snapshot = await addressesRef.where('userId', '==', userId).get();
        let pickupAddrs = [];
        let deliveryAddrs = [];

        snapshot.forEach(doc => {
            let addressData = doc.data();
            if (addressData.addressType === 'Pickup') {
                pickupAddrs.push({ id: doc.id, ...addressData });
            } else if (addressData.addressType === 'Delivery') {
                deliveryAddrs.push({ id: doc.id, ...addressData });
            }
        });

        setPickupAddresses(pickupAddrs);
        setDeliveryAddresses(deliveryAddrs);
    };

    useEffect(() => {
        if (addressUpdated) {
            fetchAddresses();
            setAddressUpdated(false);
            toast.success("Addresses updated successfully!");
        }
    }, [addressUpdated, setAddressUpdated]);

    useEffect(() => {
        fetchAddresses();
    }, [user.uid]);


    return (
        <DeliveryLayout>
            <div className={styles.container}>
                <div className={styles.introductionSection}>
                    <h2>Welcome to the Package Sending Service</h2>
                    <p>Start by selecting a pickup and delivery address for your package.</p>
                </div>

                {/* Button to navigate to AddressManager */}
                <button onClick={navigateToAddressManager} className={styles.manageAddressesButton}>
                    Manage Addresses
                </button>

                <div className={styles.addressSelection}>
                    <h3>Select Pickup Address</h3>
                    <ul>
                        {pickupAddresses.map(address => (
                            <li key={address.id}>
                                <input
                                    type="radio"
                                    name="pickupAddress"
                                    checked={selectedPickupAddress?.id === address.id}
                                    onChange={() => setSelectedPickupAddress(address)}
                                />
                                {address.fullName} - {address.street}, {address.city}
                            </li>
                        ))}
                    </ul>

                    <h3>Select Delivery Address</h3>
                    <ul>
                        {deliveryAddresses.map(address => (
                            <li key={address.id}>
                                <input
                                    type="radio"
                                    name="deliveryAddress"
                                    checked={selectedDeliveryAddress?.id === address.id}
                                    onChange={() => setSelectedDeliveryAddress(address)}
                                />
                                {address.fullName} - {address.street}, {address.city}
                            </li>
                        ))}
                    </ul>
                </div>

                {selectionError && <p className={styles.errorMessage}>{selectionError}</p>}

                <button 
                    className={styles.nextButton} 
                    onClick={handleNextClick}
                    disabled={!selectedPickupAddress || !selectedDeliveryAddress}
                >
                    Next
                </button>

                <ToastContainer />
            </div>
        </DeliveryLayout>
    );
}

export default Sendings;
