import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import AdvertArea from '../../../components/Propertu/AdvertFeatures/AdvertArea/AdvertArea';

const BooksLayout = ({ children }) => {

  const menuItems = [
    { name: 'Featured', link: '/books/featured' },
    { name: 'Genres', link: '/books/genres' },
    { name: 'Authors', link: '/books/authors' },
    { name: 'All Books', link: '/books/all' },
    { name: 'Bestsellers', link: '/books/bestsellers' },
    { name: 'Reading Lists', link: '/books/readingslists' },
  ];

  const boameColor = 'blue';

  return (
    <>
      <Navbar 
        serviceName="Books" 
        menuItems={menuItems} 
        boameColor={boameColor} />

      <main>
        {/* Pass the props to the child components */}
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {})
        )}
      </main>
      <Footer />
    </>
  );
};

export default BooksLayout;




