import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { projectFirestore, projectStorage, timestamp } from '../../../firebase/config';

import styles from './AdvancedOptions.module.css'; // Import the corresponding CSS module

const AdvancedOptions = ({ formData, setFormData }) => {
    const [certificationPreviews, setCertificationPreviews] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');
    
    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);

        // Validate each file
        const isValid = files.every(file => {
            const isSizeValid = file.size <= 5000000; // 5MB size limit
            const isFormatValid = ['image/jpeg', 'image/png'].includes(file.type); // Accept JPEG and PNG
            return isSizeValid && isFormatValid;
        });

        if (!isValid) {
            setUploadStatus('Invalid file size or format');
            return;
        }

        setUploadStatus('Uploading...');

        const uploadPromises = files.map(file => {
            const storageRef = projectStorage.ref(`productCertifications/${file.name}`);
            return storageRef.put(file).then(snapshot => snapshot.ref.getDownloadURL());
        });

        try {
            const downloadURLs = await Promise.all(uploadPromises);
            setFormData(prevFormData => ({
                ...prevFormData,
                productCertifications: [...(prevFormData.productCertifications || []), ...downloadURLs]
            }));
            setCertificationPreviews(prevPreviews => [...prevPreviews, ...downloadURLs]);
            setUploadStatus('Upload complete');
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploadStatus('Error during upload');
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
   
    


    return (
        <div className={styles.advancedOptionsContainer}>
            {/* HS Code */}
            <div className={styles.formGroup}>
                <label htmlFor="hsCode" className={styles.label}>HS Code:</label>
                <input 
                    type="text" 
                    id="hsCode" 
                    name="hsCode" 
                    value={formData.hsCode || ''} 
                    onChange={handleInputChange}
                    className={styles.input}
                />
            </div>

            {/* Upload status message */}
            {uploadStatus && <p className={styles.uploadStatus}>{uploadStatus}</p>}


            {/* Product Certifications */}
            <div className={styles.formGroup}>
                <label htmlFor="productCertifications" className={styles.label}>Product Certifications:</label>
                <input 
                    type="file" 
                    id="productCertifications" 
                    name="productCertifications" 
                    onChange={handleFileUpload}
                    className={styles.input}
                    multiple
                    accept="image/*"
                />
                <div className={styles.certificationPreviews}>
                    {certificationPreviews.map((url, index) => (
                        <img key={index} src={url} alt={`Certification ${index + 1}`} className={styles.certificationPreview} />
                    ))}
                </div>
            </div>

            {/* Export Market Experience */}
            <div className={styles.formGroup}>
                <label htmlFor="exportMarketExperience" className={styles.label}>Export Market Experience:</label>
                <textarea 
                    id="exportMarketExperience" 
                    name="exportMarketExperience" 
                    value={formData.exportMarketExperience || ''} 
                    onChange={(e) => setFormData({...formData, exportMarketExperience: e.target.value})}
                    className={styles.textarea}
                    placeholder="Describe your experience in exporting products, including countries, product types, duration, challenges, and successes."
                />
            </div>

            {/* Product Variants */}
            <div className={styles.formGroup}>
                <label htmlFor="productVariants" className={styles.label}>Product Variants:</label>
                <textarea 
                    id="productVariants" 
                    name="productVariants" 
                    value={formData.productVariants || ''} 
                    onChange={(e) => setFormData({...formData, productVariants: e.target.value})}
                    className={styles.textarea}
                    placeholder="List any variations of your product here, such as different sizes, colors, materials, or models."
                />
            </div>

            {/* Return and Warranty Policies */}
            <div className={styles.formGroup}>
                <label htmlFor="returnPolicy" className={styles.label}>Return and Warranty Policies:</label>
                <textarea 
                    id="returnPolicy" 
                    name="returnPolicy" 
                    value={formData.returnPolicy || ''} 
                    onChange={(e) => setFormData({...formData, returnPolicy: e.target.value})}
                    className={styles.textarea}
                    placeholder="Provide details about your return and warranty policies, including duration, terms, conditions, and coverage."
                />
            </div>

            {/* Product Reviews and Ratings */}
            <div className={styles.formGroup}>
                {/* Add the rating and review component here */}
                {/* Example: <RatingAndReviewComponent ...props /> */}
            </div>
        </div>
    );
};

export default AdvancedOptions;
