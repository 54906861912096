import React, { useState } from 'react';
import DeliveryLayout from '../../components/Hoc/DeliveryLayout/DeliveryLayout';

import styles from './DeliveryHelp.module.css';

function DeliveryHelp() {
  const [ticket, setTicket] = useState('');

  const handleTicketSubmission = () => {
    // Logic to handle ticket submission
    console.log('Ticket Submitted:', ticket);
    // Reset the ticket field after submission
    setTicket('');
  };

  return (
    <DeliveryLayout>
    <div className={styles.helpContainer}>
      <h1>Help & Support</h1>

      {/* FAQs and Guides */}
      <section className={styles.faqSection}>
        <h2>FAQs & Guides</h2>
        {/* List FAQs here */}
      </section>

      {/* Ticket System */}
      <section className={styles.ticketSection}>
        <h2>Raise a Ticket</h2>
        <textarea
          value={ticket}
          onChange={(e) => setTicket(e.target.value)}
          placeholder="Describe your issue or question"
        />
        <button onClick={handleTicketSubmission}>Submit Ticket</button>
      </section>

      {/* Live Chat Support */}
      <section className={styles.chatSection}>
        <h2>Live Chat Support</h2>
        {/* Implement live chat widget or link */}
      </section>

      {/* Contact Information */}
      <section className={styles.contactSection}>
        <h2>Contact Us</h2>
        <p>Email: support@deliveryapp.com</p>
        <p>Phone: +123 456 7890</p>
        {/* Additional contact details */}
      </section>

      {/* Feedback and Suggestions */}
      <section className={styles.feedbackSection}>
        <h2>Feedback & Suggestions</h2>
        <textarea placeholder="Your feedback or suggestions" />
        <button>Submit Feedback</button>
      </section>

      {/* Push Notifications Settings */}
      <section className={styles.notificationsSection}>
        <h2>Notification Settings</h2>
        {/* Implement settings toggles or links */}
      </section>

      {/* Troubleshooting Tips */}
      <section className={styles.troubleshootingSection}>
        <h2>Troubleshooting Tips</h2>
        {/* List common issues and solutions */}
      </section>
    </div>
    </DeliveryLayout>
  );
}

export default DeliveryHelp;
