import React from 'react';
import styles from './DeliveryMessages.module.css';

export default function DeliveryMessages() {
  return (
    <div className={styles.trackingContainer}>
      <h2>Boameooo Delivery Messages</h2>
      <p>"To contain Boameooo delivery info".</p>
    </div>
  );
}
