// bookTexts.js

export const descriptionText = `"African Nonism & Peomocracy" is an illuminating exploration of a novel governance philosophy designed for the African context. In this thought-provoking book, the author introduces Peomocracy, a system built on the principle that every individual, regardless of their social status or background, has an equal say in shaping society's decisions.

The book delves into the core tenets of Peomocracy, emphasizing direct decision-making by the people themselves rather than relying on elected representatives. It highlights how Peomocracy eradicates the need for political parties and intermediaries, making the governance process more transparent, efficient, and aligned with the welfare of all citizens.

Readers will discover how Peomocracy fosters an environment where contributors, highly skilled and dedicated professionals, play a pivotal role in crafting proposals for societal decisions. These proposals are then submitted to the collective constituent people for approval, ensuring that policies and regulations genuinely reflect the needs and desires of the population.

Furthermore, the book discusses the concept of Endowed Rights, emphasizing the importance of rights defined and adopted by the African people themselves rather than those imposed by external influences. It provides a dynamic framework for continuously modifying governance principles to align with the ever-evolving needs of society.

African Nonism & Peomocracy offers a comprehensive vision of a governance philosophy rooted in African values, tailored to promote the collective welfare of all citizens. This engaging exploration challenges conventional political paradigms and presents a compelling blueprint for a more equitable and participatory approach to governance in Africa and beyond.




`;

export const copyrightText = `Copyright © 2022 by Ywo E. Kporye. All Rights Reserved.
The content, concepts, terms, and ideas presented in African Nonism & Peomocracy are the exclusive property of Ywo E. Kporye and "African descendants” or simply "Africans” and are protected under international copyright laws. Unauthorized use and/or duplication of this material without express and written permission from the author and owners is strictly prohibited.
Usage and Sharing:
We encourage engagement and the sharing of ideas within the Global African community and beyond. However, any use of the material, concepts, ideas, or terms must align with the principles of African Nonism & Peomocracy and include due acknowledgment to the original work. Redistribution for commercial purposes is prohibited unless expressly permitted by the author and all monetary dues if any are paid fully.
Proceeds and Contributions:
We graciously accept rewards based on the “Pay-What-You-Want” model to support the continuous development and propagation of African Nonism & Peomocracy. In line with our vision, 82% of all proceeds received will be directed towards the Generational African Growth Fund, aimed at fostering wealth growth within the Global African community. The remaining 18% will support the ongoing work, research, and living expenses of the author, enabling continued dedication to this Generational Mission cause.
For permissions, collaborations, contributions, rewards, or inquiries, please contact [Your Contact Information].
Together, let’s build a prosperous future for the Global African family.
`;
