import React from 'react';

const Invoice = (props) => {
  const { invoiceData } = props.location.state;
  
  return (
    <div>
      <h1>Invoice</h1>
      {/* Display the invoice data here */}
    </div>
  );
};

export default Invoice;
