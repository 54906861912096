import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  // import styles

import styles from './ChapterContentUpdate.module.css';


const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],  // toggled buttons

  ['blockquote', 'code-block'],  // blocks

  [{ 'header': 1 }, { 'header': 2 }, { 'header': [3, 4, 5, 6, false] }], // headers
  
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],  // lists
  
  [{ 'script': 'sub'}, { 'script': 'super' }],  // superscript/subscript
  
  [{ 'indent': '-1'}, { 'indent': '+1' }],  // outdent/indent
  
  [{ 'direction': 'rtl' }],  // text direction
  
  [{ 'size': ['small', false, 'large', 'huge'] }],  // font size
  
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // custom header levels
  
  [{ 'color': [] }, { 'background': [] }],  // text/background color
  
  [{ 'font': [] }],  // font family
  
  [{ 'align': [] }],  // text alignment
  
  ['clean'],  // remove formatting
  
  ['link', 'image', 'video']  // link, image and video
];


const ChapterContentUpdate = () => {
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState('');
  const [selectedChapter, setSelectedChapter] = useState('');
  const [chapterContent, setChapterContent] = useState([]);
  const history = useHistory();  // Add this line
  const [isActive, setIsActive] = useState(true);
  const toggleIsActive = () => {
    setIsActive(prev => !prev);
  };
  



  useEffect(() => {
    const unsubscribe = projectFirestore.collection('anpbooks').onSnapshot(snapshot => {
      const booksData = [];
      snapshot.forEach(doc => {
        booksData.push({ ...doc.data(), id: doc.id });
      });
      setBooks(booksData);
    });
    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   if (selectedBook) {
  //     const selectedBookData = books.find(book => book.id === selectedBook);
  //     setChapterContent(selectedBookData.chapterContent || []);
  //   }
  // }, [selectedBook, books]);

  useEffect(() => {
    if (selectedBook) {
      const selectedBookData = books.find(book => book.id === selectedBook);
      setChapterContent(selectedBookData.chapterContent || []);
      setIsActive(selectedBookData.isActive || true);  // Assuming default is true if not found
    }
  }, [selectedBook, books]);
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'book') {
      setSelectedBook(value);
    } else if (name === 'chapter') {
      setSelectedChapter(value);
    }
  };

  const handleQuillChange = (value) => {
    if (selectedChapter !== '') {
      const updatedChapterContent = [...chapterContent];
      updatedChapterContent[selectedChapter] = value;
      setChapterContent(updatedChapterContent);
    }
  };


  const handleUpdate = () => {
    // Update chapterContent in Firestore
    const updatedChapterTitles = [...chapterTitles];  // Clone the original chapterTitles
    if (selectedChapter !== '') {
      updatedChapterTitles[selectedChapter].content = chapterContent[selectedChapter];  // Update the content field
    }
    projectFirestore.collection('anpbooks').doc(selectedBook).update({
      chapterContent,  // Update chapterContent
      chapterTitles: updatedChapterTitles,  // Update chapterTitles with the modified content
      isActive  // Add this line
    }).then(() => {
      history.push('/chapterupdate');  // Redirect to the desired route
    });
  };


  const selectedBookData = books.find(book => book.id === selectedBook) || {};
  const chapterTitles = selectedBookData.chapterTitles || [];

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Chapter Content Update</h1>
      <div className={styles.field}>
        <label htmlFor="book-select" className={styles.label}>Select a book:</label>
        <select id="book-select" name="book" value={selectedBook} onChange={handleChange} className={styles.select}>
          <option value="">Select a book</option>
          {books.map(book => (
            <option key={book.id} value={book.id}>
              {book.bookName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.field}>
        <label htmlFor="chapter-select" className={styles.label}>Select a chapter:</label>
        <select id="chapter-select" name="chapter" value={selectedChapter} onChange={handleChange} className={styles.select}>
          <option value="">Select a chapter</option>
          {chapterTitles.map((chapterTitle, index) => (
            <option key={index} value={index}>
              {chapterTitle.title}
            </option>
          ))}
        </select>
      </div>

      {/* <div className={styles.field}>
  <label htmlFor="active-toggle" className={styles.label}>Is Active:</label>
  <input type="checkbox" id="active-toggle" checked={isActive} onChange={toggleIsActive} />
</div> */}

<div className={styles.field}>
    <label htmlFor="active-toggle" className={styles.togglelabel}>Make Active for Readers:</label>
    <input type="checkbox" id="active-toggle" checked={isActive} onChange={toggleIsActive} />
</div> 

      <div className={styles.field}>
              <label htmlFor="editor" className={styles.label}>Chapter content:</label>
              <ReactQuill value={chapterContent[selectedChapter] || ''}
                          onChange={handleQuillChange}
                          modules={{ toolbar: toolbarOptions }} />
      </div>
            <button onClick={handleUpdate} className={styles.button}>Update</button>
      </div>
  );
};

export default ChapterContentUpdate;
