import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { projectFirestore } from '../../../firebase/config';
import styles from './PostedJobDetail.module.css'; // Assume you have corresponding CSS

const PostedJobDetail = () => {
    const { jobId } = useParams();
    const [jobDetails, setJobDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const doc = await projectFirestore.collection('jobPosts').doc(jobId).get();
                if (doc.exists) {
                    setJobDetails(doc.data());
                } else {
                    setError('Job not found');
                }
            } catch (err) {
                setError('Error fetching job details');
            }
            setLoading(false);
        };

        fetchJobDetails();
    }, [jobId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className={styles.jobDetailContainer}>
            <h1>{jobDetails?.title}</h1>
            <p><strong>Country:</strong> {jobDetails?.country}</p>
            <p><strong>Location:</strong> {jobDetails?.location}</p>
            <p><strong>Salary Range:</strong> {jobDetails?.salaryRange}</p>
            <p><strong>Sub Category:</strong> {jobDetails?.subCategory}</p>
            <p><strong>Job Description:</strong> {jobDetails?.description}</p>
            <p><strong>Country:</strong> {jobDetails?.country}</p>
        
            
            {/* <p>{jobDetails?.description}</p>
            <p>{jobDetails?.description}</p> */} 
            {/* Add more details as needed */}
        </div>
    );
};

export default PostedJobDetail;
