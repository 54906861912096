import React, { useState, useContext } from 'react';
import GeneralLayout from '../components/Hoc/GeneralLayout/GeneralLayout';
import { projectFirestore } from '../firebase/config';
import { AuthContext } from '../context/AuthContext';

import styles from './Contact.module.css';
import GoogleAccra from './GoogleCodes/GoogleAccra';

export default function Contact() {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { user } = useContext(AuthContext); // Assuming you have AuthContext

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const submitFeedback = async (e) => {
    e.preventDefault();

    const feedbackData = user
      ? {
          userId: user.uid,
          displayName: user.displayName || name,
          contactEmail: user.email || email,
          feedback,
        }
      : {
          displayName: name,
          contactEmail: email,
          feedback,
        };

    try {
      await projectFirestore.collection('contactsFeedbacks').add(feedbackData);
      console.log('Feedback submitted:', feedbackData);
      setSubmitted(true);
      setFeedback('');
      setEmail('');
      setName('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };


  return (
    <GeneralLayout>
    <div className={styles.contactContainer}>
      <h2>Contact Us</h2>
      <p>If you want to reach out to us, please through the following channels:</p>
      <div className={styles.contactInfo}>
        <p>Email: info@boameooo.com</p>
        <p>WhatsApp: +31 611278573</p>
        <p>WhatsApp: +233 555592621</p>
        {/* Add more contact information as needed */}
      </div>

      <div className={styles.feedbackForm}>
        <h3>Feedback</h3>
        <form onSubmit={submitFeedback}>
          {!user && (
            <>
              <input
                type="text"
                className={styles.nameInput}
                value={name}
                onChange={handleNameChange}
                placeholder="Your Name"
                required
              />
              <input
                type="email"
                className={styles.emailInput}
                value={email}
                onChange={handleEmailChange}
                placeholder="Your Email"
                required
              />
            </>
          )}
          <textarea
            className={styles.feedbackInput}
            value={feedback}
            onChange={handleFeedbackChange}
            placeholder="Your feedback..."
            required
          />
          <button type="submit" className={styles.submitButton}>Submit Feedback</button>
        </form>
        {submitted && <p>Thank you for your feedback!</p>}
      </div>
      <div className={styles.googleMapsDisplayContainer}>
        {/* <GoogleAccra /> */}
      </div>
    </div>

    </GeneralLayout>
  );
}