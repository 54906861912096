import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { africanCountries } from '../../constants/countries'
import styles from './PartnerDataView.module.css';

const PartnerDataView = ({ data }) => {
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const countryName = africanCountries.find(c => c.code === user.country)?.name || 'N/A';
    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        // Convert timestamp to Date object
        const date = timestamp.toDate();
        // Format date as you prefer, e.g., 'MM/DD/YYYY'
        return date.toLocaleDateString("en-UK");
    };
    


    // Function to handle edit button click
    const handleEditClick = () => {
        history.push('/edit-partner-profile');
    };

    const renderImageAttachment = (url, label) => {
        return (
            <div className={styles.attachmentItem}>
                <a href={url} target="_blank" rel="noopener noreferrer" className={styles.attachmentLink}>
                    <img src={url} alt={label} className={styles.attachmentImage} />
                    <div className={styles.attachmentName}>{label}</div>
                </a>
            </div>
        );
    };

    // Render Individual Partner Details
    const renderIndividualDetails = () => {
        return (
            <>
                <div className={styles.infoGroup}>
                    <label>Availability:</label>
                    <span>{data.availability || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Experience:</label>
                    <span>{data.experience || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Tax Information:</label>
                    <span>{data.taxInformation || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Partner Type:</label>
                    <span>{data.partnerType || 'N/A'}</span>
                </div>
                {/* Image attachments section */}
                <div className={styles.attachmentsSection}>
                    <hr className={styles.divider} />
                    <div className={styles.attachmentsHeader}>
                        <strong>Attachments</strong>
                    </div>
                    <div className={styles.attachmentsList}>
                        {data.personalIdentificationUrl && renderImageAttachment(data.personalIdentificationUrl, 'Personal Identification')}
                        {data.backgroundCheckReportUrl && renderImageAttachment(data.backgroundCheckReportUrl, 'Background Check Report')}
                        {data.profilePhotoUrl && renderImageAttachment(data.profilePhotoUrl, 'Profile Photo')}
                    </div>
                </div>
                {/* Add other individual-specific fields */}
            </>
        );
    };

    // Render Business Partner Details
    const renderBusinessDetails = () => {
        return (
            <>
                
                <div className={styles.infoGroup}>
                    <label>Operational Info:</label>
                    <span>{data.operationalInfo || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Insurance Info:</label>
                    <span>{data.insuranceInfo || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Partner Type:</label>
                    <span>{data.partnerType || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Has Reg. Certificate:</label>
                    <span>{data.hasRegistrationCertificate || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Has Tax Id:</label>
                    <span>{data.hasTaxIdentificationNumber || 'N/A'}</span>
                </div>
                <div className={styles.infoGroup}>
                    <label>Employees:</label>
                    <div>
                        {data.employees?.map((employee, index) => (
                            <p key={index}>{employee.name} - {employee.email} - {employee.phone}</p>
                        ))}
                    </div>
                </div>
                {/* Add other business-specific fields */}
            </>
        );
    };

    return (
        <div className={styles.dataViewContainer}>
            <h2>Partner Details</h2>
            <div className={styles.infoGroup}>
                    <label>Date Created:</label>
                    {/* <span>{data.createdAt || 'N/A'}</span> */}
                    <span>{formatDate(data.createdAt)}</span>
                </div>
            <div className={styles.infoGroup}>
                <label>Name:</label>
                <span>{user.displayName || 'N/A'}</span>
            </div>
            <div className={styles.infoGroup}>
                <label>Email:</label>
                {/* <span>{user.email || 'N/A'}</span> */}
                <span>{data.contactInfo?.email || 'N/A'}</span>
            </div>
            <div className={styles.infoGroup}>
                <label>Phone Number:</label>
                <span>{data.contactInfo?.phone || 'N/A'}</span>
            </div>
            <div className={styles.infoGroup}>
                <label>Country:</label>
                <span>{countryName}</span>
                
            </div>
            <div className={styles.infoGroup}>
                <label>Role:</label>
                <span>{user.role || 'N/A'}</span>
            </div>
            <div className={styles.infoGroup}>
                <label>Payment Details:</label>
                <span>{data.paymentDetails?.bankAccount || 'N/A'}</span>
            </div>
            <div className={styles.infoGroup}>
                <label>Mobile Account:</label>
                <span>{data.paymentDetails?.mobileAccount || 'N/A'}</span>
            </div>
            <div className={styles.infoGroup}>
                <label>Pref. Payment Method:</label>
                <span>{data.paymentDetails?.preferredPaymentMethod || 'N/A'}</span>
            </div>
            <div className={styles.infoGroup}>
                <label>Vehicle Types:</label>
                <span>{data.vehicleTypes ? data.vehicleTypes.join(', ') : 'N/A'}</span>
            </div>

            {data.partnerType === 'individual' ? renderIndividualDetails() : renderBusinessDetails()}

            <button onClick={handleEditClick} className={styles.editButton}>Edit Profile</button>
        </div>
    );
};

export default PartnerDataView;
