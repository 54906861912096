import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './DeliveryHistory.module.css';

const DeliveryHistory = () => {
    const [deliveredPackages, setDeliveredPackages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchDeliveredPackages = async () => {
            setIsLoading(true);
            setError(null);
          
            try {
              const snapshot = await projectFirestore.collection('deliveryorders')
                .where('orderStatus', '==', 'delivered')
                .get();
          
              if (snapshot.empty) {
                console.log('No delivered packages found.');
                setDeliveredPackages([]); // Set to an empty array if no packages are found.
              } else {
                const packagesData = snapshot.docs.map(doc => {
                  const data = doc.data();
                  let formattedPickupDate = 'Date not provided';
                  let formattedDeliveryDate = 'Date not provided';
          
                  if (data.packageDetails) {
                    if (data.packageDetails.pickupDate && typeof data.packageDetails.pickupDate.toDate === 'function') {
                      formattedPickupDate = data.packageDetails.pickupDate.toDate().toLocaleDateString();
                    } else if (data.packageDetails.pickupDate) {
                      // Handle the case where pickupDate is not a Timestamp
                      formattedPickupDate = data.packageDetails.pickupDate;
                    }
                    if (data.packageDetails.deliveryDate && typeof data.packageDetails.deliveryDate.toDate === 'function') {
                      formattedDeliveryDate = data.packageDetails.deliveryDate.toDate().toLocaleDateString();
                    } else if (data.packageDetails.deliveryDate) {
                      // Handle the case where deliveryDate is not a Timestamp
                      formattedDeliveryDate = data.packageDetails.deliveryDate;
                    }
                  }
          
                  return {
                    id: doc.id,
                    orderStatus: data.orderStatus,
                    pickupDate: formattedPickupDate,
                    deliveryDate: formattedDeliveryDate,
                    // ...other data fields
                  };
                });
                console.log('Fetched packages:', packagesData);
                setDeliveredPackages(packagesData);
              }
            } catch (error) {
              console.error('Error fetching delivered packages:', error);
              setError(error.message);
            }
          
            setIsLoading(false);
          };
          

      
        fetchDeliveredPackages();
      }, []);
      

      

    if (isLoading) {
        return <div>Loading delivery history...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className={styles.deliveryHistoryContainer}>
            <h2>Delivered Packages</h2>
            <ul className={styles.deliveryList}>
                {deliveredPackages.map(pkg => (
                    <li key={pkg.id} className={styles.deliveryItem}>
                        <div>
                            <p><strong>Package ID:</strong> {pkg.id}</p>
                            <p><strong>Order Status:</strong> {pkg.orderStatus}</p>
                            <p><strong>Pickup Date:</strong> {pkg.pickupDate}</p>
                            <p><strong>Delivery Date:</strong> {pkg.deliveryDate}</p>
                            <p><strong>Pickup Address:</strong> {pkg.pickupAddress}</p>
                            <p><strong>Delivery Address:</strong> {pkg.deliveryAddress}</p>
                            <button className={styles.statusButton}>Delivered</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DeliveryHistory;




// const fetchDeliveredPackages = async () => {
//     setIsLoading(true);
//     try {
//       const snapshot = await projectFirestore.collection('deliveryorders')
//                               .where('orderStatus', '==', 'pending')
//                               .orderBy('orderDate', 'desc')
//                               .get();
//       if (snapshot.empty) {
//         console.log('No delivered packages found.');
//         setDeliveredPackages([]); // Set to an empty array if no packages are found.
//       } else {
//         const packagesData = snapshot.docs.map(doc => {
//           const data = doc.data();
//           return {
//             id: doc.id,
//             orderStatus: data.orderStatus,
//             pickupDate: data.packageDetails?.pickupDate.toDate().toLocaleDateString() || 'Date not provided',
//             deliveryDate: data.packageDetails?.deliveryDate.toDate().toLocaleDateString() || 'Date not provided',
//             pickupAddress: data.selectedPickupAddress ? `${data.selectedPickupAddress.street}, ${data.selectedPickupAddress.city}` : 'Not provided',
//             deliveryAddress: data.selectedDeliveryAddress ? `${data.selectedDeliveryAddress.street}, ${data.selectedDeliveryAddress.city}` : 'Not provided',
//             // Add other fields as needed
//           };
//         });
//         console.log('Fetched packages:', packagesData);
//         setDeliveredPackages(packagesData);
//       }
//     } catch (error) {
//       console.error('Error fetching delivered packages:', error);
//       setError(error.message);
//     }
//     setIsLoading(false);
//   };