// ImportRequirements.js
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import { projectFirestore, projectStorage } from '../../../firebase/config';
import styles from './ImportRequirements.module.css';

const ImportRequirements = ({ formData, setFormData }) => {
    const [selectedDate, setSelectedDate] = useState(formData.expectedDeliveryDate || new Date());
    // const [localFormData, setLocalFormData] = useState({ ...formData });
    const [qualityStandards, setQualityStandards] = useState(formData.qualityStandards || '');
    const [legalCompliance, setLegalCompliance] = useState(formData.legalCompliance || '');
    const [supplierQualifications, setSupplierQualifications] = useState(formData.supplierQualifications || '');

    const [productImages, setProductImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');

  
    const handleQuillChange = (value, field) => {
        setFormData({ ...formData, [field]: value });
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormData({ ...formData, expectedDeliveryDate: date });
    };

    // Handle image file uploads
    const handleDocumentsUpload = async (e) => {
        const files = Array.from(e.target.files);
    
        // Validate each file
        const isValid = files.every(file => {
            const isSizeValid = file.size <= 5000000; // 5MB size limit
            const isFormatValid = ['image/jpeg', 'image/png'].includes(file.type); // Accept JPEG and PNG
            return isSizeValid && isFormatValid;
        });
    
        if (!isValid) {
            setUploadStatus('Invalid file size or format');
            return;
        }
    
        setUploadStatus('Uploading...');
    
        const uploadPromises = files.map(file => {
            const storageRef = projectStorage.ref(`productDocuments/${file.name}`);
            return storageRef.put(file).then(snapshot => snapshot.ref.getDownloadURL());
        });
    
        try {
            const downloadURLs = await Promise.all(uploadPromises);
            setFormData(prevFormData => ({
                ...prevFormData,
                productImages: [...(prevFormData.productImages || []), ...downloadURLs]
            }));
            setImagePreviews(prevPreviews => [...prevPreviews, ...downloadURLs]);
            setUploadStatus('Upload complete');
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploadStatus('Error during upload');
        }
    };

    return (
        <div className={styles.importRequirementsContainer}>
            <h2>Import Requirements</h2>
            {/* Quality Standards */}
            <label className={styles.label}>Quality Standards:</label>
            <ReactQuill 
                value={formData.qualityStandards || ''}
                onChange={(value) => handleQuillChange(value, 'qualityStandards')}
                className={styles.quill}
                placeholder="Detail your quality standards or certifications required"
            />

            {/* Legal and Regulatory Compliance */}
            <label className={styles.label}>Legal and Regulatory Compliance:</label>
            <ReactQuill 
                value={formData.legalCompliance || ''}
                onChange={(value) => handleQuillChange(value, 'legalCompliance')}
                className={styles.quill}
                placeholder="Any legal or regulatory compliance required"
            />

            {/* Expected Delivery Date */}
            <label className={styles.label}>Expected Delivery Date:</label>
            <DatePicker 
                selected={selectedDate}
                onChange={handleDateChange}
                className={styles.datePicker}
                required // Mark as required
            />

            {/* Preferred Supplier Qualifications */}
            <label className={styles.label}>Preferred Supplier Qualifications:</label>
            <ReactQuill 
                value={formData.supplierQualifications || ''}
                onChange={(value) => handleQuillChange(value, 'supplierQualifications')}
                className={styles.quill}
                placeholder="Specify if you prefer suppliers with certain certifications or experience"
            />

            {/* Product Images Upload */}
            <div className={styles.formGroup}>
                <label htmlFor="supportingDocuments" className={styles.label}>Upload Product Supporting Document:</label>
                <input 
                    type="file" 
                    id="supportingDocuments" 
                    name="supportingDocuments" 
                    onChange={handleDocumentsUpload}
                    className={styles.input}
                    multiple
                    accept="image/*"
                />
                
                {uploadStatus && <p className={styles.uploadStatus}>{uploadStatus}</p>}
                <div className={styles.imagePreviews}>
                    {imagePreviews.map((url, index) => (
                        <img key={index} src={url} alt={`Upload Product Supporting Document ${index + 1}`} className={styles.imagePreview} />
                    ))}
                </div>
            </div>

            {/* <label className={styles.label}>Upload Supporting Documents:</label>
            <input 
                type="file"
                className={styles.input}
                name="supportingDocuments"
                onChange={handleImageUpload}
                multiple
            />

            {/* Render uploaded file names if available 
            <div className={styles.uploadedFiles}>
                {formData.supportingDocuments && Array.from(formData.supportingDocuments).map((file, index) => (
                    <div key={index}>{file.name}</div>
                ))}
           </div> */}
            
        </div>
    );
};

export default ImportRequirements;

