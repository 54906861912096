import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MultiPayment from '../Payments/MultiPayment';
import { CartContext } from '../../context/CartContext';
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore } from '../../firebase/config';
import { useCountrySettings } from '../../context/CountrySettingsContext';
import { useHistory } from 'react-router-dom';

import styles from './CompletedCheckout.module.css';

const CompletedCheckout = () => {
  //const { cartItems } = useContext(CartContext);
  const { cartItems, dispatch } = useContext(CartContext);
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const tx_ref = `tx-${user.uid}-${Date.now()}`;
  const { selectedCountry } = useCountrySettings();
  const [successMessage, setSuccessMessage] = useState('');
  
  //console.log('Dispatch function within  CompletedCheckout:', dispatch);

  useEffect(() => {
    console.log("Cart items with useEffect:", cartItems);
  }, [cartItems]);


  const handlePaymentSuccess = (response) => {
    console.log("Payment response:", response);

    const completedBooks = cartItems.filter(
      (item) => item.bookType === "Complete Book"
    );

    //console.log("Completed books:", completedBooks);

    // Map through the serializedBooks and extract the book IDs
    const completedBookIds = completedBooks.map((book) => book.id);

    const transactionData = {
      transactionId: response.transaction_id,
      amount: response.amount,
      currency: response.currency,
      //cartItems: cartItems.filter(item => item.bookType === "Completed Book"),
      cartItems: completedBookIds,
      customer: customer,
      timestamp: new Date().toISOString(),
      purchaseType: 'purchase',
      // Add any other relevant fields from the response object or elsewhere
    };
    
    //console.log('Transaction data before saving Completed Book:', transactionData); // Added console log
    // Save transaction history to Firestore
    saveTransactionHistory(transactionData);

    console.log("Clearing cart");
    // Clear the cart after a successful transaction
    dispatch({ type: 'CLEAR_CART' });
  
    setSuccessMessage("Payment successful! Redirecting to Books page...");
    setTimeout(() => {
      history.push("/books");
    }, 3000);
  };

  const saveTransactionHistory = async (transactionData) => {
    try {
      // Get the user's transactionsHistory collection
      const transactionsHistoryRef = projectFirestore.collection("transactionsHistory");
        //console.log('Transaction data before saving:', transactionData);
        transactionData.accountUserId = user.uid;
      // Add the transactionData object to the transactionsHistory collection
      await transactionsHistoryRef.add(transactionData);
      console.log("Transaction history saved successfully");
    } catch (error) {
      console.error("Error saving transaction history:", error);
    }
  };

  const passedTotalCompleted = parseFloat(location.state?.totalCompleted) || 0;
  const currency = location.state?.currency || selectedCountry.currency;

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      try {
        const userDoc = await projectFirestore.collection('users').doc(user.uid).get();
        if (userDoc.exists) {
          setUserData(userDoc.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user]);

  const customer = userData
    ? {
        email: userData.email,
        phone_number: userData.phoneNumber || 'N/A',
        name: userData.displayName,
      }
    : null;

  return (
    <div className={styles.completedCheckout}>
      <h2>Completed Books Checkout</h2>
      <h3>Shipping Information</h3>
      <p className={styles.onlineDeliveryMessage}>
        Online delivery is the default and only option for book orders.
      </p>

      {/* <h3>Order Summary</h3>
      {cartItems.map((item) => (
        <div key={item.id} className={styles.summaryItem}>
          <h4>{item.bookName}</h4>
          <p>{item.quantity} x {item.bookPrice} {currency}</p>
        </div>
      ))}
      <h3>Total Completed Books: {passedTotalCompleted} {currency}</h3> */}
      <h3>Order Summary</h3>
      {cartItems
        .filter((item) => item.bookType === "Complete Book")
        .map((item) => (
          <div key={item.id} className={styles.summaryItem}>
            {/* <h4>{item.bookName}</h4> */}
            <p>
            {/* {item.quantity} x {item.displayPrice} {currency} */}
            {item.quantity} x {item.bookName}
            </p>
          </div>
        ))}
      <h3>Total Completed Books: {passedTotalCompleted} {currency}</h3>


      {successMessage && <div className={styles.successMessage}>{successMessage}</div>}
      {customer && (
        <MultiPayment
          //totalSerialized={0}
          totalCompleted={passedTotalCompleted}
          initialCurrency={currency}
          customer={customer}
          tx_ref={tx_ref}
          onSuccess={handlePaymentSuccess}
        />
      )}
    </div>
  );
};

export default CompletedCheckout;





