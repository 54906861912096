import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';

import styles from './WealthDisplay.module.css';

const WealthDisplay = () => {
    const [totalWealth, setTotalWealth] = useState(0);
    const [qualifiedPeople, setQualifiedPeople] = useState(1000); // Example value

    useEffect(() => {
        const fetchWealthData = async () => {
            try {
                const snapshot = await projectFirestore.collection('wealthvalue').get();
                const total = snapshot.docs.reduce((sum, doc) => sum + doc.data().value, 0);
                setTotalWealth(total);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchWealthData();
    }, []);

    const calculateDistributions = () => {
        const rewardPercentage = 0.25 * totalWealth;
        const averagePayout = rewardPercentage / qualifiedPeople;
        const runningCost = 0.25 * totalWealth;
        const developerPay = 0.5 * totalWealth;

        return { rewardPercentage, averagePayout, runningCost, developerPay };
    };

    const { rewardPercentage, averagePayout, runningCost, developerPay } = calculateDistributions();

    return (
        <div className={styles.wealthContainer}>
        <h3>Our Wealth</h3>
            <p className={styles.value}>Gross Wealth Value To Date: ${totalWealth}</p>
            <p className={styles.value}>Percentage Destined For Reward To Every Global Africa Afholders: ${rewardPercentage}</p>
            <p className={styles.value}>Average To Be Paidout To Qualified Afholders: ${averagePayout}</p>
            <p className={styles.value}>Percentage Destined For Running Boameooo: ${runningCost}</p>
            <p className={styles.value}>Percentage Reserved For Add-on Developers of Boameooo: ${developerPay}</p>
        </div>
    );
};

export default WealthDisplay;