import { AuthContext } from "../context/AuthContext";
import { useContext, useEffect } from "react";

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    // Extract user from the context
    const { user } = context;

    

    if (!context) {
        throw Error('useAuthContext must be used inside an AuthContextProvider');
    }
    // console.log('Current user in context:', context.user); // Add this line for debugging

    return context;
};
