import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import EditJobTypeForm from './EditJobTypeForm';
import styles from './JobTypesManager.module.css';

const JobTypesManager = () => {
  const [jobTypes, setJobTypes] = useState([]);
  const [editingJobType, setEditingJobType] = useState(null);
  const [expandedJobTypes, setExpandedJobTypes] = useState(new Set()); // Track expanded job types
  const [selectedCategory, setSelectedCategory] = useState(''); // New state for category selection



  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        const snapshot = await projectFirestore.collection('jobtypes').get();
        const fetchedJobTypes = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setJobTypes(fetchedJobTypes);
      } catch (err) {
        console.error("Error fetching job types: ", err);
      }
    };

    fetchJobTypes();
  }, []);

  const handleSaveJobType = async (jobTypeData) => {
    if (jobTypeData.id) {
      await handleEditJobType(jobTypeData);
    } else {
      await handleAddJobType(jobTypeData);
    }
    setEditingJobType(null);
  };

  const handleAddJobType = async (newJobType) => {
    try {
      const docRef = await projectFirestore.collection('jobtypes').add(newJobType);
      setJobTypes([...jobTypes, { id: docRef.id, ...newJobType }]);
    } catch (error) {
      console.error("Error adding job type: ", error);
    }
  };

  const handleEditJobType = async (updatedJobType) => {
    try {
      await projectFirestore.collection('jobtypes').doc(updatedJobType.id).update(updatedJobType);
      setJobTypes(jobTypes.map(jobType => jobType.id === updatedJobType.id ? updatedJobType : jobType));
    } catch (error) {
      console.error("Error updating job type: ", error);
    }
  };

  const handleDeleteJobType = async (jobTypeId) => {
    try {
      await projectFirestore.collection('jobtypes').doc(jobTypeId).delete();
      setJobTypes(jobTypes.filter(jobType => jobType.id !== jobTypeId));
    } catch (error) {
      console.error("Error deleting job type: ", error);
    }
  };

  const toggleJobTypeView = (jobTypeId) => {
    setExpandedJobTypes(prev => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(jobTypeId)) {
        newExpanded.delete(jobTypeId);
      } else {
        newExpanded.add(jobTypeId);
      }
      return newExpanded;
    });
  };

   // Function to filter job types based on selected category
   const filteredJobTypes = selectedCategory
   ? jobTypes.filter(jobType => jobType.category === selectedCategory)
   : jobTypes;

  return (
    <div className={styles.jobTypesManagerContainer}>
      <h2>Job Types</h2>
      <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
      <option value="">Select a Category</option>
            <option value="Professional-Corporate">Professional and Corporate Jobs</option>
            <option value="Personal-Local">Personal and Local Jobs</option>
      </select>
      <button onClick={() => setEditingJobType({})}>Add New Job Type</button>

      {editingJobType && (
        <EditJobTypeForm
          jobType={editingJobType}
          onSave={handleSaveJobType}
          onCancel={() => setEditingJobType(null)}
        />
      )}

      {filteredJobTypes.length === 0 ? (
        <p>No job types found.</p>
      ) : (
        <ul className={styles.jobTypeList}>
          {filteredJobTypes.map(jobType => (
            <li key={jobType.id} className={styles.jobTypeItem}>
              <div>
                <p>{jobType.name}</p>
                {expandedJobTypes.has(jobType.id) && <p>{jobType.description}</p>}
              </div>
              <div className={styles.jobTypeActions}>
                <button onClick={() => toggleJobTypeView(jobType.id)}>
                  {expandedJobTypes.has(jobType.id) ? 'Show Less' : 'Show More'}
                </button>
                <button onClick={() => setEditingJobType(jobType)}>Edit</button>
                <button onClick={() => handleDeleteJobType(jobType.id)}>Delete</button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default JobTypesManager;
