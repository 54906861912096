import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerLinks}>
        <NavLink exact to="/" activeClassName={styles.activeLink}>
          Home
        </NavLink>
        <NavLink to="/about" activeClassName={styles.activeLink}>
          About
        </NavLink>
        <NavLink to="/contact" activeClassName={styles.activeLink}>
          Contact
        </NavLink>
      </div>
      <div className={styles.footerText}>
        <p>&copy; 2023 Boameooo. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
