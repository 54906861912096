import React from 'react';
import ReactDOM from "react-dom/client";

import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthContextProvider } from './context/AuthContext';
import { ContextProvider } from './pages/Account/contexts/ContextProvider';
import { AddressUpdateProvider } from './context/AddressUpdateContext';
import { CartProvider } from './context/CartContext';
import { CountrySettingsProvider } from './context/CountrySettingsContext'
import { AfholderProvider } from './components/Accounts/Afholders/AfholderContext';
import ErrorBoundary from './utilities/ErrorBoundary';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
//import PaymentForm from './components/StripePayments/PaymentForm';
//import StripeCartProvider from './components/StripePayments/StripeCartContext';

const stripePromise = loadStripe('STRIPE_PUBLIC_KEY');
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
console.log("Google Client ID: ", googleClientId); // This line will log the Google Client ID


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  
  {/* <StripeCartProvider> */}
  <GoogleOAuthProvider clientId={googleClientId}>
  <AuthContextProvider> 
  
    <ContextProvider>
    <AddressUpdateProvider>
      <CartProvider>
        <CountrySettingsProvider>
        <AfholderProvider>
        <ErrorBoundary>
        <Elements stripe={stripePromise}>
            <App />
        </Elements>
        </ErrorBoundary>
        </AfholderProvider>
        </CountrySettingsProvider>
      </CartProvider>
      </AddressUpdateProvider>
    </ContextProvider>
 
  </AuthContextProvider>
  </GoogleOAuthProvider>
  {/* </StripeCartProvider> */}
  
  </React.StrictMode>
);

// Register the Firebase Messaging service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Service Worker registered with scope: ', registration.scope);
  }).catch(function(err) {
    console.log('Service Worker registration failed: ', err);
  });
}