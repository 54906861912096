import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import { useAuthContext } from '../../hooks/useAuthContext';
import styles from './NotificationAfholder.module.css'; 

const NotificationAfholder = () => {
    const { user } = useAuthContext();
    const [notifications, setNotifications] = useState([]);



    useEffect(() => {
        if (user) {
            const unsubscribe = projectFirestore.collection('afholderNotifications')
                .where('userId', '==', user.uid)
                .orderBy('createdAt', 'desc')
                .onSnapshot(snapshot => {
                    const fetchedNotifications = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setNotifications(fetchedNotifications);
                }, err => {
                    console.error('Error fetching afholderNotifications:', err);
                });

            return () => unsubscribe();
        }
    }, [user]);

    const markAsRead = (notificationId) => {
        projectFirestore.collection('afholderNotifications').doc(notificationId).update({ isRead: true });
    };

    return (
        <div className={styles.notificationsContainer}>
            <h3>Afholder Notifications</h3>
            {notifications.map(notification => (
                <div key={notification.id} className={`${styles.notification} ${notification.isRead ? styles.read : styles.unread}`}>
                    <p>{notification.message}</p>
                    {!notification.isRead && (
                        <button onClick={() => markAsRead(notification.id)}>Mark as Read</button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default NotificationAfholder;


// import React, { useState, useEffect } from 'react';
// import { useAfholder } from '../Accounts/Afholders/AfholderContext';
// import styles from './NotificationAfholder.module.css'; // Create this CSS module

// const NotificationAfholder = () => {
//     const { afholderInfo } = useAfholder();
//     const [notifications, setNotifications] = useState([]);

//     useEffect(() => {
//         // Fetch notifications from afholderInfo and set them
//         if (afholderInfo && afholderInfo.notifications) {
//             setNotifications(afholderInfo.notifications);
//         }
//     }, [afholderInfo]);

//     return (
//         <div className={styles.notificationsContainer}>
//             <h3>Afholder Notifications</h3>
//             {notifications.map((notification, index) => (
//                 <div key={index} className={styles.notification}>
//                     {notification.message}
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default NotificationAfholder;
