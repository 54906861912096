import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { projectFirestore } from '../../../firebase/config';
import styles from './EditBook.module.css';

const EditBook = () => {
  const history = useHistory();

  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState('');
  const [bookName, setBookName] = useState('');
  const [bookType, setBookType] = useState('');
  const [bookCoverUrl, setBookCoverUrl] = useState('');
  const [bookPrice, setBookPrice] = useState('');
  const [bookDescription, setBookDescription] = useState('');
  const [lastChapterContent, setLastChapterContent] = useState('');
  const [chapterTitles, setChapterTitles] = useState([]);

  useEffect(() => {
    const booksRef = projectFirestore.collection('anpbooks');
    booksRef.get().then((querySnapshot) => {
      const booksData = [];
      querySnapshot.forEach((doc) => {
        booksData.push({ id: doc.id, ...doc.data() });
      });
      setBooks(booksData);
    }).catch((error) => {
      console.log('Error getting books:', error);
    });
  }, []);

  const handleChangeChapterTitle = (e, index) => {
    const updatedTitles = [...chapterTitles];
    updatedTitles[index] = { ...updatedTitles[index], title: e.target.value };
    setChapterTitles(updatedTitles);
  };
  const handleChangeChapterContent = (e, index) => {
    const updatedTitles = [...chapterTitles];
    updatedTitles[index] = { ...updatedTitles[index], content: e.target.value };
    setChapterTitles(updatedTitles);
    setLastChapterContent(e.target.value);
  };

  const handleRemoveChapterTitle = (index) => {
    const updatedTitles = [...chapterTitles];
    updatedTitles.splice(index, 1);
    setChapterTitles(updatedTitles);
  };

  const handleAddChapterTitle = () => {
    if (lastChapterContent !== '') {
      const chapterNumber = chapterTitles.length + 1;
      setChapterTitles([
        ...chapterTitles,
        { number: chapterNumber, title: '', content: '' },
      ]);
      setLastChapterContent(
        'Please add the chapter content here. It can be further edited or updated in the UpdateChapter component'
      );
    }
  };

const handleBookSelect = (e) => {
    const selectedBookId = e.target.value;
    const selectedBookData = books.find((book) => book.bookName === selectedBookId);
    setSelectedBook(selectedBookId);
    setBookName(selectedBookData.bookName);
    setBookType(selectedBookData.bookType);
    setBookCoverUrl(selectedBookData.bookCoverUrl);
    setBookPrice(selectedBookData.bookPrice);
    setBookDescription(selectedBookData.bookDescription);
    setLastChapterContent(selectedBookData.lastChapterContent);
    setChapterTitles(selectedBookData.chapterTitles);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const bookRef = projectFirestore.collection('anpbooks').doc(selectedBook);
    bookRef.update({
      bookName,
      bookType,
      bookCoverUrl,
      bookPrice,
      bookDescription,
      lastChapterContent,
      chapterTitles
    }).then(() => {
      console.log('Book updated successfully!');
      history.push(`/books/${selectedBook}`);
    }).catch((error) => {
      console.log('Error updating book:', error);
    });
  };

  return (
    <div className={styles.addBookForm}>
      <h2 className={styles.pageTitle}>Edit Book</h2>
      <form onSubmit={handleSubmit} className={styles.bookForm}>
        <label className={styles.formLabel}>
          <span>Book Name:</span>
          <select value={bookName} onChange={(e) => handleBookSelect(e)}>
            <option value="">Select a Book</option>
            {books &&
              books.map((book) => (
                <option key={book.id} value={book.bookName}>
                  {book.bookName}
                </option>
              ))}
          </select>
        </label>
    <div className={styles.formGroup}>
  <label htmlFor="bookType" className={styles.formLabel}>
    Book Type
  </label>
  <div className={styles.formRadioGroup}>
    <div className={styles.formRadio}>
      <input
        type="radio"
        id="bookTypeSubscription"
        name="bookType"
        value="Subscription"
        checked={bookType === 'Subscription'}
        onChange={(e) => setBookType(e.target.value)}
        className={styles.formInput}
      />
      <label htmlFor="bookTypeSubscription" className={styles.formLabel}>
        Subscription
      </label>
    </div>
    <div className={styles.formRadio}>
      <input
        type="radio"
        id="bookTypePurchase"
        name="bookType"
        value="Purchase"
        checked={bookType === 'Purchase'}
        onChange={(e) => setBookType(e.target.value)}
        className={styles.formInput}
      />
      <label htmlFor="bookTypePurchase" className={styles.formLabel}>
        Purchase
      </label>
    </div>
  </div>
</div>

<div className={styles.formGroup}>
  <label htmlFor="bookCoverUrl" className={styles.formLabel}>
    Book Cover Image Upload
  </label>
  <input
    type="file"
    id="bookCoverUrl"
    name="bookCoverUrl"
    onChange={(e) => setBookCoverUrl(e.target.files[0])}
    className={styles.formInput}
  />
</div>

{chapterTitles.map((chapter, index) => (
          <div key={index} className={styles.chapterInputWrapper}>
  <label htmlFor={`chapterTitle-${index}`} className={styles.formLabel}>
    Chapter Title
  </label>
  <div className={styles.chapterInputContainer}>
    <input
      type="number"
      id={`chapterNumber-${index}`}
      name={`chapterNumber-${index}`}
      value={index + 1}
      className={styles.chapterNumberInput}
      disabled
    />
    <input
      type="text"
      id={`chapterTitle-${index}`}
      name={`chapterTitle-${index}`}
      value={chapter.title}
      onChange={(e) => handleChangeChapterTitle(e, index)}
      className={styles.chapterTitleInput}
      placeholder={`Chapter ${index + 1} Title`}
    />
    <button
      type="button"
      className={styles.removeChapterBtn}
      onClick={() => handleRemoveChapterTitle(index)}
    >
      Remove
    </button>
  </div>
  <div className={styles.formGroup}>
    <label htmlFor={`chapterContent-${index}`} className={styles.formLabel}>
      Chapter Content
    </label>
    <textarea
      id={`chapterContent-${index}`}
      name={`chapterContent-${index}`}
      value={chapter.content}
      onChange={(e) => handleChangeChapterContent(e, index)}
      className={styles.chapterContentInput}
      placeholder="Please add the chapter context here. It can be further edited or updated in the UpdateChapter component"
      style={{ overflow: "auto", height: "300px" }} // add this style property
    />
  </div>
  {/* TODO:  Get the scrollable bar working*/}
</div>
))}
<button type="button" className={styles.addChapterBtn} onClick={handleAddChapterTitle}>
  Add Chapter
</button>

<div className={styles.formGroup}>
  <label htmlFor="bookPrice" className={styles.formLabel}>
    Book Price
  </label>
  <input
    type="number"
    id="bookPrice"
    name="bookPrice"
    value={bookPrice}
    onChange={(e) => setBookPrice(e.target.value)}
    className={styles.formInput}
    required
  />
</div>

<div className={styles.formGroup}>
  <label htmlFor="bookDescription" className={styles.formLabel}>
    Book Description
  </label>
  <textarea
    id="bookDescription"
    name="bookDescription"
    value={bookDescription}
    onChange={(e) => setBookDescription(e.target.value)}
    className={styles.formInput}
    rows="4"
    placeholder="Enter book description"
  />
</div>

       

        <button className={styles.submitBtn}>Update Book</button>
      </form>
    </div>
  );

};

export default EditBook;



