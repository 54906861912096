// import { useState, useEffect } from 'react'
// import { projectAuth, projectFirestore, projectStorage } from '../firebase/config'
// import { useAuthContext } from './useAuthContext'
// import { africanCountries } from '../constants/countries'
// import { useHistory } from 'react-router-dom';


// export const useSignup = () => {
//   const [isCancelled, setIsCancelled] = useState(false)
//   const [error, setError] = useState(null)
//   const [isPending, setIsPending] = useState(false)
//   const { dispatch } = useAuthContext()
//   const history = useHistory();

//   const signup = async (email, password, displayName, role, thumbnail, country, phoneNumber, isAfholder, uniqueIdentifier, selectedService ) => {
//     console.log("Signup process started"); 
//     setError(null);
//     setIsPending(true);

//     try {
//       const res = await projectAuth.createUserWithEmailAndPassword(email, password);
//       if (!res) throw new Error('Could not complete signup');

//       let imgUrl = '';
//       if (thumbnail) {
//         const uploadPath = `thumbnails/${res.user.uid}/${thumbnail.name}`;
//         const img = await projectStorage.ref(uploadPath).put(thumbnail);
//         imgUrl = await img.ref.getDownloadURL();
//       }

//       const countryObj = africanCountries.find(c => c.code === country);
//       const formattedPhoneNumber = `+${countryObj.phoneCode}${phoneNumber}`;

//       await res.user.updateProfile({ email, displayName, photoURL: imgUrl });

//       await projectFirestore.collection('users').doc(res.user.uid).set({
//         online: true,
//         email,
//         displayName,
//         photoURL: imgUrl,
//         role,
//         userid: res.user.uid,
//         country,
//         phoneNumber: formattedPhoneNumber,
//         isAfholder: isAfholder,
//         uniqueIdentifier,
//         selectedService,
//       });

//       if (!isCancelled) {
//         console.log('User signed up successfully:', res.user); // Log successful signup
//         dispatch({ type: 'LOGIN', payload: res.user });
//         setIsPending(false);
//         setError(null);
//         console.log('Redirecting to home page'); // Log redirection
//         history.push('/');
//       }
//     } catch (err) {
//       if (!isCancelled) {
//         console.error("Signup error:", err);
//         setError(err.message);
//         setIsPending(false);
//       }
//     }
//   };

//   useEffect(() => {
//     return () => setIsCancelled(true);
//   }, []);

//   return { signup, error, isPending };
// };

import { useState, useEffect } from 'react';
import { africanCountries } from '../constants/countries'
import { projectAuth, projectFirestore, projectStorage } from '../firebase/config';
import { useAuthContext } from './useAuthContext';

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (email, password, displayName, role, thumbnail, country, phoneNumber, isAfholder, uniqueIdentifier, selectedService) => {
    setError(null);
    setIsPending(true);

    try {
      const res = await projectAuth.createUserWithEmailAndPassword(email, password);
      if (!res) throw new Error('Could not complete signup');

      let imgUrl = '';
      if (thumbnail) {
        const uploadPath = `thumbnails/${res.user.uid}/${thumbnail.name}`;
        const img = await projectStorage.ref(uploadPath).put(thumbnail);
        imgUrl = await img.ref.getDownloadURL();
      }
      // Ensure phoneNumber is correctly formatted with the country code
      // If not already formatted in Signup component, do it here
      const countryObj = africanCountries.find(c => c.code === country);
      if (!countryObj) throw new Error('Country code not found');
      const formattedPhoneNumber = `+${countryObj.phoneCode}${phoneNumber}`;

      await res.user.updateProfile({ email, displayName, photoURL: imgUrl });

      await projectFirestore.collection('users').doc(res.user.uid).set({
        online: true,
        email,
        displayName,
        photoURL: imgUrl,
        role,
        userid: res.user.uid,
        country,
        phoneNumber: formattedPhoneNumber,
        isAfholder: isAfholder,
        uniqueIdentifier,
        selectedService,
      });

      if (!isCancelled) {
        dispatch({ type: 'LOGIN', payload: res.user });
        setIsPending(false);
        setError(null);
        return res.user; // Return the user credential
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { signup, error, isPending };
};
