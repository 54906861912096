import React from 'react';
import AccountDeactivation from '../Accounts/AccountDeactivation/AccountDeactivation';
import CustomerSupport from '../CustomerSupport/CustomerSupport';
import ProfilePersonalInformation from '../ProfilePersonalInfo/ProfilePersonalInformation';
import AddressManager from '../../components/ShippingAddress/AddressManager'

import styles from './IndividualCustomerProfile.module.css';

const IndividualCustomerProfile = () => {
  return (
    <div className={styles.profileContainer}>
      {/* <h1 className={styles.profileTitle}>Profile</h1> */}

      {/* Personal Information */}
      <div className={styles.profileSection}>
        <ProfilePersonalInformation />
      </div>

       {/* Address Management */}
       <div className={styles.profileSection}>
        <AddressManager />
      </div>

      {/* Account Deactivation */}
      <div className={styles.profileSection}>
        <AccountDeactivation />
      </div>

      {/* Customer Support */}
      <div className={styles.profileSection}>
        <CustomerSupport />
      </div>
    </div>
  );
};

export default IndividualCustomerProfile;
