import React, { useState } from 'react';
import { projectFirestore } from '../../firebase/config';

import styles from './WealthForm.module.css';

const WealthForm = () => {
    const [date, setDate] = useState('');
    const [value, setValue] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await projectFirestore.collection('wealthvalue').doc(date).set({
                date,
                value: parseFloat(value)
            });
            // Reset form or give feedback
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className={styles.input} />
            <input type="number" value={value} onChange={(e) => setValue(e.target.value)} className={styles.input} />
            <button type="submit" className={styles.submitButton}>Submit Wealth Value</button>
        </form>
    );
};

export default WealthForm;