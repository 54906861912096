import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from "react-router-dom";
import { projectFirestore, projectStorage, timestamp } from '../../../firebase/config';
import styles from './ShippingLogistics.module.css'; // Import the corresponding CSS module

const ShippingLogistics = ({ formData, setFormData }) => {
    const history = useHistory();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [videoPreview, setVideoPreview] = useState('');

    const handleVideoUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Validate file size and format
        const isSizeValid = file.size <= 100000000; // 100MB size limit
        const isFormatValid = ['video/mp4', 'video/webm'].includes(file.type); // Accept MP4 and WebM formats
        if (!isSizeValid || !isFormatValid) {
            setUploadError('Invalid file size or format. Max size: 100MB, Formats: MP4, WebM');
            return;
        }

        setUploadError(null);
        setUploadProgress(0);

        const storageRef = projectStorage.ref(`productVideos/${file.name}`);
        const uploadTask = storageRef.put(file);

        uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
        }, error => {
            setUploadError(error.message);
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                setFormData({...formData, productVideo: downloadURL});
                setVideoPreview(downloadURL);
            });
        });
    };

    // const handleInputChange = (e) => {
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    // };


    // const handleQuillChange = (name, content) => {
    //     setFormData({ ...formData, [name]: content });
    // };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
   
    
    
    const handleQuillChange = (name, content) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: content
        }));
    };

    return (
        <div className={styles.shippingLogisticsContainer}>
            {/* Packaging Details */}
            <div className={styles.formGroup}>
                <label htmlFor="packagingDetails" className={styles.label}>Packaging Details:</label>
                <ReactQuill
                    id="packagingDetails"
                    value={formData.packagingDetails || ''}
                    onChange={(content) => handleQuillChange('packagingDetails', content)}
                    placeholder="Provide details about the packaging of your product, including materials, dimensions, and special handling requirements."
                />
            </div>

            {/* Sample Availability */}
            <div className={styles.formGroup}>
                <label htmlFor="sampleAvailability" className={styles.label}>Sample Availability:</label>
                <ReactQuill
                    id="sampleAvailability"
                    value={formData.sampleAvailability || ''}
                    onChange={(content) => handleQuillChange('sampleAvailability', content)}
                    placeholder="Describe the availability of product samples for potential buyers, including any costs or conditions."
                />
            </div>

            {/* Lead Time */}
            <div className={styles.formGroup}>
                <label htmlFor="leadTime" className={styles.label}>Lead Time:</label>
                <ReactQuill
                    id="leadTime"
                    value={formData.leadTime || ''}
                    onChange={(content) => handleQuillChange('leadTime', content)}
                    placeholder="Detail the typical lead time required from order to shipment, and any factors that may affect this."
                />
            </div>

            {/* Shipping and Logistics Options */}
            <div className={styles.formGroup}>
                <label htmlFor="shippingOptions" className={styles.label}>Shipping and Logistics Options:</label>
                <ReactQuill
                    id="shippingOptions"
                    value={formData.shippingOptions || ''}
                    onChange={(content) => handleQuillChange('shippingOptions', content)}
                    placeholder="Outline the available shipping and logistics options, including carriers, routes, and any limitations."
                />
            </div>

            {/* Volume Discounts */}
            <div className={styles.formGroup}>
                <label htmlFor="volumeDiscounts" className={styles.label}>Volume Discounts:</label>
                <ReactQuill
                    id="volumeDiscounts"
                    value={formData.volumeDiscounts || ''}
                    onChange={(content) => handleQuillChange('volumeDiscounts', content)}
                    placeholder="Provide information about any discounts offered for bulk purchases or large volume orders."
                />
            </div>

            {/* Product Video Upload */}
            <div className={styles.formGroup}>
                <label htmlFor="productVideo" className={styles.label}>Product Video:</label>
                <input 
                    type="file" 
                    id="productVideo" 
                    name="productVideo" 
                    onChange={handleVideoUpload}
                    className={styles.input}
                    accept="video/mp4, video/webm" // Accept only MP4 and WebM formats
                />
                {uploadError && <p className={styles.errorMessage}>{uploadError}</p>}
                {uploadProgress > 0 && <p>Uploading: {uploadProgress.toFixed(2)}%</p>}

                {/* Video Preview */}
                {videoPreview && (
                    <video width="320" height="240" controls>
                        <source src={videoPreview} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>

        </div>
    );
};

export default ShippingLogistics;
