import React from 'react';
import styles from './Pickups.module.css';

export default function Pickups() {
  return (
    <div className={styles.trackingContainer}>
      <h2>Boameooo PickUp's</h2>
      <p>"To contain Boameooo pick up info".</p>
    </div>
  );
}
