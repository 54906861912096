import React, { useRef, useEffect } from 'react'; // Add useRef and useEffect hooks
import useGoogleAuth from '../../hooks/useGoogleAuth';
import EmailIcon from '@mui/icons-material/Email';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useLogin } from '../../hooks/useLogin';

import styles from './Login.module.css';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, isPending: loginIsPending, error: loginError } = useLogin();
  const { signInWithGoogle, error: googleError, isPending: googleIsPending } = useGoogleAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };


  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <h2 className={styles.title}>Login</h2>
      <label className={styles.label}>
        <span>Email:</span>
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className={styles.input}
        />
      </label>
      <label className={styles.label}>
        <span>Password:</span>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          className={styles.input}
        />
      </label>
      {!loginIsPending && !googleIsPending && (
        <button className={styles.button}>
          <EmailIcon style={{ marginRight: '5px' }} />Log In with Email
        </button>
      )}
      {(loginIsPending || googleIsPending) && (
        <button className={styles.button} disabled>
          Loading...
        </button>
      )}
      {/* {(loginError || googleError) && (
        <p className={styles.error}>{loginError || googleError}</p>
      )} */}
      {(loginError || googleError) && (
        <p className={styles.error}>
          {loginError ? `Login Error: ${loginError}` : `Google Sign-in Error: ${googleError}`}
        </p>
      )}

      {/* <button className={styles.button} onClick={signInWithGoogle}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          alt="Google icon"
          width="20"
          style={{ marginRight: '5px' }}
        />
        Login with Google
      </button> */}
      <button 
        className={styles.button} 
        onClick={(e) => {
          e.preventDefault(); // Prevents form submission
          signInWithGoogle();
        }}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          alt="Google icon"
          width="20"
          style={{ marginRight: '5px' }}
        />
        Login with Google
      </button>





      <div className={styles.signup}>
        <p className={styles.link}>
          {`Don't have an account? `}
          <NavLink to="/signup"><span className={styles.spansignup}>Signup</span> here</NavLink>.
        </p>
      </div>
      
    </form>
  );
}


