import React, { useEffect, useState } from 'react';
import GeneralDashboardLayout from '../../Hoc/GeneralDashboardLayout/GeneralDashboardLayout';
import AdminProfile from '../../Profile/AdminProfile';
import CountrySettings from '../../Accounts/CountrySettings/CountrySettings';
import ExchangeRatesDisplay from '../../../components/ExchangeRates/ExchangeRatesDisplay';
import CheckCalculations from './CheckCalculations';
import MissingCurrencies from './MissingCurrencies';
import { getUserRole } from '../../../firebase/firebaseUtils';
import { useCollection } from '../../../hooks/useCollection';
import { useFirestore } from '../../../hooks/useFirestore';

const AdminDashboard = ({ user }) => {
  return (
    <AdminProfile user={user} />
  );
};

export default AdminDashboard;







// const AdminDashboard = ({ user }) => {
//   const { isPending, error, documents } = useCollection('users');
//   const { deleteDocument } = useFirestore('users');
//   const [role, setRole] = useState(null);

//   useEffect(() => {
//     async function fetchUserRole() {
//       const userRole = await getUserRole(user.uid);
//       setRole(userRole);
//     }

//     fetchUserRole();
//   }, [user]);

//   const handleDelete = (userId) => {
//     deleteDocument(userId);
//   };

//   if (!role) {
//     return <div>Loading...</div>; // Or any other loading indicator
//   }

//   return (
//     <GeneralDashboardLayout user={user}>
//       {/* Conditional rendering based on the user's role */}
//       {role === 'admin' && (
//         <>
//           <AdminProfile user={user} />
//           <CountrySettings />
//           <ExchangeRatesDisplay />
//           <MissingCurrencies />
//           <CheckCalculations />
//           {/* User list table */}
//           <div className="user-list0">
//             <h2>Admin Dashboard</h2>
//             <table>
//               <thead>
//                 <tr>
//                   <th>Name</th>
//                   <th>Email</th>
//                   <th>Role</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {isPending && <div>Loading users...</div>}
//                 {error && <div>{error}</div>}
//                 {documents && documents.map((user) => (
//                   <tr key={user.id} className="user-list-item0">
//                     <td>{user.displayName}</td>
//                     <td>{user.email}</td>
//                     <td>{user.role}</td>
//                     <td>
//                       <button onClick={() => handleDelete(user.id)}>Delete</button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </>
//       )}
//     </GeneralDashboardLayout>
//   );
// };

// export default AdminDashboard;



