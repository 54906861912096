import React, { useState } from 'react';
import { useFirestore }  from '../../hooks/useFirestore';
//import { timestamp } from '../../firebase/config';
import { useAuthContext } from '../../hooks/useAuthContext'

const RoleManagement = () => {
  const [email, setEmail] = useState('');
  const [newRole, setNewRole] = useState('');
  const { user } = useAuthContext()
  const { updateDocument, response } = useFirestore('users')

  const { updateUserRole } = useFirestore('users');

  const handleUpdate = async (e) => {
    e.preventDefault();

    const roleToAdd = {
      email: user.email,
      role: newRole,
      //createdAt: timestamp.fromDate(new Date()),
    }
    await updateDocument(user.id, {
      role: [ roleToAdd],
    })
    if (!response.error) {
      setNewRole('')
    }
    console.log("roleToAdd", roleToAdd)
    // // Get the user document ID from the email
    
  };

  

  return (
    <div>
      <h2>Role Management</h2>
      <form onSubmit={handleUpdate}>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label>
          Role:
          <select value={newRole} onChange={(e) => setNewRole(e.target.value)}>
            <option value="">Select Role</option>
            <option value="individual-customer">Individual Customer</option>
            <option value="business-customer">Business Customer</option>
            <option value="individual-partner">Individual Partner</option>
            <option value="business-partner">Business Partner</option>
            <option value="admin">Administrator</option>
          </select>
        </label>
        {/* <button type="submit">Update Role</button> */}
        <button className="btn">Update Role</button>
      </form>
    </div>
  );
};

export default RoleManagement;


// import React, { useState } from 'react';
// import { projectFirestore } from '../../firebase/config';

// const RoleManagement = () => {
//   const [email, setEmail] = useState('');
//   const [role, setRole] = useState('');

//   const handleUpdate = (e) => {
//     console.log("handleUpdate was call", role, email)
//     e.preventDefault();

//     projectFirestore.collection('users').where('email', '==', email).get()
//       .then(querySnapshot => {
//         querySnapshot.forEach(doc => {
//           projectFirestore.collection('users').doc(doc.id).update({
//             role: role
//           })
//           .then(() => {
//             console.log(`Role for ${email} updated to ${role}`)
//           })
//           .catch(error => {
//             console.error('Error updating document: ', error)
//           });
//         });
//       })
//       .catch(error => {
//         console.error('Error getting documents: ', error)
//       });
//   };

//   return (
//     <div>
//       <h2>Role Management</h2>
//       <form onSubmit={handleUpdate}>
//         <label>
//           Email:
//           <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
//         </label>
//         <label>
//           Role:
//           <select value={role} onChange={(e) => setRole(e.target.value)}>
//             <option value="">Select Role</option>
//             <option value="individual-customer">Individual Customer</option>
//             <option value="business-customer">Business Customer</option>
//             <option value="individual-partner">Individual Partner</option>
//             <option value="business-partner">Business Partner</option>
//             <option value="admin">Administrator</option>
//           </select>
//         </label>
//         {/* <button type="submit">Update Role</button> */}
//         <button className="btn">Update Role</button>
        
//       </form>
//     </div>
//   );
// };

// export default RoleManagement;


// // import React, { useState } from 'react';
// // import { projectFirestore } from '../../firebase/config';
// // import  useFirestore  from '../../hooks/useFirestore';

// // const RoleManagement = () => {
// //   const [email, setEmail] = useState('');
// //   const [role, setRole] = useState('');
// //   const { docs } = useFirestore('users');

// //   const handleUpdate = (e) => {
// //     e.preventDefault();
    
// //     const userToUpdate = docs.find((doc) => doc.email === email);
// //     console.log("handleUpdate is called", userToUpdate); 

// //     if (userToUpdate) {
// //       projectFirestore.collection('users').doc(userToUpdate.id).update({
// //         role: role,
// //       });
// //       console.log("role set handleUpdate", role)
// //       setEmail('');
// //       setRole('');
// //     }
// //   };

// //   return (
// //     <div>
// //       <h2>Role Management</h2>
// //       <form onSubmit={handleUpdate}>
// //         <label>
// //           Email:
// //           <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
// //         </label>
// //         <label>
// //           Role:
// //           <select value={role} onChange={(e) => setRole(e.target.value)}>
// //             <option value="">Select Role</option>
// //             <option value="individual-customer">Individual Customer</option>
// //             <option value="business-customer">Business Customer</option>
// //             <option value="individual-partner">Individual Partner</option>
// //             <option value="business-partner">Business Partner</option>
// //             <option value="admin">Administrator</option>
// //           </select>
// //         </label>
// //         <button type="submit">Update Role</button>
// //       </form>
// //     </div>
// //   );
// // };

// // export default RoleManagement;


// // import React, { useState } from 'react';
// // import { projectFirestore } from '../../firebase/config';

// // const RoleManagement = () => {
// //   const [email, setEmail] = useState('');
// //   const [role, setRole] = useState('');

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     try {
// //       await projectFirestore.collection('users').doc(email).update({
// //         role: role
// //       });

// //       setEmail('');
// //       setRole('');
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   };

// //   return (
// //     <div>
// //       <h2>Role Management</h2>
// //       <form onSubmit={handleSubmit}>
// //         <label>
// //           Email:
// //           <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
// //         </label>
// //         <label>
// //           Role:
// //           <select value={role} onChange={(e) => setRole(e.target.value)}>
// //             <option value="">Select Role</option>
// //             <option value="individual-customer">Individual Customer</option>
// //             <option value="business-customer">Business Customer</option>
// //             <option value="individual-partner">Individual Partner</option>
// //             <option value="business-partner">Business Partner</option>
// //             <option value="admin">Administrator</option>
// //           </select>
// //         </label>
// //         <button type="submit">Update Role</button>
// //       </form>
// //     </div>
// //   );
// // };

// // export default RoleManagement;




// // import React, { useState } from 'react';

// // const RoleManagement = () => {
// //   const [email, setEmail] = useState('');
// //   const [role, setRole] = useState('');

// //   const handleSubmit = (e) => {
// //     e.preventDefault();

// //     // TODO: Update user role in Firestore
// //   };

// //   return (
// //     <div>
// //       <h2>Role Management</h2>
// //       <form onSubmit={handleSubmit}>
// //         <label>
// //           Email:
// //           <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
// //         </label>
// //         <label>
// //           Role:
// //           <select value={role} onChange={(e) => setRole(e.target.value)}>
// //             <option value="">Select Role</option>
// //             <option value="individual-customer">Individual Customer</option>
// //             <option value="business-customer">Business Customer</option>
// //             <option value="individual-partner">Individual Partner</option>
// //             <option value="business-partner">Business Partner</option>
// //             <option value="admin">Administrator</option>
// //           </select>
// //         </label>
// //         <button type="submit">Update Role</button>
// //       </form>
// //     </div>
// //   );
// // };

// // export default RoleManagement;
