import React, { useState, useEffect, useContext } from 'react';
import AccountDeactivation from '../Accounts/AccountDeactivation/AccountDeactivation';
import CustomerSupport from '../CustomerSupport/CustomerSupport';
import ProfilePersonalInformation from '../ProfilePersonalInfo/ProfilePersonalInformation';
import PartnerDataView from '../Dashboard/PartnerDataView';
import { projectFirestore } from '../../firebase/config';
import { AuthContext } from '../../context/AuthContext';

import styles from './BusinessProfile.module.css';

const BusinessProfile = () => {
  const { user } = useContext(AuthContext);
  const [partnerData, setPartnerData] = useState(null);

  useEffect(() => {
    const fetchPartnerData = async () => {
      if (user) {
        console.log('Fetching data for user UID:', user.uid); // Log the UID being used
        try {
          const docRef = projectFirestore.collection('deliveryPartner').doc(user.uid);
          const doc = await docRef.get();
          if (doc.exists) {
            console.log('Document data:', doc.data()); // Log the fetched data
            setPartnerData(doc.data());
          } else {
            console.log('No document found for UID:', user.uid);
          }
        } catch (error) {
          console.error('Error fetching partner data:', error);
        }
      }
    };

    fetchPartnerData();
  }, [user]);

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileSection}>
        <ProfilePersonalInformation />
      </div>
      <div className={styles.profileSection}>
        {partnerData ? <PartnerDataView data={partnerData} /> : <p>Loading partner data...</p>}
      </div>
      <div className={styles.profileSection}>
        <AccountDeactivation />
      </div>
      <div className={styles.profileSection}>
        <CustomerSupport />
      </div>
    </div>
  );
};

export default BusinessProfile;
