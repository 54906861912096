import React, { useState } from 'react';
import { projectFirestore } from '../../../firebase/config';
import styles from './UserSearch.module.css';

const UserSearch = ({ onSelectUser }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = async () => {
        // Query Firestore for users matching the search term (consider limiting fields for privacy)
        const querySnapshot = await projectFirestore.collection('users')
            .where('displayName', '>=', searchTerm)
            .where('displayName', '<=', searchTerm + '\uf8ff')
            .get();

        const results = querySnapshot.docs.map(doc => ({
            id: doc.id,
            displayName: doc.data().displayName // Only get necessary fields
        }));
        setSearchResults(results);
    };

    return (
        <div className={styles.searchContainer}>
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search users"
                className={styles.searchInput}
            />
            <button onClick={handleSearch} className={styles.searchButton}>Search</button>
            <ul className={styles.searchResults}>
                {searchResults.map(user => (
                    <li key={user.id} onClick={() => onSelectUser(user)}>
                        {user.displayName}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserSearch;
