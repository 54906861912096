import React from 'react';
import estate2 from '../../../../../src/assets/estate2.jpg';
import estate3 from '../../../../../src/assets/estate3.jpg';
import estate4 from '../../../../../src/assets/estate4.jpg';
import estate5 from '../../../../../src/assets/estate5.jpg';
import styles from './FeaturedProperties.module.css';

const FeaturedProperties = ({ propertyType }) => {
  return (
    <div className={styles.featuredProperties}>
      <h3>Featured {propertyType} Properties</h3>
      <div className={styles.imageContainer}>
        <img className={styles.propertyImage} src={estate2} alt="Property Placeholder 1" />
        <img className={styles.propertyImage} src={estate3} alt="Property Placeholder 1" />
        <img className={styles.propertyImage} src={estate4} alt="Property Placeholder 1" />
        <img className={styles.propertyImage} src={estate5} alt="Property Placeholder 1" />
      </div>
    </div>
  );
};

export default FeaturedProperties;
