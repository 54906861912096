import React from 'react';
import { useHistory } from 'react-router-dom';

const PropertyPostingOptions = () => {
  const history = useHistory();

  const handleSelfPost = () => {
    history.push('/propertyform');
  };

  const handleAgentPost = () => {
    history.push('/agent-list');
  };

  return (
    <div>
      <h2>Post a Property</h2>
      <button onClick={handleSelfPost}>Post Property Yourself</button>
      <button onClick={handleAgentPost}>Use a Partner Agent</button>
    </div>
  );
};

export default PropertyPostingOptions;
