import React, { useEffect, useState, useContext } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore } from '../../firebase/config';
import { getUserRole } from '../../firebase/firebaseUtils';
import { useHistory } from 'react-router-dom';
import Sidebar from './SideBar/Sidebar';

import GeneralDashboardLayout from '../Hoc/GeneralDashboardLayout/GeneralDashboardLayout';
import IndividualCustomerDashboard from '../Dashboard/IndividualCustomerDashboard/IndividualCustomerDashboard';
import IndividualPartnerDashboard from '../Dashboard/IndividualPartnerDashboard/IndividualPartnerDashboard';
import BusinessDashboard from '../Dashboard/BusinessDashboard/BusinessDashboard';
import AdminDashboard from '../Dashboard/AdminDashboard/AdminDashboard';

import NotificationAfholder from '../Notifications/NotificationAfholder';
import ApprovalRequest from '../Accounts/Afholders/ApprovalRequest';
import ApproveAfholders from '../Accounts/Afholders/ApproveAfholders';
import BecomeAnAfholder from '../Accounts/Afholders/BecomeAnAfholder';
import ApprovalTracking from '../Accounts/Afholders/ApprovalTracking';
import RequestAfholderApproval from '../Accounts/Afholders/RequestAfholderApproval';
import InvitationComponent from '../Accounts/Afholders/InvitationComponent';

import { AddProposalBlank } from '../../pages/Proposals/screens/AddProposalBlank/AddProposalBlank';

import PackagePickup from '../../pages/DeliveryPartners/PackagePickup';
import DeliveryManagement from '../../pages/DeliveryPartners/DeliveryManagement';
import RouteOptimization from '../../pages/DeliveryPartners/RouteOptimization';
import DeliveryHistory from '../../pages/DeliveryPartners/DeliveryHistory';
import EarningsReport from '../../pages/DeliveryPartners/EarningsReport';
import FeedbackRatings from '../../pages/DeliveryPartners/FeedbackRatings';
import OrderStatusManager from '../../pages/DeliveryAdmin/OrderStatusManager';

const Dashboard = () => {
    const { user } = useAuthContext();
    const history = useHistory();
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedFeature, setSelectedFeature] = useState('');
    const [deliveryOnboardingCompleted, setDeliveryOnboardingCompleted] = useState(false);

    


    useEffect(() => {
        async function fetchUserRole() {
            if (user) {
                const userRole = await getUserRole(user.uid);
                setRole(userRole);
            }
            setLoading(false);
        }
        fetchUserRole();
    }, [user]);

    // TO BE DELETED
    // useEffect(() => {
    //     const checkDeliveryOnboarding = async () => {
    //         if (user) {
    //             const deliveryOnboardingRef = projectFirestore.collection('deliveryPartner').where('userId', '==', user.uid);
    //             const docSnapshot = await deliveryOnboardingRef.get();
    //             if (!docSnapshot.empty) {
    //                 setDeliveryOnboardingCompleted(true);
    //             } else if (user.role === 'individual-partner' || user.role === 'business-customer') {
    //                 history.push('/deliveryonboarding');
    //             }
    //         }
    //         setLoading(false);
    //     };

    //     checkDeliveryOnboarding();
    // }, [user, history]);

    // useEffect(() => {
    //     const checkDeliveryOnboarding = async () => {
    //         if (user) {
    //             const userRole = await getUserRole(user.uid);
    //             setRole(userRole);

    //             if ((userRole === 'individual-partner' || userRole === 'business-customer') && !deliveryOnboardingCompleted) {
    //                 // Redirect the user to the "/deliveryonboarding" route
    //                 history.push('/deliveryonboarding');
    //             }
    //         }
    //         setLoading(false);
    //     };

    //     checkDeliveryOnboarding();
    // }, [user, deliveryOnboardingCompleted, history]);

    useEffect(() => {
        const checkDeliveryOnboarding = async () => {
            if (user) {
                const userRole = await getUserRole(user.uid);
                if ((userRole === 'individual-partner' || userRole === 'business-customer') && !deliveryOnboardingCompleted) {
                    history.push('/deliveryonboarding');
                }
            }
            setLoading(false);
        };
    
        checkDeliveryOnboarding();
    }, [user, history, deliveryOnboardingCompleted]);
    


    if (loading) {
        return <div>Loading...</div>;
    }

    // TO BE DELETED
    // if (!deliveryOnboardingCompleted) {
    //     return <div>Redirecting to onboarding...</div>;
    // }

    const handleFeatureSelection = (feature) => {
        setSelectedFeature(feature);
    };

    const renderDashboardContent = () => {
       
        switch (selectedFeature) {
            case 'Notifications':
                return <NotificationAfholder />;
            case 'Approval Requests':
                return <ApprovalRequest />;
            case 'Approve Afholders':
                return <ApproveAfholders />;
            case 'Become an Afholder':
                return <BecomeAnAfholder />;
            case 'Approval Tracking':
                return <ApprovalTracking />;
            case 'Request Afholder Approval':
                return <RequestAfholderApproval />;
            case 'Package Pickup':
                return <PackagePickup />;
            case 'Delivery Management':
                return <DeliveryManagement />;
            case 'Route Optimization':
                return <RouteOptimization />;
            case 'Delivery History':
                return <DeliveryHistory />;
            case 'Earnings Report':
                return <EarningsReport />;
            case 'Feedback and Ratings':
                return <FeedbackRatings />; 
            case 'Order Status Manager':
                return <OrderStatusManager />;
                

            // Add other features as needed
            
            default:
        
            switch (role) {
                case 'individual-customer':
                    return <IndividualCustomerDashboard user={user} />;
                case 'individual-partner':
                    return <IndividualPartnerDashboard user={user} />;
                case 'business-customer':
                case 'business-partner':
                    return <BusinessDashboard user={user} />;
                case 'admin':
                    return <AdminDashboard user={user} />;
                default:
                    return <p>Invalid user role or feature.</p>;
            }
        }
    };

    return (
        <GeneralDashboardLayout>
            <Sidebar userRole={role} onFeatureSelect={handleFeatureSelection} />
            {renderDashboardContent()}
        </GeneralDashboardLayout>

    );
};

export default Dashboard;
