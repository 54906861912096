import { useState, useEffect } from 'react';
import { projectAuth, projectFirestore } from '../firebase/config';
import firebase from 'firebase/app';

const useGoogleAuth = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

  const createUserProfile = async (user, role, additionalData) => {
    // Create user profile in Firestore and set role and other information as needed.
    console.log('Creating user profile for:', user.uid);
    await projectFirestore
      .collection('users')
      .doc(user.uid)
      .set({
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        role: role,
        ...additionalData // Spread operator to include additional data
      });
      console.log('User profile created');
  };

  const signInWithGoogle = async (role, additionalData) => {
    setError(null);
    setIsPending(true);

    try {
      console.log('Attempting Google sign-in');
      const res = await projectAuth.signInWithPopup(googleAuthProvider);
      console.log('OAuth Response:', res); // Log the entire response object
      console.log('Google sign-in successful:', res);
      const user = res.user;

      // Check if the user exists in Firestore
      console.log('Checking if user exists in Firestore');
      const userDoc = await projectFirestore
        .collection('users')
        .doc(user.uid)
        .get();

      // Save user data to Firestore if the user doesn't exist or if they're a new user
      if (!userDoc.exists || res.additionalUserInfo.isNewUser) {
        console.log('New user or user does not exist, creating profile');
        await createUserProfile(user, role, additionalData);
      }

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
        console.log('Google authentication process completed successfully');
      }
    // } catch (err) {
    //   if (!isCancelled) {
    //     setError(err.message);
    //     setIsPending(false);
    //   }
    // }
  // } catch (err) {
  //   setError(err.message); // Set error state
  // } finally {
  //   setIsPending(false); // Reset pending state
  // }
} catch (err) {
  // Handle the error appropriately
  console.error('Error during Google authentication:', err);
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
}
  };

  useEffect(() => {
    return () => {
      console.log('Cleaning up Google auth');
      setIsCancelled(true);
  };

  }, []);

  return { signInWithGoogle, isPending, error };
};

export default useGoogleAuth;





