import React, { useState, useContext } from "react";
import { projectFirestore, timestamp } from '../../../../firebase/config';
import { projectStorage } from "../../../../firebase/config";
import ProposalLayout from '../../../../components/Hoc/ProposalLayout/ProposalLayout';
import ProposalHeader from "../../components/ProposalHeader/ProposalHeader";
import ProposalFooter from "../../components/ProposalFooter/ProposalFooter";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  // 

import styles from './AddProposalBlank.module.css';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],  // toggled buttons

  ['blockquote', 'code-block'],  // blocks

  [{ 'header': 1 }, { 'header': 2 }, { 'header': [3, 4, 5, 6, false] }], // headers
  
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],  // lists
  
  [{ 'script': 'sub'}, { 'script': 'super' }],  // superscript/subscript
  
  [{ 'indent': '-1'}, { 'indent': '+1' }],  // outdent/indent
  
  [{ 'direction': 'rtl' }],  // text direction
  
  [{ 'size': ['small', false, 'large', 'huge'] }],  // font size
  
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // custom header levels
  
  [{ 'color': [] }, { 'background': [] }],  // text/background color
  
  [{ 'font': [] }],  // font family
  
  [{ 'align': [] }],  // text alignment
  
  ['clean'],  // remove formatting
  
  ['link', 'image', 'video']  // link, image and video
];

export const AddProposalBlank = () => {
  // Retrieve user information from context
  const { user } = useAuthContext();
  const [proposal, setProposal] = useState({
    title: '',
    country: '',
    region: '',
    city: '',
    zipCode: '',
    description: '',
    category: '',
    attachment: [],
    author: user ? user.displayName : 'Anonymous', // Set the author's name
    datePosted: new Date().toLocaleDateString("en-GB") // Set the current date
  });

  // Update handleChange to work with ReactQuill
  const handleDescriptionChange = (value) => {
      setProposal({ ...proposal, description: value });
  };
      

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProposal({ ...proposal, [name]: value });
  };

  const handleAttachment = async (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    // Process each file and upload it to storage
    const uploadPromises = files.map(file => {
      const storageRef = projectStorage.ref(`proposalFiles/${file.name}`);
      return storageRef.put(file).then(snapshot => snapshot.ref.getDownloadURL());
    });


    // Wait for all files to be uploaded and get their download URLs
    try {
      const downloadURLs = await Promise.all(uploadPromises);
      console.log('All files uploaded', downloadURLs);
      // Update the proposal state with new attachments
      setProposal(prevProposal => {
        // Ensure the attachments in the previous state is an array
        const updatedAttachments = Array.isArray(prevProposal.attachments)
          ? [...prevProposal.attachments, ...downloadURLs]
          : [...downloadURLs];
  
        return {
          ...prevProposal,
          attachments: updatedAttachments,
        };
      });
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  

  // Added async to the handleSubmit function to use await for Firestore operations
  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO: Handle the file upload if necessary before saving the proposal
    try {
      const newProposal = {
        ...proposal,
        // No need to set author and datePosted here as it is already part of the state
      };

      // Changed: Save the proposal data to Firestore with the current timestamp
      const docRef = await projectFirestore.collection('proposals').add(newProposal);
        // createdAt: timestamp() 
    
      console.log('Document written with ID: ', docRef.id);
      setProposal({
         // Reset all fields, including author and datePosted
         title: '',
         country: '',
         region: '',
         city: '',
         zipCode: '',
         description: '',
         category: '',
         attachment: [],
         author: user ? user.displayName : 'Anonymous',
         datePosted: new Date().toLocaleDateString("en-GB")
      });
    } catch (error) {
      console.error("Error add document: ", error);
    }
  };

  return (
    <ProposalLayout>
    <div className={styles.addProposalBlank}>
      {/* <ProposalHeader /> */}
      <div className={styles.container}>
      <h2 className={styles.formTitle}>ADD NEW PROPOSAL</h2>
        <form className={styles.addProposalBlankForm} onSubmit={handleSubmit}>
        

        {/* Full width input for Proposal Title */}
        <div className={styles.topRow}> {/* This is a new wrapper div */}
          <div className={styles.fullWidth}>
            <label htmlFor="title" className={styles.label}>PROPOSAL TITLE</label>
            <input 
              type="text" 
              id="title" 
              name="title" 
              value={proposal.title} 
              onChange={handleChange} 
              className={styles.input} 
              placeholder="Enter Proposal Title"
            />
        </div>
        <div className={styles.formRowItem}>
        <label htmlFor="category" className={styles.label}>CATEGORY</label>
        <select 
          id="category" 
          name="category" 
          value={proposal.category} 
          onChange={handleChange} 
          className={styles.select}
        >
          <option value="">Select Category</option>
          <option value="Education and Research">Education and Research</option>
          <option value="Health and Wellness">Health and Wellness</option>
          <option value="Technology and Innovation">Technology and Innovation</option>
          <option value="Environment and Sustainability">Environment and Sustainability</option>
          <option value="Community Development">Community Development</option>
          <option value="Arts and Culture">Arts and Culture</option>
          <option value="Business and Economics">Business and Economics</option>
          <option value="Social Issues and Advocacy">Social Issues and Advocacy</option>
          <option value="Infrastructure and Urban Planning">Infrastructure and Urban Planning</option>
          <option value="Science and Space Exploration">Science and Space Exploration</option>
          {/* ... add other categories */}
        </select>
      </div>
      </div> {/* Close the new wra

        {/* Row of inputs for Country, Region, City/Town, and ZIP Code */}
        <div className={styles.formRow}>
        
          <div className={styles.formRowItem}>
            <label htmlFor="country" className={styles.label}>COUNTRY</label>
            <select 
                id="country" 
                name="country" 
                value={proposal.country} 
                onChange={handleChange} 
                className={`${styles.select} ${styles.selectArrow}`} 
              >
                {/* Options should be generated based on available countries */}
                <option value="">Select Country</option>
                <option value="Ghana">Ghana</option>
                <option value="Togo">Togo</option>
                {/* Repeat for other countries */}
              </select>
          </div>
          <div className={styles.formRowItem}>
            <label htmlFor="region" className={styles.label}>REGION</label>
            <select 
                type="text" 
                id="region" 
                name="region" 
                value={proposal.region} 
                onChange={handleChange} 
                className={`${styles.select} ${styles.selectArrow}`} 
              >
                {/* Options should be generated based on available countries */}
                <option value="">Region</option>
                <option value="Greater Accra">Greater Accra</option>
                <option value="Eastern">Eastern</option>
                {/* Repeat for other countries */}
              </select>
            
          </div>
          <div className={styles.formRowItem}>
            <label htmlFor="city" className={styles.label}>CITY / TOWN / VILLAGE</label>
            <input 
                type="text" 
                id="city" 
                name="city" 
                value={proposal.city} 
                onChange={handleChange} 
                className={styles.input} 
                placeholder="Enter City or Town"
            />
          </div>
          <div className={styles.formRowItem}>
            <label htmlFor="zipcode" className={styles.label}>ZIP CODE</label>
            <input 
                type="text" 
                id="zipCode" 
                name="zipCode" 
                value={proposal.zipCode} 
                onChange={handleChange} 
                className={styles.input} 
                placeholder="Enter ZIP Code"
            />
          </div>
        </div>
        
        {/* <div className={styles.fullWidth}>
          <label htmlFor="description" className={styles.label}>DESCRIPTION</label>
          <textarea 
          id="description" 
          name="description" 
          value={proposal.description} 
          onChange={handleChange} 
          className={styles.textarea}
          placeholder="Enter the proposal description"
        ></textarea>
        </div> */}
        <div className={styles.fullWidth}>
          <label htmlFor="description" className={styles.label}>DESCRIPTION</label>
          <ReactQuill 
            value={proposal.description}
            onChange={handleDescriptionChange}
            modules={{ toolbar: toolbarOptions }} // Use toolbarOptions for the toolbar
          />
        </div>

        {/* If you have file attachment feature */}
        <label htmlFor="attachment" className={styles.label}>ATTACHMENT</label>
        <input 
          type="file" 
          id="attachment" 
          name="attachment" 
          onChange={handleAttachment} 
          className={styles.input}
          multiple // Add this to allow selecting multiple files
        />
        <div className={styles.submitButtonContainer}>
          <button type="submit" className={styles.submitButton}>Add Proposal</button>
      </div>

      </form>
      </div>
      <ProposalFooter />
    </div>
    </ProposalLayout>
  );
};
