import React from 'react';
import styles from './ProposalFooter.module.css';

const ProposalFooter = () => {
  
  return (
    <div className={styles.footer}>
      <div className={styles.rectangle2}></div>
      <p className={styles.p}>© 2022, Boameooo Proposals. All rights reserved</p>
      <div className={styles.homePopular}>
        Home Popular Proposals Privacy Policy Term Of Use
      </div>
      <div className={styles.footerMain}>
        <div className={styles.group9}>
          <div className={styles.textWrapper9}>Boameooo</div>
          <p className={styles.loremIpsumIs}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.
          </p>
          <img className={styles.frame2} alt="Social Media Icons" src="https://c.animaapp.com/nn9493Tz/img/frame-5.svg" />
        </div>
        <div className={styles.group10}>
          <div className={styles.textWrapper10}>Our Services</div>
          <div className={styles.textWrapper11}>
            Pricing Tracking Report a Bug Your Order
          </div>
        </div>
        <div className={styles.group11}>
          <div className={styles.textWrapper10}>Quick Links</div>
          <p className={styles.textWrapper11}>
            About Us Services My Account Terms & Conditions
          </p>
        </div>
        <div className={styles.group12}>
          <div className={styles.textWrapper10}>Get In Touch</div>
          <p className={styles.addressNmai}>
            <span className={styles.textWrapper12}>
              Address
              <br />
            </span>
            <span className={styles.textWrapper13}>
              25 Nmai Dzorn Adjiringanor Road Adjiringanor,
              <br />
              GD-166-7397 Accra, Ghana
            </span>
          </p>
          <p className={styles.phone}>
            <span className={styles.textWrapper12}>
              Phone
              <br />
            </span>
            <span className={styles.textWrapper13}>+233 (0) 59 924 7478</span>
          </p>
          <p className={styles.emailInfoBoameoo}>
            <span className={styles.textWrapper12}>
              Email
              <br />
            </span>
            <span className={styles.textWrapper13}>info@boameoo.com</span>
          </p>
        </div>
      </div>
      <div className={styles.footerSmall}>
        <div className={styles.overlapGroup2}>
          <p className={styles.textWrapper14}>© 2015 - 2021 Boameooo | All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default ProposalFooter;
