
import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './PackagePickup.module.css';

const PackagePickup = () => {
    const [packages, setPackages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchPackages = async () => {
        setIsLoading(true);
        try {
            const snapshot = await projectFirestore.collection('deliveryorders')
                                    .where('orderStatus', '==', 'pending')
                                    .get();
            const packagesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPackages(packagesData);
        } catch (error) {
            console.error('Error fetching packages:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handlePickup = async (packageId) => {
        try {
            await projectFirestore.collection('deliveryorders').doc(packageId).update({
                orderStatus: 'in_transit'
            });
            fetchPackages(); // Refresh the list after updating
        } catch (error) {
            console.error('Error updating package order status:', error);
        }
    };

    const formatAddress = (address) => {
        return address ? `${address.street}, ${address.city}` : 'Not provided';
    };

    if (isLoading) {
        return <div>Loading packages...</div>;
    }

    return (
        <div className={styles.packagePickupContainer}>
            <h2>Available Packages for Pickup</h2>
            <ul className={styles.packageList}>
                {packages.map(pkg => (
                    <li key={pkg.id} className={styles.packageItem}>
                        <div>
                            <p><strong>Package ID:</strong> {pkg.id}</p>
                            <p><strong>Pickup Address:</strong> {formatAddress(pkg.selectedPickupAddress)}</p>
                            <p><strong>Delivery Address:</strong> {formatAddress(pkg.selectedDeliveryAddress)}</p>
                            <p><strong>Package Type:</strong> {pkg.packageDetails ? pkg.packageDetails.selectedCategory : 'Not provided'}</p>
                            <p><strong>Weight:</strong> {pkg.packageDetails ? pkg.packageDetails.weight + ' kg' : 'Not provided'}</p>
                            <p><strong>Order Status:</strong> {pkg.orderStatus ? pkg.orderStatus : 'Not provided'}</p>
                            {/* Add other package details as needed */}
                        </div>
                        <button onClick={() => handlePickup(pkg.id)}>Pick Up</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PackagePickup;
