import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DeliveryLayout from '../../components/Hoc/DeliveryLayout/DeliveryLayout';
// import sendingImage from '../../assets/estate1.jpg';
import sendingImage from '../../assets/dispatch.png';
import receivalsImage from '../../assets/receipt2.png';
import ordersImage from '../../assets/myorders1.png';
import helpImage from '../../assets/help.png';

import styles from './DeliveryHome.module.css';

const DeliveryHome = () => {
    const [orderId, setOrderId] = useState('');
    const history = useHistory();

    const handleTrack = (e) => {
        e.preventDefault();
        if (orderId) {
            history.push(`/tracking/${orderId}`);
        } else {
            // Handle case where orderId is empty or invalid
            console.log("Please enter a valid Order ID");
        }
    };
    

    return (
        <DeliveryLayout>
            <div className={styles.container}>
                <form onSubmit={handleTrack}>
                    <h3 className={styles.tableText}>Track Your Orders</h3>
                    <div className={styles.inputInnerGroup}>
                    <input 
                        className={`${styles.input} js--tracking-bar--input`} 
                        id="tracking-input" 
                        name="tracking-id" 
                        type="text" 
                        placeholder="Enter your order ID" // Updated placeholder text
                        required 
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                    />
                    <button 
                        className={`${styles.button} js--tracking-bar--button`} 
                        type="submit">
                        Track
                    </button>
                    </div>
                </form>
                
                <div className={styles.cardsContainer}>
                    {/* Other card components */}
                    <div className={styles.card}>
                        <img className={styles.cardImage} src={sendingImage} alt="Sending" />
                        <div className={styles.cardTextContainer}>
                            <h3 className={`${styles.cardText} ${styles.cardTitle}`}>Dispatch</h3>
                            <p className={styles.cardText}>Send a shipment</p>
                            <Link to={`/sendings`}>Sendings</Link>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <img className={styles.cardImage} src={receivalsImage} alt="Receivals" />
                        <div className={styles.cardTextContainer}>
                            <h3 className={`${styles.cardText} ${styles.cardTitle}`}>Receipt</h3>
                            <p className={styles.cardText}>Receive your shipment</p>
                            <Link to={`/receivals`}>Receivals</Link>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <img className={styles.cardImage} src={ordersImage} alt="My Orders" />
                        <div className={styles.cardTextContainer}>
                            <h3 className={`${styles.cardText} ${styles.cardTitle}`}>My Orders</h3>
                            <p className={styles.cardText}>Your Orders</p>
                            <Link to={`/myorders`}>Go to orders</Link>
                        </div>
                    </div>

                    <div className={styles.card}>
                        <img className={styles.cardImage} src={helpImage} alt="Help" />
                        <div className={styles.cardTextContainer}>
                            <h3 className={`${styles.cardText} ${styles.cardTitle}`}>Help</h3>
                            <p className={styles.cardText}>Help and Assistance</p>
                            <Link to={`/deliveryhelp`}>Let's Help You</Link>
                        </div>
                    </div>
                </div>
            </div>
        </DeliveryLayout>
    );
};

export default DeliveryHome;
