import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './FeedbackRatings.module.css'; // Ensure you have the corresponding CSS file

const FeedbackRatings = () => {
    const [feedbackList, setFeedbackList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchFeedback = async () => {
            setIsLoading(true);
            try {
                const snapshot = await projectFirestore.collection('feedback').orderBy('date', 'desc').get();
                const feedbackData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setFeedbackList(feedbackData);
            } catch (error) {
                console.error('Error fetching feedback:', error);
            }
            setIsLoading(false);
        };

        fetchFeedback();
    }, []);

    if (isLoading) {
        return <div>Loading feedback...</div>;
    }

    return (
        <div className={styles.feedbackContainer}>
            <h2>Feedback and Ratings</h2>
            <p>Ratings and Feedback Reports of each Delivery partner will be listed here</p>
            <ul className={styles.feedbackList}>
                {feedbackList.map(feedback => (
                    <li key={feedback.id} className={styles.feedbackItem}>
                        <p><strong>Date:</strong> {feedback.date}</p>
                        <p><strong>Rating:</strong> {feedback.rating} / 5</p>
                        <p><strong>Comment:</strong> {feedback.comment}</p>
                        {/* Add other feedback details as needed */}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FeedbackRatings;
