import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore, projectStorage, timestamp  } from '../../firebase/config';
import DeliveryLayout from '../../components/Hoc/DeliveryLayout/DeliveryLayout';

import styles from './DeliveryPartnerOnboarding.module.css'; // Importing the CSS module

export default function DeliveryPartnerOnboarding() {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [partnerType, setPartnerType] = useState('individual'); // New state for partner type
  // const [vehicleInfo, setVehicleInfo] = useState('');
  const [experience, setExperience] = useState('');
  const [availability, setAvailability] = useState('');
  // Additional states for new fields
  const [personalIdentification, setPersonalIdentification] = useState(null);
  const [personalIdentificationPreview, setPersonalIdentificationPreview] = useState('');
  const [backgroundCheckReport, setBackgroundCheckReport] = useState(null);
  const [backgroundCheckReportPreview, setBackgroundCheckReportPreview] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState('');

  // const [personalId, setPersonalId] = useState('');
  //const [businessInfo, setBusinessInfo] = useState('');
  const [insuranceInfo, setInsuranceInfo] = useState(''); // 'yes' or 'no'
  const [employees, setEmployees] = useState([{ name: '', email: '', phone: '' }]);
  const [paymentDetails, setPaymentDetails] = useState({
    bankAccount: '',
    mobileAccount: '',
    preferredPaymentMethod: '' // 'bank' or 'mobile'
  });
  
  const [taxInformation, setTaxInformation] = useState('');
  // const [eligibilityProof, setEligibilityProof] = useState(null);
  const [businessDocuments, setBusinessDocuments] = useState({
    hasRegistrationCertificate: '', // 'yes' or 'no'
    hasTaxIdentificationNumber: '', // 'yes' or 'no'
  });
  
  // const [employeeNumber, setEmployeeNumber] = useState('');
  const [operationalInfo, setOperationalInfo] = useState('');
  const [contactInfo, setContactInfo] = useState({
    email: user.email || '',  // user.email should be the email from the user's profile
    phone: user.phoneNumber || ''  // user.phone should be the phone number from the user's profile
  });

  const [vehicleTypes, setVehicleTypes] = useState({
    Bicycle: false,
    Tricycle: false,
    Car: false,
    Van: false,
    Bus: false,
    MiniTruck: false,
    HeavyTruck: false
  });

  const getSelectedVehicleTypes = () => {
    return Object.entries(vehicleTypes)
                 .filter(([type, isSelected]) => isSelected)
                 .map(([type]) => type);
  };
  


  const isAnyVehicleTypeSelected = () => {
    return Object.values(vehicleTypes).some(value => value);
  };
  

const handleSubmit = async (e) => {
  e.preventDefault();

  // console.log("Partner Type:", partnerType);
  // console.log("Vehicle Types:", vehicleTypes);
  // console.log("Experience:", experience);
  // console.log("Availability:", availability);
  // console.log("Personal Identification:", personalIdentification);

  if (partnerType === 'business' && (!insuranceInfo)) {
    alert('Please fill in all required fields for Business Partner Type');
    return;
  }

  try {
      // Upload files and get their URLs
      const personalIdUrl = personalIdentification ? await uploadFile(personalIdentification, 'personal_identification/' + personalIdentification.name) : null;
      const backgroundCheckUrl = backgroundCheckReport ? await uploadFile(backgroundCheckReport, 'background_checks/' + backgroundCheckReport.name) : null;
      const profilePhotoUrl = profilePhoto ? await uploadFile(profilePhoto, 'profile_photos/' + profilePhoto.name) : null;

      const selectedVehicleTypes = getSelectedVehicleTypes();

      // Construct formData
      let formData = {
          userId: user.uid,
          createdAt: timestamp(),
          partnerType,
          vehicleTypes: selectedVehicleTypes,
          ...(partnerType === 'individual' && {
              experience,
              availability,
              personalIdentificationUrl: personalIdUrl,
              backgroundCheckReportUrl: backgroundCheckUrl,
              profilePhotoUrl: profilePhotoUrl,
              contactInfo,
              taxInformation,
              paymentDetails,
          }),
          ...(partnerType === 'business' && {
              insuranceInfo,
              employees: employees.filter(emp => emp.name || emp.email || emp.phone),
              hasRegistrationCertificate: businessDocuments.hasRegistrationCertificate,
              hasTaxIdentificationNumber: businessDocuments.hasTaxIdentificationNumber,
              operationalInfo,
              contactInfo,
              taxInformation,
              paymentDetails,
          }),
      };

      // Reference to the document with the user's UID
      const docRef = projectFirestore.collection('deliveryPartner').doc(user.uid);

      // Check if a document with the user's UID already exists
      const doc = await docRef.get();
      if (doc.exists) {
          // Update the existing document
          await docRef.update(formData);
      } else {
          // Create a new document with the user's UID
          await docRef.set(formData);
      }

      history.push('/dashboard');
  } catch (error) {
      console.error('Error saving delivery partner details:', error);
      alert('An error occurred while saving your information. Please try again.');
  }
};


  const handleVehicleTypeChange = (vehicleType) => {
    setVehicleTypes(prevTypes => ({
      ...prevTypes,
      [vehicleType]: !prevTypes[vehicleType]
    }));
  };
  
  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    setContactInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleBusinessDocumentsChange = (e) => {
    const { name, value } = e.target;
    setBusinessDocuments(prevDocs => ({
      ...prevDocs,
      [name]: value
    }));
  };
  

  const handleFileChange = (e, setFile, setPreview) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleInsuranceChange = (e) => {
    setInsuranceInfo(e.target.value);
  };

  const handleEmployeeChange = (index, e) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index][e.target.name] = e.target.value;
    setEmployees(updatedEmployees);
  };
  
  const addEmployee = () => {
    setEmployees([...employees, { name: '', email: '', phone: '' }]);
  };
  
  const removeEmployee = (index) => {
    const updatedEmployees = [...employees];
    updatedEmployees.splice(index, 1);
    setEmployees(updatedEmployees);
  };
  
  
  const uploadFile = async (file, path) => {
    if (!file) return;
  
    const storageRef = projectStorage.ref(path);
    const collectionRef = projectFirestore.collection('files');
    
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    
    const createdAt = timestamp();
    await collectionRef.add({ url, createdAt });
    return url;
  };
  



return (
  <DeliveryLayout>
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.title}>Delivery Partner Onboarding</h2>
        <p className={styles.description}>
          The process of onboarding a partner to become a Delivery partner for Boameooo. Complete the form processing and we will provide you with feedback in a few days.
        </p>

        <label className={styles.label}>
          Partner Type:
          <select
            className={styles.input}
            value={partnerType}
            onChange={(e) => setPartnerType(e.target.value)}
            required
          >
            <option value="individual">Individual</option>
            <option value="business">Business</option>
          </select>
        </label>

        {partnerType === 'individual' && (
          <>
          <label className={styles.label}>
            Personal Identification:
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setPersonalIdentification, setPersonalIdentificationPreview)}
            />
            {personalIdentificationPreview && <img src={personalIdentificationPreview} alt="Preview" />}
          </label>

          <label className={styles.label}>
            Police Background Check Report:
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setBackgroundCheckReport, setBackgroundCheckReportPreview)}
            />
            {backgroundCheckReportPreview && <img src={backgroundCheckReportPreview} alt="Preview" />}
          </label>
          <label className={styles.label}>
            Profile Photo:
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setProfilePhoto, setProfilePhotoPreview)}
            />
            {profilePhotoPreview && <img src={profilePhotoPreview} alt="Preview" />}
          </label>

            <label className={styles.label}>
              Experience:
              <textarea
                className={styles.input}
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                required
              />
            </label>

            <label className={styles.label}>
              Availability:
              <textarea
                className={styles.input}
                value={availability}
                onChange={(e) => setAvailability(e.target.value)}
                required
              />
            </label>

            {/* Additional Individual Fields Here */}
          </>
        )}

        {partnerType === 'business' && (
  <>
    <div className={styles.formGroup}>
      <p>Do you have a Valid Business Registration Certificate?</p>
      <label>
        <input
          type="radio"
          name="hasRegistrationCertificate"
          value="yes"
          checked={businessDocuments.hasRegistrationCertificate === 'yes'}
          onChange={handleBusinessDocumentsChange}
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          name="hasRegistrationCertificate"
          value="no"
          checked={businessDocuments.hasRegistrationCertificate === 'no'}
          onChange={handleBusinessDocumentsChange}
        />
        No
      </label>
    </div>

    <div className={styles.formGroup}>
      <p>Do you have a Valid Tax Identification Number for the business?</p>
      <label>
        <input
          type="radio"
          name="hasTaxIdentificationNumber"
          value="yes"
          checked={businessDocuments.hasTaxIdentificationNumber === 'yes'}
          onChange={handleBusinessDocumentsChange}
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          name="hasTaxIdentificationNumber"
          value="no"
          checked={businessDocuments.hasTaxIdentificationNumber === 'no'}
          onChange={handleBusinessDocumentsChange}
        />
        No
      </label>
    </div>

    <div className={styles.formGroup}>
      <p>Do you have valid insurance?</p>
      <label>
        <input
          type="radio"
          name="insuranceInfo"
          value="yes"
          checked={insuranceInfo === 'yes'}
          onChange={handleInsuranceChange}
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          name="insuranceInfo"
          value="no"
          checked={insuranceInfo === 'no'}
          onChange={handleInsuranceChange}
        />
        No
      </label>
    </div>

    {employees.map((employee, index) => (
  <div key={index}>
    <input
      type="text"
      name="name"
      placeholder="Employee Name"
      value={employee.name}
      onChange={(e) => handleEmployeeChange(index, e)}
    />
    <input
      type="email"
      name="email"
      placeholder="Employee Email"
      value={employee.email}
      onChange={(e) => handleEmployeeChange(index, e)}
    />
    <input
      type="tel"
      name="phone"
      placeholder="Employee Phone"
      value={employee.phoneNumber}
      onChange={(e) => handleEmployeeChange(index, e)}
    />
    {index > 0 && (
      <button type="button" onClick={() => removeEmployee(index)}>Remove</button>
    )}
      </div>
    ))}
    <button type="button" onClick={addEmployee}>Add Another Employee</button>

    <label className={styles.label}>
      Operational Information:
      <textarea
        name="operationalInfo"
        className={styles.textarea}
        value={operationalInfo}
        onChange={(e) => setOperationalInfo(e.target.value)}
        placeholder="Types of goods/services and geographic coverage area"
      />
    </label>

  </>
)}


        <div className={styles.dropdown}>
          <button className={styles.dropbtn}>Select the Vehicle types you have</button>
          <div className={styles.dropdownContent}>
            {Object.keys(vehicleTypes).map((type) => (
              <label key={type}>
                <input
                  type="checkbox"
                  checked={vehicleTypes[type]}
                  onChange={() => handleVehicleTypeChange(type)}
                />
                {type}
              </label>
            ))}
          </div>
        </div>

        {/* Common Fields for Both Individual and Business */}
        {/* Common Field: Contact Information */}
        <label className={styles.label}>
          Email:
          <input
            type="email"
            name="email"
            className={styles.input}
            value={contactInfo.email}
            onChange={handleContactInfoChange}
            required
          />
        </label>

        <label className={styles.label}>
          Phone Number:
          <input
            type="tel"
            name="phone"
            className={styles.input}
            value={contactInfo.phoneNumber}
            onChange={handleContactInfoChange}
            required
          />
        </label>

        <label className={styles.label}>
          Tax Information ID:
          <input
            type="text"
            name="taxInformation"
            className={styles.input}
            value={taxInformation}
            onChange={(e) => setTaxInformation(e.target.value)}
            placeholder="Enter tax information"
          />
        </label>

       
        <h3>Payment Details</h3>
        <p>Please fill in both and check mark your preferred payment option:</p>

        <label className={styles.label}>
          Bank Account Details:
          <input
            type="text"
            name="bankAccount"
            className={styles.input}
            value={paymentDetails.bankAccount}
            onChange={handlePaymentDetailsChange}
          />
        </label>

        <label className={styles.label}>
          Mobile Account Number:
          <input
            type="tel"
            name="mobileAccount"
            className={styles.input}
            value={paymentDetails.mobileAccount}
            onChange={handlePaymentDetailsChange}
          />
        </label>

        <div className={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="preferredPaymentMethod"
              value="bank"
              checked={paymentDetails.preferredPaymentMethod === 'bank'}
              onChange={handlePaymentDetailsChange}
            />
            Bank Account (Preferred)
          </label>
          <label>
            <input
              type="radio"
              name="preferredPaymentMethod"
              value="mobile"
              checked={paymentDetails.preferredPaymentMethod === 'mobile'}
              onChange={handlePaymentDetailsChange}
            />
            Mobile Account (Preferred)
          </label>
        </div>

        <button type="submit" className={styles.button}>Complete Onboarding</button>
      </form>
    </div>
  </DeliveryLayout>
);

}




