import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../../context/AuthContext';
import { projectFirestore } from '../../../firebase/config';
import { globalCountries } from '../../../constants/countries';
import styles from './PostJobForm.module.css';

const PostJobForm = () => {
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const [jobTypes, setJobTypes] = useState([]);
    const [jobCategory, setJobCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(user?.country || '');
    const [geoRegion, setGeoRegion] = useState('Africa'); // Adjust as needed
    const [location, setLocation] = useState('');
    const [salaryRange, setSalaryRange] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    // Additional states for dynamic fields
    const [duration, setDuration] = useState('');
    const [skillsRequired, setSkillsRequired] = useState('');
    const [experienceLevel, setExperienceLevel] = useState('');
    const [serviceRequirements, setServiceRequirements] = useState('');
    const [toolsOrEquipment, setToolsOrEquipment] = useState('');
    const [workHours, setWorkHours] = useState('');

    useEffect(() => {
        // Fetch job types from Firestore
        const fetchJobTypes = async () => {
            try {
                const snapshot = await projectFirestore.collection('jobtypes').get();
                const types = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setJobTypes(types);
            } catch (error) {
                console.error("Error fetching job types: ", error);
            }
        };

        fetchJobTypes();
    }, []);

    // Function to determine if the current subCategory is Professional-Corporate
    const isProfessionalCorporate = () => {
        const professionalCategories = ["Freelancer", "Consultant"]; // Add more as needed
        return professionalCategories.includes(subCategory);
    };

    // Function to determine if the current subCategory is Personal-Local
    const isPersonalLocal = () => {
        const personalCategories = ["Handyman Services", "Agricultural Services"]; // Add more as needed
        return personalCategories.includes(subCategory);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        if (!title.trim() || !description.trim() || !jobCategory || !subCategory || !selectedCountry || !location || !salaryRange) {
            setError("All fields are required.");
            return;
        }

        // Job post object
        const jobPost = {
            title,
            description,
            jobCategory,
            subCategory,
            country: selectedCountry,
            location,
            salaryRange,
            userId: user?.uid, // Check if user is defined
            postedOn: new Date()
        };

        try {
            await projectFirestore.collection('jobPosts').add(jobPost);
            history.push('/post-job'); // Redirect after posting
        } catch (error) {
            console.error("Error posting job: ", error);
            setError("Failed to post the job. Please try again.");
        }
    };
     // Additional dynamic fields based on subCategory
     const renderAdditionalFields = () => {
        if (isProfessionalCorporate()) {
            return (
                <>
                    <div className={styles.formGroup}>
                        <input type="text" placeholder="Duration (e.g., 3 months)" value={duration} onChange={(e) => setDuration(e.target.value)} className={styles.input} />
                    </div>
                    <div className={styles.formGroup}>
                        <input type="text" placeholder="Skills Required" value={skillsRequired} onChange={(e) => setSkillsRequired(e.target.value)} className={styles.input} />
                    </div>
                    <div className={styles.formGroup}>
                        <input type="text" placeholder="Experience Level" value={experienceLevel} onChange={(e) => setExperienceLevel(e.target.value)} className={styles.input} />
                    </div>
                </>
            );
        } else if (isPersonalLocal()) {
            return (
                <>
                    <div className={styles.formGroup}>
                        <textarea placeholder="Service Requirements" value={serviceRequirements} onChange={(e) => setServiceRequirements(e.target.value)} className={styles.textarea}></textarea>
                    </div>
                    <div className={styles.formGroup}>
                        <input type="text" placeholder="Tools or Equipment" value={toolsOrEquipment} onChange={(e) => setToolsOrEquipment(e.target.value)} className={styles.input} />
                    </div>
                    <div className={styles.formGroup}>
                        <input type="text" placeholder="Work Hours" value={workHours} onChange={(e) => setWorkHours(e.target.value)} className={styles.input} />
                    </div>
                </>
            );
        }
    };

    const filteredCountries = globalCountries.filter(country => country.GeoRegion === geoRegion);

    // Filter sub-categories based on selected job category
    const filteredSubCategories = jobTypes.filter(type => type.category === jobCategory);

    return (
        <div className={styles.postJobFormContainer}>
            <form className={styles.jobPostForm} onSubmit={handleSubmit}>
                {/* Country Dropdown */}
                <div className={styles.formGroup}>
                    <label htmlFor="country" className={styles.label}>Country:</label>
                    <select 
                        id="country" 
                        value={selectedCountry} 
                        onChange={(e) => setSelectedCountry(e.target.value)} 
                        className={styles.select}
                    >
                        <option value="">Select a Country</option>
                        {filteredCountries.map(country => (
                            <option key={country.code} value={country.code}>{country.name}</option>
                        ))}
                    </select>
                </div>

                {/* Job Category Dropdown */}
                <div className={styles.formGroup}>
                    <label htmlFor="jobCategory" className={styles.label}>Job Category:</label>
                    <select 
                        id="jobCategory" 
                        value={jobCategory} 
                        onChange={(e) => setJobCategory(e.target.value)} 
                        className={styles.select}
                    >
                        <option value="">Select a Category</option>
                        <option value="Professional-Corporate">Professional and Corporate Jobs</option>
                        <option value="Personal-Local">Personal and Local Jobs</option>
                    </select>
                </div>

                {/* Sub-Category Dropdown */}
                {jobCategory && (
                    <div className={styles.formGroup}>
                        <label htmlFor="subCategory" className={styles.label}>Sub-Category:</label>
                        <select 
                            id="subCategory" 
                            value={subCategory} 
                            onChange={(e) => setSubCategory(e.target.value)} 
                            className={styles.select}
                        >
                            <option value="">Select a Sub-Category</option>
                            {filteredSubCategories.map(sub => (
                                <option key={sub.id} value={sub.name}>{sub.name}</option>
                            ))}
                        </select>
                    </div>
                )}

                {/* Other Fields */}
                <div className={styles.formGroup}>
                    <input type="text" placeholder="Job Title" value={title} onChange={(e) => setTitle(e.target.value)} className={styles.input} />
                </div>
                <div className={styles.formGroup}>
                    <textarea placeholder="Job Description" value={description} onChange={(e) => setDescription(e.target.value)} className={styles.textarea}></textarea>
                </div>
                <div className={styles.formGroup}>
                    <input type="text" placeholder="Location" value={location} onChange={(e) => setLocation(e.target.value)} className={styles.input} />
                </div>
                <div className={styles.formGroup}>
                    <input type="text" placeholder="Salary Range / Payment Terms" value={salaryRange} onChange={(e) => setSalaryRange(e.target.value)} className={styles.input} />
                </div>

                {/* Dynamic Additional Fields */}
                {renderAdditionalFields()}

                {/* Error Message */}
                {error && <p className={styles.errorMessage}>{error}</p>}

                <button type="submit" className={styles.submitButton}>Post Job</button>
            </form>
        </div>
    );
};

export default PostJobForm;
