import React from 'react';
import { useFirestoreDoc } from './useFirestoreData';

const FirestoreData = () => {
  const defaultBookId = '8xeTQJfKBuRLUJRxSnNZ';  // Replace this with the actual Firestore doc ID
  const bookData = useFirestoreDoc('anpbooks', defaultBookId);  // Fetch data for the default book

  if (!bookData) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Data from Firestore for the book ID: {defaultBookId}</h1>
      <div>
        <strong>Book Name:</strong> {bookData.bookName}
      </div>
      <div>
        <strong>Chapter Titles:</strong>
        <ul>
          {bookData.chapterTitles && bookData.chapterTitles.map((title, index) => (
            <li key={index}>{title.title}</li>
          ))}
        </ul>
      </div>
      {/* Display other fields similarly */}
    </div>
  );
};

export default FirestoreData;


// // FirestoreData.js
// import React from 'react';
// import { useFirestoreDoc } from './useFirestoreData';

// const FirestoreData = () => {
//   const defaultBookId = 'African Nonism & Peomocracy';  // Replace this with the actual Firestore doc ID if it's different
//   const bookData = useFirestoreDoc('anpbooks', defaultBookId);  // Fetch data for the default book

//   if (!bookData) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div>
//       <h1>Data from Firestore for the book: {defaultBookId}</h1>
//       <div>
//         <strong>Book Name:</strong> {bookData.bookName}
//       </div>
//       <div>
//         <strong>Chapter Titles:</strong>
//         <ul>
//           {bookData.chapterTitles.map((title, index) => (
//             <li key={index}>{title.title}</li>
//           ))}
//         </ul>
//       </div>
//       {/* Display other fields similarly */}
//     </div>
//   );
// };

// export default FirestoreData;
