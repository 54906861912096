import React from 'react';
import { useLocation } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  Configure,
  RefinementList,
  NumericMenu,
  RangeInput,
} from 'react-instantsearch-dom';
import PropertuLayout from '../../../components/Hoc/PropertuLayout/PropertuLayout';

import styles from './SearchResultsPage.module.css';

const searchClient = algoliasearch(
  '6AK4HX071D',
  '5f3395cb6c277fa4e153c7158e21fe02'
);

const PropertyHit = ({ hit }) => {
  return (
    <div>
      <h2>{hit.propertyName}</h2>
      <p>{hit.propertyAddress}</p>
    </div>
  );
};

const SearchResultsPage = () => {
  const location = useLocation();
  const searchState = location.state ? location.state.searchState : {};

  return (
    <PropertuLayout>
    <div>Search Results</div>
      <div className={styles.searchResultsPage}>
        <InstantSearch
          searchClient={searchClient}
          indexName="properties"
          searchState={searchState}
        >
          <Configure />
          <aside className={styles.sidebar}>
            {/* Advanced filter options */}
            <h3>Filters</h3>
            <RefinementList attribute="propertyType" />
            <NumericMenu
              attribute="price"
              items={[
                { label: 'All', end: undefined },
                { label: 'Under 100,000', end: 100000 },
                { label: '100,000 - 500,000', start: 100000, end: 500000 },
                { label: '500,000 - 1,000,000', start: 500000, end: 1000000 },
                { label: 'Over 1,000,000', start: 1000000 },
              ]}
            />
            <RangeInput attribute="price" />
          </aside>
          <main className={styles.propertyList}>
            <Hits hitComponent={PropertyHit} />
          </main>
        </InstantSearch>
      </div>
    </PropertuLayout>
  );
};

export default SearchResultsPage;
