

import React, { useContext } from 'react';
import styles from './GeneralDashboardLayout.module.css';
import { AuthContext } from '../../../context/AuthContext';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';

const GeneralDashboardLayout = ({ children }) => {
    const { user, logout } = useContext(AuthContext);
    const handleLogout = () => {
        logout();
        // Additional logout logic if needed
    };
    const menuItems = [
        { name: 'Home', link: '/' },
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'About', link: '/about-us' },
        // { name: 'Service', link: '/services' },
        { name: 'Contact', link: '/contact' },
        // { name: 'Feedback', link: '/feedback' },
    ];
    const authItems = user ? [
        { name: 'Logout', action: handleLogout },
    ] : [
        { name: 'Login', link: '/login' },
    ];

    const boameColor = 'blue';

    return (
        <>
            <Navbar serviceName=" " menuItems={menuItems} authItems={authItems} boameColor={boameColor} />
            <div className={styles.dashboardContainer}>
                {children}
            </div>
            <Footer />
        </>
    );
};

export default GeneralDashboardLayout;
