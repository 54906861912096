



import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore } from '../../firebase/config';
//import RouteMap from './RouteMap'; // Import the RouteMap component
import GoogleGeolocations from '../../components/Geolocations/Google/GoogleGeolocations';

import styles from './RouteOptimization.module.css';

const RouteOptimization = () => {
    const { user } = useContext(AuthContext);
    const history = useHistory();
    const [error, setError] = useState('');
    const [deliveries, setDeliveries] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]); // State to track selected orders
    const [isLoading, setIsLoading] = useState(true);
    const [isOptimizing, setIsOptimizing] = useState(false);
    const [routePath, setRoutePath] = useState([]); // Add this line


    useEffect(() => {
        const fetchDeliveries = async () => {
            setIsLoading(true);
            try {
                if (user && user.uid) {
                    console.log(`Fetching orders for user ID: ${user.uid}`); // Log user ID
                    // const snapshot = await projectFirestore.collection('deliveryorders')
                    //                     .where('orderStatus', 'in', ['pending', 'in_transit'])
                    //                     .where('userId', '==', user.uid)
                    //                     .orderBy('pickupDate', 'asc')
                    //                     .get();
                    const snapshot = await projectFirestore.collection('deliveryorders')
                                    .where('userId', '==', user.uid) // Test with only userId filter
                                    .get();


                    const deliveriesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    //console.log('Fetched deliveries:', deliveriesData); // Log fetched data
                    setDeliveries(deliveriesData);
                } else {
                    console.log('No user ID found for query');
                }
            } catch (error) {
                console.error('Error fetching deliveries:', error);
            }
            setIsLoading(false);
        };

        fetchDeliveries();
    }, [user]);

    const handleCheckboxChange = (orderId) => {
        setSelectedOrders(prevSelectedOrders =>
            prevSelectedOrders.includes(orderId)
                ? prevSelectedOrders.filter(id => id !== orderId)
                : [...prevSelectedOrders, orderId]
        );
    };

    const renderOrdersTable = () => (
        <table className={styles.ordersTable}>
            <thead>
                <tr>
                    <th>Select</th>
                    <th>Order ID</th>
                    <th>Pickup Date</th>
                    <th>Delivery Date</th>
                    <th>Order Status</th>
                </tr>
            </thead>
            <tbody>
                {deliveries.map(delivery => (
                    <tr key={delivery.id}>
                        <td>
                            <input
                                type="checkbox"
                                checked={selectedOrders.includes(delivery.id)}
                                onChange={() => handleCheckboxChange(delivery.id)}
                            />
                        </td>
                        <td>{delivery.orderId}</td>
                        <td>{new Date(delivery.packageDetails.pickupDate).toLocaleDateString()}</td>
                        <td>{new Date(delivery.packageDetails.deliveryDate).toLocaleDateString()}</td>
                        <td>{delivery.orderStatus}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    // const optimizeRoute = async () => {
    //     setIsOptimizing(true);
    //     setError('');
    
    //     try {
    //         // Extract locations from selected orders
    //         const waypoints = selectedOrders.map(orderId => {
    //             const order = deliveries.find(delivery => delivery.id === orderId);
    //             return { lat: order.latitude, lng: order.longitude };
    //         });
    
    //         // Call a route optimization API or service here
    //         // For example, using Google Maps Directions API
    //         const optimizedRoute = await getOptimizedRouteFromApi(waypoints);
    
    //         // Update the routePath state for visualization
    //         setRoutePath(optimizedRoute);
    //     } catch (error) {
    //         console.error('Optimization error:', error);
    //         setError('Failed to optimize the route. Please try again.');
    //     } finally {
    //         setIsOptimizing(false);
    //     }
    // };

    const optimizeRoute = async () => {
        setIsOptimizing(true);
        setError('');
    
        try {
            // Get the locations of selected orders
            const waypoints = selectedOrders.map(orderId => {
                const order = deliveries.find(delivery => delivery.id === orderId);
                return { lat: order.latitude, lng: order.longitude };
            });
    
            // Call your backend service to get the optimized route
            const optimizedPath = await getOptimizedRouteFromBackend(waypoints);
            setRoutePath(optimizedPath);
    
        } catch (error) {
            console.error('Optimization error:', error);
            setError('Failed to optimize the route. Please try again.');
        } finally {
            setIsOptimizing(false);
        }
    };


    
    // Placeholder function for backend API call
    const getOptimizedRouteFromBackend = async (waypoints) => {
        // Here you would make an actual API request to your backend server
        // For example, using fetch or axios to send waypoints and receive the optimized route
        // This is a mock response to illustrate the structure

        const response = await fetch('https://your-backend-server.com/optimize-route', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ waypoints }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const optimizedRoute = await response.json();
        return optimizedRoute; // This should be the array of waypoints representing the optimized route
    };

    
    

    if (isLoading) {
        return <div>Loading deliveries...</div>;
    }

    return (
        <div className={styles.routeOptimizationContainer}>
            <h2>Route Optimization</h2>
            {deliveries.length > 0 ? renderOrdersTable() : <p>No orders to display.</p>}
            {
                isOptimizing && <div>Loading, optimizing route...</div>
            }

            <button onClick={optimizeRoute} className={styles.optimizeButton}>Optimize Route</button>
            {/* Here we add the GoogleGeolocations component for route visualization */}
            <GoogleGeolocations routePath={routePath} />
        </div>
        
    );
};

export default RouteOptimization;
