


import React, { useState } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './JobTypes.module.css';

const JobTypes = () => {
    const [jobName, setJobName] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [category, setCategory] = useState(''); // Default category state
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Data Validation
        if (!jobName.trim() || !jobDescription.trim() || !category) {
            setError("Job name, description, and category are required.");
            return;
        }

        try {
            await projectFirestore.collection('jobtypes').add({
                name: jobName,
                description: jobDescription,
                category // Include category in the document
            });
            // Reset form fields
            setJobName('');
            setJobDescription('');
            setCategory(''); // Reset category
            setError('');
        } catch (error) {
            console.error("Error adding document: ", error);
            setError("Failed to save data. Please try again.");
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            {error && <p className={styles.errorMessage}>{error}</p>}
            <div className={styles.inputGroup}>
                <label htmlFor="jobName">Job Type Name:</label>
                <input
                    id="jobName"
                    type="text"
                    value={jobName}
                    onChange={(e) => setJobName(e.target.value)}
                    className={styles.input}
                />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="jobDescription">Description:</label>
                <textarea
                    id="jobDescription"
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                    className={styles.textarea}
                />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor="category">Category:</label>
                <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className={styles.select}
                >
                    <option value="">Select a Category</option>
                    <option value="Professional-Corporate">Professional and Corporate Jobs</option>
                    <option value="Personal-Local">Personal and Local Jobs</option>
                </select>
            </div>
            <button type="submit" className={styles.submitButton}>Submit Job Type</button>
        </form>
    );
};

export default JobTypes;
