// // cartReducer.js
// export const cartReducer = (state, action) => {
//     let updatedState;
    
//     switch (action.type) {
//       // case 'ADD_TO_CART':
//       //   // Check if the item is already in the cart
//       //   const existingCartItem = state.find((cartItem) => cartItem.id === action.item.id);
  
//       //   if (existingCartItem) {
//       //     // If the item is already in the cart, return the state without any modification
//       //     return state;
//       //   } else {
//       //     // If the item doesn't exist, add it to the cart with a quantity of 1
//       //     updatedState = [...state, action.item];
//       //   }
//       //   break;
//       case 'ADD_TO_CART':
//     const existingCartItemIndex = state.findIndex((cartItem) => cartItem.id === action.item.id);

//     if (existingCartItemIndex >= 0) {
//         const newState = [...state];
//         // perform some operations on newState[existingCartItemIndex] if necessary
//         updatedState = newState;
//     } else {
//         updatedState = [...state, action.item];
//     }
//     break;


  
//       case 'REMOVE_FROM_CART':
//         updatedState = state.filter((item) => item.id !== action.itemId);
//         break;
  
//       case 'UPDATE_ITEM_QUANTITY':
//         updatedState = state.map((item) =>
//           item.id === action.itemId ? { ...item, quantity: action.newQuantity } : item
//         );
//         break;
  
//       case 'REMOVE_ITEM':
//         updatedState = state.filter((item) => item.id !== action.itemId);
//         break;
        
//       case 'CLEAR_CART':
//         return [];
  
//       default:
//         return state;
//     }
  
//     // Update the cart items in local storage
//     localStorage.setItem('cartItems', JSON.stringify(updatedState));
//     return updatedState;

    
//   };
  

export const cartReducer = (state, action) => {
  let updatedState;
  
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingCartItemIndex = state.findIndex((cartItem) => cartItem.id === action.item.id);

      if (existingCartItemIndex >= 0) {
          const newState = [...state];
          // perform some operations on newState[existingCartItemIndex] if necessary
          updatedState = newState;
      } else {
          updatedState = [...state, action.item];
      }
      break;

    case 'REMOVE_FROM_CART':
      updatedState = state.filter((item) => item.id !== action.itemId);
      break;

    case 'UPDATE_ITEM_QUANTITY':
      updatedState = state.map((item) =>
        item.id === action.itemId ? { ...item, quantity: action.newQuantity } : item
      );
      break;

    case 'REMOVE_ITEM':
      updatedState = state.filter((item) => item.id !== action.itemId);
      break;
      
    case 'CLEAR_CART':
      updatedState = [];
      break;

    default:
      updatedState = state;
  }

  // Update the cart items in local storage
  localStorage.setItem('cartItems', JSON.stringify(updatedState));

  // Log the updated state
  console.log("updated state", updatedState);

  return updatedState;
};
