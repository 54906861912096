import React from 'react';
import styles from './QuoteForm.module.css';
import { useCountrySettings } from '../../context/CountrySettingsContext';
import { africanCountries } from '../../constants/countries';

const QuoteForm = ({ onSubmit }) => {
  const { selectedCountry } = useCountrySettings();

  const handleSubmit = (e) => {
    e.preventDefault();
    const quote = e.target.quote.value;
    const author = e.target.author.value || 'Anonymous'; // Use the entered author or default to 'Anonymous'
    const country = e.target.country.value;
    onSubmit(quote, author, country); // Pass the author and country to onSubmit
  };

  return (
    <form onSubmit={handleSubmit} className={styles.quoteForm}>
      <label htmlFor="quote">Post your quote of the day:</label>
  <textarea id="quote" name="quote" rows="5" required></textarea>
      <label htmlFor="author">Author (optional):</label>
      <input type="text" id="author" name="author" />
      <label htmlFor="country">Country:</label>
      <select id="country" name="country" defaultValue={selectedCountry.code}>
        {africanCountries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>
      <button type="submit">Submit</button>
    </form>
  );
};

export default QuoteForm;



