import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { projectFirestore } from '../../../firebase/config';
import ContactDeliveryPerson from './ContactDeliveryPerson';
import DeliveryInstructions from './DeliveryInstructions';
import RescheduleDelivery from './RescheduleDelivery';
import ViewPackageDetails from './ViewPackageDetails';
import DeliveryLayout from '../../../components/Hoc/DeliveryLayout/DeliveryLayout';

import styles from './Receivals.module.css';

function Receivals() {
  const { user } = useContext(AuthContext);
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showContactDeliveryPerson, setShowContactDeliveryPerson] = useState(false);
  const [showDeliveryInstructions, setShowDeliveryInstructions] = useState(false);  
  const [showReschedule, setShowReschedule] = useState(false);
  const [showPackageDetails, setShowPackageDetails] = useState(false);
  const [showViewDetails, setShowViewDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  // const [activeOrderStatus, setActiveOrderStatus] = useState('all');
  
  const formatAddress = (address) => {
    return address ? `${address.street}, ${address.city}` : 'Address not provided';
  };

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toLocaleDateString() : 'Date not provided';
  };

  useEffect(() => {
    const fetchDeliveryOrders = async () => {
      setLoading(true);

      try {
        const ordersRef = projectFirestore.collection('deliveryorders');
        const snapshot = await ordersRef.where('userId', '==', user.uid).get();

        if (snapshot.empty) {
          console.log('No delivery orders for this user.');
          setLoading(false);
          return;
        }

        const orders = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderStatus: data.orderStatus,
            pickupAddress: data.selectedPickupAddress,
            deliveryAddress: data.selectedDeliveryAddress,
            packageData: data.packageDetails,
            estimatedPickupDate: formatDate(data.packageDetails.pickupDate),
            estimatedDeliveryDate: formatDate(data.packageDetails.deliveryDate),
          };
        });
        setDeliveryOrders(orders);
      } catch (error) {
        console.error("Error fetching delivery orders: ", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchDeliveryOrders();
    }
  }, [user]);

  const renderOrdersTable = (orders) => (
    <table className={styles.deliveryOrdersTable}>
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Pickup Address</th>
          <th>Delivery Address</th>
          <th>Order Status</th>
          <th>Estimated Pickup</th>
          <th>Estimated Delivery</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(order => (
          <tr key={order.id}>
            <td>{order.id}</td>
            <td>{formatAddress(order.pickupAddress)}</td>
            <td>{formatAddress(order.deliveryAddress)}</td>
            <td>{order.orderStatus}</td>
            <td>{order.estimatedPickupDate}</td>
            <td>{order.estimatedDeliveryDate}</td>
            <td>
              <button onClick={() => handleViewDetails(order)}>View Details</button>
              <button onClick={() => handleContactDeliveryPerson(order)}>Contact Delivery</button>
              <button onClick={() => handleEditInstructions(order)}>Edit Instructions</button>
              <button onClick={() => handleReschedule(order)}>Reschedule</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderOrdersByStatus = (status) => {
    const filteredOrders = deliveryOrders.filter(order => order.orderStatus === status);
    return (
      <div>
        <h3>{status.charAt(0).toUpperCase() + status.slice(1)} Orders</h3>
        {filteredOrders.length > 0 ? renderOrdersTable(filteredOrders) : <p>No orders in {status} status.</p>}
      </div>
    );
  };


  const handleContactDeliveryPerson = (order) => {
    setSelectedOrder(order);
    setShowContactDeliveryPerson(true);
  };

  const handleEditInstructions = (order) => {
    setSelectedOrder(order);
    setShowDeliveryInstructions(true); 
  };

  const handleReschedule = (order) => {
    setSelectedOrder(order);
    setShowReschedule(true); 
  };

  const handleViewDetails = (order) => {
    console.log("Selected order for viewing details:", order);
    setSelectedOrder(order);
    setShowViewDetails(true);
  };
  const handleClose = () => {
    setShowContactDeliveryPerson(false);
    setShowDeliveryInstructions(false); 
    setShowReschedule(false);
    setShowPackageDetails(false);
    setSelectedOrder(null);
  };

  const handleCloseViewDetails = () => {
    setShowViewDetails(false);
    setSelectedOrder(null);
  };

  if (loading) {
    return <div>Loading delivery orders...</div>;
  }

  return (
    <DeliveryLayout>
      <div className={styles.receivalsContainer}>
        <h1>My Delivery Orders</h1>
        {renderOrdersByStatus('pending')}
        {renderOrdersByStatus('in_transit')}
        {renderOrdersByStatus('delivered')}
      </div>

      {showContactDeliveryPerson && selectedOrder && (
        <ContactDeliveryPerson
          deliveryPersonContact={selectedOrder.deliveryPersonContact}
          onClose={handleClose}
        />
      )}

      {showDeliveryInstructions && selectedOrder && (
        <DeliveryInstructions
          orderId={selectedOrder.id}
          onClose={handleClose}
        />
      )}

      {showReschedule && selectedOrder && (
        <RescheduleDelivery
          orderId={selectedOrder.id}
          onClose={handleClose}
        />
      )}

      {showViewDetails && selectedOrder && selectedOrder.packageData && (
      
      <ViewPackageDetails 
        senderName={selectedOrder.pickupAddress.fullName}
        senderAddress={`${selectedOrder.pickupAddress.street}, ${selectedOrder.pickupAddress.city}`}
        deliveryName={selectedOrder.deliveryAddress.fullName}
        deliveryAddress={`${selectedOrder.deliveryAddress.street}, ${selectedOrder.deliveryAddress.city}`}
        dimensions={selectedOrder.packageData.dimensions}
        weight={selectedOrder.packageData.weight}
        cost={selectedOrder.deliveryCost}
        onClose={handleCloseViewDetails}
      />
    )}
    </DeliveryLayout>
  );
}

export default Receivals;

