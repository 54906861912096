import React, { useState, useContext } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import AdvertArea from '../../../components/Propertu/AdvertFeatures/AdvertArea/AdvertArea';
import { useCountrySettings } from '../../../context/CountrySettingsContext';



const PropertuLayout = ({ children }) => {
  const { selectedCountry, setSelectedCountry } = useCountrySettings();

    const menuItems = [
        { name: 'Rentals', link: '/rentals' },
        { name: 'To Buy', link: '/buy' },
        { name: 'To Sell', link: '/sell' },
        { name: 'New Developments', link: '/developments' },
        { name: 'Adverts', link: '/adverts' },
      ];

      const boameColor = '#f9a826';

    return (
      <>
        <Navbar 
          serviceName="Propertu" 
          menuItems={menuItems} 
          boameColor={boameColor} 
          selectedCountry={selectedCountry}
          onCountryChange={setSelectedCountry}
          />
        <main>
          <AdvertArea selectedCountry={selectedCountry} />
          {children}
        </main>
        <Footer />
      </>
    );
  };
  
  export default PropertuLayout;
