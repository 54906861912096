import React, { useState } from 'react';
import { projectFirestore } from '../../firebase/config';
import { useAuthContext } from '../../hooks/useAuthContext';
import commentIcon from '../../assets/commentIcon.svg';


const Comments = ({ proposalId, initialComments = [] }) => {
    const { user } = useAuthContext(); // Use the user from context
    const [comments, setComments] = useState(initialComments);
    const [showCommentBox, setShowCommentBox] = useState(false);
  
    const handleCommentClick = () => {
      // Toggle the display of the comment box
      setShowCommentBox(!showCommentBox);
    };
  
  
    const handleAddComment = async (newComment) => {
      // Add author and date to the comment
      const commentWithAuthor = {
          ...newComment,
          author: user ? user.displayName : 'Anonymous',
          date: new Date().toLocaleDateString("en-GB")
      };

      const updatedComments = [...comments, commentWithAuthor];
      setComments(updatedComments);
      setShowCommentBox(false);

      // Update comments in Firestore
      const proposalRef = projectFirestore.collection('proposals').doc(proposalId);
      await proposalRef.update({ comments: updatedComments });
  };
  

  return (
    <>
        <span onClick={handleCommentClick}>
            <img src={commentIcon} alt="Comments" className="icon" />
            {comments.length} Comments
        </span>
        {showCommentBox && (
            <CommentBox onSubmit={handleAddComment} user={user} />
        )}
    </>
);
};

// CommentBox Component
const CommentBox = ({ onSubmit, user }) => {
const [commentText, setCommentText] = useState('');

const handleSubmit = () => {
    if (commentText) {
        const newComment = {
            text: commentText,
            // Include author and date here if you want to have it immediately after adding (optional)
            author: user ? user.displayName : 'Anonymous',
            date: new Date().toLocaleDateString("en-GB")
        };
        onSubmit(newComment);
        setCommentText('');
    }
};

return (
    <div>
        <textarea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
        />
        <button onClick={handleSubmit}>Submit Comment</button>
    </div>
);
};

export default Comments;