// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { projectFirestore } from '../../firebase/config';
// import styles from './Tracking.module.css';

// export default function Tracking() {
//   const { orderId } = useParams(); // Extract 'orderId' from URL, which is the tracking ID
//   const [orderDetails, setOrderDetails] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     setIsLoading(true);
//     projectFirestore.collection('deliveryorders')
//       .doc(orderId) // Use the orderId to fetch the specific order
//       .get()
//       .then(doc => {
//         if (doc.exists) {
//           setOrderDetails(doc.data());
//         } else {
//           setError('Order not found with provided ID');
//         }
//       })
//       .catch(err => setError('Error fetching order: ' + err.message))
//       .finally(() => setIsLoading(false));
//   }, [orderId]);

//   if (isLoading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   return (
//     <div className={styles.trackingContainer}>
//       <h2>Tracking Details</h2>
//       {isLoading ? (
//         <p>Loading...</p>
//       ) : error ? (
//         <p>{error}</p>
//       ) : orderDetails ? (
//         <div>
//           <p><strong>Order ID:</strong> {orderDetails.id}</p>
//           <p><strong>Status:</strong> {orderDetails.status}</p>
//           {/* Add more details as needed, for example: */}
//           <p><strong>Pickup Address:</strong> {orderDetails.selectedPickupAddress?.street}, {orderDetails.selectedPickupAddress?.city}</p>
//           <p><strong>Delivery Address:</strong> {orderDetails.selectedDeliveryAddress?.street}, {orderDetails.selectedDeliveryAddress?.city}</p>
//           <p><strong>Order Date:</strong> {orderDetails.orderDate && new Date(orderDetails.orderDate.seconds * 1000).toLocaleDateString()}</p>
//           {/* Continue adding other relevant details */}
//         </div>
//       ) : (
//         <p>Enter a valid order ID to see order details.</p>
//       )}
//     </div>
//   );
//       }


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { projectFirestore } from '../../firebase/config';
import styles from './Tracking.module.css';

export default function Tracking() {
  const { orderId } = useParams(); // Extract 'orderId' from URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (orderId) {
      console.log("Fetching order with ID:", orderId); // Log the orderId being queried
      setIsLoading(true);
      projectFirestore.collection('deliveryorders').doc(orderId)
        .get()
        .then(doc => {
          if (doc.exists) {
            setOrderDetails({ id: doc.id, ...doc.data() });
          } else {
            setError('Order not found with provided ID');
            console.log("No document found with ID:", orderId); // Log when no document is found
          }
        })
        .catch(err => {
          setError('Error fetching order: ' + err.message);
          console.error('Error fetching order:', err); // Log the error
        })
        .finally(() => setIsLoading(false));
    } else {
      setError('No Order ID provided');
      setIsLoading(false);
    }
  }, [orderId]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className={styles.trackingContainer}>
      <h2>Tracking Details</h2>
      {orderDetails ? (
        <div>
          <p><strong>Order ID:</strong> {orderDetails.id}</p>
          <p><strong>Status:</strong> {orderDetails.status}</p>
          <p><strong>Pickup Address:</strong> {orderDetails.selectedPickupAddress?.street}, {orderDetails.selectedPickupAddress?.city}</p>
          <p><strong>Delivery Address:</strong> {orderDetails.selectedDeliveryAddress?.street}, {orderDetails.selectedDeliveryAddress?.city}</p>
          <p><strong>Order Date:</strong> {orderDetails.orderDate && new Date(orderDetails.orderDate.seconds * 1000).toLocaleDateString()}</p>
          {/* Continue adding other relevant details */}
        </div>
      ) : (
        <p>Enter a valid order ID to see order details.</p>
      )}
    </div>
  );
}
