import React from 'react';
import styles from './NotificationsDeactivation.module.css'; // Import your CSS module here

const NotificationDeactivation = ({ message, type, onClose }) => {
  // You can add more types such as 'error', 'info', etc.
  const notificationStyles = [styles.notification, styles[type]].join(' ');

  return (
    <div className={notificationStyles} onClick={onClose}>
      {message}
    </div>
  );
};

export default NotificationDeactivation;