import React from 'react';
import styles from './Feedback.module.css';

export default function Feedback() {
  return (
    <div className={styles.feedbackContainer}>
      <h2>Feedback Boameooo</h2>
      <p>"To content Boameooo feedback info".</p>
    </div>
  );
}
