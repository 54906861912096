import React, { useState, useEffect } from 'react';
import useAdverts from '../../../../hooks/useAdverts';

import styles from './AdvertArea.module.css';

const AdvertArea = ({ selectedCountry }) => {
  const { adverts } = useAdverts();
  const filteredAdverts = adverts.filter(
    (advert) => advert.country === selectedCountry
  );
  console.log("adverts:", adverts);
  console.log("filteredAdverts:", filteredAdverts);

  const [currentAdvertIndex, setCurrentAdvertIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdvertIndex((prevIndex) => (prevIndex + 1) % filteredAdverts.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [filteredAdverts.length]);

  const getAdvertSizeClass = (index) => {
    switch (index % 9) {
      case 0:
        return styles.mediumRectangle;
      case 1:
        return styles.largeRectangle;
      case 2:
        return styles.leaderboard;
      case 3:
        return styles.wideSkyscraper;
      case 4:
        return styles.halfPageAd;
      case 5:
        return styles.largeMobileBanner;
      case 6:
        return styles.smallSquare;
      case 7:
        return styles.square;
      default:
        return styles.mediumRectangle;
    }
  };

  return (
    <div className={styles.advertArea}>
      <h3>Adverts</h3>
      <div className={styles.imageContainer}>
        {filteredAdverts.map((advert, index) => (
          <div
            key={advert.id}
            className={`${styles.advertItem} ${getAdvertSizeClass(index)}`}
            style={{ display: index === currentAdvertIndex ? 'block' : 'none' }}
          >
            <img
              className={styles.advertImage}
              src={advert.imageUrl}
              alt={advert.title}
            />
            <h4>{advert.title}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdvertArea;
