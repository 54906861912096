import React from 'react';
import NavbarReduce from '../../Navbar/NavbarReduce';
import Footer from '../../Footer/Footer';

const BooksLayoutBlank = ({ children }) => {
    const menuItems = [
        // { name: 'Rentals', link: '/rentals' },
        // { name: 'To Buy', link: '/buy' },
        // { name: 'To Sell', link: '/sell' },
        // { name: 'New Developments', link: '/developments' },
        // { name: 'Adverts', link: '/adverts' },
        
      ];
      const boameColor = 'blue';
    return (
      <>
        <NavbarReduce serviceName="Books " menuItems={menuItems} boameColor={boameColor} />
        <main>{children}</main>
        <Footer />
      </>
    );
  };
  
  export default BooksLayoutBlank;
