export const africanCountries = [
  { name: 'Select a Country'},
  { name: 'Algeria', code: 'DZ', flag: '🇩🇿', currency: 'DZD', language: 'Arabic', phoneCode: '213' },
  { name: 'Angola', code: 'AO', flag: '🇦🇴', currency: 'AOA', language: 'Portuguese', phoneCode: '244' },
  { name: 'Benin', code: 'BJ', flag: '🇧🇯', currency: 'XOF', language: 'French', phoneCode: '229' },
  { name: 'Botswana', code: 'BW', flag: '🇧🇼', currency: 'BWP', language: 'English', phoneCode: '267' },
  { name: 'Burkina Faso', code: 'BF', flag: '🇧🇫', currency: 'XOF', language: 'French', phoneCode: '226' },
  { name: 'Burundi', code: 'BI', flag: '🇧🇮', currency: 'BIF', language: 'Kirundi, French', phoneCode: '257' },
  { name: 'Cape Verde', code: 'CV', flag: '🇨🇻', currency: 'CVE', language: 'Portuguese', phoneCode: '238' },
  { name: 'Cameroon', code: 'CM', flag: '🇨🇲', currency: 'XAF', language: 'English, French', phoneCode: '237' },
  { name: 'Central African Republic', code: 'CF', flag: '🇨🇫', currency: 'XAF', language: 'French, Sango', phoneCode: '236' },
  { name: 'Chad', code: 'TD', flag: '🇹🇩', currency: 'XAF', language: 'Arabic, French', phoneCode: '235' },
  { name: 'Comoros', code: 'KM', flag: '🇰🇲', currency: 'KMF', language: 'Arabic, French, Comorian', phoneCode: '269' },
  { name: 'Democratic Republic of the Congo', code: 'CD', flag: '🇨🇩', currency: 'CDF', language: 'French', phoneCode: '243' },
  { name: 'Republic of the Congo', code: 'CG', flag: '🇨🇬', currency: 'XAF', language: 'French', phoneCode: '242' },
  { name: 'Djibouti', code: 'DJ', flag: '🇩🇯', currency: 'DJF', language: 'Arabic, French', phoneCode: '253' },
  { name: 'Egypt', code: 'EG', flag: '🇪🇬', currency: 'EGP', language: 'Arabic', phoneCode: '20' },
  { name: 'Equatorial Guinea', code: 'GQ', flag: '🇬🇶', currency: 'XAF', language: 'Spanish, French, Portuguese', phoneCode: '240' },
  { name: 'Eritrea', code: 'ER', flag: '🇪🇷', currency: 'ERN', language: 'Tigrinya, Arabic, English', phoneCode: '291' },
  { name: 'Eswatini', code: 'SZ', flag: '🇸🇿', currency: 'SZL', language: 'English, Swazi', phoneCode: '268' },
  { name: 'Ethiopia', code: 'ET', flag: '🇪🇹', currency: 'ETB', language: 'Amharic', phoneCode: '251' },
  { name: 'Gabon', code: 'GA', flag: '🇬🇦', currency: 'XAF', language: 'French', phoneCode: '241' },
  { name: 'Gambia', code: 'GM', flag: '🇬🇲', currency: 'GMD', language: 'English', phoneCode: '220' },
  { name: 'Ghana', code: 'GH', flag: '🇬🇭', currency: 'GHS', language: 'English', phoneCode: '233' },
  { name: 'Guinea', code: 'GN', flag: '🇬🇳', currency: 'GNF', language: 'French', phoneCode: '224' },
  { name: 'Guinea-Bissau', code: 'GW', flag: '🇬🇼', currency: 'XOF', language: 'Portuguese', phoneCode: '245' },
  { name: 'Ivory Coast', code: 'CI', flag: '🇨🇮', currency: 'XOF', language: 'French', phoneCode: '225' },
  { name: 'Kenya', code: 'KE', flag: '🇰🇪', currency: 'KES', language: 'English, Swahili', phoneCode: '254' },
  { name: 'Lesotho', code: 'LS', flag: '🇱🇸', currency: 'LSL', language: 'English, Sesotho', phoneCode: '266' },
  { name: 'Liberia', code: 'LR', flag: '🇱🇷', currency: 'LRD', language: 'English', phoneCode: '231' },
  { name: 'Libya', code: 'LY', flag: '🇱🇾', currency: 'LYD', language: 'Arabic', phoneCode: '218' },
  { name: 'Madagascar', code: 'MG', flag: '🇲🇬', currency: 'MGA', language: 'French, Malagasy', phoneCode: '261' },
  { name: 'Malawi', code: 'MW', flag: '🇲🇼', currency: 'MWK', language: 'English, Chichewa', phoneCode: '265' },
  { name: 'Mali', code: 'ML', flag: '🇲🇱', currency: 'XOF', language: 'French', phoneCode: '223' },
  { name: 'Mauritania', code: 'MR', flag: '🇲🇷', currency: 'MRU', language: 'Arabic', phoneCode: '222' },
  { name: 'Mauritius', code: 'MU', flag: '🇲🇺', currency: 'MUR', language: 'English', phoneCode: '230' },
  { name: 'Morocco', code: 'MA', flag: '🇲🇦', currency: 'MAD', language: 'Arabic', phoneCode: '212' },
  { name: 'Mozambique', code: 'MZ', flag: '🇲🇿', currency: 'MZN', language: 'Portuguese', phoneCode: '258' },
  { name: 'Namibia', code: 'NA', flag: '🇳🇦', currency: 'NAD', language: 'English', phoneCode: '264' },
  { name: 'Niger', code: 'NE', flag: '🇳🇪', currency: 'XOF', language: 'French', phoneCode: '227' },
  { name: 'Nigeria', code: 'NG', flag: '🇳🇬', currency: 'NGN', language: 'English', phoneCode: '234' },
  { name: 'Rwanda', code: 'RW', flag: '🇷🇼', currency: 'RWF', language: 'Kinyarwanda, English, French', phoneCode: '250' },
  { name: 'São Tomé and Príncipe', code: 'ST', flag: '🇸🇹', currency: 'STN', language: 'Portuguese', phoneCode: '239' },
  { name: 'Senegal', code: 'SN', flag: '🇸🇳', currency: 'XOF', language: 'French', phoneCode: '221' },
  { name: 'Seychelles', code: 'SC', flag: '🇸🇨', currency: 'SCR', language: 'English, French', phoneCode: '248' },
  { name: 'Sierra Leone', code: 'SL', flag: '🇸🇱', currency: 'SLL', language: 'English', phoneCode: '232' },
  { name: 'Somalia', code: 'SO', flag: '🇸🇴', currency: 'SOS', language: 'Somali, Arabic', phoneCode: '252' },
  { name: 'South Africa', code: 'ZA', flag: '🇿🇦', currency: 'ZAR', language: 'English, Afrikaans, Zulu, Xhosa, Sotho', phoneCode: '27' },
  { name: 'South Sudan', code: 'SS', flag: '🇸🇸', currency: 'SSP', language: 'English', phoneCode: '211' },
  { name: 'Sudan', code: 'SD', flag: '🇸🇩', currency: 'SDG', language: 'Arabic, English', phoneCode: '249' },
  { name: 'Tanzania', code: 'TZ', flag: '🇹🇿', currency: 'TZS', language: 'Swahili, English', phoneCode: '255' },
  { name: 'Togo', code: 'TG', flag: '🇹🇬', currency: 'XOF', language: 'French', phoneCode: '228' },
  { name: 'Tunisia', code: 'TN', flag: '🇹🇳', currency: 'TND', language: 'Arabic', phoneCode: '216' },
  { name: 'Uganda', code: 'UG', flag: '🇺🇬', currency: 'UGX', language: 'English, Swahili', phoneCode: '256' },
  { name: 'Zambia', code: 'ZM', flag: '🇿🇲', currency: 'ZMW', language: 'English', phoneCode: '260' },
  { name: 'Zimbabwe', code: 'ZW', flag: '🇿🇼', currency: 'USD', language: 'English, Shona, Sindebele', phoneCode: '263' },
];





export const globalCountries = [
  // Existing African countries (example)
  { name: 'Algeria', code: 'DZ', flag: '🇩🇿', currency: 'DZD', language: 'Arabic', phoneCode: '213', GeoRegion: 'Africa' },
  { name: 'Angola', code: 'AO', flag: '🇦🇴', currency: 'AOA', language: 'Portuguese', phoneCode: '244', GeoRegion: 'Africa' },
  { name: 'Benin', code: 'BJ', flag: '🇧🇯', currency: 'XOF', language: 'French', phoneCode: '229', GeoRegion: 'Africa' },
  { name: 'Botswana', code: 'BW', flag: '🇧🇼', currency: 'BWP', language: 'English', phoneCode: '267', GeoRegion: 'Africa' },
  { name: 'Burkina Faso', code: 'BF', flag: '🇧🇫', currency: 'XOF', language: 'French', phoneCode: '226', GeoRegion: 'Africa' },
  { name: 'Burundi', code: 'BI', flag: '🇧🇮', currency: 'BIF', language: 'Kirundi, French', phoneCode: '257', GeoRegion: 'Africa' },
  { name: 'Cape Verde', code: 'CV', flag: '🇨🇻', currency: 'CVE', language: 'Portuguese', phoneCode: '238', GeoRegion: 'Africa' },
  { name: 'Cameroon', code: 'CM', flag: '🇨🇲', currency: 'XAF', language: 'English, French', phoneCode: '237', GeoRegion: 'Africa' },
  { name: 'Central African Republic', code: 'CF', flag: '🇨🇫', currency: 'XAF', language: 'French, Sango', phoneCode: '236', GeoRegion: 'Africa' },
  { name: 'Chad', code: 'TD', flag: '🇹🇩', currency: 'XAF', language: 'Arabic, French', phoneCode: '235', GeoRegion: 'Africa' },
  { name: 'Comoros', code: 'KM', flag: '🇰🇲', currency: 'KMF', language: 'Arabic, French, Comorian', phoneCode: '269', GeoRegion: 'Africa' },
  { name: 'Democratic Republic of the Congo', code: 'CD', flag: '🇨🇩', currency: 'CDF', language: 'French', phoneCode: '243', GeoRegion: 'Africa' },
  { name: 'Republic of the Congo', code: 'CG', flag: '🇨🇬', currency: 'XAF', language: 'French', phoneCode: '242', GeoRegion: 'Africa' },
  { name: 'Djibouti', code: 'DJ', flag: '🇩🇯', currency: 'DJF', language: 'Arabic, French', phoneCode: '253', GeoRegion: 'Africa' },
  { name: 'Egypt', code: 'EG', flag: '🇪🇬', currency: 'EGP', language: 'Arabic', phoneCode: '20', GeoRegion: 'Africa' },
  { name: 'Equatorial Guinea', code: 'GQ', flag: '🇬🇶', currency: 'XAF', language: 'Spanish, French, Portuguese', phoneCode: '240', GeoRegion: 'Africa' },
  { name: 'Eritrea', code: 'ER', flag: '🇪🇷', currency: 'ERN', language: 'Tigrinya, Arabic, English', phoneCode: '291', GeoRegion: 'Africa' },
  { name: 'Eswatini', code: 'SZ', flag: '🇸🇿', currency: 'SZL', language: 'English, Swazi', phoneCode: '268', GeoRegion: 'Africa' },
  { name: 'Ethiopia', code: 'ET', flag: '🇪🇹', currency: 'ETB', language: 'Amharic', phoneCode: '251', GeoRegion: 'Africa' },
  { name: 'Gabon', code: 'GA', flag: '🇬🇦', currency: 'XAF', language: 'French', phoneCode: '241', GeoRegion: 'Africa' },
  { name: 'Gambia', code: 'GM', flag: '🇬🇲', currency: 'GMD', language: 'English', phoneCode: '220', GeoRegion: 'Africa' },
  { name: 'Ghana', code: 'GH', flag: '🇬🇭', currency: 'GHS', language: 'English', phoneCode: '233', GeoRegion: 'Africa' },
  { name: 'Guinea', code: 'GN', flag: '🇬🇳', currency: 'GNF', language: 'French', phoneCode: '224', GeoRegion: 'Africa' },
  { name: 'Guinea-Bissau', code: 'GW', flag: '🇬🇼', currency: 'XOF', language: 'Portuguese', phoneCode: '245', GeoRegion: 'Africa' },
  { name: 'Ivory Coast', code: 'CI', flag: '🇨🇮', currency: 'XOF', language: 'French', phoneCode: '225', GeoRegion: 'Africa' },
  { name: 'Kenya', code: 'KE', flag: '🇰🇪', currency: 'KES', language: 'English, Swahili', phoneCode: '254', GeoRegion: 'Africa' },
  { name: 'Lesotho', code: 'LS', flag: '🇱🇸', currency: 'LSL', language: 'English, Sesotho', phoneCode: '266', GeoRegion: 'Africa' },
  { name: 'Liberia', code: 'LR', flag: '🇱🇷', currency: 'LRD', language: 'English', phoneCode: '231', GeoRegion: 'Africa' },
  { name: 'Libya', code: 'LY', flag: '🇱🇾', currency: 'LYD', language: 'Arabic', phoneCode: '218', GeoRegion: 'Africa' },
  { name: 'Madagascar', code: 'MG', flag: '🇲🇬', currency: 'MGA', language: 'French, Malagasy', phoneCode: '261', GeoRegion: 'Africa' },
  { name: 'Malawi', code: 'MW', flag: '🇲🇼', currency: 'MWK', language: 'English, Chichewa', phoneCode: '265', GeoRegion: 'Africa' },
  { name: 'Mali', code: 'ML', flag: '🇲🇱', currency: 'XOF', language: 'French', phoneCode: '223', GeoRegion: 'Africa' },
  { name: 'Mauritania', code: 'MR', flag: '🇲🇷', currency: 'MRU', language: 'Arabic', phoneCode: '222', GeoRegion: 'Africa' },
  { name: 'Mauritius', code: 'MU', flag: '🇲🇺', currency: 'MUR', language: 'English', phoneCode: '230', GeoRegion: 'Africa' },
  { name: 'Morocco', code: 'MA', flag: '🇲🇦', currency: 'MAD', language: 'Arabic', phoneCode: '212', GeoRegion: 'Africa' },
  { name: 'Mozambique', code: 'MZ', flag: '🇲🇿', currency: 'MZN', language: 'Portuguese', phoneCode: '258', GeoRegion: 'Africa' },
  { name: 'Namibia', code: 'NA', flag: '🇳🇦', currency: 'NAD', language: 'English', phoneCode: '264', GeoRegion: 'Africa' },
  { name: 'Niger', code: 'NE', flag: '🇳🇪', currency: 'XOF', language: 'French', phoneCode: '227', GeoRegion: 'Africa' },
  { name: 'Nigeria', code: 'NG', flag: '🇳🇬', currency: 'NGN', language: 'English', phoneCode: '234', GeoRegion: 'Africa' },
  { name: 'Rwanda', code: 'RW', flag: '🇷🇼', currency: 'RWF', language: 'Kinyarwanda, English, French', phoneCode: '250', GeoRegion: 'Africa' },
  { name: 'São Tomé and Príncipe', code: 'ST', flag: '🇸🇹', currency: 'STN', language: 'Portuguese', phoneCode: '239', GeoRegion: 'Africa'  },
  { name: 'Senegal', code: 'SN', flag: '🇸🇳', currency: 'XOF', language: 'French', phoneCode: '221', GeoRegion: 'Africa'  },
  { name: 'Seychelles', code: 'SC', flag: '🇸🇨', currency: 'SCR', language: 'English, French', phoneCode: '248', GeoRegion: 'Africa'  },
  { name: 'Sierra Leone', code: 'SL', flag: '🇸🇱', currency: 'SLL', language: 'English', phoneCode: '232', GeoRegion: 'Africa'  },
  { name: 'Somalia', code: 'SO', flag: '🇸🇴', currency: 'SOS', language: 'Somali, Arabic', phoneCode: '252', GeoRegion: 'Africa'  },
  { name: 'South Africa', code: 'ZA', flag: '🇿🇦', currency: 'ZAR', language: 'English, Afrikaans, Zulu, Xhosa, Sotho', phoneCode: '27', GeoRegion: 'Africa'  },
  { name: 'South Sudan', code: 'SS', flag: '🇸🇸', currency: 'SSP', language: 'English', phoneCode: '211', GeoRegion: 'Africa'  },
  { name: 'Sudan', code: 'SD', flag: '🇸🇩', currency: 'SDG', language: 'Arabic, English', phoneCode: '249', GeoRegion: 'Africa'  },
  { name: 'Tanzania', code: 'TZ', flag: '🇹🇿', currency: 'TZS', language: 'Swahili, English', phoneCode: '255', GeoRegion: 'Africa'  },
  { name: 'Togo', code: 'TG', flag: '🇹🇬', currency: 'XOF', language: 'French', phoneCode: '228', GeoRegion: 'Africa'  },
  { name: 'Tunisia', code: 'TN', flag: '🇹🇳', currency: 'TND', language: 'Arabic', phoneCode: '216', GeoRegion: 'Africa'  },
  { name: 'Uganda', code: 'UG', flag: '🇺🇬', currency: 'UGX', language: 'English, Swahili', phoneCode: '256', GeoRegion: 'Africa'  },
  { name: 'Zambia', code: 'ZM', flag: '🇿🇲', currency: 'ZMW', language: 'English', phoneCode: '260', GeoRegion: 'Africa'  },
  { name: 'Zimbabwe', code: 'ZW', flag: '🇿🇼', currency: 'USD', language: 'English, Shona, Sindebele', phoneCode: '263', GeoRegion: 'Africa'  },

  
  // North America
  { name: 'United States', code: 'US', flag: '🇺🇸', currency: 'USD', language: 'English', phoneCode: '1', GeoRegion: 'North America' },
  { name: 'Canada', code: 'CA', flag: '🇨🇦', currency: 'CAD', language: 'English, French', phoneCode: '1', GeoRegion: 'North America' },
  { name: 'Mexico', code: 'MX', flag: '🇲🇽', currency: 'MXN', language: 'Spanish', phoneCode: '52', GeoRegion: 'North America' },
  { name: 'Guatemala', code: 'GT', flag: '🇬🇹', currency: 'GTQ', language: 'Spanish', phoneCode: '502', GeoRegion: 'North America' },
  { name: 'Belize', code: 'BZ', flag: '🇧🇿', currency: 'BZD', language: 'English', phoneCode: '501', GeoRegion: 'North America' },
  { name: 'El Salvador', code: 'SV', flag: '🇸🇻', currency: 'USD', language: 'Spanish', phoneCode: '503', GeoRegion: 'North America' },
  { name: 'Honduras', code: 'HN', flag: '🇭🇳', currency: 'HNL', language: 'Spanish', phoneCode: '504', GeoRegion: 'North America' },
  { name: 'Nicaragua', code: 'NI', flag: '🇳🇮', currency: 'NIO', language: 'Spanish', phoneCode: '505', GeoRegion: 'North America' },
  { name: 'Costa Rica', code: 'CR', flag: '🇨🇷', currency: 'CRC', language: 'Spanish', phoneCode: '506', GeoRegion: 'North America' },
  { name: 'Panama', code: 'PA', flag: '🇵🇦', currency: 'PAB, USD', language: 'Spanish', phoneCode: '507', GeoRegion: 'North America' },
  

  // The Caribbean
  { name: 'Jamaica', code: 'JM', flag: '🇯🇲', currency: 'JMD', language: 'English', phoneCode: '1-876', GeoRegion: 'Caribbean' },
  { name: 'Haiti', code: 'HT', flag: '🇭🇹', currency: 'HTG', language: 'French, Haitian Creole', phoneCode: '509', GeoRegion: 'Caribbean' },
  { name: 'Dominican Republic', code: 'DO', flag: '🇩🇴', currency: 'DOP', language: 'Spanish', phoneCode: '1-809, 1-829, 1-849', GeoRegion: 'Caribbean' },
  { name: 'Cuba', code: 'CU', flag: '🇨🇺', currency: 'CUP', language: 'Spanish', phoneCode: '53', GeoRegion: 'Caribbean' },
  { name: 'Bahamas', code: 'BS', flag: '🇧🇸', currency: 'BSD', language: 'English', phoneCode: '1-242', GeoRegion: 'Caribbean' },
  { name: 'Barbados', code: 'BB', flag: '🇧🇧', currency: 'BBD', language: 'English', phoneCode: '1-246', GeoRegion: 'Caribbean' },
  { name: 'Saint Lucia', code: 'LC', flag: '🇱🇨', currency: 'XCD', language: 'English', phoneCode: '1-758', GeoRegion: 'Caribbean' },
  { name: 'Grenada', code: 'GD', flag: '🇬🇩', currency: 'XCD', language: 'English', phoneCode: '1-473', GeoRegion: 'Caribbean' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', flag: '🇻🇨', currency: 'XCD', language: 'English', phoneCode: '1-784', GeoRegion: 'Caribbean' },
  { name: 'Antigua and Barbuda', code: 'AG', flag: '🇦🇬', currency: 'XCD', language: 'English', phoneCode: '1-268', GeoRegion: 'Caribbean' },
  { name: 'Dominica', code: 'DM', flag: '🇩🇲', currency: 'XCD', language: 'English', phoneCode: '1-767', GeoRegion: 'Caribbean' },
  { name: 'Trinidad and Tobago', code: 'TT', flag: '🇹🇹', currency: 'TTD', language: 'English', phoneCode: '1-868', GeoRegion: 'Caribbean' },
  { name: 'Saint Kitts and Nevis', code: 'KN', flag: '🇰🇳', currency: 'XCD', language: 'English', phoneCode: '1-869', GeoRegion: 'Caribbean' },
  { name: 'Puerto Rico', code: 'PR', flag: '🇵🇷', currency: 'USD', language: 'Spanish, English', phoneCode: '1-787, 1-939', GeoRegion: 'Caribbean' },
  { name: 'Cayman Islands', code: 'KY', flag: '🇰🇾', currency: 'KYD', language: 'English', phoneCode: '1-345', GeoRegion: 'Caribbean' },
  { name: 'Bermuda', code: 'BM', flag: '🇧🇲', currency: 'BMD', language: 'English', phoneCode: '1-441', GeoRegion: 'Caribbean' },
  { name: 'British Virgin Islands', code: 'VG', flag: '🇻🇬', currency: 'USD', language: 'English', phoneCode: '1-284', GeoRegion: 'Caribbean' },
  { name: 'Turks and Caicos Islands', code: 'TC', flag: '🇹🇨', currency: 'USD', language: 'English', phoneCode: '1-649', GeoRegion: 'Caribbean' },
  { name: 'Anguilla', code: 'AI', flag: '🇦🇮', currency: 'XCD', language: 'English', phoneCode: '1-264', GeoRegion: 'Caribbean' },
  { name: 'Montserrat', code: 'MS', flag: '🇲🇸', currency: 'XCD', language: 'English', phoneCode: '1-664', GeoRegion: 'Caribbean' },
  { name: 'Guadeloupe', code: 'GP', flag: '🇬🇵', currency: 'EUR', language: 'French', phoneCode: '590', GeoRegion: 'Caribbean' },
  { name: 'Martinique', code: 'MQ', flag: '🇲🇶', currency: 'EUR', language: 'French', phoneCode: '596', GeoRegion: 'Caribbean' },
  { name: 'Aruba', code: 'AW', flag: '🇦🇼', currency: 'AWG', language: 'Dutch, Papiamento', phoneCode: '297', GeoRegion: 'Caribbean' },
  { name: 'Netherlands Antilles', code: 'AN', flag: '🇦🇳', currency: 'ANG', language: 'Dutch', phoneCode: '599', GeoRegion: 'Caribbean' },
  
 
  // Europe
  { name: 'Germany', code: 'DE', flag: '🇩🇪', currency: 'EUR', language: 'German', phoneCode: '49', GeoRegion: 'Europe' },
  { name: 'United Kingdom', code: 'GB', flag: '🇬🇧', currency: 'GBP', language: 'English', phoneCode: '44', GeoRegion: 'Europe' },
  { name: 'France', code: 'FR', flag: '🇫🇷', currency: 'EUR', language: 'French', phoneCode: '33', GeoRegion: 'Europe' },
  { name: 'Italy', code: 'IT', flag: '🇮🇹', currency: 'EUR', language: 'Italian', phoneCode: '39', GeoRegion: 'Europe' },
  { name: 'Spain', code: 'ES', flag: '🇪🇸', currency: 'EUR', language: 'Spanish', phoneCode: '34', GeoRegion: 'Europe' },
  { name: 'Ukraine', code: 'UA', flag: '🇺🇦', currency: 'UAH', language: 'Ukrainian', phoneCode: '380', GeoRegion: 'Europe' },
  { name: 'Poland', code: 'PL', flag: '🇵🇱', currency: 'PLN', language: 'Polish', phoneCode: '48', GeoRegion: 'Europe' },
  { name: 'Romania', code: 'RO', flag: '🇷🇴', currency: 'RON', language: 'Romanian', phoneCode: '40', GeoRegion: 'Europe' },
  { name: 'Netherlands', code: 'NL', flag: '🇳🇱', currency: 'EUR', language: 'Dutch', phoneCode: '31', GeoRegion: 'Europe' },
  { name: 'Belgium', code: 'BE', flag: '🇧🇪', currency: 'EUR', language: 'Dutch, French, German', phoneCode: '32', GeoRegion: 'Europe' },
  { name: 'Greece', code: 'GR', flag: '🇬🇷', currency: 'EUR', language: 'Greek', phoneCode: '30', GeoRegion: 'Europe' },
  { name: 'Czech Republic', code: 'CZ', flag: '🇨🇿', currency: 'CZK', language: 'Czech', phoneCode: '420', GeoRegion: 'Europe' },
  { name: 'Portugal', code: 'PT', flag: '🇵🇹', currency: 'EUR', language: 'Portuguese', phoneCode: '351', GeoRegion: 'Europe' },
  { name: 'Sweden', code: 'SE', flag: '🇸🇪', currency: 'SEK', language: 'Swedish', phoneCode: '46', GeoRegion: 'Europe' },
  { name: 'Hungary', code: 'HU', flag: '🇭🇺', currency: 'HUF', language: 'Hungarian', phoneCode: '36', GeoRegion: 'Europe' },
  { name: 'Belarus', code: 'BY', flag: '🇧🇾', currency: 'BYN', language: 'Belarusian, Russian', phoneCode: '375', GeoRegion: 'Europe' },
  { name: 'Austria', code: 'AT', flag: '🇦🇹', currency: 'EUR', language: 'German', phoneCode: '43', GeoRegion: 'Europe' },
  { name: 'Switzerland', code: 'CH', flag: '🇨🇭', currency: 'CHF', language: 'German, French, Italian, Romansh', phoneCode: '41', GeoRegion: 'Europe' },
  { name: 'Bulgaria', code: 'BG', flag: '🇧🇬', currency: 'BGN', language: 'Bulgarian', phoneCode: '359', GeoRegion: 'Europe' },
  { name: 'Denmark', code: 'DK', flag: '🇩🇰', currency: 'DKK', language: 'Danish', phoneCode: '45', GeoRegion: 'Europe' },
  { name: 'Finland', code: 'FI', flag: '🇫🇮', currency: 'EUR', language: 'Finnish, Swedish', phoneCode: '358', GeoRegion: 'Europe' },
  { name: 'Slovakia', code: 'SK', flag: '🇸🇰', currency: 'EUR', language: 'Slovak', phoneCode: '421', GeoRegion: 'Europe' },
  { name: 'Norway', code: 'NO', flag: '🇳🇴', currency: 'NOK', language: 'Norwegian', phoneCode: '47', GeoRegion: 'Europe' },
  { name: 'Ireland', code: 'IE', flag: '🇮🇪', currency: 'EUR', language: 'English, Irish', phoneCode: '353', GeoRegion: 'Europe' },
  { name: 'Croatia', code: 'HR', flag: '🇭🇷', currency: 'HRK', language: 'Croatian', phoneCode: '385', GeoRegion: 'Europe' },
  { name: 'Moldova', code: 'MD', flag: '🇲🇩', currency: 'MDL', language: 'Romanian', phoneCode: '373', GeoRegion: 'Europe' },
  { name: 'Bosnia and Herzegovina', code: 'BA', flag: '🇧🇦', currency: 'BAM', language: 'Bosnian, Croatian, Serbian', phoneCode: '387', GeoRegion: 'Europe' },
  { name: 'Albania', code: 'AL', flag: '🇦🇱', currency: 'ALL', language: 'Albanian', phoneCode: '355', GeoRegion: 'Europe' },
  { name: 'Lithuania', code: 'LT', flag: '🇱🇹', currency: 'EUR', language: 'Lithuanian', phoneCode: '370', GeoRegion: 'Europe' },
  { name: 'North Macedonia', code: 'MK', flag: '🇲🇰', currency: 'MKD', language: 'Macedonian', phoneCode: '389', GeoRegion: 'Europe' },
  { name: 'Slovenia', code: 'SI', flag: '🇸🇮', currency: 'EUR', language: 'Slovene', phoneCode: '386', GeoRegion: 'Europe' },
  { name: 'Latvia', code: 'LV', flag: '🇱🇻', currency: 'EUR', language: 'Latvian', phoneCode: '371', GeoRegion: 'Europe' },
  { name: 'Estonia', code: 'EE', flag: '🇪🇪', currency: 'EUR', language: 'Estonian', phoneCode: '372', GeoRegion: 'Europe' },
  { name: 'Montenegro', code: 'ME', flag: '🇲🇪', currency: 'EUR', language: 'Montenegrin', phoneCode: '382', GeoRegion: 'Europe' },
  { name: 'Luxembourg', code: 'LU', flag: '🇱🇺', currency: 'EUR', language: 'Luxembourgish, French, German', phoneCode: '352', GeoRegion: 'Europe' },
  { name: 'Andorra', code: 'AD', flag: '🇦🇩', currency: 'EUR', language: 'Catalan', phoneCode: '376', GeoRegion: 'Europe' },
  { name: 'Malta', code: 'MT', flag: '🇲🇹', currency: 'EUR', language: 'Maltese, English', phoneCode: '356', GeoRegion: 'Europe' },
  { name: 'Iceland', code: 'IS', flag: '🇮🇸', currency: 'ISK', language: 'Icelandic', phoneCode: '354', GeoRegion: 'Europe' },
  { name: 'Liechtenstein', code: 'LI', flag: '🇱🇮', currency: 'CHF', language: 'German', phoneCode: '423', GeoRegion: 'Europe' },
  { name: 'Monaco', code: 'MC', flag: '🇲🇨', currency: 'EUR', language: 'French', phoneCode: '377', GeoRegion: 'Europe' },
  { name: 'San Marino', code: 'SM', flag: '🇸🇲', currency: 'EUR', language: 'Italian', phoneCode: '378', GeoRegion: 'Europe' },

  // Asia
  { name: 'China', code: 'CN', flag: '🇨🇳', currency: 'CNY', language: 'Mandarin', phoneCode: '86', GeoRegion: 'Asia' },
  { name: 'India', code: 'IN', flag: '🇮🇳', currency: 'INR', language: 'Hindi, English', phoneCode: '91', GeoRegion: 'Asia' },
  { name: 'Indonesia', code: 'ID', flag: '🇮🇩', currency: 'IDR', language: 'Indonesian', phoneCode: '62', GeoRegion: 'Asia' },
  { name: 'Pakistan', code: 'PK', flag: '🇵🇰', currency: 'PKR', language: 'Urdu, English', phoneCode: '92', GeoRegion: 'Asia' },
  { name: 'Bangladesh', code: 'BD', flag: '🇧🇩', currency: 'BDT', language: 'Bengali', phoneCode: '880', GeoRegion: 'Asia' },
  { name: 'Japan', code: 'JP', flag: '🇯🇵', currency: 'JPY', language: 'Japanese', phoneCode: '81', GeoRegion: 'Asia' },
  { name: 'Philippines', code: 'PH', flag: '🇵🇭', currency: 'PHP', language: 'Filipino, English', phoneCode: '63', GeoRegion: 'Asia' },
  { name: 'Vietnam', code: 'VN', flag: '🇻🇳', currency: 'VND', language: 'Vietnamese', phoneCode: '84', GeoRegion: 'Asia' },
 
  
  { name: 'Thailand', code: 'TH', flag: '🇹🇭', currency: 'THB', language: 'Thai', phoneCode: '66', GeoRegion: 'Asia' },
  { name: 'Myanmar', code: 'MM', flag: '🇲🇲', currency: 'MMK', language: 'Burmese', phoneCode: '95', GeoRegion: 'Asia' },
  { name: 'South Korea', code: 'KR', flag: '🇰🇷', currency: 'KRW', language: 'Korean', phoneCode: '82', GeoRegion: 'Asia' },
  { name: 'Afghanistan', code: 'AF', flag: '🇦🇫', currency: 'AFN', language: 'Pashto, Dari', phoneCode: '93', GeoRegion: 'Asia' },
  { name: 'Uzbekistan', code: 'UZ', flag: '🇺🇿', currency: 'UZS', language: 'Uzbek', phoneCode: '998', GeoRegion: 'Asia' },
  { name: 'Malaysia', code: 'MY', flag: '🇲🇾', currency: 'MYR', language: 'Malay', phoneCode: '60', GeoRegion: 'Asia' },
  { name: 'Nepal', code: 'NP', flag: '🇳🇵', currency: 'NPR', language: 'Nepali', phoneCode: '977', GeoRegion: 'Asia' },
  { name: 'North Korea', code: 'KP', flag: '🇰🇵', currency: 'KPW', language: 'Korean', phoneCode: '850', GeoRegion: 'Asia' },
  { name: 'Sri Lanka', code: 'LK', flag: '🇱🇰', currency: 'LKR', language: 'Sinhala, Tamil', phoneCode: '94', GeoRegion: 'Asia' },
  { name: 'Kazakhstan', code: 'KZ', flag: '🇰🇿', currency: 'KZT', language: 'Kazakh, Russian', phoneCode: '7', GeoRegion: 'Asia' },
  { name: 'Cambodia', code: 'KH', flag: '🇰🇭', currency: 'KHR', language: 'Khmer', phoneCode: '855', GeoRegion: 'Asia' },
  { name: 'Azerbaijan', code: 'AZ', flag: '🇦🇿', currency: 'AZN', language: 'Azerbaijani', phoneCode: '994', GeoRegion: 'Asia' },
  { name: 'Tajikistan', code: 'TJ', flag: '🇹🇯', currency: 'TJS', language: 'Tajik', phoneCode: '992', GeoRegion: 'Asia' },
  { name: 'Laos', code: 'LA', flag: '🇱🇦', currency: 'LAK', language: 'Lao', phoneCode: '856', GeoRegion: 'Asia' },
  { name: 'Kyrgyzstan', code: 'KG', flag: '🇰🇬', currency: 'KGS', language: 'Kyrgyz, Russian', phoneCode: '996', GeoRegion: 'Asia' },
  { name: 'Turkmenistan', code: 'TM', flag: '🇹🇲', currency: 'TMT', language: 'Turkmen', phoneCode: '993', GeoRegion: 'Asia' },
  { name: 'Singapore', code: 'SG', flag: '🇸🇬', currency: 'SGD', language: 'English, Malay, Mandarin, Tamil', phoneCode: '65', GeoRegion: 'Asia' },
  { name: 'Georgia', code: 'GE', flag: '🇬🇪', currency: 'GEL', language: 'Georgian', phoneCode: '995', GeoRegion: 'Asia' },
  { name: 'Mongolia', code: 'MN', flag: '🇲🇳', currency: 'MNT', language: 'Mongolian', phoneCode: '976', GeoRegion: 'Asia' },
  { name: 'Armenia', code: 'AM', flag: '🇦🇲', currency: 'AMD', language: 'Armenian', phoneCode: '374', GeoRegion: 'Asia' },

  // Oceania
  { name: 'Australia', code: 'AU', flag: '🇦🇺', currency: 'AUD', language: 'English', phoneCode: '61', GeoRegion: 'Oceania' },
  { name: 'Fiji', code: 'FJ', flag: '🇫🇯', currency: 'FJD', language: 'English, Fijian', phoneCode: '679', GeoRegion: 'Oceania' },
  { name: 'Kiribati', code: 'KI', flag: '🇰🇮', currency: 'AUD', language: 'English, Gilbertese', phoneCode: '686', GeoRegion: 'Oceania' },
  { name: 'Marshall Islands', code: 'MH', flag: '🇲🇭', currency: 'USD', language: 'Marshallese, English', phoneCode: '692', GeoRegion: 'Oceania' },
  { name: 'Micronesia', code: 'FM', flag: '🇫🇲', currency: 'USD', language: 'English', phoneCode: '691', GeoRegion: 'Oceania' },
  { name: 'Nauru', code: 'NR', flag: '🇳🇷', currency: 'AUD', language: 'Nauruan, English', phoneCode: '674', GeoRegion: 'Oceania' },
  { name: 'New Zealand', code: 'NZ', flag: '🇳🇿', currency: 'NZD', language: 'English, Māori', phoneCode: '64', GeoRegion: 'Oceania' },
  { name: 'Palau', code: 'PW', flag: '🇵🇼', currency: 'USD', language: 'English, Palauan', phoneCode: '680', GeoRegion: 'Oceania' },
  { name: 'Papua New Guinea', code: 'PG', flag: '🇵🇬', currency: 'PGK', language: 'English, Tok Pisin, Hiri Motu', phoneCode: '675', GeoRegion: 'Oceania' },
  { name: 'Samoa', code: 'WS', flag: '🇼🇸', currency: 'WST', language: 'Samoan, English', phoneCode: '685', GeoRegion: 'Oceania' },
  { name: 'Solomon Islands', code: 'SB', flag: '🇸🇧', currency: 'SBD', language: 'English', phoneCode: '677', GeoRegion: 'Oceania' },
  { name: 'Tonga', code: 'TO', flag: '🇹🇴', currency: 'TOP', language: 'Tongan, English', phoneCode: '676', GeoRegion: 'Oceania' },
  { name: 'Tuvalu', code: 'TV', flag: '🇹🇻', currency: 'AUD', language: 'Tuvaluan, English', phoneCode: '688', GeoRegion: 'Oceania' },
  { name: 'Vanuatu', code: 'VU', flag: '🇻🇺', currency: 'VUV', language: 'Bislama, English, French', phoneCode: '678', GeoRegion: 'Oceania' },

  
  // The Middle East
  { name: 'Saudi Arabia', code: 'SA', flag: '🇸🇦', currency: 'SAR', language: 'Arabic', phoneCode: '966', GeoRegion: 'West Asia' },
  { name: 'Turkey', code: 'TR', flag: '🇹🇷', currency: 'TRY', language: 'Turkish', phoneCode: '90', GeoRegion: 'West Asia' },
  { name: 'Iran', code: 'IR', flag: '🇮🇷', currency: 'IRR', language: 'Persian', phoneCode: '98', GeoRegion: 'West Asia' },
  { name: 'United Arab Emirates', code: 'AE', flag: '🇦🇪', currency: 'AED', language: 'Arabic', phoneCode: '971', GeoRegion: 'West Asia' },
  { name: 'Israel', code: 'IL', flag: '🇮🇱', currency: 'ILS', language: 'Hebrew, Arabic', phoneCode: '972', GeoRegion: 'West Asia' },
  { name: 'Jordan', code: 'JO', flag: '🇯🇴', currency: 'JOD', language: 'Arabic', phoneCode: '962', GeoRegion: 'West Asia' },
  { name: 'Iraq', code: 'IQ', flag: '🇮🇶', currency: 'IQD', language: 'Arabic, Kurdish', phoneCode: '964', GeoRegion: 'West Asia' },
  { name: 'Qatar', code: 'QA', flag: '🇶🇦', currency: 'QAR', language: 'Arabic', phoneCode: '974', GeoRegion: 'West Asia' },
  { name: 'Kuwait', code: 'KW', flag: '🇰🇼', currency: 'KWD', language: 'Arabic', phoneCode: '965', GeoRegion: 'West Asia' },
  { name: 'Bahrain', code: 'BH', flag: '🇧🇭', currency: 'BHD', language: 'Arabic', phoneCode: '973', GeoRegion: 'West Asia' },
  { name: 'Lebanon', code: 'LB', flag: '🇱🇧', currency: 'LBP', language: 'Arabic, French', phoneCode: '961', GeoRegion: 'West Asia' },
  { name: 'Oman', code: 'OM', flag: '🇴🇲', currency: 'OMR', language: 'Arabic', phoneCode: '968', GeoRegion: 'West Asia' },
  { name: 'Syria', code: 'SY', flag: '🇸🇾', currency: 'SYP', language: 'Arabic', phoneCode: '963', GeoRegion: 'West Asia' },
  { name: 'Yemen', code: 'YE', flag: '🇾🇪', currency: 'YER', language: 'Arabic', phoneCode: '967', GeoRegion: 'West Asia' },
  { name: 'Palestine', code: 'PS', flag: '🇵🇸', currency: 'ILS', language: 'Arabic', phoneCode: '970', GeoRegion: 'West Asia' }
];









