import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore, projectStorage, timestamp } from '../../../firebase/config';
import { genres, languages } from '../../../constants/bookData';
import { africanCountries } from '../../../constants/countries';
import { useCountrySettings } from '../../../context/CountrySettingsContext';

import styles from './AddBook.module.css';

const AddBook = ({ defaultCountry }) => {
  // Move the useState for currency below the selectedDefaultCountry declaration
const { user } = useAuthContext();
const history = useHistory();

// Retrieve the temporary country code from sessionStorage
const tempCountryCode = sessionStorage.getItem('tempCountry');
const tempCountry = africanCountries.find((country) => country.code === tempCountryCode);

// Update the defaultCountry to be the tempCountry, the user's defaultCountry, or the first country in africanCountries
const selectedDefaultCountry = tempCountry || defaultCountry || africanCountries[0];

// Move the useState for currency here and initialize it with the selectedDefaultCountry's currency
const [currency, setCurrency] = useState(selectedDefaultCountry.currency);

const { selectedCountry, setSelectedCountry } = useCountrySettings(selectedDefaultCountry.code);

// Other useState declarations and the rest of your code


  const [bookName, setBookName] = useState(''); 
  const [bookAuthor, setBookAuthor] = useState('');
  const [bookType, setBookType] = useState('Subscription');
  const [bookPrice, setBookPrice] = useState('');
  const [subscriptionFee, setSubscriptionFee] = useState('');
  const [bookFreeAccess, setBookFreeAccess] = useState(false);
  const [bookSubscriptionAccess, setBookSubscriptionAccess] = useState(false);
  const [bookGenre, setBookGenre] = useState('');
  const [bookLanguage, setBookLanguage] = useState('');
  //const [bookRating, setBookRating] = useState('');
  const [bookDescription, setBookDescription] = useState('');
  const [bookCoverUrl, setBookCoverUrl] = useState('');
  const [bookCoverImage, setBookCoverImage] = useState(null)
  const [bookCoverImageError, setBookCoverImageError] = useState(null)
  
  //const [bookCurrency, setBookCurrency] = useState('');

  const [lastChapterContent, setLastChapterContent] = useState(
    'Please add the chapter content here. It can be further edited or updated in the UpdateChapter component'
  );
  
  const [chapterTitles, setChapterTitles] = useState([{ number: 1, title: '', content: 'Please add the chapter content here. It can be further edited or updated in the UpdateChapter component' }]);
  
  const handleChangeChapterTitle = (e, index) => {
    const updatedTitles = [...chapterTitles];
    updatedTitles[index] = { ...updatedTitles[index], title: e.target.value };
    setChapterTitles(updatedTitles);
  };

   const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    const selectedCountryData = africanCountries.find((country) => country.code === e.target.value);
    setCurrency(selectedCountryData.currency);
  };
  


  useEffect(() => {
    const selectedCountryData = africanCountries.find(
      (country) => country.code === selectedCountry
    );
    if (selectedCountryData) {
      setCurrency(selectedCountryData.currency);
    }
  }, [selectedCountry]);
  


  const handleChangeChapterContent = (e, index) => {
    const updatedTitles = [...chapterTitles];
    updatedTitles[index] = { ...updatedTitles[index], content: e.target.value };
    setChapterTitles(updatedTitles);
    setLastChapterContent(e.target.value);
  };
  


  const handleAddChapterTitle = () => {
    if (lastChapterContent !== '') {
      const chapterNumber = chapterTitles.length + 1;
      setChapterTitles([
        ...chapterTitles,
        { number: chapterNumber, title: '', content: '' },
      ]);
      setLastChapterContent(
        'Please add the chapter content here. It can be further edited or updated in the UpdateChapter component'
      );
    }
  };
  

  const handleRemoveChapterTitle = (index) => {
    const updatedTitles = [...chapterTitles];
    updatedTitles.splice(index, 1);
    setChapterTitles(updatedTitles);
  };

  const handleBookCoverImageFile = async (e) => {
    setBookCoverImage(null);
    let selected = e.target.files[0];
    console.log(selected);
  
    if (!selected) {
      setBookCoverImageError('Please select a file');
      return;
    }
    if (!selected.type.includes('image')) {
      setBookCoverImageError('Selected file must be an image');
      return;
    }
    if (selected.size > 250000) {
      setBookCoverImageError('Image file size must be less than 250kb');
      return;
    }
    // TODO: Set the visual image size uploadable.
  
    setBookCoverImageError(null);
    setBookCoverImage(selected);
    console.log('visualNail image uploaded');
  
    // Upload the file to the storage location
    const storageRef = projectStorage.ref(`bookCovers/${selected.name}`);
    const uploadTask = storageRef.put(selected);
  
    uploadTask.on('state_changed', 
      (snapshot) => {
        // You can use this callback to monitor the progress of the upload
      }, 
      (error) => {
        console.error('Error uploading file: ', error);
      }, 
      async () => {
        // Get the download URL of the uploaded file
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        setBookCoverUrl(downloadURL);
        console.log('Book cover image URL: ', downloadURL);
        console.log('Book cover bookCoverUrl: ', bookCoverUrl);
      }
    );
  };
  


  const handleBookSubmit = (e) => {
    e.preventDefault();

    const bookRef = projectFirestore.collection('anpbooks').doc();
    const createdAt = timestamp();

    const newBook = {
      id: bookRef.id,
      bookName,
      bookAuthor,
      bookType,
      bookPrice,
      subscriptionFee,
      currency,
      bookFreeAccess,
      bookSubscriptionAccess,
      bookGenre,
      bookLanguage, 
     // bookRating,
      bookDescription,
      bookCoverUrl,
      accountUser: user.displayName,
      accountUserId: user.uid,
      chapterTitles,
      chapterContent: chapterTitles.map((chapter) => chapter.content),
      createdAt,
    };

    console.log("bookRef Not this one", bookRef)
    console.log("newBook, is the written top firestore", newBook)

     bookRef
       .set(newBook)
       .then(() => {
         console.log('New book added');
         history.push('/books');
       })
       .catch((error) => {
         console.error('Error adding book: ', error);
       });
  };

  return (
    <div className={styles.addBookForm}>
      <h2 className={styles.pageTitle}>Add Book</h2>
      <form onSubmit={handleBookSubmit} className={styles.bookForm}>
        <div className={styles.formGroup}>
        <label htmlFor="country-select">Country</label>
        <select
          id="country-select"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          {africanCountries.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
          <label htmlFor="bookName" className={styles.formLabel}>
            Book Name
          </label>
          <input
            type="text"
            id="bookName"
            name="bookName"
            value={bookName}
            onChange={(e) => setBookName(e.target.value)}
            className={styles.formInput}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="bookAuthor" className={styles.formLabel}>
            Book Author
          </label>
          <input
            type="text"
            id="bookAuthor"
            name="bookAuthor"
            value={bookAuthor}
            onChange={(e) => setBookAuthor(e.target.value)}
            className={styles.formInput}
            required
          />
        </div>

        <div className={styles.formGroup}>
              <label htmlFor="bookType" className={styles.formLabel}>
                Book Type
              </label>
              <div className={styles.formRadioGroup}>
              <div className={styles.formRadio}>
                  <input
                    type="radio"
                    id="bookTypeCompleteBook"
                    name="bookType"
                    value="Complete Book"
                    checked={bookType === 'Complete Book'}
                    onChange={(e) => setBookType(e.target.value)}
                    className={styles.formInput}
                  />
                  <label htmlFor="bookTypeCompleteBook" className={styles.formLabel}>
                    Complete Book
                  </label>
                </div>

                <div className={styles.formRadio}>
                  <input
                    type="radio"
                    id="bookTypeSerializedBook"
                    name="bookType"
                    value="Serialized Book"
                    checked={bookType === 'Serialized Book'}
                    onChange={(e) => setBookType(e.target.value)}
                    className={styles.formInput}
                    required
                  />
                  <label htmlFor="bookTypeSerializedBook" className={styles.formLabel}>
                  Serialized Book
                  </label>
                </div>
                
                <div className={styles.formRadio}>
                  <input
                    type="radio"
                    id="bookTypeFreeBook"
                    name="bookType"
                    value="Free Book"
                    checked={bookType === 'Free Book'}
                    onChange={(e) => setBookType(e.target.value)}
                    className={styles.formInput}
                  />
                  <label htmlFor="bookTypeFreeBook" className={styles.formLabel}>
                    Free Book
                  </label>
                </div>
              </div>
            </div>

            {bookType === 'Complete Book' && (
              <div className={styles.formGroup}>
  <label htmlFor="bookPrice" className={styles.formLabel}>
    Book Price
  </label>
  <div className={styles.inputContainer}>
    <input
      type="number"
      id="bookPrice"
      name="bookPrice"
      value={bookPrice}
      onChange={(e) => setBookPrice(e.target.value)}
      className={styles.formInput}
      required
    />
    <div className={styles.currencySymbolWrapper}>
      <span className={styles.currencySymbol}>{currency}</span>
    </div>
  </div>
</div>

)}

{bookType === 'Serialized Book' && (
  <div className={styles.formGroup}>
    <label htmlFor="subscriptionFee" className={styles.formLabel}>
      Monthly Subscription Fee
    </label>
    <input
      type="number"
      id="subscriptionFee"
      name="subscriptionFee"
      value={subscriptionFee}
      onChange={(e) => setSubscriptionFee(e.target.value)}
      className={styles.formInput}
      required
    />
    <div className={styles.currencySymbolWrapper}>
      <span className={styles.currencySymbol}>{currency}</span>
    </div>
  </div>

)}



      {bookType === 'Free Book' && (
        <div className={styles.formGroup}>
          <label htmlFor="bookFreeAccess" className={styles.formLabel}>
            Free Access
          </label>
          <input
            type="checkbox"
            id="bookFreeAccess"
            name="bookFreeAccess"
            checked={bookFreeAccess}
            onChange={(e) => setBookFreeAccess(e.target.checked)}
            className={styles.formInput}
          />
        </div>
      )}


      {bookType === 'Serialized Book' && (
        <div className={styles.formGroup}>
          <label htmlFor="bookSubscriptionAccess" className={styles.formLabel}>
            Subscription Access
          </label>
          <input
            type="checkbox"
            id="bookSubscriptionAccess"
            name="bookSubscriptionAccess"
            checked={bookSubscriptionAccess}
            onChange={(e) => setBookSubscriptionAccess(e.target.checked)}
            className={styles.formInput}
          />
        </div>
      )}
      <div className={styles.formGroup}>
        <label htmlFor="bookGenre" className={styles.formLabel}>
          Book Genre
        </label>
        <select
          id="bookGenre"
          name="bookGenre"
          value={bookGenre}
          onChange={(e) => setBookGenre(e.target.value)}
          className={styles.formInput}
          required
        >
          {genres.map((genre, index) => (
            <option key={index} value={genre}>
              {genre}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="bookLanguage" className={styles.formLabel}>
          Book Language
        </label>
        <select
          id="bookLanguage"
          name="bookLanguage"
          value={bookLanguage}
          onChange={(e) => setBookLanguage(e.target.value)}
          className={styles.formInput}
          required
        >
          {languages.map((language, index) => (
            <option key={index} value={language}>
              {language}
            </option>
          ))}
        </select>
      </div>

      


    <div className={styles.formGroup}>
        <label htmlFor="bookDescription" className={styles.formLabel}>
          Book Description
        </label>
        <textarea
          id="bookDescription"
          name="bookDescription"
          value={bookDescription}
          onChange={(e) => setBookDescription(e.target.value)}
          className={styles.formInput}
          rows="4"
          placeholder="Enter book description"
        />
    </div>


      <div className={styles.formGroup}>
      <label htmlFor="bookCoverUrl" className={styles.formLabel}>
            Book Cover Image Upload
            <input 
            required
            type="file" 
            onChange={handleBookCoverImageFile}
          />
          {bookCoverImageError && <div className={styles.error}>{bookCoverImageError}</div>}
          </label>
      </div>

        {chapterTitles.map((chapter, index) => (
          <div key={index} className={styles.chapterInputWrapper}>
  <label htmlFor={`chapterTitle-${index}`} className={styles.formLabel}>
    Chapter Title
  </label>
  <div className={styles.chapterInputContainer}>
    <input
      type="number"
      id={`chapterNumber-${index}`}
      name={`chapterNumber-${index}`}
      value={index + 1}
      className={styles.chapterNumberInput}
      disabled
    />
    <input
      type="text"
      id={`chapterTitle-${index}`}
      name={`chapterTitle-${index}`}
      value={chapter.title}
      onChange={(e) => handleChangeChapterTitle(e, index)}
      className={styles.chapterTitleInput}
      placeholder={`Chapter ${index + 1} Title`}
    />
    <button
      type="button"
      className={styles.removeChapterBtn}
      onClick={() => handleRemoveChapterTitle(index)}
    >
      Remove
    </button>
  </div>
  <div className={styles.formGroup}>
    <label htmlFor={`chapterContent-${index}`} className={styles.formLabel}>
      Chapter Content
    </label>
    <textarea
      id={`chapterContent-${index}`}
      name={`chapterContent-${index}`}
      value={chapter.content}
      onChange={(e) => handleChangeChapterContent(e, index)}
      className={styles.chapterContentInput}
      placeholder="Please add the chapter context here. It can be further edited or updated in the UpdateChapter component"
      style={{ overflow: "auto", height: "300px" }} // add this style property
    />
  </div>
  {/* TODO:  Get the scrollable bar working*/}
</div>
))}
<button type="button" className={styles.addChapterBtn} onClick={handleAddChapterTitle}>
  Add Chapter
</button>


<div className={styles.formGroup}>
  <button type="submit" className={styles.submitBtn}>
    Add Book
  </button>
</div>
</form>
</div>
);
}

export default AddBook;
