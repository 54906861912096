import React, { useState, useEffect } from 'react';
import { africanCountries } from '../../../constants/countries';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { projectFirestore } from '../../../firebase/config';
import { useAuthContext } from '../../../hooks/useAuthContext';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const CountrySettings = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [defaultCountry, setDefaultCountry] = useState('');
  const { user } = useAuthContext(); // Get the currentUser from the auth context
  const defaultCountryName = africanCountries.find(country => country.code === defaultCountry)?.name;

  useEffect(() => {
    console.log("Current user from Country Settings:", user);

    if (user) {
      const fetchCountry = async () => {
        try {
          const userDocRef = projectFirestore.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();
          if (userDoc.exists && userDoc.data()) {
            const userCountry = userDoc.data().country;
            setSelectedCountry(userCountry);
            setDefaultCountry(userCountry);
          }
        } catch (error) {
          console.error('Error fetching user country:', error);
        }
      };
      fetchCountry();
    }
  }, [user]); // Add the currentUser to the dependency array

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSubmit = async () => {
    if (!user) {
      console.log("Current user is not defined.");
      return;
    }
  
    try {
      const userDocRef = projectFirestore.collection("users").doc(user.uid);
      await userDocRef.update({ country: selectedCountry });
      setDefaultCountry(selectedCountry);
      sessionStorage.setItem("tempCountry", selectedCountry);
      console.log("Country updated successfully.");
    } catch (error) {
      console.error('Error updating user country:', error);
    }
  };
  
  return (
    <div>
      <StyledFormControl>
        <InputLabel htmlFor="country-select">Country</InputLabel>
        <Select
          value={selectedCountry}
          onChange={handleCountryChange}
          inputProps={{
            name: 'country',
            id: 'country-select',
          }}
        >
          {africanCountries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.flag} {country.name}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <div>
        <p>Your default country is: {defaultCountryName}</p>
      </div>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Save
      </StyledButton>
    </div>
  );
};

export default CountrySettings;
