// DeliveryCostCalculations.js
import { projectFirestore } from "../../../firebase/config";
// import { haversineDistance, adjustDistance, calculateDistanceFee, calculateWeightCost, calculateSizeCost, calculateWeightSizeMultiplier } from './DeliveryCostHelpers';
import { 
  haversineDistance, 
  adjustDistance, 
  calculateDistanceFee, 
  calculateWeightCost, 
  calculateSizeCost, 
  calculateWeightSizeMultiplier,
  SERVICE_LEVEL_MULTIPLIER 
} from './DeliveryCostHelpers';

export const calculateDeliveryCost = async (packageDetails, serviceLevel, pickupAddress, deliveryAddress) => {
  const weight = parseFloat(packageDetails.weight);
  const dimensions = {
    length: parseFloat(packageDetails.dimensions.length),
    width: parseFloat(packageDetails.dimensions.width),
    height: parseFloat(packageDetails.dimensions.height),
  };

  if (isNaN(weight) || isNaN(dimensions.length) || isNaN(dimensions.width) || isNaN(dimensions.height)) {
    console.error("Invalid package details provided for delivery cost calculation.");
    return 0;
  }

  const pickupCoords = {
    lat: parseFloat(pickupAddress.latitude),
    lng: parseFloat(pickupAddress.longitude)
  };

  const deliveryCoords = {
    lat: parseFloat(deliveryAddress.latitude),
    lng: parseFloat(deliveryAddress.longitude)
  };

  // Validate coordinates
  if (isNaN(pickupCoords.lat) || isNaN(pickupCoords.lng) || isNaN(deliveryCoords.lat) || isNaN(deliveryCoords.lng)) {
    console.error("Invalid coordinates for delivery cost calculation.");
    return 0;
  }

  const straightLineDistance = haversineDistance(pickupCoords, deliveryCoords);
  console.log("Straight Line Distance", straightLineDistance)

  const adjustedDistance = adjustDistance(straightLineDistance);
  console.log("Adjusted Distance", adjustedDistance)

  const distanceFee = calculateDistanceFee(adjustedDistance);
  console.log("Distance Fee", distanceFee)

  const weightCost = calculateWeightCost(weight);
  console.log("Weight Cost", weightCost)

  const sizeCost = calculateSizeCost(dimensions);
  console.log("Size Cost", sizeCost)

  const weightSizeMultiplier = calculateWeightSizeMultiplier(weight, dimensions);
  console.log("Weight Size Multiplier", weightSizeMultiplier)

  const totalCost = (distanceFee + weightCost + sizeCost) * weightSizeMultiplier * SERVICE_LEVEL_MULTIPLIER[serviceLevel];
  console.log("TotalCost", totalCost)

  return totalCost;
};
