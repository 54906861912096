import { useEffect, useState } from 'react'
import { projectAuth, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()
  
  const logout = async () => {
    setError(null);
    setIsPending(true);
  
    try {
      // update online status
      const { uid } = projectAuth.currentUser;
      console.log(`User UID: ${uid}`); // Add this line
  
      // Read the document before updating
      const userDocRef = projectFirestore.collection("users").doc(uid);
      const userDoc = await userDocRef.get();
  
      if (!userDoc.exists) {
        console.log("Document doesn't exist");
      } else {
        console.log("Attempting to update online status"); // Add this line
        await userDocRef.update({ online: false });
        console.log("Online status updated"); // Add this line
      }
  
      // sign the user out
      console.log("Attempting to sign out"); // Add this line
      await projectAuth.signOut();
      console.log("Signed out"); // Add this line
  
      // dispatch logout action
      console.log("Attempting to dispatch logout action"); // Add this line
      dispatch({ type: "LOGOUT" });
      console.log("Dispatched logout action"); // Add this line
  
      // update state
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      console.log("Error:", err); // Add this line
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };
  

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { logout, error, isPending }
}
