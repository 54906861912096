import { useState, useContext } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { AuthContext } from "../../context/AuthContext";
import styles from './FlutterwavePayments.module.css';

export default function FlutterwavePayments({ amount, onPaymentSuccess }) {
    const { user } = useContext(AuthContext);

    // Set default values from user context
    const [name, setName] = useState(user.displayName || "");
    const [phone, setPhone] = useState(user.phoneNumber || "");
    const [email, setEmail] = useState(user.email || "");

    const config = {
        public_key: "FLWPUBK_TEST-fca134bfcf214dc9f3ef2182b0dfd7fd-X",
        tx_ref: Date.now(),
        amount: amount,
        currency: "GHS",
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: email,
            phone_number: phone,
            name: name,
        },
        customizations: {
            title: "my Payment Title",
            description: "Payment for items in cart",
            logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    return (
        <div className={styles.flutterwavepayments}>
            <div className={styles.container}>
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email" // For email fields
                />
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="name" // For name fields
                />
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    autoComplete="tel" // For telephone number fields
                />

                <button
                    className={styles.button}
                    onClick={() =>
                        handleFlutterPayment({
                            callback: (response) => {
                                console.log(response);
                                onPaymentSuccess(response);
                                closePaymentModal();
                            },
                            onClose: () => {},
                        })
                    }
                >
                    Pay {amount ? `${amount.toFixed(2)}` : ''}
                </button>
            </div>
        </div>
    );
}
