import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './OrderStatusManager.module.css';

const OrderStatusManager = () => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchOrders = async () => {
            setIsLoading(true);
            try {
                const snapshot = await projectFirestore.collection('deliveryorders').get();
                const ordersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrders(ordersData);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
            setIsLoading(false);
        };

        fetchOrders();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            orders.forEach(order => {
                // Assume there's a timestamp field 'statusUpdatedAt' that stores when the status was last updated
                const statusUpdatedAt = order.statusUpdatedAt ? order.statusUpdatedAt.toDate() : new Date();
                const timeSinceUpdate = new Date() - statusUpdatedAt;

                if (order.orderStatus === 'picked_up' && timeSinceUpdate > 10 * 60 * 1000) { // 10 minutes passed
                    updateOrderStatus(order.id, 'in_transit');
                } else if (order.orderStatus === 'in_transit' && timeSinceUpdate > 60 * 60 * 1000) { // 1 hour passed
                    updateOrderStatus(order.id, 'delivered');
                }
            });
        }, 5 * 60 * 1000); // Check every 5 minutes

        return () => clearInterval(intervalId);
    }, [orders]);

    const updateOrderStatus = async (orderId, newStatus) => {
        console.log(`Updating status for order ID ${orderId} to ${newStatus}...`);
        try {
            await projectFirestore.collection('deliveryorders').doc(orderId).update({
                orderStatus: newStatus,
                statusUpdatedAt: new Date() // set the current date as the last status update time
            });
            console.log(`Status updated to ${newStatus} for order ID ${orderId}`);
            // Update the local state to reflect changes immediately
            setOrders(orders.map(order => order.id === orderId ? { ...order, orderStatus: newStatus } : order));
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    // Function to manually update status via button click
    const handleStatusChangeClick = (orderId, newStatus) => {
        updateOrderStatus(orderId, newStatus);
    };

    if (isLoading) {
        return <div>Loading orders...</div>;
    }

    return (
        <div className={styles.orderStatusManagerContainer}>
            <h2>Order Status Manager</h2>
            <ul className={styles.ordersList}>
                {orders.map(order => (
                    <li key={order.id} className={styles.orderItem}>
                        <div>
                            <p><strong>Order ID:</strong> {order.id}</p>
                            <p><strong>Current Status:</strong> {order.orderStatus}</p>
                            {/* Add more details if needed */}
                        </div>
                        <div className={styles.actions}>
                            {/* Render buttons based on current status */}
                            {order.orderStatus === 'pending' && (
                                <button onClick={() => handleStatusChangeClick(order.id, 'picked_up')}>
                                    Mark as Picked Up
                                </button>
                            )}
                            {order.orderStatus === 'picked_up' && (
                                <button onClick={() => handleStatusChangeClick(order.id, 'in_transit')}>
                                    Mark as In Transit
                                </button>
                            )}
                            {order.orderStatus === 'in_transit' && (
                                <button onClick={() => handleStatusChangeClick(order.id, 'delivered')}>
                                    Mark as Delivered
                                </button>
                            )}
                            {/* Add more status update buttons as needed */}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default OrderStatusManager;