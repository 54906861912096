import React, { useState } from 'react';
import { projectFirestore, projectStorage, timestamp } from '../../../firebase/config';
import { africanCountries } from '../../../constants/countries';

import styles from './AgentRegistrationForm.module.css';

const AgentRegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    whatsappLink: '',
    website: '',
    logoImage: null,
    pagePicture: null,
    pageDescription: '',
    agentPromoVideo: null,
    affiliations: '',
    languages: '',
    services: '',
    numberOfEmployees: '',
    reviewsAverageScore: '',
    country: ''
  });

  const [pagePictures, setPagePictures] = useState([]);
  const [agentPromoVideos, setAgentPromoVideos] = useState([]);

  const handleLogoImageChange = (event) => {
    setFormData({ ...formData, logoImage: event.target.files[0] });
  };  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePagePictureChange = (event) => {
    setPagePictures([...event.target.files]);
  };

  const handleAgentPromoVideoChange = (event) => {
    setAgentPromoVideos([...event.target.files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Upload logoImage to Firebase Storage
      const logoImageFile = formData.logoImage;
      const logoImageName = logoImageFile.name;
      const logoImageRef = projectStorage.ref(`logoImages/${logoImageName}`);
      await logoImageRef.put(logoImageFile);
      const logoImageUrl = await logoImageRef.getDownloadURL();

     
      // Upload pagePictures to Firebase Storage
      const pagePictureUrls = await Promise.all(
        pagePictures.map(async (file) => {
          const storageRef = projectStorage.ref(`pagePictures/${file.name}`);
          await storageRef.put(file);
          return await storageRef.getDownloadURL();
        })
      );

      // Upload agentPromoVideos to Firebase Storage
      const agentPromoVideoUrls = await Promise.all(
        agentPromoVideos.map(async (file) => {
          const storageRef = projectStorage.ref(`agentPromoVideos/${file.name}`);
          await storageRef.put(file);
          return await storageRef.getDownloadURL();
        })
      );

      // Add a new document to the "agents" collection
      const docRef = await projectFirestore.collection('agents').add({
        ...formData,
        logoImage: logoImageUrl,
        pagePictures: pagePictureUrls,
        agentPromoVideos: agentPromoVideoUrls,
        createdAt: timestamp()
      });

      console.log('Document written with ID: ', docRef.id);

      // Clear the form data after a successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        whatsappLink: '',
        website: '',
        logoImage: 'null',
        pagePicture: '',
        pageDescription: '',
        agentPromoVideo: '',
        affiliations: '',
        languages: '',
        services: '',
        numberOfEmployees: '',
        reviewsAverageScore: '',
        country: ''
        
      });

      setPagePictures([]);
      setAgentPromoVideos([]);

      // Redirect or display a success message
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <h2>Agent Registration</h2>

      {/* Render form fields and labels */}
      {Object.entries(formData).map(([fieldName, fieldValue]) => (
        <label key={fieldName} className={styles.formRow}>
          {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
          {fieldName === 'logoImage' ? ( // Add this input field
            <input
              type="file"
              name="logoImage"
              onChange={handleLogoImageChange}
              accept="image/*"
              className={styles.inputField}
            />
          ) : fieldName === 'pagePicture' ? (
            <input
              type="file"
              name="pagePicture"
              onChange={handlePagePictureChange}
              accept="image/*"
              multiple
              className={styles.inputField}
            />
          ) : fieldName === 'agentPromoVideo' ? (
            <input
              type="file"
              name="agentPromoVideo"
              onChange={handleAgentPromoVideoChange}
              accept="video/*"
              multiple
              className={styles.inputField}
            />
          ) : fieldName === 'pageDescription' ? (
            <textarea
              name={fieldName}
              value={fieldValue}
              onChange={handleChange}
              required
              className={styles.inputField}
            ></textarea>
          ) : fieldName === 'country' ? (
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              className={styles.inputField}
            >
              <option value="">-- Select Country --</option>
              {africanCountries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              name={fieldName}
              value={fieldValue}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          )}
        </label>
      ))}

      <button type="submit" className={styles.submitButton}>
        Submit
      </button>
    </form>
  );

};

export default AgentRegistrationForm;

