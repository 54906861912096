import React from 'react';
import styles from './Rating.module.css';

const Rating = ({ quoteId, rating, displayText = false, onRate }) => {
  const validRating = typeof rating === 'number' && !isNaN(rating);
  const filledStars = validRating ? Math.round(rating) : 0;
  const emptyStars = 5 - filledStars;

  const handleRate = (value) => {
    if (onRate) {
      onRate(quoteId, value);
    }
  };

  return (
    <div className={styles.rating}>
      {Array.from({ length: 5 }, (_, index) => {
        const starRating = index + 1;
        const isFilled = rating && starRating <= rating; // Show user rating
        const starClass = isFilled ? styles['rating__star--filled'] : styles['rating__star--empty'];

        return (
          <span
            key={index}
            className={`${styles.rating__star} ${starClass}`}
            onClick={() => handleRate(starRating)}
          >
            ★
          </span>
        );
      })}
      {displayText && validRating && <span className={styles.rating__text}>{rating.toFixed(1)}</span>}
    </div>
  );
};

export default Rating;


