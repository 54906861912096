import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config';
import styles from './ApprovalRequest.module.css';

const ApprovalRequest = () => {
    const { user } = useAuthContext();
    const [invitations, setInvitations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!user) return;

        setIsLoading(true);
        setError(null);

        const unsubscribe = projectFirestore.collection('invitations')
            .where('inviterId', '==', user.uid)
            .onSnapshot(snapshot => {
                const fetchedInvitations = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setInvitations(fetchedInvitations);
                setIsLoading(false);
            }, err => {
                setError(err.message);
                setIsLoading(false);
            });

        return () => unsubscribe();
    }, [user]);

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className={styles.invitationsContainer}>
            <h3>Pending Afholder Approval Requests</h3>
            <h6>Here are the invitations you've sent to existing Afholders, requesting their approval for you to become an Afholder yourself.</h6>
            {invitations.length > 0 ? (
                <ul className={styles.invitationList}>
                    {invitations.map((invite) => (
                        <li key={invite.id}>{invite.inviteeEmail} - {invite.status}</li>
                    ))}
                </ul>
            ) : (
                <p>No invitations sent.</p>
            )}
        </div>
    );
};

export default ApprovalRequest;
