//import React from 'react';
import React, { useState, useRef, useEffect, useContext  } from 'react';
import { NavLink } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { africanCountries } from '../../constants/countries';
import { useCountrySettings } from '../../context/CountrySettingsContext'; 
import Avatar from '../Avatar/Avatar';
import styles from './Navbar.module.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// Import these additional components from MUI at the top of the file
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';



const NavbarReduce = ({ serviceName, menuItems, boameColor }) => {
  const { logout } = useLogout();
  const { user, role, country } = useAuthContext();
  const serviceNameRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [servicesAnchorEl, setServicesAnchorEl] = React.useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // Add a new state for the selected country
  const { selectedCountry, setSelectedCountry } = useCountrySettings();
  

  const services = [
    {
      name: 'Visuals',
      icon: '📷',
      link: '/pictures',
      
    },
    {
      name: 'Books',
      icon: '📚',
      link: '/books',
      
    },
    {
      name: 'Delivery',
      icon: '🚚',
      link: '/deliveryhome',
      
    },
    {
      name: 'Propertu',
      icon: '🏠',
      link: '/propertu',
      
    },
  ];

  
  // Inside your component
useEffect(() => {
  if (serviceNameRef.current) {
    const serviceNameWidth = serviceNameRef.current.offsetWidth;
    const calculatedOffset = serviceNameWidth / 2;
    setOffset(calculatedOffset);
  }
}, [serviceName]);


useEffect(() => {
  // console.log('Selected country:', selectedCountry);
}, [selectedCountry]);


useEffect(() => {
  if (!selectedCountry) {
    const sessionStorageCountry = sessionStorage.getItem("tempCountry");
    if (sessionStorageCountry) {
      setSelectedCountry(sessionStorageCountry);
    } else {
      setSelectedCountry(africanCountries[0].code);
    }
  }
}, [selectedCountry, setSelectedCountry]);


const handleCountryChange = (event) => {
  const countryCode = event.target.value;
  const newCountry = africanCountries.find(c => c.code === countryCode);
  setSelectedCountry(newCountry);
  sessionStorage.setItem("tempCountry", countryCode); 
};


  const handleServicesClick = (event) => {
    setServicesAnchorEl(event.currentTarget);
  };

  const handleServicesClose = () => {
    setServicesAnchorEl(null);
  };

  const handleAvatarClick = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const handleLogoutClick = async () => {
    console.log("Logout clicked"); // Add this line
    handleClose();
    await logout();
  };
  

  return (
    <nav className={styles.nav}>
      <div className={styles.logoWrapper}>
  <div className={styles.logoContainer}>
    <h1 className={styles.logo}>
      <span className={styles.boame} style={{ color: boameColor }}>
        Boame
      </span>
      <span className={styles.ooo}>ooo</span>
    </h1>
    {serviceName && (
      <h2 className={`${styles.serviceName} ${styles.logoServiceName}`}>{serviceName}</h2>
    )}
  </div>
</div>
  {/* <FormControl className={styles.countryDropdown}>
  <InputLabel htmlFor="country-select" className={styles.inputLabelCountry}>
  Country
</InputLabel>
<Select
  value={selectedCountry.code}
  onChange={handleCountryChange}
  label="Country"
  inputProps={{
    name: 'country',
    id: 'country-select',
  }}
>
  
  {africanCountries.map((country) => (
      <MenuItem key={country.code} value={country.code}>
        {country.flag} {country.name}
      </MenuItem>
  ))}
</Select>

  </FormControl> */}
  
  <ul className={styles.menuItems}>
  {menuItems &&
    menuItems.map((item, index) => (
      <li key={index}>
        <NavLink to={item.link} activeClassName={styles.activeLink}>
          <span
            className={`${styles.serviceName} ${styles.serviceNameBlack} ${item.type}`}
          >
            {item.name}
          </span>
        </NavLink>
      </li>
    ))}
</ul>
  <div className={styles.dropdownsWrapper}>
    {/* Services Dropdown */}
    <div className={styles.servicesDropdown}>
      <IconButton onClick={handleServicesClick}>
        <div className={styles.nineDots}></div>
      </IconButton>
      <Menu
        anchorEl={servicesAnchorEl}
        open={Boolean(servicesAnchorEl)}
        onClose={handleServicesClose}
        onClick={handleServicesClose}
      >
        {services.map((service, index) => (
          <MenuItem key={index}>
            <NavLink to={service.link} className={styles.serviceLink}>
              <span className={styles.serviceIcon}>{service.icon}</span>
              <span className={styles.serviceName}>{service.name}</span>
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </div>

    {/* Avatar Dropdown */}
    <div className={styles.avatarDropdown}>
      <IconButton onClick={handleAvatarClick}>
        <Avatar src={user?.photoURL} />
      </IconButton>
      <Menu
        anchorEl={avatarAnchorEl}
        open={Boolean(avatarAnchorEl)}
        onClose={handleAvatarClose}
        onClick={handleAvatarClose}
      >
        {user ? (
          [
            <MenuItem key="displayName">{user.displayName}</MenuItem>,
            <MenuItem key="email">{user.email}</MenuItem>,
            <MenuItem key="role">{role}</MenuItem>,
            <MenuItem key="country">{country}</MenuItem>,
           
            <MenuItem key="account">
              <NavLink to="/dashboard" className={styles.dropdownItem}>
                Manage Account
              </NavLink>
            </MenuItem>,
            <MenuItem key="logout" onClick={handleLogoutClick}>
              Logout
            </MenuItem>,
          ]
        ) : (
          [
            <MenuItem key="login">
              <NavLink to="/login" className={styles.dropdownItem}>
                Login
              </NavLink>
            </MenuItem>,
            <MenuItem key="signup">
              <NavLink to="/signup" className={styles.dropdownItem}>
                Signup
              </NavLink>
            </MenuItem>,
          ]
        )}
      </Menu>
    </div>
  </div>
</nav>

  );
};

export default NavbarReduce;
