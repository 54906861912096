// DeliveryManagement.js
import React, { useState, useEffect } from 'react';
import { projectFirestore, FieldValue } from '../../firebase/config';
import styles from './DeliveryManagement.module.css';

const DeliveryManagement = () => {
    const [deliveries, setDeliveries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const formatDate = (dateField) => {
        if (!dateField) return 'Not provided';
        // Check if the dateField is a Firestore Timestamp
        if (dateField.toDate) {
          return dateField.toDate().toLocaleDateString();
        } else if (typeof dateField === 'string') {
          // Parse the string into a date object
          const date = new Date(dateField);
          return isNaN(date.getTime()) ? 'Invalid date' : date.toLocaleDateString();
        } else {
          return 'Invalid date format';
        }
      };

    const fetchDeliveries = async () => {
        setIsLoading(true);
        try {
          const snapshot = await projectFirestore.collection('deliveryorders')
            .where('orderStatus', 'in', ['in_transit', 'scheduled'])
            .get();
      
          const deliveriesData = snapshot.docs.map(doc => {
            const data = doc.data();
      
            // Use formatDate function to handle date formatting
            const formattedPickupDate = formatDate(data.packageDetails?.pickupDate);
            const formattedDeliveryDate = formatDate(data.packageDetails?.deliveryDate);
      
            return {
              id: doc.id,
              ...data,
              formattedPickupDate,
              formattedDeliveryDate
            };
          });
          setDeliveries(deliveriesData);
        } catch (error) {
          console.error('Error fetching deliveries:', error);
        }
        setIsLoading(false);
      };
    
      useEffect(() => {
        fetchDeliveries();
      }, []);

      // This function is called when an order needs to be marked as in_transit
    const handleMarkAsInTransit = async (deliveryId) => {
        console.log(`Marking delivery ID ${deliveryId} as in_transit...`);
    
        // Additional fields to update
    const additionalUpdates = {
        statusUpdatedAt: FieldValue.serverTimestamp(), // Timestamp of the status update
        lastKnownLocation: { latitude: 0, longitude: 0 }, // Example static coordinates
        // In a real-world scenario, you would get these coordinates from a GPS device or a location API
        deliveryPartnerId: 'partnerId', // ID of the delivery partner who is handling the delivery
        estimatedDeliveryTime: new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toISOString(), // Example ETA 2 hours from now
    };
    
        try {
            // Update the delivery order status to 'in_transit'
            await projectFirestore.collection('deliveryorders').doc(deliveryId).update({
                orderStatus: 'in_transit',
                ...additionalUpdates,
            });
            console.log(`Delivery ID ${deliveryId} marked as in_transit`);
    
            // Refresh the deliveries list to reflect the change
            fetchDeliveries();
        } catch (error) {
            console.error(`Error marking delivery ID ${deliveryId} as in_transit: `, error);
        }
    };

    const handleMarkAsDelivered = async (deliveryId, deliveryCost) => {
        console.log(`Marking delivery ID ${deliveryId} as delivered...`);
    
        try {
            // Update the delivery order status to 'delivered'
            await projectFirestore.collection('deliveryorders').doc(deliveryId).update({
                orderStatus: 'delivered',
                statusUpdatedAt: FieldValue.serverTimestamp(), // Record the time of delivery
            });
            console.log(`Delivery ID ${deliveryId} marked as delivered`);
    
            // Record earnings now that the delivery is marked as delivered
            await recordEarnings(deliveryId, deliveryCost);
    
            // Refresh the deliveries list to reflect the change
            fetchDeliveries();
        } catch (error) {
            console.error(`Error marking delivery ID ${deliveryId} as delivered: `, error);
        }
    };
    
    
      // Function to calculate and record delivery partner earnings
    const recordEarnings = async (orderId, deliveryCost) => {
        const earnings = deliveryCost * 0.75; // 75% of delivery cost

        try {
            await projectFirestore.collection('deliveryPartnerEarnings').add({
                orderId,
                earnings,
                recordedAt: new Date() // Record the current date and time
            });
            console.log(`Earnings recorded for order ID ${orderId}`);
        } catch (error) {
            console.error('Error recording earnings:', error);
        }
    };

    const updateDeliveryStatus = async (deliveryId, newStatus, deliveryCost) => {
        console.log(`Updating status for delivery ID ${deliveryId} to ${newStatus}...`);
        try {
            await projectFirestore.collection('deliveryorders').doc(deliveryId).update({
                orderStatus: newStatus
            });
            console.log(`Status updated to ${newStatus} for delivery ID ${deliveryId}`);

            // Record earnings when an order is marked as delivered
            if (newStatus === 'delivered') {
                await recordEarnings(deliveryId, deliveryCost);
            }

            fetchDeliveries();
        } catch (error) {
            console.error('Error updating delivery status:', error);
        }
    };

    const formatAddress = (address) => {
        return address ? `${address.street}, ${address.city}` : 'Address not provided';
    };

    if (isLoading) {
        console.log('Loading deliveries...');
        return <div>Loading deliveries...</div>;
    }

    if (!deliveries.length) {
        console.log('No deliveries to display.');
        return <div>No deliveries found.</div>;
    }

    // return (
    //     <div className={styles.deliveryManagementContainer}>
    //         <h2>Delivery Management</h2>
    //         <ul className={styles.deliveryList}>
    //             {deliveries.map(delivery => (
    //                 <li key={delivery.id} className={styles.deliveryItem}>
    //                     <div>
    //                         <p><strong>Delivery ID:</strong> {delivery.id}</p>
    //                         <p><strong>Order Status:</strong> {delivery.orderStatus}</p>
    //                         <p><strong>Pickup Date:</strong> {delivery.formattedPickupDate}</p>
    //                         <p><strong>Delivery Date:</strong> {delivery.formattedDeliveryDate}</p>
    //                         <p><strong>Pickup Address:</strong> {formatAddress(delivery.selectedPickupAddress)}</p>
    //                         <p><strong>Delivery Address:</strong> {formatAddress(delivery.selectedDeliveryAddress)}</p>
    //                     </div>
    //                     {delivery.orderStatus === 'pending' && (
    //                         <button onClick={() => updateDeliveryStatus(delivery.id, 'picked_up', delivery.deliveryCost)}>
    //                             Mark as Picked Up
    //                         </button>
    //                     )}
    //                     {delivery.orderStatus === 'picked_up' && (
    //                         <button onClick={() => updateDeliveryStatus(delivery.id, 'in_transit', delivery.deliveryCost)}>
    //                             Mark as In Transit
    //                         </button>
    //                     )}
    //                     {delivery.orderStatus === 'in_transit' && (
    //                         <button onClick={() => updateDeliveryStatus(delivery.id, 'delivered', delivery.deliveryCost)}>
    //                             Mark as Delivered
    //                         </button>
    //                     )}
    //                 </li>
    //             ))}
    //         </ul>
    //     </div>
    // );

    return (
        <div className={styles.deliveryManagementContainer}>
            <h2>Delivery Management</h2>
            <ul className={styles.deliveryList}>
                {deliveries.map(delivery => (
                    <li key={delivery.id} className={styles.deliveryItem}>
                        <div>
                            {/* ... existing content like ID, status, dates, addresses ... */}
                            <p><strong>Delivery ID:</strong> {delivery.id}</p>
                            <p><strong>Order Status:</strong> {delivery.orderStatus}</p>
                            <p><strong>Pickup Date:</strong> {delivery.formattedPickupDate}</p>
                            <p><strong>Delivery Date:</strong> {delivery.formattedDeliveryDate}</p>
                            <p><strong>Pickup Address:</strong> {formatAddress(delivery.selectedPickupAddress)}</p>
                            <p><strong>Delivery Address:</strong> {formatAddress(delivery.selectedDeliveryAddress)}</p>
                        </div>
                        <div className={styles.actions}>
                            {delivery.orderStatus === 'pending' && (
                                <button onClick={() => updateDeliveryStatus(delivery.id, 'picked_up', delivery.deliveryCost)}>
                                    Mark as Picked Up
                                </button>
                            )}
                            {delivery.orderStatus === 'picked_up' && (
                                <button onClick={() => handleMarkAsInTransit(delivery.id)}>
                                    Mark as In Transit
                                </button>
                            )}
                            {delivery.orderStatus === 'in_transit' && (
                                <button onClick={() => handleMarkAsDelivered(delivery.id, delivery.deliveryCost)}>
                                    Mark as Delivered
                                </button>
                            )}
                            {/* ... other buttons for status transitions ... */}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
    
    
};

export default DeliveryManagement;
