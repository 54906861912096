import React, { useState, useEffect } from 'react';
import GeneralLayout from '../../Hoc/GeneralLayout/GeneralLayout';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config';
import getConfirmationThreshold from './thresholdUtils';
import styles from './BecomeAnAfholder.module.css';

const BecomeAnAfholder = () => {
    const { user } = useAuthContext();
    const [requestSent, setRequestSent] = useState(false);
    const [canInitiateRequest, setCanInitiateRequest] = useState(false);
    const [answers, setAnswers] = useState({
        bornInAfrica: '',
        parentFromAfrica: '',
        africanAncestry: '',
        africanNationality: '',
        marriedToAfrican: '',
        longTermResidency: '',
        dualHeritage: '',
        legallyAdopted: ''
    });

    useEffect(() => {
        if (user) {
            projectFirestore.collection('afholderRequests').where("userId", "==", user.uid).get()
                .then(querySnapshot => {
                    setCanInitiateRequest(querySnapshot.empty); // Can initiate if no existing requests
                })
                .catch(error => {
                    console.error("Error checking existing requests:", error);
                });
        }
    }, [user]);

    const handleChange = (question, value) => {
        setAnswers({
            ...answers,
            [question]: value
        });
    };

    const allQuestionsAnswered = Object.values(answers).every(value => value === 'yes' || value === 'no');


    const handleRequest = async (event) => {
        event.preventDefault(); // Prevent default form submission action
        if (!user || !allQuestionsAnswered || !canInitiateRequest) return;

        const confirmationThreshold = getConfirmationThreshold(user.location);

        await projectFirestore.collection('afholderRequests').add({
            userId: user.uid,
            userName: user.displayName || '', // or another identifier
            userEmail: user.email || '', // if you need to store email
            criteria: answers,
            approvalsNeeded: confirmationThreshold,
            approvalsReceived: 0,
            requestInitiated: new Date() // Store the date of request
        });

        setRequestSent(true);
    };

    if (requestSent) {
        return <p>Your request to become an Afholder has been initiated.</p>;
    }

    if (!canInitiateRequest) {
        return <p>You have already initiated an Afholder request or are already an Afholder.</p>;
    }


return (
    
    <div className={styles.container}>
    <div className={styles.headerText}>
        <h5>​​Afholders are beneficiaries and owners of the productive capacities 
        generated by Boameooo. This is based on the concept of peomocracy, 
        afconomics, and aftalism system.</h5>
        <p>To become an Afholder and start receiving benefits, please fill out the questionnaire below.</p>
    </div>
            <form className={styles.form} onSubmit={handleRequest}>
            <div className={styles.card}>
            <div className={styles.questionText}>
                Were you born in an African country?
            </div>
            <div className={styles.radioGroup}>
                    <label>
                        <input 
                            type="radio" 
                            name="bornInAfrica" 
                            value="yes"
                            checked={answers.bornInAfrica === 'yes'} 
                            onChange={() => handleChange('bornInAfrica', 'yes')}
                        />
                        Yes
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            name="bornInAfrica" 
                            value="no"
                            checked={answers.bornInAfrica === 'no'} 
                            onChange={() => handleChange('bornInAfrica', 'no')}
                        />
                        No
                    </label>
                </div>
                </div>
                <div className={styles.card}>
                <div className={styles.questionText}>
                    Are either of your parents of African descent or were they born in an African country?
                </div>
                    <div className={styles.radioGroup}>
                    <label>
                        <input 
                            type="radio" 
                            name="parentFromAfrica" 
                            value="yes"
                            checked={answers.parentFromAfrica === 'yes'} 
                            onChange={() => handleChange('parentFromAfrica', 'yes')}
                        />
                        Yes
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            name="parentFromAfrica" 
                            value="no"
                            checked={answers.parentFromAfrica === 'no'} 
                            onChange={() => handleChange('parentFromAfrica', 'no')}
                        />
                        No
                    </label>
                </div>
                </div>

                {/* Question 3 */}
                <div className={styles.card}>
                <div className={styles.questionText}>
                    Do you have documented African ancestry (e.g., grandparents or great-grandparents who were born in Africa)?
                </div>
                <div className={styles.radioGroup}>
                    <label>
                        <input 
                            type="radio" 
                            name="africanAncestry" 
                            value="yes"
                            checked={answers.africanAncestry === 'yes'} 
                            onChange={() => handleChange('africanAncestry', 'yes')}
                        />
                        Yes
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            name="africanAncestry" 
                            value="no"
                            checked={answers.africanAncestry === 'no'} 
                            onChange={() => handleChange('africanAncestry', 'no')}
                        />
                        No
                    </label>
                </div>
                </div>


                    {/* Question 4 */}
                    <div className={styles.card}>
                    <div className={styles.questionText}>
                        Do you hold citizenship or nationality of an African country?
                    </div>
                        <div className={styles.radioGroup}>
                        <label>
                            <input 
                                type="radio" 
                                name="africanNationality" 
                                value="yes"
                                checked={answers.africanNationality === 'yes'} 
                                onChange={() => handleChange('africanNationality', 'yes')}
                            />
                            Yes
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="africanNationality" 
                                value="no"
                                checked={answers.africanNationality === 'no'} 
                                onChange={() => handleChange('africanNationality', 'no')}
                            />
                            No
                        </label>
                    </div>
                    </div>


                    {/* Question 5 */}
                    <div className={styles.card}>
                    <div className={styles.questionText}>
                        Are you married to a person of African descent or a citizen of an African country?
                    </div>
                        <div className={styles.radioGroup}>
                        <label>
                            <input 
                                type="radio" 
                                name="marriedToAfrican" 
                                value="yes"
                                checked={answers.marriedToAfrican === 'yes'} 
                                onChange={() => handleChange('marriedToAfrican', 'yes')}
                            />
                            Yes
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="marriedToAfrican" 
                                value="no"
                                checked={answers.marriedToAfrican === 'no'} 
                                onChange={() => handleChange('marriedToAfrican', 'no')}
                            />
                            No
                        </label>
                    </div>
                    </div>


                    {/* Question 6 */}
                    <div className={styles.card}>
                    <div className={styles.questionText}>
                        Have you lived in an African country for a significant part of your life?
                    </div>
                        <div className={styles.radioGroup}>
                        <label>
                            <input 
                                type="radio" 
                                name="longTermResidency" 
                                value="yes"
                                checked={answers.longTermResidency === 'yes'} 
                                onChange={() => handleChange('longTermResidency', 'yes')}
                            />
                            Yes
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="longTermResidency" 
                                value="no"
                                checked={answers.longTermResidency === 'no'} 
                                onChange={() => handleChange('longTermResidency', 'no')}
                            />
                            No
                        </label>
                    </div>
                    </div>


                    {/* Question 7 */}
                    <div className={styles.card}>
                    <div className={styles.questionText}>
                        Do you have dual heritage, including African descent?
                    </div>
                        <div className={styles.radioGroup}>
                        <label>
                            <input 
                                type="radio" 
                                name="dualHeritage" 
                                value="yes"
                                checked={answers.dualHeritage === 'yes'} 
                                onChange={() => handleChange('dualHeritage', 'yes')}
                            />
                            Yes
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="dualHeritage" 
                                value="no"
                                checked={answers.dualHeritage === 'no'} 
                                onChange={() => handleChange('dualHeritage', 'no')}
                            />
                            No
                        </label>
                    </div>
                    </div>


                    {/* Question 8 */}
                    <div className={styles.card}>
                    <div className={styles.questionText}>
                        Were you legally adopted by parents of African descent or citizens of an African country?
                    </div>
                        <div className={styles.radioGroup}>
                        <label>
                            <input 
                                type="radio" 
                                name="legallyAdopted" 
                                value="yes"
                                checked={answers.legallyAdopted === 'yes'} 
                                onChange={() => handleChange('legallyAdopted', 'yes')}
                            />
                            Yes
                        </label>
                        <label>
                            <input 
                                type="radio" 
                                name="legallyAdopted" 
                                value="no"
                                checked={answers.legallyAdopted === 'no'} 
                                onChange={() => handleChange('legallyAdopted', 'no')}
                            />
                            No
                        </label>
                    </div>
                    </div>

                <div className={styles.buttonCard}>
                    <button 
                        type="submit"
                        disabled={!allQuestionsAnswered}
                        className={styles.button}
                    >
                        Initiate Afholder Request
                    </button>
                </div>
            </form>
            {requestSent && <p>Your request to become an Afholder has been initiated.</p>}
        </div>
       
    );
};

export default BecomeAnAfholder;