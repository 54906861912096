
import React, { useState, useEffect, useContext } from 'react';
import GlobalAfricaExchangeLayout from '../../../components/Hoc/GlobalAfricaExchangeLayout/GlobalAfricaExchangeLayout'; 
import ImportBasicDetails from './ImportBasicDetails';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../../context/AuthContext';
import { projectFirestore, projectStorage } from '../../../firebase/config';
import ImportRequirements from './ImportRequirements';
import ImportLogistics from './ImportLogistics';

import styles from './ImportForm.module.css'; // Assume you have a CSS module for styling

const ImportForm = () => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [step, setStep] = useState(1); // Manage the current step of the form
    const [formData, setFormData] = useState({}); // Central state for form data
    const [formErrors, setFormErrors] = useState({}); // New state for form errors


    // Function to go to the next form step
    const nextStep = () => {
        setStep(step + 1);
    };

    // Function to go to the previous form step
    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };
  
    // Function to validate the data in ImportBasicDetails before moving to the next step
const validateBasicDetails = () => {
    // Check if all required fields in formData are filled out
    let errors = {};

    if (!formData.countryOfDesiredImport) {
        errors.countryOfDesiredImport = "Country of desired import is required.";
    }
    if (!formData.targetPriceRange) {
        errors.targetPriceRange = "Target price range is required.";
    }
    if (!formData.preferredCurrency) {
        errors.preferredCurrency = "Preferred currency is required.";
    }
    if (!formData.desiredQuantity) {
        errors.desiredQuantity = "Desired quantity is required.";
    }
    if (!formData.paymentTerms) {
        errors.paymentTerms = "Payment terms are required.";
    }
    if (!formData.productName) {
        errors.productName = "Product name is required.";
    }
    if (!formData.qualityRequirements || formData.qualityRequirements.trim() === '') {
        errors.qualityRequirements = "Quality requirements are required.";
    }

    // Log errors and return false if any errors exist
    if (Object.keys(errors).length > 0) {
        console.error("Validation errors:", errors);
        // Update setFormData with errors to reflect in the ImportBasicDetails component
        setFormData({ ...formData, errors });
        return false;
    }

    // Clear any previous errors if validation passes
    if (formData.errors) {
        setFormData({ ...formData, errors: {} });
    }

    return true;
};

// Function to handle going to the next step from ImportBasicDetails
const handleBasicDetailsNext = () => {
    if (validateBasicDetails()) {
        nextStep();
    }
};

const handleSubmit = async () => {
    console.log("Submit button clicked");
    if (validateAllSteps()) {
        console.log("All steps validation passed. Submitting data...", formData);
        try {
            const docRef = await projectFirestore.collection('postimports').add(formData);
            console.log("Document written with ID: ", docRef.id);
            history.push('/post-import'); // Redirect on success
        } catch (error) {
            console.error("Error adding document: ", error);
            setFormErrors({ submissionError: "Failed to submit form. Please try again." });
        }
    } else {
        console.log("Validation failed at one or more steps. Form data:", formData, "Errors:", formErrors);
    }
};


const validateAllSteps = () => {
    let errors = {};
    let isValid = true;

    // Validate ImportBasicDetails
    if (!formData.countryOfDesiredImport) {
        errors.countryOfDesiredImport = "Country of desired import is required.";
        isValid = false;
    }
    if (!formData.targetPriceRange) {
        errors.targetPriceRange = "Target price range is required.";
        isValid = false;
    }
    // ... other validations for ImportBasicDetails ...

    // Validate ImportRequirements
    if (!formData.qualityStandards || formData.qualityStandards.trim() === '') {
        errors.qualityStandards = "Quality standards are required.";
        isValid = false;
    }
    // ... other validations for ImportRequirements ...

    // Validate ImportLogistics
    if (!formData.expectedDeliveryDate) {
        errors.expectedDeliveryDate = "Expected delivery date is required.";
        isValid = false;
    }
    // ... other validations for ImportLogistics ...
    
    if (Object.keys(errors).length > 0) {
        console.error("Validation errors found:", errors);
        isValid = false;
    } else {
        console.log("No validation errors found. Data appears to be valid.");
    }

    setFormErrors(errors);
    return isValid;
};
    // Rendering different form components based on the current step
    let formContent;
    switch (step) {
        case 1:
            formContent = (
                <div className={styles.formContainer}>
                    <ImportBasicDetails formData={formData} setFormData={setFormData} />
                    <button onClick={handleBasicDetailsNext} className={styles.nextButton}>Next</button>
                </div>
            );
            break;
        case 2:
            formContent = (
                <div className={styles.formContainer}>
                    <ImportRequirements formData={formData} setFormData={setFormData} />
                    <button onClick={prevStep} className={styles.prevButton}>Previous</button>
                    <button onClick={nextStep} className={styles.nextButton}>Next</button>
                </div>
            );
            break;
        case 3:
            formContent = (
                <div className={styles.formContainer}>
                    <ImportLogistics formData={formData} setFormData={setFormData} />
                    <button onClick={prevStep} className={styles.prevButton}>Previous</button>
                    <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
                </div>
            );
            break;
        default:
            formContent = null;
    }

    return (
        <GlobalAfricaExchangeLayout>
            {formContent}
            {formErrors.submissionError && <p className={styles.error}>{formErrors.submissionError}</p>} 
        </GlobalAfricaExchangeLayout>
    );
};

export default ImportForm;
