import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calculateDeliveryCost } from '../DeliveryCost/DeliveryCostCalculations';
import { projectFirestore } from '../../../firebase/config'; 

import styles from './DeliveryForm.module.css';

const DeliveryForm = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { selectedPickupAddress, selectedDeliveryAddress } = location.state || {};
  const [categories, setCategories] = useState(location.state?.categories || []);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  

  const [packageDetails, setPackageDetails] = useState({
    weight: '',
    dimensions: { length: '', width: '', height: '' },
    description: '',
    pickupDate: new Date(),  // Initialize with the current date
    deliveryDate: new Date(), // Initialize with the current date
    attributes: {} // Add this to store attribute values
  });
  const [deliveryCost, setDeliveryCost] = useState(0);

  useEffect(() => {
    if (!categories.length) {
      const fetchCategories = async () => {
        const snapshot = await projectFirestore.collection('DeliveryCategories').get();
        const fetchedCategories = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCategories(fetchedCategories);
      };
      fetchCategories();
    }
  }, [categories]);

  

  useEffect(() => {
    const calculateCost = async () => {
      // Check if all necessary data is present and valid
      if (!selectedPickupAddress || !selectedDeliveryAddress || !packageDetails.weight ||
          isNaN(parseFloat(packageDetails.dimensions.length)) || 
          isNaN(parseFloat(packageDetails.dimensions.width)) || 
          isNaN(parseFloat(packageDetails.dimensions.height))) {
        console.log("Waiting for complete package details");
        return; // Exit the function if data is incomplete or invalid
      }
  
      try {
        const cost = await calculateDeliveryCost(packageDetails, "STANDARD", selectedPickupAddress, selectedDeliveryAddress);
        setDeliveryCost(isNaN(cost) ? 0 : cost);
      } catch (error) {
        console.error("Error calculating delivery cost:", error);
      }
    };
  
    if (selectedPickupAddress && selectedDeliveryAddress && packageDetails.weight) {
      calculateCost();
    }
  }, [selectedPickupAddress, selectedDeliveryAddress, packageDetails]);
  

  


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPackageDetails(prevDetails => ({ ...prevDetails, [name]: value }));
  };

  const handleDimensionChange = (dimension, value) => {
    setPackageDetails(prevDetails => ({
      ...prevDetails,
      dimensions: { ...prevDetails.dimensions, [dimension]: value }
    }));
  };

// Updated function to handle attribute changes
const handleAttributeChange = (attributeKey, option, isChecked, isSubCategory) => {
  setPackageDetails(prevDetails => {
    const updatedAttributes = prevDetails.attributes[attributeKey] ? [...prevDetails.attributes[attributeKey]] : [];
    if (isChecked) {
      updatedAttributes.push(option);
    } else {
      const index = updatedAttributes.indexOf(option);
      if (index > -1) {
        updatedAttributes.splice(index, 1);
      }
    }
    return {
      ...prevDetails,
      attributes: { ...prevDetails.attributes, [attributeKey]: updatedAttributes }
    };
  });
};

const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubCategory('');
    setPackageDetails({ ...packageDetails, attributes: {} }); // Reset attributes on category change
};

const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
    setPackageDetails({ ...packageDetails, attributes: {} }); // Reset attributes on sub-category change
};

// Function to render category/sub-category attributes
const renderAttributesInput = (attributes, isSubCategory = false) => {
  return Object.entries(attributes).map(([key, options], index) => {
    // Assuming options is a string of comma-separated values
    const optionsArray = options.split(', ');

    return (
      <div key={index}>
        <label>{key}:</label>
        {optionsArray.map(option => (
          <div key={option}>
            <input
              type="checkbox"
              id={isSubCategory ? `sub-${key}-${option}` : `cat-${key}-${option}`}
              name={key}
              value={option}
              checked={packageDetails.attributes[key]?.includes(option) || false}
              onChange={(e) => handleAttributeChange(key, option, e.target.checked, isSubCategory)}
            />
            <label htmlFor={isSubCategory ? `sub-${key}-${option}` : `cat-${key}-${option}`}>{option}</label>
          </div>
        ))}
      </div>
    );
  });
};

const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      console.error("No user logged in.");
      return;
    }

    const orderData = {
      userId: user.uid,
      selectedPickupAddress,
      selectedDeliveryAddress,
      packageDetails: {
        selectedCategory,
        selectedSubCategory,
        ...packageDetails,
        pickupDate: packageDetails.pickupDate.toISOString(),
        deliveryDate: packageDetails.deliveryDate.toISOString()
      },
      deliveryCost,
      orderStatus: 'pending',
      paymentStatus: 'unpaid',
      isPaymentCompleted: false,
      orderDate: new Date().toISOString()
    };

    try {
      const docRef = await projectFirestore.collection('deliveryorders').add(orderData);
      const newOrderId = docRef.id;
      await projectFirestore.collection('deliveryorders').doc(newOrderId).update({ orderId: newOrderId });
      history.push('/order-summary', { orderData: { ...orderData, orderId: newOrderId } });
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  // Find selected category and sub-category objects
  const selectedCategoryObj = categories.find(cat => cat.id === selectedCategory);
  const selectedSubCategoryObj = selectedCategoryObj?.subCategories.find(sub => sub.id === selectedSubCategory);



  return (
    <div className={styles.deliveryFormContainer}>
      <form className={styles.deliveryForm} onSubmit={handleSubmit}>
        <div className={styles.addressInfo}>
            <p>
                <strong>Pickup Address:</strong> 
                {selectedPickupAddress ? `${selectedPickupAddress.fullName} - ${selectedPickupAddress.street}, ${selectedPickupAddress.city}` : 'Not selected'}
            </p>
            <p>
                <strong>Delivery Address:</strong> 
                {selectedDeliveryAddress ? `${selectedDeliveryAddress.fullName} - ${selectedDeliveryAddress.street}, ${selectedDeliveryAddress.city}` : 'Not selected'}
            </p>
        </div>

        {/* Category Selection */}
        <label className={styles.formLabel}>
          Category:
          <select 
            className={styles.formSelect}
            value={selectedCategory} 
            onChange={handleCategoryChange}
            >
            <option value="">Select a Category</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
        </label>

        {/* Render category attributes */}
        {selectedCategoryObj && renderAttributesInput(selectedCategoryObj.attributes)}

        {/* Sub-Category Selection */}
        <label className={styles.formLabel}>
          Sub-Category:
          <select 
            className={styles.formSelect}
            value={selectedSubCategory} 
            onChange={handleSubCategoryChange}
            >
            <option value="">Select a Sub-Category</option>
            {selectedCategory && categories.find(cat => cat.id === selectedCategory)?.subCategories.map(subCat => (
              <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
            ))}
          </select>
        </label>

        {/* Render sub-category attributes */}
        {selectedSubCategoryObj && renderAttributesInput(selectedSubCategoryObj.attributes, true)}


        {/* Additional Package Details */}
        <label className={styles.formLabel}>
          Weight:
          <input type="number" name="weight" className={styles.formInput} required value={packageDetails.weight} onChange={handleInputChange} />

        </label>
        <div className={styles.dimensions}>
          <label className={styles.formLabel}>
            Length:
            <input type="number" name="length" className={styles.formInput} value={packageDetails.dimensions.length} onChange={(e) => handleDimensionChange('length', e.target.value)} />
          </label>
          <label className={styles.formLabel}>
            Width:
            <input type="number" name="width" className={styles.formInput} value={packageDetails.dimensions.width} onChange={(e) => handleDimensionChange('width', e.target.value)} />
          </label>
          <label className={styles.formLabel}>
            Height:
            <input type="number" name="height" className={styles.formInput} value={packageDetails.dimensions.height} onChange={(e) => handleDimensionChange('height', e.target.value)} />
          </label>
        </div>


        <label className={styles.formLabel}>
          Description:
          <textarea name="description" className={styles.formInput} required value={packageDetails.description} onChange={handleInputChange} />
        </label>

        {/* Pickup and Delivery Information */}
        <label className={styles.formLabel} >
            Pickup Date:
            <DatePicker 
                className={styles.formInput}
                required selected={packageDetails.pickupDate} 
                onChange={date => setPackageDetails({ ...packageDetails, pickupDate: date })}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                closeOnScroll={true}
                 />
            </label>

            <label className={styles.formLabel}>
            Delivery Date:
            <DatePicker 
                className={styles.formInput}
                required selected={packageDetails.deliveryDate} 
                onChange={date => setPackageDetails({ ...packageDetails, deliveryDate: date })}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                closeOnScroll={true}
                 />
              </label>
                {/* Delivery Cost Calculations */}
                <p>Calculated Delivery Cost: {deliveryCost.toFixed(2)}</p>

        <button className={styles.formInput} type="submit">Next</button>
      </form>
    </div>
  );
};

export default DeliveryForm;
