import React from 'react';
import styles from './Title.module.css';

const Title = () => {
  return (
    <div className={styles.title}>
      <h2 className={styles.h2}>Visual Africans</h2>
      <p className={styles.p}>Sharing the heritage and lives of everyone of African descent within Global African domain.</p>
    </div>
  );
};

export default Title;








