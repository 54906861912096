import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../../firebase/config';

const AgentPage = () => {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const querySnapshot = await projectFirestore.collection('agents').get();
        const agentList = [];
        querySnapshot.forEach((doc) => {
          agentList.push({ id: doc.id, ...doc.data() });
        });
        setAgents(agentList);
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    fetchAgents();
  }, []);

  if (!agents) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Agent List</h2>
      <ul>
        {agents.map((agent) => (
          <li key={agent.id}>
            <h3>{agent.name}</h3>
            <p>Email: {agent.email}</p>
            <p>Phone: {agent.phone}</p>
            <p>Website: {agent.website}</p>
            {/* Add any other agent details you want to display */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AgentPage;

