import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.top}>
                <div className={styles.overlap39}>
                    <div className={styles.group288}>
                        <p className={styles.spanWrapper64}>
                            <span className={styles.textWrapper177}>About</span>
                        </p>
                        <p className={styles.spanWrapper65}>
                            <span className={styles.textWrapper178}>
                                About Us
                                <br />
                                Contact
                                <br />
                                Terms &amp; Conditions
                                <br />
                                Be Merchant
                            </span>
                        </p>
                    </div>
                    <div className={styles.group289}>
                        <p className={styles.spanWrapper64}>
                            <span className={styles.textWrapper177}>Help</span>
                        </p>
                        <p className={styles.spanWrapper65}>
                            <span className={styles.textWrapper178}>
                                Payment
                                <br />
                                Shipping
                                <br />
                                FAQ
                                <br />
                                Cancellations &amp; Returns
                            </span>
                        </p>
                    </div>
                    <div className={styles.group290}>
                        <p className={styles.spanWrapper64}>
                            <span className={styles.textWrapper177}>Policy</span>
                        </p>
                        <p className={styles.spanWrapper65}>
                            <span className={styles.textWrapper178}>
                                Return
                                <br />
                                Terms Of Use
                                <br />
                                Privacy
                            </span>
                        </p>
                    </div>
                    <div className={styles.group291}>
                        <p className={styles.spanWrapper64}>
                            <span className={styles.textWrapper177}>SOCIAL</span>
                        </p>
                        <p className={styles.spanWrapper65}>
                            <span className={styles.textWrapper178}>
                                Facebook
                                <br />
                                Twitter
                                <br />
                                Instagram
                            </span>
                        </p>
                    </div>
                    <div className={styles.group292}>
                        <p className={styles.spanWrapper64}>
                            <span className={styles.textWrapper177}>NEW TO Boameooo?</span>
                        </p>
                        <p className={styles.subscribeToGet}>
                            <span className={styles.textWrapper179}>Subscribe to get updates on our latest offers!</span>
                        </p>
                        <div className={styles.group293}>
                            <div className={styles.overlapGroup40}>
                                <p className={styles.enterEmail}>
                                    <span className={styles.textWrapper180}>Enter Email</span>
                                </p>
                                <img className={styles.vector21} alt="Vector" src="/img/vector-127.png" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.group294}>
                        <p className={styles.spanWrapper64}>
                            <span className={styles.textWrapper177}>Download App</span>
                        </p>
                        <div className={styles.group295}>
                            <div className={styles.overlapGroup41}>
                                <div className={styles.group296}>
                                    <img className={styles.appStore} alt="App store" src="/img/app-store-19.png" />
                                    <img className={styles.downloadOnThe} alt="Download on the" src="/img/download-on-the-18.png" />
                                    <img className={styles.LOGO} alt="Logo" src="/img/logo-16.png" />
                                </div>
                            </div>
                        </div>
                        <div className={styles.group297}>
                            <div className={styles.group298}>
                                <img className={styles.googlePlayLOGO} alt="Google play LOGO" src="/img/google-play-logo-15.png" />
                                <img className={styles.googlePlay} alt="Google play" src="/img/google-play-11.png" />
                                <img className={styles.GETITON} alt="Get IT ON" src="/img/get-it-on-16.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.elementBoameoooComWrapper}>
                    <p className={styles.elementBoameoooCom}>
                        <span className={styles.textWrapper178}>
                            © 2021 Boameooo.com&nbsp;&nbsp;|&nbsp;&nbsp;All rights Reserved
                        </span>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
