import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete, Polyline } from '@react-google-maps/api';
import styles from './GoogleGeolocations.module.css';

const libraries = ['places'];

function GoogleGeolocations({ onLocationSelect, mapCenter, updateMapCenter, routePath }) {
  const [location, setLocation] = useState({ lat: 6.700071, lng: -1.630783 });
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setLocation({ lat, lng });
      setMarker({ lat, lng });
      onLocationSelect(lat, lng);
      // updateMapCenter(lat, lng); // Update the map center
    }
  };

  const onMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setLocation({ lat, lng });
    setMarker({ lat, lng });
    onLocationSelect(lat, lng);
  };

 // Update map center when mapCenter prop changes
 useEffect(() => {
  if (mapCenter) {
    setLocation(mapCenter);
    setMarker(mapCenter);
  }
}, [mapCenter]);


  return (
    <LoadScript googleMapsApiKey="AIzaSyD8q2NkkRzydgefD5GbwH92vogyQDEIbFs" libraries={libraries}>
      <div className={styles.googleGeocontainer}>
        <div className={styles.googleGeosearchBox}>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <input
              type="text"
              placeholder="Search location"
              style={{ width: '100%', height: '23px' }}
            />
          </Autocomplete>
        </div>

        <GoogleMap
          mapContainerClassName={styles.googleGeomapContainer}
          center={location}
          zoom={10}
          onClick={onMapClick}
        >
          {marker && <Marker position={marker} />}
          {/* Render the route if routePath is provided */}
          {routePath && (
            <Polyline path={routePath} options={{ strokeColor: '#FF0000' }} />
          )}
        </GoogleMap>

        {location && (
          <div className={styles.googleGeolocationDetails}>
            <p>Latitude: {location.lat}</p>
            <p>Longitude: {location.lng}</p>
          </div>
        )}
      </div>
    </LoadScript>
  )
}

export default React.memo(GoogleGeolocations);
