import React, { useState, useEffect } from 'react';
import styles from './NotificationSettings.module.css';

const NotificationSettings = ({ user }) => {
  
  const [notifications, setNotifications] = useState({
    email: user?.notificationSettings?.email || false,
    sms: user?.notificationSettings?.sms || false,
    push: user?.notificationSettings?.push || false,
  });

  // Fetch the user's notification preferences from the database
  useEffect(() => {
    const fetchNotificationSettings = async () => {
      const notificationSettings = {}; // Fetch the notification settings from the database
      setNotifications({
        email: notificationSettings.email || false,
        sms: notificationSettings.sms || false,
        push: notificationSettings.push || false,
      });
    };

    fetchNotificationSettings();
  }, [user]);

  // Update the user's notification preferences
  const updateNotificationSettings = async (newSettings) => {
    // Update the notification preferences in the database and update the state
  };

  const handleNotificationToggle = (type) => {
    const newSettings = { ...notifications, [type]: !notifications[type] };
    updateNotificationSettings(newSettings);
    setNotifications(newSettings);
  };

  return (
    <div className={styles.notificationSettingsContainer}>
      <h2 className={styles.notificationSettingsTitle}>
        Notifications Settings
      </h2>
      <div className={styles.notificationSettings}>
        <label>
          <input
            type="checkbox"
            checked={notifications.email}
            onChange={() => handleNotificationToggle('email')}
          />
          Email Notifications
        </label>
        <label>
          <input
            type="checkbox"
            checked={notifications.sms}
            onChange={() => handleNotificationToggle('sms')}
          />
          SMS Notifications
        </label>
        <label>
          <input
            type="checkbox"
            checked={notifications.push}
            onChange={() => handleNotificationToggle('push')}
          />
          Push Notifications
        </label>
      </div>
    </div>
  );
};

export default NotificationSettings;




