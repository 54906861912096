// src/components/NavBar/NavBar.js
// import React from 'react';
// import { Link } from 'react-router-dom';

// import Style  from './servicesnavbar.module.css'


// const ServicesNavBar = () => {
//   return (
//     <div className="navbarContainer">
//       <div className="logo">
//         <Link to="/">Boameooo</Link>
//       </div>
//       <div className="menuItems">
//         <Link to="/ecommerce/services">Services</Link>
//         {/* Add other links as needed */}
//       </div>
//       <div className="userControls">
//         <Link to="/login">Login</Link>
//         <Link to="/account">My Account</Link>
//       </div>
//     </div>
//   );
// };

// export default ServicesNavBar;


import React from 'react';
import { Link } from 'react-router-dom';
import Style from './servicesnavbar.module.css';

const ServicesNavBar = () => {
  return (
    <div className={Style.navbarContainer}>
      <div className={Style.logo}>
        <Link to="/">Boameooo</Link>
      </div>
      <div className={Style.menuItems}>
        <Link to="/ecommerce/services">Services</Link>
        {/* Add other links as needed */}
      </div>
      <div className={Style.userControls}>
        <Link to="/login">Login</Link>
        <Link to="/account">My Account</Link>
        </div>
    </div>
  );
 };

 export default ServicesNavBar;
