import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import AdvertArea from '../../../components/Propertu/AdvertFeatures/AdvertArea/AdvertArea';
import { useCountrySettings } from '../../../context/CountrySettingsContext';
import { africanCountries } from '../../../constants/countries';

const DeliveryLayout = ({ children }) => {
  const { selectedCountry, setSelectedCountry } = useCountrySettings();
  const selectedCurrency = africanCountries.find(
    (country) => country.code === selectedCountry
  )?.currency;

  
    const menuItems = [
        { name: 'Home', link: '/' },
        { name: 'Deliveries', link: '/deliveryhome' },
        { name: 'Dashboard', link: '/dashboard' },
        // { name: 'Tracking', link: '/tracking' },
        // { name: "PickUp's", link: '/pickups' },
        // { name: 'Messages', link: '/deliverymessages' },
      ];

      const boameColor = 'green';

  return (
    <>
      <Navbar 
        serviceName="Deliveries" 
        menuItems={menuItems} 
        boameColor={boameColor} 
        selectedCountry={selectedCountry}
        onCountryChange={setSelectedCountry} />

     
      <main>
        {React.Children.map(children, (child) =>
          child ? React.cloneElement(child, { currency: selectedCurrency }) : null
        )}
      </main> 
      <Footer />
    </>
  );
};

export default DeliveryLayout;


