import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FlutterwavePayments from '../../components/Payments/FlutterwavePayments';
import { projectFirestore } from '../../firebase/config';

import styles from './OrderSummary.module.css';


function OrderSummary() {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [error, setError] = useState(null); // New error state
  const orderId = location.state?.orderData?.orderId;
  const [orderData, setOrderData] = useState(null);
  const { selectedPickupAddress, selectedDeliveryAddress, packageDetails, deliveryCost } = orderData || {};


  useEffect(() => {
    // console.log("Order data received in OrderSummary inside:", orderData);
    // console.log("Location state in OrderSummary:", location.state);
    // console.log("Received pickupDate:", orderData?.packageDetails?.pickupDate);
    // console.log("Received deliveryDate inside:", orderData?.packageDetails?.deliveryDate);

    const fetchOrderData = async () => {
      setIsLoading(true); // Start loading
      setError(null); // Reset error state
      if (!orderId) {
        setError("Order ID not found.");
        setIsLoading(false);
        return;
      }

      try {
        const doc = await projectFirestore.collection('deliveryorders').doc(orderId).get();
        if (doc.exists) {
          setOrderData({ orderId, ...doc.data() });
        } else {
          setError("No such order!");
        }
      } catch (error) {
        setError(`Error fetching order: ${error.message}`);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchOrderData();
  }, [orderId]);


    // // console.log("Order data received in OrderSummary outside:", orderData);
    // // console.log("Location state in OrderSummary:", location.state);
    // // console.log("Received pickupDate:", orderData?.packageDetails?.pickupDate);
    // // console.log("Received deliveryDate outside:", orderData?.packageDetails?.deliveryDate);


  if (isLoading) {
    return <div>Loading order data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderData) {
    return <div>Order data not found.</div>;
  }

function formatDate(dateString) {
  if (!dateString) return 'Date not set';
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString();
}

// console.log("Received orderId at the start:", orderId);

const handlePaymentSuccess = async (response) => {
  // console.log("Payment success callback started");
  // console.log("Order data:", orderData);
  // console.log("Payment response:", response);
  // console.log("Received orderId:", orderId);
  // console.log("Received orderId inside:", orderId);



  if (response.redirectstatus === undefined) {
    delete response.redirectstatus;
  }

  try {
    await projectFirestore.collection('deliveryorders').doc(orderId).update({
      isPaymentCompleted: true,
      paymentStatus: 'paid',
      // paymentDetails: response
    });
    console.log(`Payment status updated for order ID: ${orderId}`);
    history.push('/sendings');
  } catch (error) {
    console.error('Error updating payment status:', error);
  }
  console.log("Payment success callback ended");
};
console.log("Received orderId Outside:", orderId);

  // Function to format address
  const formatAddress = (address) => {
    return address ? `${address.street}, ${address.city}` : 'No address selected';
  };

  return (
    <div className={styles.summaryContainer}>
      <div className={styles.header}>
        <h2>Order Summary</h2>
        <p>Order ID: {orderData.orderId || 'Not available'}</p>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Addresses</div>
        <div className={styles.detailsRow}>
          <span className={styles.detailsLabel}>Pickup Address:</span>
          <span className={styles.detailsValue}>{formatAddress(selectedPickupAddress)}</span>
        </div>
        <div className={styles.detailsRow}>
          <span className={styles.detailsLabel}>Delivery Address:</span>
          <span className={styles.detailsValue}>{formatAddress(selectedDeliveryAddress)}</span>
        </div>
      </div>

     {/* Package Details Section */}
    <div className={styles.section}>
      <div className={styles.sectionTitle}>Package Details</div>
      <div className={styles.detailsRow}>
        <span className={styles.detailsLabel}>Package Type:</span>
        <span className={styles.detailsValue}>{packageDetails.selectedCategory}</span>
      </div>
      <div className={styles.detailsRow}>
        <span className={styles.detailsLabel}>Weight:</span>
        <span className={styles.detailsValue}>{packageDetails.weight}</span>
      </div>
      <div className={styles.detailsRow}>
        <span className={styles.detailsLabel}>Dimensions:</span>
        <span className={styles.detailsValue}>{`${packageDetails.dimensions.length} x ${packageDetails.dimensions.width} x ${packageDetails.dimensions.height}`}</span>
      </div>
      <div className={styles.detailsRow}>
        <span className={styles.detailsLabel}>Description:</span>
        <span className={styles.detailsValue}>{packageDetails.description}</span>
      </div>
    </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Dates</div>
        <div className={styles.detailsRow}>
          <span className={styles.detailsLabel}>Pickup Date:</span>
          <span className={styles.detailsValue}>{formatDate(packageDetails?.pickupDate)}</span>
        </div>
        <div className={styles.detailsRow}>
          <span className={styles.detailsLabel}>Delivery Date:</span>
          <span className={styles.detailsValue}>{formatDate(packageDetails?.deliveryDate)}</span>
        </div>
      </div>

     

    {deliveryCost !== undefined && (
      <FlutterwavePayments
        amount={deliveryCost}
        onPaymentSuccess={handlePaymentSuccess}
      />
    )}

  </div>
    
    
  );
}

export default OrderSummary;

