import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import WorksAndJobsLayout from '../../components/Hoc/WorksAndJobsLayout/WorksAndJobsLayout';
import { globalCountries } from '../../constants/countries';
import { projectFirestore } from '../../firebase/config';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';

import styles from './WorksAndJobsLanding.module.css';

function WorksAndJobsLanding() {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [postedJobs, setPostedJobs] = useState([]);
    const [requestedWorks, setRequestedWorks] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(''); // Country selection state

    useEffect(() => {
        const fetchJobs = async () => {
            const snapshot = await projectFirestore.collection('jobPosts').get();
            const jobs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            // Separate the jobs into two categories
            const posted = jobs.filter(job => job.jobCategory === 'Professional-Corporate');
            const requested = jobs.filter(job => job.jobCategory === 'Personal-Local');

            setPostedJobs(posted);
            setRequestedWorks(requested);
        };

        fetchJobs();
    }, []);

    // Function to navigate to job details
    const navigateToJobDetails = (jobId) => {
        // Replace '/job-details/' with your actual route
        history.push(`/posted-job/${jobId}`);
    };

    // Function to navigate to requested work details
    const navigateToWorkDetails = (workId) => {
        history.push(`/requested-work/${workId}`);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <WorksAndJobsLayout>
            <div className={styles.container}>
                {/* <div className={styles.introductionSection}> */}
                <header className={styles.header}>
                <h2>Welcome to Works and Jobs</h2>
                    <p>
                        Discover a world of opportunities with Works and Jobs. Our platform connects talented individuals with a diverse range of employment options, from freelance gigs to full-time career paths. We specialize in tailoring job searches to your skills and preferences, ensuring that every connection we make is a step towards your ideal professional future.
                    </p>
                    <p>
                        Whether you're seeking short-term projects, contemplating a major career move, or exploring consultancy and advisory roles, our comprehensive service portfolio is designed to meet your every need. Dive into our community resources for insights, tips, and networking opportunities. Join us and pave the way to a fulfilling and dynamic professional journey.
                    </p>
                </header>
                {/* </div> */}
                <div className={styles.searchAndSelect}>
                    <select 
                        className={styles.countryDropdown} 
                        onChange={(e) => setSelectedCountry(e.target.value)} 
                        value={selectedCountry}
                    >
                        <option value="">Select a Country</option>
                        {globalCountries.map(country => (
                            <option key={country.code} value={country.code}>{country.name}</option>
                        ))}
                    </select>
                    <input 
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className={styles.searchInput}
                    />
                </div>

                {/* Services Section */}
                <div className={styles.servicesContainer}>
                    {/* Posted Jobs Section */}
                    <section className={styles.postedJobs}>
                        <h4>Posted Jobs</h4>
                        <div className={styles.listLayout}>
                            {postedJobs.map(job => (
                                <div key={job.id} onClick={() => navigateToJobDetails(job.id)} className={styles.listItem}>
                                    <h5 className={styles.jobTitle}>{job.title}</h5>
                                    <div className={styles.jobDetails}>
                                        <span>Location: {job.location}</span>
                                        {/* <span>Category: {job.jobCategory}</span> */}
                                        <span>Salary: {job.salaryRange}</span>
                                        <span>Posted On: {new Date(job.postedOn.toDate()).toLocaleDateString()}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Requested Works Section */}
                    <section className={styles.requestedWorks}>
                        <h4>Requested Works</h4>
                        <div className={styles.listLayout}>
                            {requestedWorks.map(work => (
                                <div key={work.id} onClick={() => navigateToWorkDetails(work.id)} className={styles.listItem}>
                                    <h5 className={styles.jobTitle}>{work.title}</h5>
                                    <div className={styles.jobDetails}>
                                        <span>Location: {work.location}</span>
                                        {/* <span>Category: {work.jobCategory}</span> */}
                                        <span>Salary: {work.salaryRange}</span>
                                        <span>Posted On: {new Date(work.postedOn.toDate()).toLocaleDateString()}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>




                <ToastContainer />
            </div>
        </WorksAndJobsLayout>
    );
}

export default WorksAndJobsLanding;
