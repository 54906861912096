// import React, { useState, useEffect } from 'react';
// import { commonAccountFeatures, 
//             manageAfholderFeatures, 
//             manageDeliveryPartnerFeatures,
//             manageIndivCusBooksFeatures, 
//             manageVisualsFeatures, 
//             managePropertuFeatures, 
//             manageDeliveryFeatures, 
//             manageIndivPartnerBooksFeatures, 
//             manageProposalFeatures,
            
//          } from '../../../constants/constants';

// import styles from './Sidebar.module.css';

// const Sidebar = ({ userRole, onFeatureSelect }) => {
//     const [selectedService, setSelectedService] = useState('Afholder');

//     // Define services array
//     const services = {
//         'Afholder': manageAfholderFeatures,
//         'Delivery Partners': manageDeliveryPartnerFeatures,
//         // 'Books': manageIndivCusBooksFeatures,
//         // 'visuals': manageVisualsFeatures,
//         // 'propertu': managePropertuFeatures,
//         // 'delivery': manageDeliveryFeatures,
//         // 'proposallanding': manageProposalFeatures,
       
//         // ... other services
//     };

   
//     // useEffect(() => {
//     //     // Reset to default when role changes
//     //     setSelectedService('Afholder');
//     // }, [userRole]);

//     useEffect(() => {
//         // Default to 'Afholder' for most roles
//         let defaultService = 'Afholder';
    
//         // For specific roles, set the default service to 'Delivery Partners'
//         if (['business-customer', 'individual-partner', 'business-partner'].includes(userRole)) {
//             defaultService = 'Delivery Partners';
//         }
    
//         // For 'admin', possibly give a different default or handle separately
//         if (userRole === 'admin') {
//             defaultService = 'Admin Dashboard'; // or another appropriate default
//         }
    
//         setSelectedService(defaultService);
//     }, [userRole]);
    

//     return (
//         <div className={styles.sidebar}>
//             <select
//                 value={selectedService}
//                 className={styles.servicesDropdown}
//                 onChange={(e) => setSelectedService(e.target.value)}
//             >
//                 {Object.keys(services).map(service => (
//                     <option key={service} value={service}>{service}</option>
//                 ))}
//             </select>
//             <div className={styles.serviceFeatures}>
//                 {services[selectedService]?.map(feature => (
//                     <div
//                         key={feature}
//                         className={styles.featureListItem}
//                         onClick={() => onFeatureSelect(feature)}
//                     >
//                         {feature}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default Sidebar;


import React, { useState, useEffect } from 'react';
import { manageAfholderFeatures, manageDeliveryPartnerFeatures } from '../../../constants/constants';

import styles from './Sidebar.module.css';

const Sidebar = ({ userRole, onFeatureSelect }) => {
    const [selectedService, setSelectedService] = useState('Afholder');

    // Define services array with Delivery Partner features
    const services = {
        'Afholder': manageAfholderFeatures,
        'Delivery Partners': manageDeliveryPartnerFeatures,
        
        // Add other services here
    };

    useEffect(() => {
        // Update the default service based on the user role
        if (['business-customer', 'individual-partner', 'business-partner'].includes(userRole)) {
            setSelectedService('Delivery Partners');
        } else if (userRole === 'admin') {
            setSelectedService('Admin Dashboard'); // Adjust as needed
        } else {
            setSelectedService('Afholder');
        }
    }, [userRole]);

    return (
        <div className={styles.sidebar}>
            <select
                value={selectedService}
                className={styles.servicesDropdown}
                onChange={(e) => setSelectedService(e.target.value)}
            >
                {Object.keys(services).map(service => (
                    <option key={service} value={service}>{service}</option>
                ))}
            </select>
            <div className={styles.serviceFeatures}>
                {services[selectedService]?.map(feature => (
                    <div
                        key={feature}
                        className={styles.featureListItem}
                        onClick={() => onFeatureSelect(feature)}
                    >
                        {feature}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
