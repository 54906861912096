import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { projectFirestore } from '../../../firebase/config';
import styles from './RequestedWorkDetail.module.css'; // Assume you have corresponding CSS

const RequestedWorkDetail = () => {
    const { workId } = useParams();
    const [workDetails, setWorkDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWorkDetails = async () => {
            try {
                const doc = await projectFirestore.collection('jobPosts').doc(workId).get();
                if (doc.exists) {
                    setWorkDetails(doc.data());
                } else {
                    setError('Work request not found');
                }
            } catch (err) {
                setError('Error fetching work request details');
            }
            setLoading(false);
        };

        fetchWorkDetails();
    }, [workId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className={styles.workDetailContainer}>
            <h1>{workDetails?.title}</h1>
            <p><strong>Country:</strong> {workDetails?.country}</p>
            <p><strong>Location:</strong> {workDetails?.location}</p>
            <p><strong>Salary Range:</strong> {workDetails?.salaryRange}</p>
            <p><strong>Sub Category:</strong> {workDetails?.subCategory}</p>
            <p><strong>Job Description:</strong> {workDetails?.description}</p>
            
            {/* Add more details as needed */}
        </div>
    );
};

export default RequestedWorkDetail;
