import React, { useEffect, useState } from 'react';

import BusinessProfile from '../../Profile/BusinessProfile';

const BusinessDashboard = ({ user }) => {
  return (
    <BusinessProfile user={user} />
  );
};

export default BusinessDashboard;


