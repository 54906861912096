import React, { createContext, useReducer, useEffect } from 'react';
import { cartReducer } from './cartReducer';
import { useAuthContext } from '../hooks/useAuthContext';
import { useCollection } from '../hooks/useCollection';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { user } = useAuthContext();
  const { documents, error } = useCollection('anpbooks');
  const initialState = JSON.parse(localStorage.getItem('cartItems')) || [];
  const [cartItems, dispatch] = useReducer(cartReducer, initialState);
  

  useEffect(() => {
   // console.log('Cart items after adding in useEffect:', cartItems);
    // Save cart items to local storage whenever they change
    if (cartItems.length > 0) {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    } else {
      localStorage.removeItem('cartItems');
    }
  }, [cartItems]);

  const addToCart = (book, fromCurrency, displayPrice ) => {
    console.log('addToCart called in CartContext', book, fromCurrency);

    const newItem = {
      id: book.id,
      bookName: book.bookName,
      bookAuthor: book.bookAuthor,
      bookPrice: book.bookPrice,
      displayPrice: displayPrice,
      bookCoverUrl: book.bookCoverUrl,
      bookType: book.bookType,
      subscriptionFee: book.subscriptionFee || null,
      quantity: 1,
      currency: fromCurrency,
    };

    console.log('New item added with displayPrice:', newItem); // Add this line

    dispatch({ type: 'ADD_TO_CART', item: newItem });
    console.log('Cart items after adding in addToCart function:', cartItems);
    console.log('New item added:', newItem);
  };

  const removeFromCart = (itemId) => {
    dispatch({ type: 'REMOVE_FROM_CART', itemId });
  };

  const updateItemQuantity = (itemId, newQuantity) => {
    dispatch({ type: 'UPDATE_ITEM_QUANTITY', itemId, newQuantity });
  };

  const removeItem = (itemId) => {
    dispatch({ type: 'REMOVE_ITEM', itemId });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  }

  return (
    <CartContext.Provider value={{ cartItems, dispatch, documents, addToCart, removeFromCart, updateItemQuantity, removeItem, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
