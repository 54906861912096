import React, { useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config';

import styles from './BoameoooServices.module.css';

const BoameoooServices = () => {
    const [serviceName, setServiceName] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await projectFirestore.collection('boameoooServices').add({
                serviceName,
                serviceDescription
            });
            // Reset form fields after successful submission
            setServiceName('');
            setServiceDescription('');
        } catch (error) {
            console.error("Error adding service: ", error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <input 
                type="text" 
                value={serviceName} 
                onChange={(e) => setServiceName(e.target.value)} 
                className={styles.input} 
                placeholder="Service Name"
            />
            <textarea 
                value={serviceDescription} 
                onChange={(e) => setServiceDescription(e.target.value)} 
                className={styles.textarea} 
                placeholder="Service Description"
            />
            <button type="submit" className={styles.submitButton}>Submit Service</button>
        </form>
    );
};

export default BoameoooServices;