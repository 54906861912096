export const homeData = {
    spanText1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    spanText2: "here are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
    spanText9: "Subscribe to get updates about new books",
    spanText10: "Enter your email address",
    spanText11: "Subscribe Now"
  }
  
  export const categories = [  { value: 'development', label: 'Development' },  
                        { value: 'afconomy', label: 'Afconomy' },  
                        { value: 'wealth', label: 'Wealth' },  
                        { value: 'marketing', label: 'Marketing' },]

  export const currencies = {
                          "USD": "US Dollar",
                          "EUR": "Euro",
                          "JPY": "Japanese Yen",
                          "GBP": "Pound Sterling",
                          "AUD": "Australian Dollar",
                          "CAD": "Canadian Dollar",
                          "CHF": "Swiss Franc",
                          "CNY": "Chinese Yuan",
                          "HKD": "Hong Kong Dollar",
                          "NZD": "New Zealand Dollar"
                        }
                        
  export const genres = [
                          'Action and Adventure',
                          'Cultural',
                          'Development',
                          'Wealth',
                          'Afcomony',
                          'Ecomony',
                          'Finance',
                          'Mystery',
                          'Fantasy',
                          'Science Fiction',
                          'Romance',
                          'Horror',
                          'Historical Fiction',
                          'Biography',
                          'Non-Fiction',
                          'Governance Philosophy and African Studies',
                        ];
                        
  export const languages = [
                          'English',
                          'Spanish',
                          'Chinese',
                          'French',
                          'German',
                          'Japanese',
                          'Russian',
                          'Italian',
                          'Portuguese',
                        ];
                        