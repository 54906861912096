// UserProfile.js
import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectFirestore } from '../../../firebase/config';
import styles from './UserProfile.module.css'; 

const UserProfile = () => {
    const { user } = useAuthContext();
    const [profile, setProfile] = useState({
        displayName: '',
        email: '',
        phoneNumber: '',
        afrorole: '',
        experience: '',
        skills: '',
        professionalSite: '',
        afholderStatus: '', // Add new field for Afholder status
    });
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (user) {
            setIsLoading(true);
            const docRef = projectFirestore.collection('users').doc(user.uid);
            docRef.get().then(doc => {
                if (doc.exists) {
                    setProfile({ ...profile, ...doc.data() });
                }
                setIsLoading(false);
            }).catch(error => {
                setMessage('Error fetching profile');
                setIsLoading(false);
            });
        }
    }, [user]);

    const handleChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await projectFirestore.collection('users').doc(user.uid).update(profile);
            setMessage('Profile updated successfully');
        } catch (error) {
            setMessage('Error updating profile');
        }
        setIsLoading(false);
    };

    return (
        <div className={styles.profileContainer}>
            <form onSubmit={handleSubmit} className={styles.profileContainer}>
                <label className={styles.profileLabel}>
                    Full Name:
                    <input
                        type="text"
                        name="displayName"
                        value={profile.displayName}
                        onChange={handleChange}
                        className={styles.profileInput}
                    />
                </label>
                <label className={styles.profileLabel}>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={profile.email}
                        onChange={handleChange}
                        className={styles.profileInput}
                    />
                </label>
                <label className={styles.profileLabel}>
                    Phone Number:
                    <input
                        type="tel"
                        name="phoneNumber"
                        value={profile.phoneNumber}
                        onChange={handleChange}
                        className={styles.profileInput}
                    />
                </label>
                <label className={styles.profileLabel}>
                    Role:
                    <select
                        name="afrorole"
                        value={profile.afrorole}
                        onChange={handleChange}
                        className={styles.profileSelect}
                    >
                        <option value="">Select a Role</option>
                        <option value="AfroPunks">AfroPunks</option>
                        <option value="African Guardian Council">African Guardian Council</option>
                    </select>
                </label>
                <label className={styles.profileLabel}>
                    Relevant Experience or Background:
                    <textarea
                        name="experience"
                        value={profile.experience}
                        onChange={handleChange}
                        className={styles.profileTextarea}
                    />
                </label>
                <label className={styles.profileLabel}>
                    Specific Skills or Qualifications:
                    <textarea
                        name="skills"
                        value={profile.skills}
                        onChange={handleChange}
                        className={styles.profileTextarea}
                    />
                </label>
                <label className={styles.profileLabel}>
                    Professional Networking Site:
                    <input
                        type="text"
                        name="professionalSite"
                        placeholder="Your LinkedIn profile address"
                        value={profile.professionalSite}
                        onChange={handleChange}
                        className={styles.profileInput}
                    />
                </label>
                <div>
                    <label>Afholder Status:</label>
                    <span>{profile.afholderStatus}</span>
                </div>
                <button type="submit" className={styles.profileButton}>Update Profile</button>
                {isLoading && <p>Loading...</p>}
                {message && <p>{message}</p>}
            </form>
        </div>
    );
};

export default UserProfile;
