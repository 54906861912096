// import React, { useState, useEffect } from 'react';
// import styles from './EditJobTypeForm.module.css';

// const EditJobTypeForm = ({ jobType, onSave, onCancel }) => {
//   const [name, setName] = useState('');
//   const [description, setDescription] = useState('');
//   const [category, setCategory] = useState(''); // New state
//   const [error, setError] = useState('');

//   // useEffect(() => {
//   //   if (jobType && jobType.id) {
//   //     setName(jobType.name);
//   //     setDescription(jobType.description);
//   //   } else {
//   //     setName('');
//   //     setDescription('');
//   //   }
//   // }, [jobType]);

//   useEffect(() => {
//     if (jobType) {
//         setName(jobType.name || '');
//         setDescription(jobType.description || '');
//         setCategory(jobType.category || '');
//     }
// }, [jobType]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!name.trim() || !description.trim()) {
//       setError('Both name and description are required.');
//       return;
//     }
//     setError('');
//     onSave({ ...jobType, name, description });
//   };

//   return (
//     <form onSubmit={handleSubmit} className={styles.form}>
//         {error && <p className={styles.error}>{error}</p>}
//         <div className={styles.inputGroup}>
//             <label htmlFor="name">Job Type Name:</label>
//             <input
//                 id="name"
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 className={styles.input}
//             />
//         </div>
//         <div className={styles.inputGroup}>
//             <label htmlFor="description">Description:</label>
//             <textarea
//                 id="description"
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//                 className={styles.textarea}
//             />
//         </div>
//         <div className={styles.inputGroup}>
//             <label htmlFor="editCategory">Category:</label>
//             <select
//                 id="editCategory"
//                 value={category}
//                 onChange={(e) => setCategory(e.target.value)}
//                 className={styles.select}
//             >
//                 <option value="">Select a Category</option>
//                 <option value="High-End">High-End Services</option>
//                 <option value="Low-End">Low-End Services</option>
//             </select>
//         </div>
//         <div className={styles.actions}>
//             <button type="submit" className={styles.saveButton}>Save</button>
//             <button type="button" onClick={onCancel} className={styles.cancelButton}>Cancel</button>
//         </div>
//     </form>
// );
// };

// export default EditJobTypeForm;


import React, { useState, useEffect } from 'react';
import styles from './EditJobTypeForm.module.css';

const EditJobTypeForm = ({ jobType, onSave, onCancel }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState(''); // New state
  const [error, setError] = useState('');

  useEffect(() => {
    if (jobType) {
        setName(jobType.name || '');
        setDescription(jobType.description || '');
        setCategory(jobType.category || '');
    }
  }, [jobType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim() || !description.trim() || !category.trim()) {
      setError('Name, description, and category are required.');
      return;
    }
    setError('');
    onSave({ ...jobType, name, description, category }); // Include category in onSave
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.inputGroup}>
        <label htmlFor="name">Job Type Name:</label>
        <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="description">Description:</label>
        <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={styles.textarea}
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="editCategory">Category:</label>
        <select
            id="editCategory"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className={styles.select}
        >
            <option value="">Select a Category</option>
            <option value="Professional-Corporate">Professional and Corporate Jobs</option>
            <option value="Personal-Local">Personal and Local Jobs</option>
        </select>
      </div>
      <div className={styles.actions}>
        <button type="submit" className={styles.saveButton}>Save</button>
        <button type="button" onClick={onCancel} className={styles.cancelButton}>Cancel</button>
      </div>
    </form>
  );
};

export default EditJobTypeForm;
