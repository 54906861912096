// Signup.js
import React, { useRef, useEffect, useState, useContext } from 'react'; 
import { v4 as uuidv4 } from 'uuid';
import { africanCountries } from '../../constants/countries';
import useGoogleAuth from '../../hooks/useGoogleAuth';
import EmailIcon from '@mui/icons-material/Email';
import { NavLink } from 'react-router-dom';
import { useSignup } from '../../hooks/useSignup'
import googleIcons8 from '../../assets/google.svg'
import { AuthContext } from '../../context/AuthContext';
import { projectFirestore } from '../../firebase/config';
import { useHistory } from "react-router-dom";

import styles from './Signup.module.css';

export default function Signup() {
  const { user } = useContext(AuthContext);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [displayName, setDisplayName] = useState('')
  const history = useHistory();
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)
  const [boameoServices, setBoameoServices] = useState([]);
  const [role, setRole] = useState('') // set default role
  const [country, setCountry] = useState(''); // Add state for the selected country
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  // const { signup, isPending, error } = useSignup()
  
  // const { signInWithGoogle, error: googleError, isPending: googleIsPending } = useGoogleAuth();
  const [isGoogleSignup, setIsGoogleSignup] = useState(false); // State to track Google signup
  const [isFormValid, setIsFormValid] = useState(false);
  const [signupMethod, setSignupMethod] = useState(null); // null, 'email', or 'google'
  const [selectedService, setSelectedService] = useState(''); // State for the selected service
  const selectedCountryObj = africanCountries.find(c => c.code === country);

  // const { signInWithGoogle, isPending, error: googleError } = useGoogleAuth();
  // const { signInWithGoogle, isPending: isGooglePending, error: googleError } = useGoogleAuth();
  const { signup, isPending: isEmailPending, error: emailSignupError } = useSignup();
  const { signInWithGoogle, isPending: isGooglePending, error: googleSignupError } = useGoogleAuth();


  useEffect(() => {
    if (user && user.uid) {
      // Redirect based on the user's state
      const redirectTo = user.role === 'Delivery' ? '/deliveryonboarding' : '/contact';
      history.push(redirectTo, { userId: user.uid });
    }
  }, [user, history]);


  const handleGoogleSignup = () => {
    console.log('Google signup button clicked');
    signInWithGoogle();
    //signInWithGoogle();
  };

   const completeGoogleSignup = async () => {
    // Check if additional fields are filled out
    if (!country || !phoneNumber || !role) {
      // Show validation errors if required fields are not filled
      alert('Please fill all required fields: Country, Phone Number, and Role.'); // Or use a more sophisticated way to show errors
      return;
    }
  
    // Trigger Google sign-in
    try {
      await signInWithGoogle(role, { country, phoneNumber });
      // After successful sign-in with Google, you might want to redirect the user or set the state to show additional fields.
    } catch (error) {
      // Handle errors, such as showing a message to the user
      alert('Error during Google sign-up: ' + error.message); // Display error message to the user
    }
  };
  
  const handleEmailSignupClick = () => {
    setSignupMethod('email');
  };

  // Define handleChooseDifferentMethod here
  const handleChooseDifferentMethod = () => {
    setSignupMethod(null);
    // Also reset other form states if necessary
    // setEmail('');
    // setPassword('');
    // setDisplayName('');
    // setThumbnail(null);
    // setThumbnailError(null);
  };

  // Function to validate form
  const validateForm = () => {
    // Check if country, phoneNumber, and role are filled
    // Add other necessary validations as needed
    return country && phoneNumber && role;
  };

  const validateEmail = (email) => {
    // This is a simple, commonly used regex for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!re.test(String(email).toLowerCase())) {
      setEmailError('Invalid email format');
      return false;
    }
    setEmailError('');
    return true;
  };
  
  const validatePassword = (password) => {
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters');
      return false;
    }
    setPasswordError('');
    return true;
  };

 

useEffect(() => {
  const fetchServices = async () => {
    const servicesRef = projectFirestore.collection('boameoooServices'); // Make sure this matches your Firestore collection name
    const snapshot = await servicesRef.get();
    const servicesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setBoameoServices(servicesData);
  };

  fetchServices();
}, []);


  // useEffect to update isFormValid when relevant states change
  useEffect(() => {
    setIsFormValid(validateForm());
  }, [country, phoneNumber, role]);
  

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log("handleSubmit started");
  console.log('Form submitted');

  // Only proceed if the form is valid
  if (!validateForm()) {
    console.error('Form validation failed');
    return;
  }

  const phoneNumberToSave = phoneNumber;
  const uniqueIdentifier = uuidv4();

  try {

    // Handle Google Sign Up
    if (signupMethod === 'google') {
      await signInWithGoogle(role, {
        uniqueIdentifier,
        selectedService,
        // ... other user details ...
      });
    } else {
      // Proceed with Email Sign Up
      const isEmailValid = validateEmail(email);
      const isPasswordValid = validatePassword(password);

      // Stop submission if validation fails
      if (!isEmailValid || !isPasswordValid) {
        console.error('Email or password validation failed');
        return;
      }
      console.log("Signup process complete, checking user context");

      // Log the user object from AuthContext
      console.log("User from context:", user);

      // Determine if the role is eligible for being an 'afholder'
      const addIsAfholder = role === "individual-customer" || role === "individual-partner" || role === "admin";

      // Call the signup function with all necessary parameters for email sign-up
      await signup(
        email,
        password,
        displayName,
        role,
        thumbnail,
        country,
        phoneNumberToSave,
        addIsAfholder ? false : null,
        uniqueIdentifier,
        selectedService,
      );
    }

    // After the signup process completes, handle redirection based on selected service
    // Wait a brief moment to ensure user context is updated
    setTimeout(async () => {
    if (user && user.uid) {
      console.log("User is authenticated, UID:", user.uid);
      // Fetch the serviceName using selectedService ID
      const serviceRef = projectFirestore.collection('boameoooServices').doc(selectedService);
      const serviceDoc = await serviceRef.get();

      console.log("Service document fetched:", serviceDoc.exists, serviceDoc.data());


      if (serviceDoc.exists && serviceDoc.data().serviceName === 'Delivery') {
        console.log("Redirecting to deliveryonboarding");
        history.push('/deliveryonboarding', { userId: user.uid });
      } else {
        console.log("Redirecting to contact");
        history.push('/contact');
      }
    } else {
      console.log("No authenticated user found in context");
    }
  }, 500); // Adjust the delay if needed
  } catch (error) {
    console.error('Error during signup:', error.message);
    console.log("Error caught in handleSubmit:", error);
    // Handle error, maybe set an error state to show in UI
  }
};


  
  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }
  
  const handleFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];
  
    if (!selected) {
      setThumbnailError('Please select a file');
      return;
    }
    if (!selected.type.includes('image')) {
      setThumbnailError('Please select an image file (jpg, png, etc.)'); // Updated error message
      return;
    }
    if (selected.size > 100000) {
      setThumbnailError('Image size must be less than 100KB'); // Updated error message
      return;
    }
    
    setThumbnailError(null);
    setThumbnail(selected);
  };

  

  const renderEmailSignUpFields = () => {
    return signupMethod === 'email' && (
      <>
        <label className={styles.signlabel}>
          <span>Country:</span>
          <select value={country} onChange={(e) => setCountry(e.target.value)} required>
            {africanCountries.map((c) => (
              <option key={c.code} value={c.code}>
                {c.flag} {c.name}
              </option>
            ))}
          </select>
        </label>

        <label className={styles.signlabel}>
          Phone Number:
          <div className={styles.phoneNumberContainer}>
            <span className={styles.phoneCode}>{selectedCountryObj ? `+${selectedCountryObj.phoneCode}` : ''}</span>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter phone number"
              required
              className={`${styles.input} phoneNumber`}
            />
          </div>
        </label>

        <label className={styles.signlabel}>
          <span>User Role:</span>
          <select value={role} onChange={handleRoleChange} required>
            <option value="" disabled>Select a Role</option>
            <option value="individual-customer">Individual Customer</option>
            <option value="business-customer">Business Customer</option>
            <option value="individual-partner">Individual Partner</option>
            <option value="business-partner">Business Partner</option>
            <option value="admin">Administrator</option>
          </select>
        </label>

        <label className={styles.signlabel}>
          Service:
          <select
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            required
          >
            <option value="">Select a Service</option>
            {boameoServices.map(service => (
              <option key={service.id} value={service.id}>{service.serviceName}</option>
            ))}
          </select>
        </label>

        <label className={styles.signlabel}>
          <span>Email:</span>
          <input 
            type="email" 
            onChange={(e) => setEmail(e.target.value)} 
            value={email}
            className={styles.input}
            placeholder="email"
            required
          />
          {emailError && <p className={styles.error}>{emailError}</p>}
        </label>

        <label className={styles.signlabel}>
          <span>Password:</span>
          <input 
            type="password" 
            onChange={(e) => setPassword(e.target.value)} 
            value={password} 
            className={styles.input}
            placeholder="password"
            required
          />
          {passwordError && <p className={styles.error}>{passwordError}</p>}
        </label>

        <label className={styles.signlabel}>
          <span>Full Name:</span>
          <input 
            type="text" 
            onChange={(e) => setDisplayName(e.target.value)}
            value={displayName}
            className={styles.input}
            placeholder="full name"
            required
          />
        </label>

        <label className={styles.signlabel}>
          <span>Profile Thumbnail:</span>
          <input 
            type="file"
            onChange={handleFileChange} 
            className={styles.input}
            required
          />
          {thumbnailError && <div className={styles.error}>{thumbnailError}</div>}
        </label>

        {!isEmailPending && (
          <button className={styles.button} type="submit">
            <EmailIcon style={{ marginRight: '5px' }} />Sign Up with Email
          </button>
        )}
        {isEmailPending && (
          <button className={styles.button} disabled>Loading</button>
        )}
        {emailSignupError && <p className={styles.error}>{emailSignupError}</p>}
      </>
    );
};


  const renderGoogleSignUpFields = () => (
    <>
      {/* Google signup form fields */}
          {signupMethod === 'google' && (
            <>
              <label className={styles.signlabel}>
                <span>Country:</span>
                {/* <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                >
                  {africanCountries.map((c) => (
                    <option key={c.code} value={c.code}>
                      {c.flag} {c.name}
                    </option>
                  ))}
                </select> */}
                <select value={country} onChange={(e) => setCountry(e.target.value)} required>
                  {africanCountries.map((c) => (
                    <option key={c.code} value={c.code}>
                      {c.flag} {c.name}
                    </option>
                  ))}
                </select>

              </label>

              <label>
                Phone Number:
                <div className={styles.phoneNumberContainer}>
                  <span className={styles.phoneCode}>{selectedCountryObj ? `+${selectedCountryObj.phoneCode}` : ''}</span>
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter phone number"
                    required
                    className={`${styles.input} phoneNumber`} // Add the additional class here
                  />
                </div>
              </label>

              <label className={styles.signlabel}>
                <span>User Role:</span>
                <select value={role} onChange={handleRoleChange} required>
                  <option value="" disabled>Select a Role</option>
                  <option value="individual-customer">Individual Customer</option>
                  <option value="business-customer">Business Customer</option>
                  <option value="individual-partner">Individual Partner</option>
                  <option value="business-partner">Business Partner</option>
                  <option value="admin">Administrator</option>
                </select>
              </label>
              <label className={styles.signlabel}>
              Service:
              <select
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                required
              >
                <option value="">Select a Service</option>
                {boameoServices.map(service => (
                  <option key={service.id} value={service.id}>{service.serviceName}</option> // Assuming each service has a 'name' property
                ))}
              </select>
            </label>

              <label className={styles.signlabel}>
                <span>Full Name:</span>
                <input 
                  type="text" 
                  onChange={(e) => setDisplayName(e.target.value)}
                  value={displayName}
                  className={styles.input}
                  placeholder="full name"
                  required
                />
              </label>
              {/* You can remove this if you handle the Google sign up button separately */}
              <button type="button" onClick={completeGoogleSignup}>Complete Google Signup</button>             
            </>
          )}
              </>
            );

            return (
              <form onSubmit={handleSubmit} className={styles.form}>
                <h2 className={styles.title}>Sign up with Email or Google</h2>
          
                {/* Buttons to choose signup method */}
                {!signupMethod && (
                  <>
                    <button className={styles.button} onClick={handleEmailSignupClick}>
                      <EmailIcon style={{ marginRight: '5px' }} />Sign Up with Email
                    </button>
                    <button 
                      className={styles.button} 
                      onClick={handleGoogleSignup}
                      disabled={isEmailPending || isGooglePending}
                    >
                      <img src={googleIcons8} alt="Google icon" width="20" style={{ marginRight: '5px' }} />
                      Sign Up with Google
                    </button>
                  </>
                )}
          
                {/* Form fields for email signup */}
                {signupMethod === 'email' && renderEmailSignUpFields()}
          
                {/* Form fields for Google signup */}
                {signupMethod === 'google' && renderGoogleSignUpFields()}
          
                {/* Button to switch methods if a signup method is selected */}
                {signupMethod && (
                  <button type="button" onClick={handleChooseDifferentMethod}>
                    Choose Different Method
                  </button>
                )}
          
                {/* Display any errors from email signup */}
                {emailSignupError && <p className={styles.error}>{emailSignupError}</p>}
          
                {/* Display any errors from Google signup */}
                {googleSignupError && <p className={styles.error}>{googleSignupError}</p>}
          
                {/* Link to login page */}
                <div className={styles.login}>
                  <p className={styles.link}>
                    Already have an account? 
                    <NavLink to="/login">
                      <span className={styles.spanlogin}>Login</span>
                    </NavLink>.
                  </p>
                </div>
              </form>
            );
          }
          




