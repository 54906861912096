// src/pages/Rentals/Rentals.js

import React from 'react';
import PropertuLayout from '../../../components/Hoc/PropertuLayout/PropertuLayout';

const Rentals = () => {
  return (
    <PropertuLayout>
      {/* Your Rentals page content */}
    </PropertuLayout>
  );
};

export default Rentals;
