import React, { useState } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './BookUpdate.module.css';

export default function AddBook({ bookData, onUpdate }) {
  const [bookName, setBookName] = useState(bookData ? bookData.bookName : '');
  const [author, setAuthor] = useState(bookData ? bookData.author : '');
  const [bookType, setBookType] = useState(bookData ? bookData.bookType : '');
  const [bookPrice, setBookPrice] = useState(bookData ? bookData.bookPrice : '');
  const [bookDescription, setBookDescription] = useState(bookData ? bookData.bookDescription : '');
  const [bookUrl, setBookUrl] = useState(bookData ? bookData.bookUrl : '');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const book = {
      bookName,
      author,
      bookType,
      bookPrice,
      bookDescription,
      bookUrl
    };
    setIsPending(true);

    try {
      if (bookData) {
        await projectFirestore.collection('anpbooks').doc(bookData.id).update(book);
        onUpdate();
      } else {
        await projectFirestore.collection('anpbooks').add(book);
      }
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
      setError(err.message);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.pageTitle}>{bookData ? 'Update Book' : 'Add Book'}</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="bookName">Book Name:</label>
          <input
            type="text"
            id="bookName"
            name="bookName"
            value={bookName}
            onChange={(event) => setBookName(event.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="author">Author:</label>
          <input
            type="text"
            id="author"
            name="author"
            value={author}
            onChange={(event) => setAuthor(event.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="bookType">Book Type:</label>
          <input
            type="text"
            id="bookType"
            name="bookType"
            value={bookType}
            onChange={(event) => setBookType(event.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="bookPrice">Price:</label>
          <input
            type="number"
            id="bookPrice"
            name="bookPrice"
            value={bookPrice}
            onChange={(event) => setBookPrice(event.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
        <label htmlFor="bookDescription">Description:</label>
          <textarea
            id="bookDescription"
            name="bookDescription"
            value={bookDescription}
            onChange={(event) => setBookDescription(event.target.value)}
            required
          ></textarea>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="bookUrl">Book URL:</label>
          <input
            type="url"
            id="bookUrl"
            name="bookUrl"
            value={bookUrl}
            onChange={(event) => setBookUrl(event.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <button>{bookData ? 'Update Book' : 'Add Book'}</button>
        </div>
        {isPending && <p className={styles.loading}>Loading...</p>}
      </form>
    </div>
  );
};
