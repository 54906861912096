
 import React, { useState } from "react";


 import "./Mouse1.css";


 function HoverableComponent() {
  const [showMessage, setShowMessage] = useState(false);


  return (
   <>
      <div
        className="hoverableDiv"
        onMouseEnter={() => {
          setShowMessage(true);
        }}
        onMouseLeave={() => {
           setShowMessage(false);
        }}
      >
        Hover me!
      </div>


       {showMessage && <h1>Hello, World!</h1>}
    </>
   );
  }

  export default HoverableComponent;


// // import React, { useState } from 'react';
// // import './Mouse1.css';

// // const Mouse1 = () => {
// //   const [enter, setEnter] = useState(0);
// //   const [leave, setLeave] = useState(0);

// //   const handleMouseEnter = (e) => {
// //     setEnter(enter + 1);
// //   };

// //   const handleMouseLeave = (e) => {
// //     setLeave(leave + 1);
// //   };

// //   return (
// //     <section>
// //       <form>
// //       <label>Enter your name:
// //         <input type="text" 
// //         className="Mouse1"
// //         onMouseEnter={handleMouseEnter}
// //         onMouseLeave={handleMouseLeave}
// //         />
// //         </label>
// //         </form>
      
// //       <span>Mouse enter: {enter}</span>
// //       <span>Mouse leave: {leave}</span>
// //     </section>
// //   );
// // };

// // export default Mouse1;
