import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import GlobalAfricaExchangeLayout from '../../components/Hoc/GlobalAfricaExchangeLayout/GlobalAfricaExchangeLayout';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import { projectFirestore } from '../../firebase/config';
import ReactSlick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './GlobalAfricaExchangeLanding.module.css';
import { globalCountries } from '../../constants/countries';

function GlobalAfricaExchangeLanding() {
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState('');
    const [exports, setExports] = useState([]);
    const [imports, setImports] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(''); // Country selection state

    const filteredExports = exports.filter(exportItem => 
        exportItem.productName.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const filteredImports = imports.filter(importItem => 
        importItem.productName.toLowerCase().includes(searchTerm.toLowerCase()));
    console.log("Filtered Imports: ", filteredImports); // Debugging log
    

        useEffect(() => {
            const fetchImports = async () => {
                try {
                    const snapshot = await projectFirestore.collection('postimports').get();
                    const importsData = snapshot.docs.map(doc => {
                        const data = doc.data();
                        return { 
                            id: doc.id, 
                            ...data, 
                            expectedDeliveryDate: data.expectedDeliveryDate ? new Date(data.expectedDeliveryDate.toDate()).toLocaleDateString() : ''
                        };
                    });
                    console.log("Fetched Imports: ", importsData); // Debugging log
                    setImports(importsData);
                } catch (error) {
                    console.error("Error fetching imports: ", error);
                }
            };
        
            fetchImports();
        }, []);
        
        

    useEffect(() => {
        const fetchExports = async () => {
            try {
                const snapshot = await projectFirestore.collection('postexports')
                                    .where('createdAt', '>=', new Date(new Date().setMonth(new Date().getMonth() - 3)))
                                    .get();
                const exportsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setExports(exportsData);
            } catch (error) {
                console.error("Error fetching exports: ", error);
            }
        };

        fetchExports();
    }, []);

    

    // const navigateToDetailPage = (item, type) => {
    //     const detailPagePath = type === 'export' ? `/export-details/${item.id}` : `/import-details/${item.id}`;
    //     history.push(detailPagePath);
    // };

    const navigateToDetailPage = (item, type) => {
        const detailPagePath = type === 'export' ? `/export-details/${item.id}` : `/import-details/${item.id}`;
        history.push(detailPagePath);
    };
    

    

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <GlobalAfricaExchangeLayout>
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1>Welcome to Global Africa Exchange</h1>
                    <p>Experience the richness of African trade with Global Africa Exchange...</p>
                </header>
                
                <div className={styles.searchAndSelect}>
                    <select 
                        className={styles.countryDropdown} 
                        onChange={(e) => setSelectedCountry(e.target.value)} 
                        value={selectedCountry}
                    >
                        <option value="">Select a Country</option>
                        {globalCountries.map(country => (
                            <option key={country.code} value={country.code}>{country.name}</option>
                        ))}
                    </select>
                    <input 
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className={styles.searchInput}
                    />
                </div>

                <div className={styles.listingsContainer}>
                {/* Posted Exports Section */}
                <section className={styles.exportsSection}>
                    <h2>Posted Exports</h2>
                    <div className={styles.listLayout}>
                        {filteredExports.map(exportItem => (
                            <div key={exportItem.id} onClick={() => navigateToDetailPage(exportItem, 'export')} className={styles.listItem}>
                                <img 
                                    src={exportItem.productImages && exportItem.productImages.length > 0 
                                        ? exportItem.productImages[0] 
                                        : 'default-image-path.jpg'} 
                                    alt={exportItem.productName} 
                                />
                                <span>{exportItem.item || exportItem.productName.split(' - ')[2]}</span>
                                <span>{exportItem.countryOfOrigin}</span>
                                <span>${exportItem.price}</span>
                                <span>{new Date(exportItem.createdAt.toDate()).toLocaleDateString()}</span>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Posted Imports Section */}
                <section className={styles.importsSection}>
                        <h2>Posted Imports</h2>
                        <div className={styles.listLayout}>
                            {filteredImports.map(importItem => (
                                <div key={importItem.id} onClick={() => navigateToDetailPage(importItem, 'import')} className={styles.listItem}>
                                    <img 
                                        src={importItem.productImages && importItem.productImages.length > 0 
                                            ? importItem.productImages[0] 
                                            : 'default-image-path.jpg'} 
                                        alt={importItem.productName || 'Product'}
                                    />
                                    <span>{importItem.productName || 'No product name available'}</span>
                                    <span>{importItem.countryOfDesiredImport}</span>
                                    <span>${importItem.targetPriceRange}</span>
                                    <span>{importItem.expectedDeliveryDate}</span>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
                <ToastContainer />
            </div>
        </GlobalAfricaExchangeLayout>
    );
}

export default GlobalAfricaExchangeLanding;
