import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import GoogleGeolocations from '../Geolocations/Google/GoogleGeolocations';
import axios from 'axios';
import styles from './AddEditAddressForm.module.css'; // CSS module for styling

const AddEditAddressForm = ({ address, onSave, onCancel }) => {
  // Define initial values for latitude and longitude
  const initialLat = 6.700071; // Replace with your default latitude
  const initialLng = -1.630783; // Replace with your default longitude
  const [mapCenter, setMapCenter] = useState({ lat: initialLat, lng: initialLng });
  const [formState, setFormState] = useState({
    fullName: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    latitude: '',
    longitude: '',
    addressType: ''
  });


  useEffect(() => {
    if (address) {
      // When there's an existing address, populate the form with its data
      setFormState({
        fullName: address.fullName || '',
        fullAddress: address.fullAddress || '',
        street: address.street || '',
        city: address.city || '',
        state: address.state || '',
        zipCode: address.zipCode || '',
        country: address.country || '',
        latitude: address.latitude || '',
        longitude: address.longitude || '',
        addressType: address.addressType || ''
      });
    } else {
      // When adding a new address, reset the form with default values
      setFormState({
        fullName: '',
        fullAddress: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        latitude: '',
        longitude: '',
        addressType: '' // Empty string for "Select Address Type"
      });
    }
  }, [address]);
  
  

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormState(prevState => ({ ...prevState, [name]: value }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed: ${name} = ${value}`); // This will log the name of the form field and the new value.
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const updateMapCenter = (lat, lng) => {
    setMapCenter({ lat, lng });
  };
  

  // const handleMapSelect = (lat, lng) => {
  //   setFormState(prevState => ({
  //     ...prevState,
  //     latitude: lat.toString(),
  //     longitude: lng.toString()
  //   }));
  // };

  const handleMapSelect = (lat, lng) => {
    console.log("Selected coordinates:", lat, lng); // This will log the latitude and longitude to the console.
    setFormState(prevState => ({
      ...prevState,
      latitude: lat.toString(),
      longitude: lng.toString()
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formState.addressType) {
      // Prevent form submission if addressType is not selected
      alert('Please select an address type.');
      return;
    }

  const apiKey = 'AIzaSyD8q2NkkRzydgefD5GbwH92vogyQDEIbFs'; 
  const fullAddress = `${formState.street}, ${formState.city}, ${formState.state}, ${formState.zipCode}, ${formState.country}`;

  // try {
  //   const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${apiKey}`);
  //   if (response.data.results.length > 0) {
  //     const { lat, lng } = response.data.results[0].geometry.location;
  //     setFormState(prevState => ({
  //       ...prevState,
  //       latitude: lat.toString(),
  //       longitude: lng.toString()
  //     }));

  //     // Optionally, update the map center
  //     // You need to pass a function to update the map center from your GoogleGeolocations component
  //     updateMapCenter(lat, lng);  // Update the map's center

  //   } else {
  //     alert('No results found for the specified address.');
  //   }
  // } catch (error) {
  //   console.error('Geocoding API error:', error);
  //   alert('Failed to fetch coordinates for the address.');
  // }


    console.log("Submitting form data:", formState);
    onSave(formState);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.editAddressForm}>
      {/* Form fields for each attribute */}
      <input type="text" name="fullName" value={formState.fullName} onChange={handleChange} placeholder="Full Name" />
      {/* <input type="text" name="fullAddress" value={formState.fullAddress} onChange={handleChange} placeholder="Enter full address" /> */}
      <input type="text" name="street" value={formState.street} onChange={handleChange} placeholder="Street" />
      <input type="text" name="city" value={formState.city} onChange={handleChange} placeholder="City" />
      <input type="text" name="state" value={formState.state} onChange={handleChange} placeholder="State" />
      <input type="text" name="zipCode" value={formState.zipCode} onChange={handleChange} placeholder="Zip Code" />
      <input type="text" name="country" value={formState.country} onChange={handleChange} placeholder="Country" />
      <div className="googleMapContainer">
        <GoogleGeolocations onLocationSelect={handleMapSelect} />
        {/* <GoogleGeolocations onLocationSelect={handleMapSelect} updateMapCenter={updateMapCenter} /> */}
      </div>
      <input
      type="text"
      name="latitude" // This should match the key in your state
      value={formState.latitude} // This binds the input value to the latitude in your state
      onChange={handleChange}
      placeholder="Latitude"
    />
      <input type="text" name="longitude" value={formState.longitude} onChange={handleChange} placeholder="Longitude" />
      <select
        name="addressType"
        value={formState.addressType}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Select Address Type</option>
        <option value="Pickup">Pickup</option>
        <option value="Delivery">Delivery</option>
      </select>
      <button type="submit">Save</button>
      <button type="button" onClick={onCancel}>Cancel</button>
    </form>
  );
};

export default AddEditAddressForm;



