import  firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/functions'; 
import 'firebase/messaging';



const firebaseConfig = {
    apiKey: "AIzaSyD8q2NkkRzydgefD5GbwH92vogyQDEIbFs",
    authDomain: "boameooo-portal.firebaseapp.com",
    projectId: "boameooo-portal",
    storageBucket: "boameooo-portal.appspot.com",
    messagingSenderId: "97631445029",
    appId: "1:97631445029:web:426da8e72be998852b5fcc"
  };


// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const projectAuth = firebase.auth()
const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const projectFunctions = firebase.functions(); 


//const timestamp2 = firebase.firestore.Timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export const { serverTimestamp } = firebase.firestore.FieldValue;
export const FieldValue = firebase.firestore.FieldValue;
export { projectStorage, projectFirestore, projectAuth, timestamp, googleProvider, projectFunctions };


  