import React, { useContext } from 'react';
import { projectFirestore, FieldValue } from '../../firebase/config';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Link } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';

import PurchaseSubscribe from '../AnPbooks/PurchaseSubscribe/PurchaseSubscribe';
import Book from './Book';
import styles from './BookList.module.css';

const BookList = ({ documents, renderItem }) => {
  const { user } = useAuthContext();
  const { addToCart } = useContext(CartContext);

  const addToLibrary = (book) => {
    const libraryRef = projectFirestore.collection('libraryBooks').doc(user.uid);
    const { id, bookName, bookAuthor, bookCoverUrl } = book;

    if (!id || !bookName || !bookAuthor || !bookCoverUrl) {
      alert('Cannot add incomplete book to library.');
      return;
    }

    libraryRef.set({
      books: FieldValue.arrayUnion(id),
      [id]: { bookName, bookAuthor, bookCoverUrl },
      accountUserId: user.uid,
    }, { merge: true }).then(() => {
      alert(`Added ${book.bookName} to your library!`);
    }).catch((error) => {
      console.error(error);
      alert(`Failed to add ${book.bookName} to your library.`);
    });
  };

  const handleRatingChange = (bookId, newRating) => {
    console.log('New rating:', newRating);
  
    const bookRef = projectFirestore.collection('anpbooks').doc(bookId);
  
    bookRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        bookRef.update({
          bookRating: newRating,
        })
        .then(() => {
          console.log('Rating updated successfully');
        })
        .catch((error) => {
          console.error('Error updating rating:', error);
        });
      } else {
        bookRef.set({
          bookRating: newRating,
        })
        .then(() => {
          console.log('Rating created successfully');
        })
        .catch((error) => {
          console.error('Error creating rating:', error);
        });
      }
    }).catch((error) => {
      console.error('Error checking document existence:', error);
    });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.pageTitle}>All Books</h2>
      <div className={styles.bookList}>
        {documents &&
          documents.map((book) => (
            <div key={book.id} className={styles.bookListItem}>
              <Link to={`/chapters/${book.id}`} className={styles.bookLink}>
                <div className={styles.bookImage}>
                  <img src={book.bookCoverUrl} alt={book.bookName} />
                </div>
                <h3 className={styles.bookName}>{book.bookName}</h3>
              </Link>
                
              <div className={styles.buttonContainer}>
                <button
                  className={styles.addButton}
                  onClick={() => {
                    console.log('Add to cart button clicked');
                    addToCart(book);
                  }}
                >
                  Add to Cart
                </button>

                <button
                  className={styles.addButton}
                  onClick={() => addToLibrary(book)}
                >
                  Add to Library
                </button>
              </div>

              <Book
                key={book.id}
                book={book}
                renderItem={renderItem}
                onRatingChange={(newRating) =>  handleRatingChange(book.id, newRating)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default BookList;



// import React, { useContext } from 'react';
// import { projectFirestore, timestamp, serverTimestamp, FieldValue } from '../../firebase/config';
// import { useAuthContext } from '../../hooks/useAuthContext';
// import { Link } from 'react-router-dom';
// import { CartContext } from '../../context/CartContext';
// import { useCountrySettings } from '../../context/CountrySettingsContext';
// import { africanCountries } from '../../constants/countries';

// import PurchaseSubscribe from '../AnPbooks/PurchaseSubscribe/PurchaseSubscribe';
// import Book from './Book';
// import styles from './BookList.module.css';


// const BookList = ({ documents, renderItem, selectedCountry, exchangeRates }) => {
//   const { user } = useAuthContext();
//   const { addToCart } = useContext(CartContext);
  
  
//   const calculateFormattedPrice = (price, fromCurrency, toCurrency, exchangeRates) => {
//     console.log("Selected Currency:", selectedCurrency);
//     console.log("From Currency:", fromCurrency);
//     console.log("Exchange Rates in BookList:", exchangeRates);
//     const exchangeRate =
//       exchangeRates &&
//       exchangeRates[fromCurrency] &&
//       exchangeRates[fromCurrency][toCurrency]
//         ? exchangeRates[fromCurrency][toCurrency]
//         : null;
  
//     const convertedPrice = exchangeRate ? price * exchangeRate : null;
//     return convertedPrice ? `${convertedPrice.toFixed(2)} ${toCurrency}` : "Exchange rate not available in BookList";
//   };
  
  
//   const selectedCurrency = selectedCountry && africanCountries.find(
//     (country) => country.code === selectedCountry.code
// )?.currency;

  

//   const addToLibrary = (book) => {
//     const libraryRef = projectFirestore.collection('libraryBooks').doc(user.uid);
//     const { id, bookName, bookAuthor, bookCoverUrl } = book;
    
//     if (!id || !bookName || !bookAuthor || !bookCoverUrl) {
//       alert('Cannot add incomplete book to library.');
//       return;
//     }
    
//     libraryRef.set({
//       books: FieldValue.arrayUnion(id),
//       [id]: { bookName, bookAuthor, bookCoverUrl },
//       accountUserId: user.uid,
//     }, { merge: true }).then(() => {
//       alert(`Added ${book.bookName} to your library!`);
//     }).catch((error) => {
//       console.error(error);
//       alert(`Failed to add ${book.bookName} to your library.`);
//     });
//   };
  

//   const handleRatingChange = (bookId, newRating) => {
//     console.log('New rating:', newRating);
  
//     // Get a reference to the book document
//     const bookRef = projectFirestore.collection('anpbooks').doc(bookId);
  
//     // Check if the document exists
//     bookRef.get().then((docSnapshot) => {
//       if (docSnapshot.exists) {
//         // Update the book rating in the Firestore
//         bookRef.update({
//           bookRating: newRating,
//         })
//         .then(() => {
//           console.log('Rating updated successfully');
//         })
//         .catch((error) => {
//           console.error('Error updating rating:', error);
//         });
//       } else {
//         // Create the book document with the initial rating
//         bookRef.set({
//           bookRating: newRating,
//         })
//         .then(() => {
//           console.log('Rating created successfully');
//         })
//         .catch((error) => {
//           console.error('Error creating rating:', error);
//         });
//       }
//     }).catch((error) => {
//       console.error('Error checking document existence:', error);
//     });
//   };



 
//   const handlePaymentSuccess = (transactionData) => {
//     // Handle successful payment, update relevant collections
//     console.log('Payment successful', transactionData);
//   };

//   return (
//     <div className={styles.container}>
//       <h2 className={styles.pageTitle}>All Books</h2>
//       <div className={styles.bookList}>
//         {documents &&
//           documents.map((book) => (
//             <div key={book.id} className={styles.bookListItem}>
//               <Link to={`/chapters/${book.id}`} className={styles.bookLink}>
//                 <div className={styles.bookImage}>
//                   <img src={book.bookCoverUrl} alt={book.bookName} />
//                 </div>
//                 <h3 className={styles.bookName}>{book.bookName}</h3>
//               </Link>
                
//                 <div className={styles.buttonContainer}>
//                   <button
//                     className={styles.addButton}
//                     onClick={() => {
//                       console.log('Add to cart button clicked');
//                       const displayPrice = calculateFormattedPrice(book.bookPrice, book.currency, selectedCurrency, exchangeRates);
//                       addToCart(book, selectedCurrency, displayPrice);
//                     }}
//                   >
//                     Add to Cart
//                   </button>

//                   <button
//                     className={styles.addButton}
//                     onClick={() => addToLibrary(book)}
//                   >
//                     Add to Library
//                   </button>
//                 </div>

//                 <Book
//                 key={book.id}
//                 book={book}
//                 renderItem={renderItem}
//                 currency={selectedCurrency}
//                 onRatingChange={(newRating) =>  handleRatingChange(book.id, newRating)}
//                 selectedCountry={selectedCountry}
//                 exchangeRates={exchangeRates}
//               />
//               </div>
//           ))}
//       </div>
//     </div>
//   );
// };

// export default BookList;



          