import React, { useState, useEffect } from 'react';
import GeneralLayout from '../Hoc/GeneralLayout/GeneralLayout';
import { useAuthContext } from '../../hooks/useAuthContext';
import styles from './AfroForm.module.css';

function AfroForm() {
    const { user } = useAuthContext(); // Get user from context

    // Initialize formData with user data if available
    const [formData, setFormData] = useState({
        fullName: user?.displayName || '',
        email: user?.email || '',
        phoneNumber: user?.phoneNumber || '',
        afrorole: '',
        experience: '',
        skills: '',
        professionalSite: ''
    });

    useEffect(() => {
        // Update formData if user data changes
        if (user) {
            setFormData({
                ...formData,
                fullName: user.displayName,
                email: user.email,
                phoneNumber: user.phoneNumber
            });
        }
    }, [user]);

    // Function to handle changes in form inputs
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Place form submission logic here
        console.log(formData);
    };

    return (
        <GeneralLayout>
        <form onSubmit={handleSubmit} className={styles.formContainer}>
        <h3>Afro Builders</h3>
            <label className={styles.formLabel}>
                Full Name:
                <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className={styles.formInput}
                />
            </label>
            <br />
            <label className={styles.formLabel}>
                Email:
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={styles.formInput}
                />
            </label>
            <br />
            <label className={styles.formLabel}>
                Phone Number:
                <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className={styles.formInput}
                />
            </label>
            <br />
            <label className={styles.formLabel}>
                Role:
                <select
                    name="afrorole"
                    value={formData.afrorole}
                    onChange={handleChange}
                    className={styles.formSelect}
                >
                    <option value="">Select a Role</option>
                    <option value="AfroPunks">AfroPunks</option>
                    <option value="African Guardian Council">African Guardian Council</option>
                </select>
            </label>
            <br />
            <label className={styles.formLabel}>
                Relevant Experience or Background:
                <textarea
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    className={styles.formTextarea}
                />
            </label>
            <br />
            <label className={styles.formLabel}>
                Specific Skills or Qualifications:
                <textarea
                    name="skills"
                    value={formData.skills}
                    onChange={handleChange}
                    className={styles.formTextarea}
                />
            </label>
            <br />
            <label className={styles.formLabel}>
                Professional Networking Site:
                <input
                    type="text"
                    name="professionalSite"
                    placeholder="Your LinkedIn profile address"
                    value={formData.professionalSite}
                    onChange={handleChange}
                    className={styles.formInput}
                />
            </label>
            <br />
            <button type="submit" className={styles.formButton}>Submit</button>
        </form>
        </GeneralLayout>
    );
}

export default AfroForm;
