import React from 'react';
import GeneralLayout from '../components/Hoc/GeneralLayout/GeneralLayout';
import WealthDisplay from './WealthValues/WeathDisplay';
import WeAreLookingFor from './WeAreLookingFor/WeAreLookingFor';
import styles from './Home.module.css';

export default function Home() {
  return (
    <GeneralLayout>
      <div className={styles.homeContainer}>
        <h1 className={styles.title}>Welcome to Boameooo Business</h1>

        {/* Introduction Paragraph */}
        <p className={styles.subtitle}>
          Boameooo platform's business strategy is centered on becoming a leading technology solution provider, 
          addressing issues specific to the Global African Siblinghood. We focus on markets of people of African 
          descent, diaspora, and nationals, underscoring the shared heritage, culture, and experiences that define 
          this diverse population. Our approach is grounded in the principles of Peomocracy, Generational Growth Model (GGM), 
          Afcomony, and Aftalism.
        </p>
        <hr />

        {/* Peomocracy Paragraph */}
        <section>
        <h3>Peomocracy and Boameooo</h3>
          <p className={styles.subtitle}>
            Peomocracy, central to Boameooo, operates on a contributory basis. Participation is voluntary and motivated 
            by the potential benefits for individuals, families, and communities. We concentrate on creating Value 
            Vehicles—ranging from businesses, infrastructures, and products to projects and knowledge-based educational 
            assets—that generate wealth for the afholders.
          </p>
        </section>
        <hr />

        {/* Generational Growth Fund Paragraph */}
        <section>
        <h3>Generational Growth Fund and Afholders</h3>
        <p className={styles.subtitle}>
          The Generational Growth Fund (GGF), integral to our Generational Growth Model (GGM), is funded by 25% of 
          revenue from Value Vehicles. Acting as a wealth reservoir, it benefits the collective constituents, the 
          peovernment, and the Afholders. Contrary to socialist models, the GGF represents a return on the efforts 
          of the wealth's creators and owners - the Afholders.
        </p>
        </section>
        <hr />

        {/* Afvestment and Aftalism Paragraph */}
        <section>
        <h3>Afvestment, Afconomy and Aftalism</h3>
        <p className={styles.subtitle}>
          At Boameooo, Afvestment signifies that every citizen has a stake in the afconomic future of Global Africa. 
          This ethos allows for equitable rewards within the Wealth Industry Complex. Contributions are made not in 
          monetary terms but through talent, skills, and professionalism. Our model ensures that 50% of returns go 
          to the contributors of add-ons, 25% to the GGF, and 25% for sustaining the Boameooo platform, fostering 
          sustainable growth and societal as well as afcietal benefits. Moreover, our practice of Aftalism is even 
          more focused than capitalism, as it centers solely on the creation of wealth and its retention by the Afholders.
          <br/><br/>
          To learn the details of Peomocracy, Generational Growth Model (GGM), Afcomony, Aftalism etc., read the book
          <a href="https://withkoji.com/@kedem00/u7fb" target="_blank" rel="noopener noreferrer"> African Nonism and Peomocracy</a>.
        </p>
        </section>


        <div className={styles.wealthDisplayContainer}>
          <WealthDisplay />
        </div>
        <WeAreLookingFor />
        {/* <SubmitQuote /> */}
      </div>
    </GeneralLayout>
  );
}
