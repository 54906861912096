import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import { useCountrySettings } from '../../../context/CountrySettingsContext';
import { africanCountries } from '../../../constants/countries';

const GlobalAfricaExchangeLayout = ({ children }) => {
  const { selectedCountry, setSelectedCountry } = useCountrySettings();
  const selectedCurrency = africanCountries.find(
    (country) => country.code === selectedCountry
  )?.currency;

  const menuItems = [
    { name: 'Home', link: '/' },
    { name: 'Export Marketplace', link: '/export-marketplace', dropdown: [
      { name: 'Agriculture', link: '/export/agriculture' },
      { name: 'Textiles', link: '/export/textiles' },
      // Additional export categories...
    ]},
    { name: 'Import Requests', link: '/import-requests', dropdown: [
      { name: 'Electronics', link: '/import/electronics' },
      { name: 'Machinery', link: '/import/machinery' },
      // Additional import categories...
    ]},
    { name: 'Trade Insights', link: '/trade-insights', dropdown: [
      { name: 'Market Trends', link: '/trends' },
      { name: 'Trade Regulations', link: '/regulations' },
      // Additional insights options...
    ]},
    { name: 'Logistics', link: '/logistics', dropdown: [
      { name: 'Shipping Partners', link: '/shipping-partners' },
      { name: 'Customs & Clearance', link: '/customs-clearance' },
      // Additional logistics options...
    ]},
    { name: 'Support', link: '/support', dropdown: [
      { name: 'FAQs', link: '/faqs' },
      { name: 'Contact Us', link: '/contact' },
      // Additional support options...
    ]}
    // Other primary categories as needed
  ];

  const serviceColor = 'green'; // Or any color that represents AfriConnect Exchange

  return (
    <>
      <Navbar 
        serviceName="GlobalAfricaExchange" 
        menuItems={menuItems} 
        serviceColor={serviceColor} 
        selectedCountry={selectedCountry}
        onCountryChange={setSelectedCountry} />

      <main>
        {React.Children.map(children, (child) =>
          child ? React.cloneElement(child, { currency: selectedCurrency }) : null
        )}
      </main>
      <Footer />
    </>
  );
};

export default GlobalAfricaExchangeLayout;
