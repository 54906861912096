import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import voteIcon from '../../../../assets/voteIcon.svg';
import commentIcon from '../../../../assets/commentIcon.svg'
import ProposalLayout from '../../../../components/Hoc/ProposalLayout/ProposalLayout';
import ProposalHeader from '../../components/ProposalHeader/ProposalHeader';
import ProposalFooter from '../../components/ProposalFooter/ProposalFooter';
import DOMPurify from 'dompurify';

import { projectFirestore } from '../../../../firebase/config';
import styles from './ProposalDetails.module.css';

const ProposalDetails = () => {
    const { user } = useAuthContext();
    const { id } = useParams();
    const [proposal, setProposal] = useState(null);
    const [comments, setComments] = useState([]); // State to hold comments
    const [votes, setVotes] = useState({ support: 0, notSure: 0, doNotSupport: 0 }); // Add this line
    // const totalVotes = votes.support + votes.notSure + votes.doNotSupport;
    const [percentages, setPercentages] = useState({ support: 0, notSure: 0, doNotSupport: 0 });
    const [newComment, setNewComment] = useState(''); // State to handle new comment input
    const [showFullCommentId, setShowFullCommentId] = useState(null);
    // const sanitizedDescription = DOMPurify.sanitize(proposal.description);
    const sanitizedDescription = proposal ? DOMPurify.sanitize(proposal.description) : '';

    useEffect(() => {
      // Calculate total votes
      const totalVotes = votes.support + votes.notSure + votes.doNotSupport;
      
      // Calculate percentages
      const supportPercentage = totalVotes ? (votes.support / totalVotes * 100).toFixed(2) : 0;
      const notSurePercentage = totalVotes ? (votes.notSure / totalVotes * 100).toFixed(2) : 0;
      const doNotSupportPercentage = totalVotes ? (votes.doNotSupport / totalVotes * 100).toFixed(2) : 0;
  
      // Update state
      setPercentages({
          support: supportPercentage,
          notSure: notSurePercentage,
          doNotSupport: doNotSupportPercentage
      });
  }, [votes]);



    function truncateText(text, lineCount) {
      if (!text) return ''; // Return an empty string if text is undefined or falsy
    
      const lines = text.split('\n');
      if (lines.length <= lineCount) {
        return text;
      }
      return lines.slice(0, lineCount).join('\n') + '...';
    }
    

    const handleCommentClick = (id) => {
      // If the clicked comment is already open, close it, otherwise open the clicked comment
      setShowFullCommentId(showFullCommentId === id ? null : id);
    };

    const handleAddComment = async () => {
      if (!newComment.trim()) return; // Avoid adding empty comments
      const commentToAdd = {
        text: newComment,
        author: user ? user.displayName : 'Anonymous',
        date: new Date().toLocaleDateString("en-GB") // Replace with a proper date if needed
      };
      try {
        await projectFirestore.collection('proposals').doc(id).update({
          comments: [...comments, commentToAdd] // Append the new comment to the existing array
        });
        setComments([...comments, commentToAdd]); // Update the local state
        setNewComment(''); // Clear the input after submitting
      } catch (error) {
        console.error("Error adding comment: ", error);
      }
    };



    useEffect(() => {
      const unsubscribe = projectFirestore.collection('proposals')
          .doc(id)
          .onSnapshot(doc => {
              if (doc.exists) {
                  const data = doc.data();
                  setProposal({ id: doc.id, ...data });
                  setComments(data.comments || []); // Set the comments state
                  setVotes(data.votes || { support: 0, notSure: 0, doNotSupport: 0 }); // Set the votes state
              } else {
                  console.log("No such proposal!");
              }
          }, error => {
              console.error("Error fetching proposal details: ", error);
          });
    
      return () => unsubscribe();
    }, [id]);useEffect(() => {
  const unsubscribe = projectFirestore.collection('proposals')
      .doc(id)
      .onSnapshot(doc => {
          if (doc.exists) {
              const data = doc.data();
              setProposal({ id: doc.id, ...data });
              setComments(data.comments || []); // Set the comments state
          } else {
              console.log("No such proposal!");
          }
      }, error => {
          console.error("Error fetching proposal details: ", error);
      });

  return () => unsubscribe();
}, [id]);

    if (!proposal) {
        return <div>Loading...</div>; 
    }
    return (
      <ProposalLayout>
        <div className={styles.proposalDetails}>
          {/* <ProposalHeader /> */}
          <div className={styles.mainContent}>
            <div className={styles.leftColumn}>
              <h3 className={styles.commentsTitle}>{proposal.title}</h3>
              <div>
                    <div className={styles.details}>
                      <span className={styles.author}>By {proposal.author}</span>
                      <span className={styles.date}>at {proposal.datePosted}</span>
                    </div>
              <div className={styles.proposalDetailsSection}>
                <hr className={styles.proposalDivider} />
                <div className={styles.detailsAndLocationContainer}>
                  
                <div className={styles.locationContainer}>
                  <div className={styles.locationSegment}>
                    <div className={styles.locationLabel}>Country</div>
                    <span>{proposal.country}</span>
                  </div>
                  <div className={styles.locationSegment}>
                    <div className={styles.locationLabel}>Region</div>
                    <span>{proposal.region}</span>
                  </div>
                  <div className={styles.locationSegment}>
                    <div className={styles.locationLabel}>City/Town/Village</div>
                    <span>{proposal.city}</span>
                  </div>
                  <div className={styles.locationSegment}>
                    <div className={styles.locationLabel}>Zip/Post Code</div>
                    <span>{proposal.zipCode}</span>
                  </div>
                </div>
                <div className={styles.voteAndComments}>
                <img src={voteIcon} alt="Vote" className={styles.voteIcon} />
                <span>Vote</span>
                <div className={styles.voteCounts}>
                    <span className={styles.votePercentage}>Support: {percentages.support}%</span>
                    <span className={styles.votePercentage}>Not Sure: {percentages.notSure}%</span>
                    <span className={styles.votePercentage}>Do Not Support: {percentages.doNotSupport}%</span>
                </div>
                    <img src={commentIcon} alt="Comments" />
                    <span>{comments.length} Comments</span> {/* Dynamic comments count */}
                  </div>
                </div>
                  
                </div>
                <hr className={styles.proposalDivider} />
              </div>


              {/* <p className={styles.description}>{proposal.description}</p> */}
              <div className={styles.description} dangerouslySetInnerHTML={{ __html: sanitizedDescription }}></div>

             
              <div className={styles.attachmentsSection}>
                <hr className={styles.divider} />
                <div className={styles.attachmentsHeader}>
                  <strong>{proposal.attachments?.length || 0} Attachments</strong>
                </div>
                <div className={styles.attachmentsList}>
                  {proposal.attachments && proposal.attachments.map((url, index) => (
                    <div key={index} className={styles.attachmentItem}>
                      <a href={url} target="_blank" rel="noopener noreferrer" className={styles.attachmentLink}>
                        <img src={url} alt={`Attachment ${index + 1}`} className={styles.attachmentImage} />
                        <div className={styles.attachmentName}>{`Attachment ${index + 1}`}</div>
                      </a>
                    </div>
                  ))}
                </div>
              </div> 
            </div>
            <div className={styles.rightColumn}>
                    {/* Display Comments */}
                    <div className={styles.commentsHeader}>
                        <strong>{proposal.comments?.length || 0} Comments</strong>
                    </div>
                    <hr className={styles.commentsDivider} />
                    {proposal.comments && proposal.comments.map((comment, index) => (
                      <div key={index} className={styles.comment}>
                        <div className={styles.commentHeader}>
                          <span className={styles.commentAuthor}>{comment.author}</span>
                          <span className={styles.commentDate}>{comment.date}</span>
                        </div>
                        <p 
                          className={styles.commentText} 
                          onClick={() => handleCommentClick(index)}
                        >
                          {showFullCommentId === index ? comment.text : truncateText(comment.text || '', 5)}
                        </p>

                      </div>
                    ))}
                    <div className={styles.addCommentContainer}>
                        <textarea 
                            className={styles.commentInput} 
                            placeholder="Write a comment..." 
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <button 
                            className={styles.addCommentButton} 
                            onClick={handleAddComment}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
            {/* <ProposalFooter /> */}
        </div>
        </ProposalLayout>
    );
};

export default ProposalDetails;
    

