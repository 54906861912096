import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../../firebase/config';

import styles from './AgentList.module.css';

const AgentList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    const unsubscribe = projectFirestore
      .collection('agents')
      .orderBy('name')
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setAgents(data);
      });

    return unsubscribe;
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAgents = agents.filter((agent) =>
    agent.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h2>Partner Agents</h2>
      <input
        type="text"
        placeholder="Search for an agent"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <ul>
        {filteredAgents.map((agent) => (
          <li key={agent.id} className={styles.agentCard}>
            <img src={agent.logoImage} alt={`${agent.name} logo`} className={styles.agentLogo} />
            <div className={styles.agentDetails}>

              <h3 className={styles.agentName}>{agent.name}</h3>
              <p className={styles.agentLocation}>{agent.reviewsAverageScore}</p>
              <p className={styles.agentLocation}>{agent.numberOfEmployees}</p>
              <p className={styles.agentLocation}>{agent.location}</p>
              <p className={styles.agentLocation}>{agent.country}</p>
              <p className={styles.agentServices}>{agent.services}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AgentList;
