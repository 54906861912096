// RescheduleDelivery.js
import React, { useState } from 'react';
import { projectFirestore } from '../../../firebase/config';
import styles from './RescheduleDelivery.module.css'; // Import the CSS module

const RescheduleDelivery = ({ orderId }) => {
  const [newDate, setNewDate] = useState('');
  const [newTime, setNewTime] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await projectFirestore.collection('deliveryorders').doc(orderId).update({
        rescheduledDate: newDate,
        rescheduledTime: newTime
      });

      alert('Delivery date and time rescheduled successfully.');
      setNewDate('');
      setNewTime('');
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.rescheduleContainer}>
      <h3 className={styles.title}>Reschedule Delivery</h3>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="date"
          className={styles.dateInput}
          value={newDate}
          onChange={(e) => setNewDate(e.target.value)}
          required
        />
        <input
          type="time"
          className={styles.timeInput}
          value={newTime}
          onChange={(e) => setNewTime(e.target.value)}
          required
        />
        <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
          {isSubmitting ? 'Rescheduling...' : 'Reschedule'}
        </button>
      </form>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default RescheduleDelivery;
