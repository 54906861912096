import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import { AuthContext } from '../../../context/AuthContext';

const GeneralLayout = ({ children }) => {
  const { user, logout } = useContext(AuthContext);
  const handleLogout = () => {
    logout();
    // Additional logout logic if needed
};
    const menuItems = [
      { name: 'Home', link: '/' },
      { name: 'Dashboard', link: '/dashboard' },
      { name: 'About', link: '/about-us' },
      // { name: 'Service', link: '/services' },
      // { name: 'Tracking', link: '/tracking' },
      { name: 'Contact', link: '/contact' },
      // { name: 'Feedback', link: '/feedback' },
        
      ];
      const authItems = user ? [
        { name: 'Logout', action: handleLogout },
    ] : [
        { name: 'Login', link: '/login' },
    ];

    const boameColor = 'green';
    return (
      <>
        {/* <Navbar serviceName=" " menuItems={menuItems} boameColor={boameColor} /> */}
        <Navbar serviceName=" " menuItems={menuItems} authItems={authItems} boameColor={boameColor} />
        <main>{children}</main>
        <Footer />
      </>
    );
  };
  
  export default GeneralLayout;
