// import React, { useState, useEffect } from 'react';
// import { projectFirestore } from '../../firebase/config';
// import styles from './EarningsReport.module.css'; // Ensure you have the corresponding CSS file

// const EarningsReport = () => {
//     const [earningsData, setEarningsData] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);

//     useEffect(() => {
//         const fetchEarningsData = async () => {
//             setIsLoading(true);
//             try {
//                 const snapshot = await projectFirestore.collection('earningsReports').get();
//                 const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//                 setEarningsData(data);
//             } catch (error) {
//                 console.error('Error fetching earnings data:', error);
//             }
//             setIsLoading(false);
//         };

//         fetchEarningsData();
//     }, []);

//     if (isLoading) {
//         return <div>Loading earnings data...</div>;
//     }

//     return (
//         <div className={styles.earningsContainer}>
//             <h2>Earnings Report</h2>
//             <p>Detailed Earnings Report of each Delivery partner will be listed here</p>
//             <ul className={styles.earningsList}>
//                 {earningsData.map(item => (
//                     <li key={item.id} className={styles.earningsItem}>
//                         <div>
//                             <p><strong>Date:</strong> {item.date}</p>
//                             <p><strong>Total Earnings:</strong> ${item.totalEarnings}</p>
//                             <p><strong>Total Deliveries:</strong> {item.totalDeliveries}</p>
//                             <p><strong>Average Earnings per Delivery:</strong> ${item.averageEarningsPerDelivery}</p>
//                             {/* Add other financial statistics as needed */}
//                         </div>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default EarningsReport;


import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import styles from './EarningsReport.module.css';

const EarningsReport = () => {
    const [earningsData, setEarningsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEarningsData = async () => {
            setIsLoading(true);
            try {
                const snapshot = await projectFirestore.collection('deliveryPartnerEarnings').get();
                const data = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    return { 
                        id: doc.id, 
                        date: docData.recordedAt.toDate().toLocaleDateString(), // Formatting the date
                        earnings: docData.earnings,
                        orderId: docData.orderId
                    };
                });
                setEarningsData(data);
            } catch (error) {
                console.error('Error fetching earnings data:', error);
            }
            setIsLoading(false);
        };

        fetchEarningsData();
    }, []);

    if (isLoading) {
        return <div>Loading earnings data...</div>;
    }

    return (
        <div className={styles.earningsContainer}>
            <h2>Earnings Report</h2>
            <p>Detailed Earnings Report of each Delivery partner will be listed here</p>
            <ul className={styles.earningsList}>
                {earningsData.map(item => (
                    <li key={item.id} className={styles.earningsItem}>
                        <div>
                            <p><strong>Date:</strong> {item.date}</p>
                            <p><strong>Order ID:</strong> {item.orderId}</p>
                            <p><strong>Total Earnings:</strong> ${item.earnings.toFixed(2)}</p>
                            {/* Add other financial statistics as needed */}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EarningsReport;
