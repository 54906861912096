import React from 'react';
import GeneralLayout from '../components/Hoc/GeneralLayout/GeneralLayout';
import styles from './About.module.css';

export default function About() {
  return (
    <GeneralLayout>
    <div className={styles.aboutContainer}>
      <h2>About Boameooo</h2>
      <p>Boameooo is a pioneering technology platform dedicated to serving the Global African Siblinghood, encompassing people of African descent, diaspora, and nationals. Our business strategy is to become a leading technology solutions provider, focusing on markets deeply rooted in shared African heritage, culture, and experiences. At the heart of our approach are the principles of Peomocracy, Generational Growth Model (GGM), Afcomony, and Aftalism.</p>

      <section>
        <h3>Peomocracy and Value Vehicles</h3>
        <p>Central to our ethos, Peomocracy operates on a contributory basis, encouraging voluntary participation with the aim of benefiting individuals, families, and communities. We are committed to creating Value Vehicles, ranging from businesses and infrastructures to educational assets. These vehicles are designed to generate wealth for our afholders, ensuring that participation in Boameooo yields tangible benefits.</p>
      </section>

      <section>
        <h3>Generational Growth Fund (GGF) and GGM</h3>
        <p>An essential aspect of our Generational Growth Model is the Generational Growth Fund (GGF), sustained by 25% of revenue from our Value Vehicles. This fund acts as a reservoir of wealth, benefiting not just the collective constituents but also the peovernment and the Afholders. It symbolizes a return on investment for the creators and owners of this wealth, distinguishing itself from traditional socialist models.</p>
      </section>

      <section>
        <h3>Afvestment and Wealth Industry Complex</h3>
        <p>At Boameooo, Afvestment means that every citizen is a stakeholder in the afconomic future of Global Africa. Our model emphasizes equitable rewards within the Wealth Industry Complex, where contributions are made through talent, skills, and professionalism rather than monetary terms. We ensure that 50% of returns are allocated to contributors of add-ons, 25% to the GGF, and the remaining 25% for sustaining the Boameooo platform. This structure fosters sustainable growth and benefits both society and afcietal levels.</p>
      </section>

      <section>
        <h3>Aftalism and African Nonism & Peomocracy</h3>
        <p>Our practice of Aftalism focuses on wealth creation and its retention by the Afholders, going beyond traditional capitalism. It aligns with our vision of African Nonism & Peomocracy, a unique governance philosophy designed specifically for the African context. The name Boameooo, derived from the Ghanaian Twi language, means 'assist me,' epitomizing our commitment to providing tailored software services. We aim to build a community where African values and innovative solutions merge, contributing to a prosperous and sustainable future for Africans worldwide.</p>
      </section>

      <p>To explore more about Peomocracy, GGM, Afcomony, Aftalism, and other aspects of our model, we invite you to read "African Nonism and Peomocracy," a detailed exposition of our philosophies and methodologies. Join us on this journey to redefine African innovation and community empowerment.</p>
    </div>
    </GeneralLayout>
  );
}