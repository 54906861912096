import React, { useState } from 'react';
import UploadForm from "../../pages/Pictures/components/uploadform/UploadForm";
import ImageGrid from '../../pages/Pictures/components/imagegrid/ImageGrid';
import Modal from '../../pages/Pictures/components/modal/Modal';
import Title from '../../pages/Pictures/components/title/Title';

import './Pictures.css'

export default function Pictures() {
    const [selectedImg, setSelectedImg] = useState(null);
    return (
      <div>
        <Title/>
        <UploadForm />
      <ImageGrid setSelectedImg={setSelectedImg} />
      { selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
          </div>
    )
  }