import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import { useCountrySettings } from '../../../context/CountrySettingsContext';
import { africanCountries } from '../../../constants/countries';

const WorksAndJobsLayout = ({ children }) => {
  const { selectedCountry, setSelectedCountry } = useCountrySettings();
  const selectedCurrency = africanCountries.find(
    (country) => country.code === selectedCountry
  )?.currency;


  const menuItems = [
    { name: 'Home', link: '/' },
    { name: 'Find Jobs', link: '/jobs', dropdown: [
      { name: 'Local Services', link: '/local-services' },
      { name: 'Professional Careers', link: '/professional-careers' },
      { name: 'Internships', link: '/internships' },
      { name: 'Short-Term Gigs', link: '/short-term-gigs' },
      { name: 'Remote Opportunities', link: '/remote-jobs' }
    ]},
    { name: 'Career Development', link: '/career-development', dropdown: [
      { name: 'Career Resources', link: '/career-resources' },
      { name: 'Training and Workshops', link: '/training-workshops' },
      { name: 'Market Trends', link: '/market-trends' }
    ]},
    { name: 'Community', link: '/community', dropdown: [
      { name: 'Community Forum', link: '/forum' },
      { name: 'Event Calendar', link: '/events' },
      { name: 'Blog', link: '/blog' }
    ]},
    { name: 'For Employers', link: '/employers', dropdown: [
      { name: 'Post a Job', link: '/post-job' },
      { name: 'Employer Services', link: '/employer-services' },
      { name: 'Legal Advice', link: '/legal-advice' }
    ]}
    // Other primary categories as needed
  ];
  

  const serviceColor = 'blue'; 

  return (
    <>
      <Navbar 
        serviceName="WorksAndJobs" 
        menuItems={menuItems} 
        serviceColor={serviceColor} 
        selectedCountry={selectedCountry}
        onCountryChange={setSelectedCountry} />

      <main>
        {React.Children.map(children, (child) =>
          child ? React.cloneElement(child, { currency: selectedCurrency }) : null
        )}
      </main> 
      <Footer />
    </>
  );
};

export default WorksAndJobsLayout;


